<template>
    <div>
        <Navbar />
        <div class="setting-main-wrapper">
            <div class="main-left-menu">
                <LeftMenu />
            </div>
            <Banner />

            <div class="shop-side p-md-0">
                <LeftMenuSide :openTabSet="openTabSet" />
                <div class="row no-gutters content-wrap-info">
                    <div class="event-top-sec">
                        <div class="top-sec-left">
                            <div class="sec-left-inner">
                                <div class="inner-date">
                                    Fri Mar 8 to Sun Mar 10, 2024 (Eastern Time)
                                </div>
                                <div class="inner-name">
                                    March Madness II Championship of the World
                                </div>
                            </div>
                            <div class="sec-left-bottom">
                                <div class="bottom-asso">
                                    SSUSA - Tournament
                                </div>
                                <div class="bottom-team">
                                    Dudley Lightening 60 Major+
                                </div>
                            </div>
                        </div>
                        <div class="top-sec-right">
                            <div class="sec-right-inner">
                                <button>
                                    <img :src="printer" alt="roster" />
                                    <span>Roster</span>
                                </button>
                                <button>
                                    <img :src="printer" alt="travel" />
                                    <span>Travel</span>
                                </button>
                                <button>
                                    <img :src="ellipse" alt="action" />
                                </button>
                            </div>
                            <div class="sec-right-bottom">
                                <div class="play-info">
                                    <div class="game-play">
                                        <span>Games</span>
                                        <span>0</span>
                                    </div>
                                    <div class="game-win">
                                        <span>Won</span>
                                        <span>0</span>
                                    </div>
                                    <div class="game-loss">
                                        <span>Lost</span>
                                        <span>0</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="event-sec-content">
                        <div class="container-fluid p-0 px-xl-4 my-3">
                            <div class="row no-gutters">
                                <div class="col-12 col-xl-8">
                                    <div class="sec-content-left">
                                        <div class="content-left-top">
                                            <div class="btn-container">
                                                <button class="btn-1">All</button>
                                                <button class="btn-1 active">Ongoing</button>
                                                <button class="btn-1">Final</button>
                                            </div>
                                            <div class="add-game-btn">
                                                <button>Add Game</button>
                                            </div>
                                        </div>
                                        <div class="game-date-time">
                                            Fri, April 19, 2024
                                        </div>
                                        <div class="row w-100 mx-0">
                                            <div class="col-12 col-md-6 col-xl-4 pl-0 content-left-col">
                                                <div class="info-sec-content">
                                                    <div class="live-inner-top">
                                                        <div class="live-inner-left">
                                                            <div class="delayed-tag">
                                                                <span>Delayed</span>
                                                            </div>
                                                            <span class="delayed-tag live-tag">Live</span>
                                                            <img :src="video" alt="video" />
                                                            <span class="pool-text">Pool 1</span>
                                                        </div>
                                                        <div class="live-count-option">
                                                            <div class=live-count>3</div>
                                                            <div class="small-show-options">
                                                                <img :src="ellipse" alt="ellipse" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="live-center-info">
                                                        <span class="live-time">12:30 PM</span>
                                                        <div class="live-teams">
                                                            <div class="live-teams-info">
                                                                <div class="team-info-sec">
                                                                    <img :src="team_image" alt="team-image" />
                                                                    <span class="name-info">#1: Vegas Boyz</span>
                                                                </div>
                                                                <div class="team-info-sec">
                                                                    <img :src="team_image" alt="team-image" />
                                                                    <span class="name-info">#4: Dudley 60</span>
                                                                </div>
                                                            </div>
                                                            <div class="live-teams-scores">
                                                                <div class="score-info-sec">
                                                                    <span>0</span>
                                                                    <span>0</span>
                                                                </div>
                                                                <img :src="arrow_left" alt="arrow-left" />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div class="live-ground-sec">
                                                                <img :src="loc" alt="loc" />
                                                                <p>Homefield Baseball Complex - Field 1</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 col-xl-4 pl-0 content-left-col">
                                                <div class="info-sec-content">
                                                    <div class="live-inner-top">
                                                        <div class="live-inner-left">
                                                            <div class="delayed-tag">
                                                                <span>Delayed</span>
                                                            </div>
                                                            <span class="delayed-tag live-tag">Live</span>
                                                            <img :src="video" alt="video" />
                                                            <span class="pool-text">Pool 1</span>
                                                        </div>
                                                        <div class="live-count-option">
                                                            <div class=live-count>3</div>
                                                            <div class="small-show-options">
                                                                <img :src="ellipse" alt="ellipse" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="live-center-info">
                                                        <span class="live-time">12:30 PM</span>
                                                        <div class="live-teams">
                                                            <div class="live-teams-info">
                                                                <div class="team-info-sec">
                                                                    <img :src="team_image" alt="team-image" />
                                                                    <span class="name-info">#1: Vegas Boyz</span>
                                                                </div>
                                                                <div class="team-info-sec">
                                                                    <img :src="team_image" alt="team-image" />
                                                                    <span class="name-info">#4: Dudley 60</span>
                                                                </div>
                                                            </div>
                                                            <div class="live-teams-scores">
                                                                <div class="score-info-sec">
                                                                    <span>0</span>
                                                                    <span>0</span>
                                                                </div>
                                                                <img :src="arrow_left" alt="arrow-left" />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div class="live-ground-sec">
                                                                <img :src="loc" alt="loc" />
                                                                <p>Homefield Baseball Complex - Field 1</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-xl-4 pl-xl-3">
                                    <div class="sec-content-right mb-3 mt-3 mt-xl-0">
                                        <div class="sec-right-top">
                                            <h6>Location</h6>
                                            <div class="location-wrap">
                                                <img :src="location" alt="location">
                                                <p>200 E Wells St, Milwaukee City Hall</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="event-attendee-sec">
                                        <div class="attendee-sec-top">
                                            <h6>Are you going ?</h6>
                                            <div class="attendee-btns">
                                                <button type="button" class="active">
                                                    <span>Going</span>
                                                </button>
                                                <button type="button">
                                                    <span>Not Going</span>
                                                </button>
                                                <button type="button">
                                                    <span>Maybe</span>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="rsvp-container">
                                            <!-- Status Tabs -->
                                            <div class="status-tabs">
                                                <div class="tab-item" :class="{ active: true }">
                                                    <span>Going</span>
                                                    <span class="counter-attendee">1</span>
                                                </div>
                                                <div class="tab-item">
                                                    <span>Not Going</span>
                                                    <span class="counter-attendee">1</span>
                                                </div>
                                                <div class="tab-item">
                                                    <span>Maybe</span>
                                                    <span class="counter-attendee">10</span>
                                                </div>
                                                <div class="tab-item">
                                                    <span>Not Responded</span>
                                                    <span class="counter-attendee">2</span>
                                                </div>
                                            </div>

                                            <!-- People List -->
                                            <div class="people-list">
                                                <div v-for="person in people" :key="person.id" class="person-item">
                                                    <div class="attendee-people-info">
                                                        <div class="people-info-img">
                                                            <img :src="member" alt="member" />
                                                        </div>
                                                        <span class="person-name">{{ person.name }}</span>
                                                    </div>
                                                    <div>
                                                        <div class="show-options" @click="openMemOption(person)">
                                                            <img :src="ellipse" alt="ellipse" />
                                                        </div>

                                                        <div class="sub-setting-options" v-if="person.optionsVisible">
                                                            <ul>
                                                                <li>Edit Attendee</li>
                                                                <li>Edit Travel Details</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import Navbar from "@/components/Header/Navbar.vue";
import Banner from "@/components/BannerNotify/BannerPage.vue";
import LeftMenu from "@/components/Home/LeftMenu.vue";
import LeftMenuSide from "@/components/NewGameTime/LeftMenuSide.vue";

export default {
    name: "MyEventDetails",
    components: {
        Navbar,
        Banner,
        LeftMenu,
        LeftMenuSide
    },
    data() {
        return {
            openTabSet: 1,
            selectedEvent: {},
            gameList: [],
            showGameShimmer: 0,
            close: require("../../../assets/Svg/close.svg"),
            loc: require("../../../assets/Svg/location.svg"),
            location: require("../../../assets/Svg/location.svg"),
            member: require("../../../assets/Svg/member.svg"),
            ellipse: require("../../../assets/Svg/ellipse.svg"),
            printer: require("../../../assets/Svg/printer.svg"),
            video: require("../../../assets/Svg/video.svg"),
            team_image: require("../../../assets/Svg/people.svg"),
            arrow_left: require("../../../assets/Svg/arrow-left.svg"),
            people_outlined: require("../../../assets/Svg/people-outlined.svg"),
            people: [
                { id: 1, name: 'David Melton (Dave)', status: 'going', optionsVisible: false },
                { id: 2, name: 'Bruce Whitesides', status: 'not-going', optionsVisible: false },
                { id: 3, name: 'Murray Wortman', status: 'maybe', optionsVisible: false },
                { id: 4, name: 'Paul Ohlen', status: 'not-responded', optionsVisible: false },
                { id: 5, name: 'Kenneth Graham', status: 'not-responded', optionsVisible: false }
            ],
        }
    },

    methods: {
        openMemOption(person) {
            this.people.forEach(p => {
                if (p.id === person.id) {
                    p.optionsVisible = !p.optionsVisible;
                } else {
                    p.optionsVisible = false; // Close others
                }
            });
        },

        onClickOutside() {
            this.memberOptions = false;
        },
        formatEventDate(startDate, endDate) {
            const options = { month: "long", day: "numeric" };
            const start = new Date(startDate).toLocaleDateString("en-US", options);
            const end = new Date(endDate).toLocaleDateString("en-US", options);
            const year = new Date(endDate).getFullYear();
            return `${start} to ${end}, ${year}`;
        },
        // Remove "UTC" from time zone string
        formatTimeZone(timeZone) {
            if (!timeZone) {
                return ""; // Return an empty string or a default value if timeZone is undefined or null
            }
            return timeZone.replace(/\(UTC[^)]+\)\s*/, "").trim(); // Removes text like "(UTC-06:00)"
        },
        async getGames(event) {
            this.showGameShimmer = 1;
            this.gameList = [];
            var formData = new FormData();
            formData.append("event_id", event.id);
            formData.append("team_id", event.team_id);
            await axios
                .post(process.env.VUE_APP_API_URL + "game/gameListing", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((response) => {
                    this.gameList = response.data.data;
                    event.showGameList = true;
                    this.showGameShimmer = 0;
                });
        },
    },

    mounted() {
        this.$root.$on("openMyEventDetailsPopup", (event) => {
            var elem = this.$refs.openMyEventDetails;
            if (elem) {
                elem.click();
            }
            this.selectedEvent = event;
            this.getGames(event);
        });
    },
};
</script>