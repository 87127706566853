var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('GameListModal',{attrs:{"selectedRoomTeam":_vm.selectedRoomTeam,"singleGame":_vm.singleGame}}),_c('div',{staticClass:"box-score-top-container"},[_c('div',{staticClass:"left-section flex-row"},[_c('div',{staticClass:"switch-game cursor-pointer",on:{"click":_vm.openGameModal}},[_c('span',{staticClass:"content"},[_vm._v("Switch Game")]),_c('h6',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.singleGame.name))])]),(_vm.scores.length == 0)?_c('div',{staticClass:"opponent-data"},[_c('h6',[_vm._v(" "+_vm._s(_vm.singleGame.opponent_name)+" ")]),_c('div',{staticClass:"custom-avatar",style:({ backgroundColor: _vm.getRandomColor() })},[_vm._v(" "+_vm._s(_vm.getInitials(_vm.singleGame.opponent_name))+" ")]),_c('span',[_vm._v("-")])]):_vm._e(),(_vm.scores.length != 0)?_c('div',[(
                    _vm.scores?.some((team) => team.team_type === 1)
                )?_c('div',{staticClass:"opponent-name"},[_c('h6',[_vm._v(" "+_vm._s(_vm.scores?.find( (team) => team.team_type === 1 )?.team_name)+" ")]),(
                        _vm.scores?.find(
                            (team) =>
                                team.team_type === 1 &&
                                team.team_name !== _vm.selectedRoomTeam.teamName
                        )
                    )?_c('div',{staticClass:"custom-avatar",style:({ backgroundColor: _vm.getRandomColor() })},[_vm._v(" "+_vm._s(_vm.getInitials( _vm.scores?.find( (team) => team.team_type === 1 )?.team_name ))+" ")]):_c('div',{staticClass:"team-image"},[(_vm.imgLoader == 1)?_c('div',{staticClass:"users-notification"},[_c('img',{attrs:{"src":require("../../assets/loader.gif"),"alt":""}})]):_vm._e(),(_vm.selectedRoomTeam.teamAvatar == '')?_c('img',{attrs:{"src":_vm.team_image,"alt":""},on:{"load":_vm.onImageLoad}}):_vm._e(),(
                            _vm.selectedRoomTeam.teamAvatar != '' &&
                            _vm.scores?.find(
                                (team) =>
                                    team.team_name === _vm.selectedRoomTeam.teamName
                            )
                        )?_c('img',{attrs:{"src":_vm.url + _vm.selectedRoomTeam.teamAvatar,"alt":""},on:{"load":_vm.onImageLoad}}):_vm._e()]),_c('span',[_vm._v(_vm._s(_vm.receivedScores.visitScore))]),(
                        _vm.singleGame.status === 1 &&
                        _vm.scores &&
                        _vm.scores?.find(
                            (team) =>

                                team.team_type == 1 &&
                                team.batting_flag == 1
                        )
                    )?_c('img',{attrs:{"src":_vm.arrow_left,"alt":"arrow-left"}}):_vm._e(),(_vm.singleGame.status === 2 && _vm.singleGame.win_status === 1 && _vm.scores[0].team_type == 1)?_c('img',{attrs:{"src":_vm.cup,"alt":"cup"}}):_vm._e(),(_vm.singleGame.status === 2 && _vm.singleGame.win_status === 2 && _vm.scores[0].team_type == 2)?_c('img',{attrs:{"src":_vm.cup,"alt":"cup"}}):_vm._e()]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"center-content"},[_c('span',[_vm._v(_vm._s(_vm._f("formatTimeToAM")(_vm.singleGame.start_time)))]),(_vm.singleGame.game_scores.length == 0)?_c('div',{staticClass:"begin-btn"},[_vm._v("Yet to Begin")]):_vm._e(),(_vm.singleGame.game_scores.length != 0 && _vm.singleGame.status === 1)?_c('div',{staticClass:"begin-btn inning-btn"},[_vm._v(" Inning: "+_vm._s(_vm.scores[0].end_inning_flag))]):_vm._e(),(_vm.singleGame.game_scores.length != 0 && _vm.singleGame.status === 2)?_c('div',{staticClass:"begin-btn final-btn"},[_vm._v(" Final")]):_vm._e()]),_c('div',{staticClass:"right-section flex-md-row"},[(_vm.scores.length != 0)?_c('div',[(
                    _vm.scores?.some((team) => team.team_type === 2)
                )?_c('div',{staticClass:"opponent-name"},[(
                        _vm.singleGame.status === 1 &&
                        _vm.scores &&
                        _vm.scores?.find(
                            (team) =>

                                team.team_type == 2 &&
                                team.batting_flag == 1
                        )
                    )?_c('img',{staticClass:"arrow-image",attrs:{"src":_vm.arrow_left,"alt":"arrow-left"}}):_vm._e(),(_vm.singleGame.status === 2 && _vm.singleGame.win_status === 2 && _vm.scores[0].team_type == 1)?_c('img',{attrs:{"src":_vm.cup,"alt":"cup"}}):_vm._e(),(_vm.singleGame.status === 2 && _vm.singleGame.win_status === 1 && _vm.scores[0].team_type == 2)?_c('img',{attrs:{"src":_vm.cup,"alt":"cup"}}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.receivedScores.homeScore))]),(
                        _vm.scores?.find(
                            (team) =>
                                team.team_type === 2 &&
                                team.team_name !== _vm.selectedRoomTeam.teamName
                        )
                    )?_c('div',{staticClass:"custom-avatar",style:({ backgroundColor: _vm.getRandomColor() })},[_vm._v(" "+_vm._s(_vm.getInitials( _vm.scores?.find( (team) => team.team_type === 2 )?.team_name ))+" ")]):_c('div',{staticClass:"team-image"},[(_vm.imgLoader == 1)?_c('div',{staticClass:"users-notification"},[_c('img',{attrs:{"src":require("../../assets/loader.gif"),"alt":""}})]):_vm._e(),(_vm.selectedRoomTeam.teamAvatar == '')?_c('img',{attrs:{"src":_vm.team_image,"alt":""},on:{"load":_vm.onImageLoad}}):_vm._e(),(
                            _vm.selectedRoomTeam.teamAvatar != '' &&
                            _vm.scores?.find(
                                (team) =>
                                    team.team_name === _vm.selectedRoomTeam.teamName
                            )
                        )?_c('img',{attrs:{"src":_vm.url + _vm.selectedRoomTeam.teamAvatar,"alt":""},on:{"load":_vm.onImageLoad}}):_vm._e()]),_c('h6',[_vm._v(" "+_vm._s(_vm.scores?.find( (team) => team.team_type === 2 )?.team_name)+" ")])]):_vm._e()]):_c('div',{staticClass:"opponent-data"},[_c('span',[_vm._v("-")]),_c('div',{staticClass:"team-image"},[(_vm.imgLoader == 1)?_c('div',{staticClass:"users-notification"},[_c('img',{attrs:{"src":require("../../assets/loader.gif"),"alt":""}})]):_vm._e(),(_vm.selectedRoomTeam.teamAvatar == '')?_c('img',{attrs:{"src":_vm.team_image,"alt":""},on:{"load":_vm.onImageLoad}}):_vm._e(),(
                        _vm.selectedRoomTeam.teamAvatar != ''
                    )?_c('img',{attrs:{"src":_vm.url + _vm.selectedRoomTeam.teamAvatar,"alt":""},on:{"load":_vm.onImageLoad}}):_vm._e()]),_c('h6',[_vm._v(" "+_vm._s(_vm.selectedRoomTeam.teamName)+" ")])]),_c('router-link',{staticClass:"m-0",attrs:{"to":{ name: 'SingleEventDetail', params: { id: _vm.singleGame.event.id } }}},[_c('h6',[_vm._v(" "+_vm._s(_vm.singleGame.event.eventName)+" ")])])],1)]),_c('div',{staticClass:"back-to-event-div"},[_c('router-link',{staticClass:"m-0",attrs:{"to":{ name: 'SingleEventDetail', params: { id: _vm.singleGame.event.id } }}},[_c('h6',[_c('img',{staticClass:"mr-1",attrs:{"src":require("../../assets/Svg/back-arrow.svg"),"alt":"back-arrow"}}),_vm._v("Back to Event - "),_c('span',[_vm._v(_vm._s(_vm.singleGame.event.eventName))])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }