<template>
    <div>
        <Navbar />
        <div class="setting-main-wrapper">
            <div class="main-left-menu">
                <LeftMenu />
            </div>
            <Banner />
            <div class="shop-side p-md-0">
                <ContactForm />
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from "../components/Header/Navbar.vue";
import LeftMenu from "../components/Home/LeftMenu.vue";
import Banner from "../components/BannerNotify/BannerPage.vue";
import ContactForm from "../components/ContactUs/ContactForm.vue";

export default {
    name: "ContactUs",
    data() {
        return {
        };
    },
    components: {
        Navbar,
        LeftMenu,
        Banner,
        ContactForm
    },
    methods: {},
    mounted() {
    },
};
</script>