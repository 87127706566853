<template>
  <div>
    <b-row class="no-gutters">
      <div
        v-for="value in TeamDetails"
        :key="value.id"
        class="col-12 col-sm-6 col-lg-4 col-xl-3 p-0 pr-sm-3 products-column"
      >
        <div class="event-card">
          <div class="content">
            <div class="img-wrapper" @click="handleEventClick(value)">
              <div class="shop-products-img-loader" v-if="imgLoader == 1">
                <div class="lds-dual-ring-media"></div>
              </div>
              <img
                @load="onImageLoad"
                v-if="value.avatar == '' || value.avatar == null"
                alt="Image"
                class="mb-2 default-event-image"
                src="../../assets/cover-default.png"
              />
              <img
                @load="onImageLoad"
                v-else
                :src="eventURL + value.avatar"
                alt="Image"
                class="mb-2"
              />
            </div>
            <div class="p-3" @click="handleEventClick(value)">
              <h3 class="event-name calendar-side">{{ value.eventName }}</h3>
              <div class="event-description my-2" style="min-height: 120px">
                <div class="association">
                  <span class="heading">Created By :</span>
                  <span class="content">{{ value.createdByName }} </span>
                </div>
                <div class="association">
                  <span class="heading">Date :</span>
                  <span class="content">{{
                    value.createdByDate | formatDateChat
                  }}</span>
                </div>

                <div class="association" v-if="value.association != '' && value.association != null && value.association != 'null'">
                  <span class="heading">Association :</span>
                  <span class="content">{{ value.association }}</span>
                </div>
                <div class="event-type">
                  <span class="heading">Event Type :</span>
                  <span class="content"
                    >{{ value.eventType }}
                    <span v-if="value.status == 2">(Online)</span></span
                  >
                </div>
                <div
                  v-if="
                    (value.status == 1 &&
                      value.address != null &&
                      value.address != '' &&
                      value.address != 'null') ||
                    (value.city != null &&
                      value.city != '' &&
                      value.city != 'null') ||
                    (value.state != null &&
                      value.state != '' &&
                      value.state != 'null')
                  "
                  class="event-location"
                >
                  <img src="../../assets/images/location.png" />
                  <!-- <span class="content">{{
                    value.address !== "" &&
                    value.address !== null &&
                    value.address !== "null"
                      ? value.address
                      : value.city
                  }}</span> -->
                  <span class="content"
                    >{{ value.address }} {{ value.city }},
                    {{ value.state }}</span
                  >
                </div>
                <div v-if="value.status == 2" class="event-location">
                  <img src="../../assets/images/link.png" />
                  <a
                    :href="value.Url"
                    class="content card-link"
                    target="_blank"
                  >
                    <span style="word-break: break-all">{{ value.Url }}</span>
                  </a>
                </div>
              </div>
              <div class="event-date">
                <span class="heading">Date & Time :</span>
                <div class="content">
                  <span v-if="value.exactStartDate == value.exactEndDate">{{
                    value.exactStartDate
                  }}</span>
                  <span v-else
                    >{{ value.exactStartDate.split(",")[0] }} to
                    {{ value.exactEndDate }}</span
                  >
                  <span v-if="value.allDay == 'true'"> All Day.</span>
                  <span v-else>
                    From {{ value.exactStartTime }} to
                    {{ value.exactEndTime }}</span
                  >
                  <span v-if="value.time_zone !== null">
                    {{ value.time_zone }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <b-card-footer style="margin-top: 10px; display: none">
          </b-card-footer>
        </div>
      </div>
      <div
        class="card-event col-12 no-card-event"
        v-if="showLoader == 0 && TeamDetails.length == 0"
      >
        <img src="../../assets/images/calendar-search.png" alt="" />
        <p class="no-event-header">No upnext events</p>
        <p class="no-event-text">
          All upnext events for this team will appear here.
        </p>
      </div>
      <div class="col-12 p-0" v-if="showLoader == 1">
        <div class="row">
          <div class="col-12 col-md-6 col-lg-3 mt-md-4 pr-md-0">
            <div class="products-list-shim">
              <content-placeholders :rounded="true">
                <content-placeholders-img />
                <content-placeholders-heading />
                <content-placeholders-text :lines="3" />
              </content-placeholders>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-3 mt-4 pr-md-0">
            <div class="products-list-shim">
              <content-placeholders :rounded="true">
                <content-placeholders-img />
                <content-placeholders-heading />
                <content-placeholders-text :lines="3" />
              </content-placeholders>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-3 mt-4 pr-md-0">
            <div class="products-list-shim">
              <content-placeholders :rounded="true">
                <content-placeholders-img />
                <content-placeholders-heading />
                <content-placeholders-text :lines="3" />
              </content-placeholders>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-3 mt-4 pr-md-0 pr-lg-3">
            <div class="products-list-shim">
              <content-placeholders :rounded="true">
                <content-placeholders-img />
                <content-placeholders-heading />
                <content-placeholders-text :lines="3" />
              </content-placeholders>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-3 mt-4 pr-md-0">
            <div class="products-list-shim">
              <content-placeholders :rounded="true">
                <content-placeholders-img />
                <content-placeholders-heading />
                <content-placeholders-text :lines="3" />
              </content-placeholders>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-3 mt-4 pr-md-0">
            <div class="products-list-shim">
              <content-placeholders :rounded="true">
                <content-placeholders-img />
                <content-placeholders-heading />
                <content-placeholders-text :lines="3" />
              </content-placeholders>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-3 mt-4 pr-md-0">
            <div class="products-list-shim">
              <content-placeholders :rounded="true">
                <content-placeholders-img />
                <content-placeholders-heading />
                <content-placeholders-text :lines="3" />
              </content-placeholders>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-3 mt-4 pr-md-0 pr-lg-3">
            <div class="products-list-shim">
              <content-placeholders :rounded="true">
                <content-placeholders-img />
                <content-placeholders-heading />
                <content-placeholders-text :lines="3" />
              </content-placeholders>
            </div>
          </div>
        </div>
      </div>
    </b-row>
  </div>
</template>

<script>
import axios from "axios";
import { parseTimestamp } from "../../utils/dates";
import { mapGetters } from "vuex";

export default {
  name: "allEventView",

  data: function () {
    return {
      imgLoader: 1,
      firstTimeEventsA: [],
      eventURL: this.$eventAvatarStorageURL,
      eventsDetails: [],
      page: 1,
      lastPage: 1,
      TeamDetail: this.TeamDetails,
      avatar: "",
      eventName: "",
      createdByName: "",
      createdByDate: "",
      association: "",
      eventType: "",
      status: "",
      address: "",
      city: "",
      Url: "",
      exactStartDate: "",
      exactEndDate: "",
      allDay: "",
      exactStartTime: "",
      exactEndTime: "",
      eventTypeA: "",
      showEventMemeberCountShimmer: 0,
      currentUserEventStatus: 1,
      showEventsMember: 0,
      showGameList: 0,
      showDefualt: 1,
      totalGoing: 0,
      totalNotGoing: 0,
      totalMayBe: 0,
      totalNotRespond: 0,
      showGameShimmer: 1,
      UserDetail: [],
      gameList: [],
      selectAttendeeTab: 0,
      membersAttendee: [],
      API_URL: this.$userProfileStorageURL,
      selectedRoomTeam: [],
      selectMainTab: 0,
      teamID: "",
      eventID: "",
      selectVisitEventStatus: [],
      singleEvent: [],
      comingstat: this.comingstatus,
      total: 0,
      eventTo: 0,
    };
  },

  props: {
    TeamDetails: Array,
    UserDetails: Array,
    filters: Object,
    showLoader: Number,
    currentUserId: String
  },
  computed: {
    ...mapGetters(["errors"]),
    ...mapGetters("events", ["comingstatus"]),
  },
  methods: {
    onImageLoad() {
      this.imgLoader = 0;
    },
    openGoogleMap(item) {
      const strlatLng = item["lan"] + "," + item["long"];
      const urlSuffix = strlatLng
        ? strlatLng
        : item["address"] + ", " + item["city"] + ", " + item["zipCode"];

      window.open(
        "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
        "_blank"
      );
    },

    handleEventClick(clickInfo_event) {
      document.getElementById("overlay").style.display = "block";
      this.eventID = clickInfo_event;
      this.selectMainTab = 0;
      this.$root.$emit("gameScoreLoaderShow", 1);
      const formData = new FormData();
      formData.append("teamIDs", this.eventID.id);
      axios
        .post(process.env.VUE_APP_API_URL + "game/boxScores", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          const data = res.data.data.listing;
          const event_name = this.eventID.eventName;
          this.$root.$emit("dataItems", data, event_name);
        });

      const getItem = document.querySelector(".box-score-right-side");

      getItem.style.display = "block";
    },
    checkStatus(endDateForField, exactStartTime) {
      var checkDateIfPast = parseTimestamp(
        endDateForField + "&&" + exactStartTime,
        "checkIfPast"
      );
      if (checkDateIfPast == "upComing") {
        return "upComing";
      } else {
        return "past";
      }
    },
    openGoogleMapforGamePark(item) {
      const strlatLng = item["park_lat"] + "," + item["park_lng"];
      const urlSuffix = strlatLng ? strlatLng : item["park_address"];

      window.open(
        "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
        "_blank"
      );
    },
    // on: function() {
    //   document.getElementById("overlay").style.display = "block";
    // },
  },

  // watch: {
  // },

  mounted() {
    this.lastPage = 2;
    this.page = 1;
  },
};
</script>

<style scoped>
.content .img-wrapper {
  width: 100%;
  height: 200px;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.content .img-wrapper img {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  border-radius: 5px 5px 0 0;
}

.card-text {
  position: relative;
  width: 100%;
  margin-bottom: 50px;
}

.created-by,
.date {
  font-size: 14px;
  font-weight: 500;
}

.event-description .created-by {
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.event-description .date .content {
  margin-left: 43px;
}

.event-details-wrapper {
  padding-top: 10px;
  overflow-x: hidden;
  z-index: 99;
}

.event-details-top {
  display: flex;
  align-items: center;
}

.event-details-top .back-arrow {
  color: #188ef5;
  font-size: 20px;
  margin-right: 15px;
  cursor: pointer;
  padding: 0 5px;
}

.event-details-top .back-arrow:hover {
  background-color: #188ef5;
  color: #ffffff;
  border-radius: 5px;
}

.event-details-top h6 {
  margin: 0;
  text-transform: uppercase;
  color: #a7a6a6;
  font-weight: 500;
}

.event-image-name {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
}

.event-image-name .image {
  min-width: 120px;
  max-width: 120px;
  overflow: hidden;
  border-radius: 5px;
  margin-right: 10px;
}

.event-image-name .image img {
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}

.created-by,
.date {
  font-size: 14px;
  font-weight: 500;
}

.created-by .heading,
.date .heading {
  color: #a7a6a6;
  margin-right: 3px;
  font-weight: 400;
}

.event-label h6 {
  color: #333333;
  font-weight: 400;
  margin: 10px 5px;
  font-size: 16px;
}

.cursor-p {
  cursor: pointer;
}

.cursor-p-null {
  cursor: default !important;
}

.event-label ul li.active {
  background-color: #188ef5;
  color: #ffffff;
}

.event-label ul li.active:before {
  content: "✓";
  position: absolute;
  top: -10px;
  background-color: #ff5b66;
  width: 15px;
  height: 15px;
  font-size: 10px;
  border-radius: 5px;
  text-align: center;
}

.view-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.view-details span {
  font-weight: 500;
  font-size: 14px;
  text-align: right;
  color: #188ef5;
  /*cursor: pointer;*/
}

/* .user-counter .counting {
  display: block;
  align-items: center;
  padding-bottom: 8px;
}


.user-counter .counting li span {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.user-counter .counting li span:first-child {
  font-size: 14px;
  font-weight: 500;
  color: #333333;
  margin-bottom: 5px;
}

.user-counter .counting li span:last-child {
  font-weight: 400;
  font-size: 13px;
  color: #b4b3b3; */
/* overflow: hidden; */
/* text-overflow: ellipsis; */
/* display: -webkit-box; */
/* -webkit-line-clamp: 1; number of lines to show */
/* -webkit-box-orient: vertical; */
/* } */

.games-section {
  padding: 12px 0;
}

.game-content-section {
  max-width: 350px;
  overflow-x: auto;
}

.game-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.game-top .game-heading h6 {
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}

.game-top .game-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.game-top .game-details a {
  font-size: 14px;
  font-weight: 500;
  margin: 0px 7px;
  color: #188ef5 !important;
}

.date-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.date-content p {
  font-weight: 500;
  font-size: 10px;
  color: #ff5b66;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
}

.actions-content {
  width: 12px;
  position: relative;
}

.event-card {
  cursor: pointer;
  -webkit-box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
  -moz-box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
  -ms-box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
  -o-box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
  box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
  transition: all 0.7s;
  background-color: #ffffff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  margin-top: 15px;
}

.event-card:hover {
  cursor: pointer;
  -webkit-box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  -moz-box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  -ms-box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  -o-box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
}

.content .img-wrapper img.default-event-image {
  width: 30px !important;
  height: 30px !important;
  border-radius: 0 0 0 0 !important;
}
.card-event {
  border-radius: 5px;
  padding: 15px;
  margin-top: 15px;
  -webkit-box-shadow: 0 1px 4px rgb(0 0 0 / 20%);
  -moz-box-shadow: 0 1px 4px rgb(0 0 0 / 20%);
  -ms-box-shadow: 0 1px 4px rgb(0 0 0 / 20%);
  -o-box-shadow: 0 1px 4px rgb(0 0 0 / 20%);
  box-shadow: 0 1px 4px rgb(0 0 0 / 20%);
  background-color: #ffffff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  margin-top: 15px;
}
</style>
