<template>
  <div>
    <StreamingToggle />
    <KeepScores />
    <p class="live-stream-content d-none">
      This will publish the boxscores for live stream and live stream channel
      for this game will be available for users to view.
    </p>
    <div class="row no-gutters" v-if="gameActionLoader == 0 && this.scores.length == 0 && currentIsAdmin == '1'">
      <div class="col-12 p-0">
        <div @click="callStartGameModal"
          class="live-stream-tags my-0 d-flex justify-content-center align-items-center">
          <img src="../../assets/Svg/timer-start-blue.svg" alt="">
          <h6 class="mb-0">Start Game</h6>
          <p>Hit Start Game above, select the home team and keep your inning by inning score.</p>
        </div>
      </div>
    </div>
    <content-placeholders class="post-view " :rounded="true" v-if="gameActionLoader == 1">
      <content-placeholders-text :lines="6" />
    </content-placeholders>
    <div class="row no-gutters" v-if="gameActionLoader == 0 && gameFetchedright.status == 2 && currentIsAdmin == '1'">
      <div class="col-12 p-6">
        <div class="live-stream-tags my-0 d-flex justify-content-center align-items-center" @click="restartGame()">
          <img src="../../assets/Svg/timer-start-blue.svg" alt="">
          <h6>Restart Game</h6>
          <p>Hit Restart Game above, select the home team and keep your inning by inning score.</p>
        </div>
      </div>
    </div>
    <div class="d-flex flex-wrap flex-column flex-md-row" style="gap: 20px;">
      <div class="score w-50" v-if="
        showShimmer == 0 &&
        gameFetchedright.status != 2 &&
        currentIsAdmin == '1' &&
        this.scores.length != 0
      ">
        <div class="inning-counter">
          <div class="d-flex align-items-start" style="gap: 5px;">
            <span>Inning</span>
            <span>{{ totalInnings }}</span>
          </div>
          <p class="team-name">{{ currentBattingTeam }}</p>
        </div>
        <div class="team-position">
          <div class="team-score-hr">
            <div class="team-score">
              <span class="head">Runs</span>

              <div class="product-inc-dec">
                <div class="product-decrement d-block" @click="runsDecrement()">
                  <img src="../../assets/Svg/minus.svg" alt="" />
                </div>
                <div class="product-value">
                  <span>{{ runs }}</span>
                </div>
                <div class="product-increment" @click="runsIncrement()">
                  <img src="../../assets/Svg/plus.svg" alt="" />
                </div>
              </div>
            </div>
            <div class="team-hr">
              <span class="head">HR</span>
              <div class="product-inc-dec">
                <div class="product-decrement d-block" @click="hrDecrement()">
                  <img src="../../assets/Svg/minus.svg" alt="" />
                </div>
                <div class="product-value">
                  <span>{{ hr }}</span>
                </div>
                <div class="product-increment" @click="hrIncrement()">
                  <img src="../../assets/Svg/plus.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <content-placeholders class="score mt-0 w-50" :rounded="true" v-if="showShimmer == 1">
        <content-placeholders-text :lines="4" />
      </content-placeholders>
      <div class="inning-section" v-if="
        gameActionLoader == 0 &&
        this.scores.length != 0 &&
        gameFetchedright.status != 2 &&
        currentIsAdmin == '1'
      ">
        <div class="d-flex align-items-center justify-content-between w-100" style="gap: 10px;">
          <div class="inning-tags" @click="endHalfInning">
            <h6>End Half Inning</h6>
          </div>
          <div class="inning-tags" v-if="gameFetchedright.status != 2 && currentIsAdmin == '1'"
            @click="swapHomeVisitor">
            <h6>Swap Home / Visitor</h6>
          </div>
          <div class="inning-tags" v-if="gameFetchedright.status != 2 && currentIsAdmin == '1'"
            @click="deleteLastInning">
            <h6>Delete Last Inning</h6>
          </div>
        </div>
        <div class="end-game-tags" v-if="gameFetchedright.status != 2 && currentIsAdmin == '1'" @click="endGame">
          <h6>End Game</h6>
        </div>
      </div>
    </div>
    <!-- <div class="text-center" :class="{ 'd-none': canvaLoader == 0 }">
      <canvas ref="configCanvas" width="458" height="360" class="mt-3" v-if="this.scores.length != 0"></canvas>
    </div>
    <div class="game-ground" v-if="this.scores.length != 0" :class="{ 'd-none': canvaLoader == 0 }">
      <img ref="groundImage" :src="require('../../assets/images/ground.jpg')" alt="" @load="loadGroundImage"
        style="display: none" />
    </div>
    <div class="no-event-wrap" v-else>
      <img src="../../assets/Svg/maximize-grey.svg" alt="" />
          <p class="header">No Field Configuration</p>
          <p class="info">No field associated with this game. Field configuration will appear here once it is configured. </p>
    </div>

    <div class="mt-3">
      <content-placeholders class="post-view " :rounded="true" v-if="canvaLoader == 0">
        <content-placeholders-text :lines="6" />
      </content-placeholders>
    </div> -->
  </div>
</template>

<script>
import StreamingToggle from "./SubRight/StreamingToggle";
import KeepScores from "../Teams/Games/KeepScores.vue";
import axios from "axios";
export default {
  name: "RightSide",
  props: {
    currentIsAdmin: {
      type: String,
    },
    configDataFromChild: {
      type: Array,
      default: () => [],
    },
    fetchConfig: {
      type: Array,
      default: () => [],
    },
    gameLineUps: {
      type: Array,
      default: () => [],
    },
    gameFetchedright: Object,
    selectedRoomTeam: Object,
    totalInnings: Number,
    currentBattingTeam: String,
    currentTeamType: Number,
    currentTeamHR: {},
    currentTeamRuns: {},
    showShimmer: Number,
    currentInningVisitScore: Object,
    currentInningVisitHR: Object,
    currentInningHomeScore: Object,
    currentInningHomeHR: Object,
    homeTeamName: String,
    visitTeamName: String,
    scores: {
      type: Array,
    },
  },
  data() {
    return {
      streamStatus: false,
      runs: 0,
      hr: 0,
      isGroundImageLoaded: false,
      gameActionLoader: 0,
      canvaLoader: 0,
      unUsualdata: {},
    };
  },
  components: {
    StreamingToggle,
    KeepScores,
  },
  computed: {
    watchGameLineUps: function () {
      // This computed property will be automatically re-evaluated whenever gameLineUps changes
      return this.gameLineUps;
    },
    formattedNumber() {
      // Check for the last digit to determine the suffix
      const lastDigit = this.totalInnings % 10;

      // Handle special cases for 11th, 12th, and 13th
      if (this.totalInnings >= 11 && this.totalInnings <= 13) {
        return `${this.totalInnings}th`;
      }

      // Assign the appropriate suffix based on the last digit
      switch (lastDigit) {
        case 1:
          return `${this.totalInnings}st`;
        case 2:
          return `${this.totalInnings}nd`;
        case 3:
          return `${this.totalInnings}rd`;
        default:
          return `${this.totalInnings}th`;
      }
    },
  },
  watch: {
    configDataFromChild: "plotConfigData",
    fetchConfig: "plotConfigData",
    watchGameLineUps: function (newGameLineUps, oldGameLineUps) {
      this.unUsualdata = newGameLineUps;
      this.unUsualdata = oldGameLineUps;
      this.plotConfigData();
    },
    gameLineUps: {
      handler: function (newGameLineUps, oldGameLineUps) {
        this.unUsualdata = newGameLineUps;
        this.unUsualdata = oldGameLineUps;
        this.canvaLoader = 1;
      },
      deep: true
    }
  },
  methods: {
    loadGroundImage() {
      this.isGroundImageLoaded = true;
      this.plotConfigData();
    },
    plotConfigData() {
      if (!this.isGroundImageLoaded) {
        return;
      }

      const configData =
        this.configDataFromChild.length > 0
          ? this.configDataFromChild
          : this.fetchConfig;

      const canvas = this.$refs.configCanvas;
      const ctx = canvas.getContext("2d");

      ctx.clearRect(0, 0, canvas.width, canvas.height);

      const groundImage = this.$refs.groundImage;
      ctx.drawImage(groundImage, 0, 0, canvas.width, canvas.height);



      configData.forEach((configItem) => {
        // Check if position array and position_name property exist
        if (configItem && configItem.position_name) {
          const matchingGameLineUp = this.gameLineUps.find((gameLineUpItem) => {
            if (gameLineUpItem.position) {
              const matchingPositions = gameLineUpItem.position.filter(
                (pos) => pos.position_name === configItem.position_name
              );
              return matchingPositions.length > 0;
            }
            return false;
          });

          if (matchingGameLineUp) {


            ctx.fillStyle = "#ff0000";
            ctx.fillRect(configItem.x_axis, configItem.y_axis, 18, 15);

            ctx.fillStyle = "#ffffff";
            ctx.font = "7px";
            const textToDisplay = `${configItem.position_name} - ${matchingGameLineUp.user.name}`;
            ctx.fillText(
              textToDisplay,
              configItem.x_axis + 3,
              configItem.y_axis + 10
            );
          }
        }
      });

    },

    callStartGameModal: function () {

      this.$root.$emit(
        "openSelectHomeTeamModal",
        this.gameFetchedright,
        this.selectedRoomTeam
      );
    },
    fetchTeamPosition() {
      var formData = new FormData();
      formData.append("gameId", this.gameFetchedright.id);
      axios
        .post(process.env.VUE_APP_API_URL + "game/getGameLineups", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.gameLineUps = response.data.data.gameLineUps;
          this.$root.$emit("RefreshPlotConfigData", this.gameLineUps);
        });
    },
    endHalfInning: function () {
      var lastMessage = "";
      var lastVisitScore = "";
      if (this.currentTeamType == 2) {
        lastMessage = `End of ${this.formattedNumber} Inning`;
        lastVisitScore = `${this.visitTeamName}\n ${this.currentInningVisitScore.score} R , ${this.currentInningVisitHR.score} HR\n${this.currentBattingTeam}\n ${this.runs} R, ${this.hr} HR\n`;
      } else {
        lastMessage = `Middle of ${this.formattedNumber} Inning`;
        lastVisitScore = `${this.currentBattingTeam}\n ${this.runs} R, ${this.hr} HR\n`;
      }
      this.$fire({
        title: lastMessage,
        html: `<div>${lastVisitScore.replace(/\n/g, "<br>")}</div>`,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "End Half Inning",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.value) {
          this.gameActionLoader = 1;
          var formData = new FormData();
          formData.append("game_id", this.gameFetchedright.id);
          axios
            .post(
              process.env.VUE_APP_API_URL + "game/endHalfInning",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then((response) => {
              if (response.data.statusCode == 300) {
                alert(response.data.message);
              } else if (response.data.statusCode == 404) {
                alert(response.data.message);
              } else if (response.data.statusCode == 500) {
                alert(response.data.message);
              } else {
                let loader = this.$loading.show({
                  container: this.fullPage ? null : this.$refs.formContainer,

                  color: "#ffffff",
                  backgroundColor: "#333",
                  opacity: 0.6,
                });

                loader.hide();
                this.gameActionLoader = 0;
                this.$root.$emit("showSingleBoxScore");
              }
            });
        }
      });
    },
    swapHomeVisitor: function () {
      var swapMessage = `Swap Home & Visitor team will erase\n all game data.\n${this.visitTeamName} will become the Home team.`;
      this.$fire({
        title: "Swap Home/ Visitor",
        html: `<div>${swapMessage.replace(/\n/g, "<br>")}</div>`,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Continue",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.value) {
          this.gameActionLoader = 1;
          var formData = new FormData();
          formData.append("game_id", this.gameFetchedright.id);
          axios
            .post(
              process.env.VUE_APP_API_URL + "game/swapGameTeams",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then((response) => {
              if (response.data.statusCode == 300) {
                alert(response.data.message);
              } else if (response.data.statusCode == 404) {
                alert(response.data.message);
              } else if (response.data.statusCode == 500) {
                alert(response.data.message);
              } else {
                let loader = this.$loading.show({
                  container: this.fullPage ? null : this.$refs.formContainer,

                  color: "#ffffff",
                  backgroundColor: "#333",
                  opacity: 0.6,
                });
                this.gameActionLoader = 0;
                loader.hide();
                this.$root.$emit("showSingleBoxScore");
              }
            });
        }
      });
    },
    deleteLastInning: function () {
      this.$confirm(
        "Are you sure to delete this inning,\n You will not be able to revert this action. Continue ?",
        "Delete Inning."
      ).then(() => {
        this.gameActionLoader = 1;
        var formData = new FormData();
        formData.append("game_id", this.gameFetchedright.id);
        axios
          .post(
            process.env.VUE_APP_API_URL + "game/deleteLastInning",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            if (response.data.statusCode == 300) {
              alert(response.data.message);
            } else if (response.data.statusCode == 404) {
              alert(response.data.message);
            } else if (response.data.statusCode == 500) {
              alert(response.data.message);
            } else {
              let loader = this.$loading.show({
                container: this.fullPage ? null : this.$refs.formContainer,

                color: "#ffffff",
                backgroundColor: "#333",
                opacity: 0.6,
              });
              // this.getScores();

              loader.hide();
              this.gameActionLoader = 0;
              this.$root.$emit("showSingleBoxScore");
            }
          });
      });
    },
    endGame: function () {
      this.$confirm(
        "Are you sure to end this Game, You will not be able to revert this action. Continue ?",
        "End Game."
      ).then(() => {
        this.gameActionLoader = 1;
        var formData = new FormData();
        formData.append("game_id", this.gameFetchedright.id);
        axios
          .post(process.env.VUE_APP_API_URL + "game/endGame", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            if (response.data.statusCode == 300) {
              alert(response.data.message);
            } else if (response.data.statusCode == 404) {
              alert(response.data.message);
            } else if (response.data.statusCode == 500) {
              alert(response.data.message);
            } else {
              let loader = this.$loading.show({
                container: this.fullPage ? null : this.$refs.formContainer,

                color: "#ffffff",
                backgroundColor: "#333",
                opacity: 0.6,
              });
              // this.getScores();

              loader.hide();
              this.gameActionLoader = 0;
              this.$root.$emit("showSingleBoxScore");
            }
          });
      });
    },
    restartGame: function () {
      this.$confirm(
        "Are you sure to restart this Game. Continue ?",
        "Restart Game."
      ).then(() => {
        this.gameActionLoader = 1;
        var formData = new FormData();
        formData.append("game_id", this.gameFetchedright.id);
        axios
          .post(process.env.VUE_APP_API_URL + "game/reopenGame", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            if (response.data.statusCode == 300) {
              alert(response.data.message);
            } else if (response.data.statusCode == 404) {
              alert(response.data.message);
            } else if (response.data.statusCode == 500) {
              alert(response.data.message);
            } else {
              let loader = this.$loading.show({
                container: this.fullPage ? null : this.$refs.formContainer,

                color: "#ffffff",
                backgroundColor: "#333",
                opacity: 0.6,
              });
              // this.getScores();
              this.gameActionLoader = 0;
              loader.hide();
              this.$root.$emit("showSingleBoxScore");
            }
          });
      });
    },
    runsIncrement() {
      this.runs = this.runs + 1;
      this.$emit("passData", this.runs);
      this.scoreUpdate(this.runs, 2);
    },

    runsDecrement() {
      var newRuns = this.runs - 1;

      if (newRuns >= 0) {
        this.runs = this.runs - 1;
        this.scoreUpdate(this.runs, 2);
      }
      this.$emit("passData", this.runs);
    },

    hrIncrement() {
      this.hr = this.hr + 1;
      this.$emit("passHrData", this.hr);
      this.scoreUpdate(this.hr, 1);
    },

    hrDecrement() {
      var newHr = this.hr - 1;

      if (newHr >= 0) {
        this.hr = this.hr - 1;
        this.scoreUpdate(this.hr, 1);
      }

      this.$emit("passHrData", this.hr);
    },
    async scoreUpdate(score, inning_type) {
      var formData = new FormData();
      formData.append("score", score);
      formData.append("game_id", this.gameFetchedright.id);
      formData.append("team_type", this.currentTeamType);
      formData.append("inning_no", this.totalInnings);
      formData.append("inning_type", inning_type);
      await axios
        .post(process.env.VUE_APP_API_URL + "game/updateScore", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {

        });
    },
  },
  mounted() {
    this.$root.$on("updateScoreHr", (homeRun, score) => {
      this.runs = score;
      this.hr = homeRun;
    });
    if (!this.isGroundImageLoaded) {
      this.loadGroundImage();
    }
    window.addEventListener("resize", this.handleResize);
    this.$root.$on("showCanvasLoader", (showLoader) => {
      if (showLoader == 1) {
        this.canvaLoader = 0;
      }
    });
  },
};
</script>
