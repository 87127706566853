<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#AddTournament"
      ref="openModalAddTournament"
      @click="dataReset()"
    ></button>
    <div class="modal fade" tabindex="-1" role="dialog" id="AddTournament">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title m-0">Add Division</h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModalAddTournament"
            >
              <img src="../../../assets/close.png" />
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="name">Division Name</label>
              <input
                id="name"
                class="form-control"
                v-model="tournament_name"
                spellcheck="false"
                autocomplete="off"
                trim
                type="text"
                required
                @input="errorNull"
              />
            </div>
            <div class="row no-gutters">
              <div class="col-sm-12 col-md-6 pr-0 pr-md-2">
                <div class="form-group">
                  <label for="startDateCheck">Start Date</label>
                  <span
                    class="label-type error event-error ml-2"
                    v-if="startDateError != ''"
                  >
                    Please select Start Date.
                  </span>
                  <span
                    class="label-type error event-error ml-2"
                    v-if="eventStartDateError != ''"
                  >
                  Start date is outside the event date range.
                  </span>
                  <date-picker
                    :open="open"
                    @close="open = false"
                    :editable="false"
                    :clearable="false"
                    @click.native="handleClickStart"
                    v-model="startDate"
                    valueType="format"
                    format="MMM DD, YYYY"
                    placeholder="mmm dd, yyyy"
                    @change="checkdate;errorNull"
                  >
                    <i slot="icon-calendar" class="cursor-pointer">
                      <img src="../../../assets/images/calendar-input.png" />
                    </i>
                  </date-picker>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 pl-lg-0 mt-2 mt-md-0">
                <div class="form-group">
                  <label for="endDateCheck">End Date</label>
                  <span
                    class="label-type error event-error ml-2"
                    v-if="endDateError != ''"
                  >
                    Please select End Date.
                  </span>
                  <span
                    class="label-type error event-error ml-2"
                    v-if="endDateErrorGreater != ''"
                  >
                    End Date should be greater than start date
                  </span>
                  <span
                    class="label-type error event-error ml-2"
                    v-if="eventEndDateError != ''"
                  >
                  End date is outside the event date range.
                  </span>
                  <date-picker
                    :open="openEnd"
                    @close="openEnd = false"
                    :editable="false"
                    :clearable="false"
                    @click.native="handleClickEnd"
                    v-model="endDate"
                    valueType="format"
                    format="MMM DD, YYYY"
                    placeholder="mmm dd, yyyy"
                    @change="checkdate;errorNull"
                  >
                    <i slot="icon-calendar" class="cursor-pointer">
                      <img src="../../../assets/images/calendar-input.png" />
                    </i>
                  </date-picker>
                </div>
              </div>
            </div>
              <div class="col-12 p-0 mt-2 mb-3">
                <div class="form-check">
                  <input 
                    class="form-check-input" 
                    type="checkbox" 
                    id="defaultCheck1" 
                    v-model="isChecked"
                  />
                  <label class="form-check-label" for="defaultCheck1" style="margin-top: 2px;">
                    Add specific age & division
                  </label>
                </div>
              </div>

              <!-- The row will only be displayed when the checkbox is checked -->
              <div class="row no-gutters my-3" v-if="isChecked">
                <div class="col-12 col-md-6 pr-md-2">
                  <label for="age">Select Age</label>
                  <span
                    class="label-type error event-error ml-2"
                    v-if="showErrors && !selectedAge"
                  >
                    Please select age.
                  </span>
                  <select class="cust-select" v-model="selectedAge">
                    <option disabled value="">Select age</option>
                    <option v-for="option in ageOptions" :key="option.id" :value="option.id"> {{ option.name }}</option>
                  </select>
                </div>
                <div class="col-12 col-md-6">
                  <label for="division">Select Division</label>
                  <span
                    class="label-type error event-error ml-2"
                    v-if="showErrors && !selectedDivision"
                  >
                    Please select division.
                  </span>
                  <select class="cust-select" v-model="selectedDivision">
                    <option disabled value="">Select divison</option>
                    <option v-for="option in ratingOptions" :value="option.id" :key="option.id">
                      {{ option.rate }}
                  </option>
                  </select>
                </div>
              </div>
            <div class="col-12 p-0 my-2">
              <div class="form-group">
                  <label for="tournamentFormat">Division Format</label>
                  <textarea
                    type="text"
                    class="form-control pt-3 mt-2 add-notes"
                    id="tournamentFormat"
                    rows="4"
                    v-model="tournamentFormat"
                    @keyup="errorNull"
                    required
                  ></textarea>
                </div>
              <!-- <div>
                <label for="summernote">Tournament Format</label>
                <vue-editor v-model="tournamentFormat" :editorToolbar="customToolbar"></vue-editor>
              </div> -->
            </div>
            <div class="col-12 p-0">
              <div class="form-group">
                  <label for="tournamentNotes">Notes</label>
                  <textarea
                    type="text"
                    class="form-control pt-3 mt-2 add-notes"
                    id="tournamentNotes"
                    rows="4"
                    v-model="tournamentNotes"
                    @keyup="errorNull"
                    required
                  ></textarea>
                </div>
            </div>
          </div>
          <div class="event-btns-wrapper p-3 justify-content-end">
            <button
              v-if="!isDisabled"
              type="button"
              class="btn btn-primary w-25"
              block
              @click="saveTournament(0)"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Save</span>
            </button>
            <button
              v-else
              type="button"
              class="btn btn-primary-disabled w-25"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Save</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { VueEditor, Quill } from 'vue2-editor';
import axios from "axios";

// Generate code-friendly font names
// function getFontName(font) {
//     return font.toLowerCase().replace(/\s/g, "-");
// }

// // Specify Quill fonts
// const fontList = ['Arial', 'Courier', 'Garamond', 'Tahoma', 'Times New Roman', 'Verdana', 'sans-serif', 'Serif', 'Monospace'];
// const fontNames = fontList.map(font => getFontName(font));
// const fonts = Quill.import('formats/font');
// fonts.whitelist = fontNames;
// Quill.register(fonts, true);

// // Add fonts to CSS style
// let fontStyles = "";
// fontList.forEach(function(font) {
//     let fontName = getFontName(font);
//     fontStyles += ".ql-snow .ql-picker.ql-font .ql-picker-label[data-value=" + fontName + "]::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=" + fontName + "]::before {" +
//         "content: '" + font + "';" +
//         "font-family: '" + font + "', sans-serif;" +
//         "}" +
//         ".ql-font-" + fontName + "{" +
//         " font-family: '" + font + "', sans-serif;" +
//         "}";
// });
// const node = document.createElement('style');
// node.innerHTML = fontStyles;
// document.body.appendChild(node);

export default {
  name: "AddTournament",
  components: {
    // VueEditor
  },
  data() {
    return {
      open: false,
      openEnd: false,
      startDate: null,
      endDate: null,
      exactStartDate: "",
      startDateForField: "",
      tournament_name: "",
      tournamentFormat: "",
      tournamentNotes: "",
      isChecked: false,
      endDateForField: "",
      exactEndDate: "",
      startDateError: "",
      eventStartDateError: "",
      eventEndDateError: "",
      endDateError: "",
      endDateErrorGreater: "",
      exactStartDateformat: "",
      exactEndDateformat: "",
      isDisabled: true,
      isLoadingArray: [],
     
      // customToolbar: [
      //   ["bold", "italic", "underline", "strike"],
      //   [{ list: "ordered" }, { list: "bullet" }],
      //   [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      //   [{ font: fonts.whitelist }],
      //   // [{ font: [] }],
      //   // [{ size: ["small", false, "large", "huge"] }],
      //   [{ align: [] }],
      //   [{ color: [] }, { background: [] }],
      //   // ["link", "image", "video"],
      //   // ["code-block"],
      //   // ["clean"]
      // ],
      selectedAge: '', // Holds the selected age
      selectedDivision: '', // Holds the selected division
      showErrors: false,
      startDateFormatted: "",
    };
  },
  props: {
    eventId: {
      type: Number,
    },
    association_id: {
      type: Number,
    },
    eventExactStartDate: {
      type: String,
    },
    eventExactEndDate: {
      type: String,
    },
    ratingOptions: {
      type: Array,
    },
    ageOptions: {
      type: Array,
    }, 
  },
  watch: {
    tournament_name: "errorNull",
    startDate: "checkdate",
    endDate: "checkdate",
    tournamentFormat: "errorNull",

    isChecked(newValue) {
      // If the checkbox is unchecked, hide the errors
      if (!newValue) {
        this.selectedAge = "";
        this.selectedDivision = "";
        this.showErrors = false;
      }
    },
   
  },
  methods: {

    initializeDate() {
      // Ensure the date is valid and set it to the date picker
      if (this.eventExactStartDate) {
        this.startDate = this.formatDate(this.eventExactStartDate);
      }
      if (this.eventExactEndDate) {
        this.endDate = this.formatDate(this.eventExactEndDate);
      }
    },

    formatDate(date) {
        const monthNames = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        const d = new Date(date);
        const month = monthNames[d.getMonth()];
        const day = String(d.getDate()).padStart(2, '0');
        const year = d.getFullYear();

        return `${month} ${day}, ${year}`;
    },

    handleClickStart(evt) {
      if (evt.target.tagName !== "INPUT") {
        this.open = true;
      }
    },
    handleClickEnd(evt) {
      if (evt.target.tagName !== "INPUT") {
        this.openEnd = true;
      }
    },
    dataReset() {
      this.tournament_name = "";
      this.selectedAge = "";
      this.selectedDivision = "";
      this.startDate = "";
      this.endDate = "";
      this.tournamentFormat = "";
      this.tournamentNotes = "";
      this.exactStartDate = "";
      this.exactEndDate = "";
      this.startDateError = "";
      this.eventStartDateError = "";
      this.eventEndDateError = "";
      this.endDateError = "";
      this.endDateErrorGreater = "";
      this.showErrors = false;
    },
    errorNull() {
      if (!this.tournament_name || !this.startDate || !this.endDate || !this.tournamentFormat || this.eventStartDateError || this.eventEndDateError || this.endDateErrorGreater !== "") {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    },
    checkdate: function () {
        const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        // Event exact start and end date
        const eventExactStart = new Date(this.eventExactStartDate);
        const eventExactEnd = new Date(this.eventExactEndDate);
        eventExactStart.setHours(12, 0, 0, 0);
        eventExactEnd.setHours(12, 0, 0, 0);

        // Start Date
        if (this.startDate.length === 12) {
            const startDateObj = new Date(this.startDate);
            startDateObj.setHours(12, 0, 0, 0);

            this.startDateFormatted = startDateObj;
            this.exactStartDateformat = this.startDateFormatted;
            this.startDateForField = startDateObj.toISOString().split("T")[0];
            this.exactStartDate = `${month[startDateObj.getMonth()]} ${startDateObj.getDate()}, ${startDateObj.getFullYear()}`;

            // Check if start date is within event start and end dates
            this.eventStartDateError = (startDateObj < eventExactStart || startDateObj > eventExactEnd) ? "on" : "";
        }

        // End Date
        if (this.endDate.length === 12) {
            const endDateObj = new Date(this.endDate);
            endDateObj.setHours(12, 0, 0, 0);

            this.exactEndDateformat = endDateObj;
            this.endDateForField = endDateObj.toISOString().split("T")[0];
            this.exactEndDate = `${month[endDateObj.getMonth()]} ${endDateObj.getDate()}, ${endDateObj.getFullYear()}`;

            // Check if end date is within event start and end dates
            this.eventEndDateError = (endDateObj < eventExactStart || endDateObj > eventExactEnd) ? "on" : "";

            // Ensure end date is not before start date
            if (endDateObj < this.startDateFormatted && !this.eventEndDateError && !this.eventStartDateError) {
                this.endDateError = "";
                this.endDateErrorGreater = "on";
                this.exactEndDate = "";
                this.isDisabledEvent = true;
            } else {
                this.endDateError = "";
                this.endDateErrorGreater = "";
            }
        }

        this.checkTimeDate();
    },
    checkTimeDate() {
      if (!this.exactStartDate && this.exactEndDate) {
        this.startDateError = "on";
      } else {
        this.startDateError = "";
      }

      if (!this.exactEndDate && this.exactStartDate) {
        this.endDateError = "on";
      } else {
        this.endDateError = "";
      }

      // if (this.exactStartDate && this.exactEndDate) {
        this.errorNull();
      // }
    },
    saveTournament(index) {
      if(this.isChecked) {
          if (!this.selectedAge || !this.selectedDivision) {
            this.showErrors = true;  // Show validation errors
          }
      
          else {
          const formData = {
            event_id: this.eventId,
            tournamentName: this.tournament_name,
            format: this.tournamentFormat,
            notes: this.tournamentNotes,
            startDateForField: this.startDateForField,
            endDateForField: this.endDateForField,
            startDate: this.exactStartDateformat,
            endDate: this.exactEndDateformat,
            exactStartDate: this.exactStartDate,
            exactEndDate: this.exactEndDate,
            association_id: this.association_id,
            rate_id: this.selectedDivision,
            age_group: this.selectedAge,
          };
          this.$set(this.isLoadingArray, index, true);

          axios.post('/associationEvent/createTournament', formData)
            .then(response => {

                if (response.data.statusCode === 200) {
                    
                    this.$root.$emit("fetchTournaments"); // Refresh tournaments
                    this.$refs.closeModalAddTournament.click(); // Close modal
                    this.dataReset(); // Reset form data
                    this.$set(this.isLoadingArray, index, false); // Reset loading state
                } 
                else if (response.data.statusCode === 404) {
                  this.$refs.closeModalAddTournament.click(); // Close modal
                    this.$set(this.isLoadingArray, index, false); // Reset loading state
                    this.$alert(response.data.message, 'Error');
                    this.$router.push({ name: "Teams" });            } 
                else {
                    
                    this.$set(this.isLoadingArray, index, false); // Reset loading state
                    this.$alert(response.data.message || "An unknown error occurred.");
                }
            })
            .catch(error => {
                // Handle request failure (network/server issue)
                console.error("Request failed: ", error);
                this.$set(this.isLoadingArray, index, false); // Reset loading state
                this.$alert("Failed to create tournament. Please try again.");
            });
          }
      }
      else {
           const formData = {
            event_id: this.eventId,
            tournamentName: this.tournament_name,
            format: this.tournamentFormat,
            notes: this.tournamentNotes,
            startDateForField: this.startDateForField,
            endDateForField: this.endDateForField,
            startDate: this.exactStartDateformat,
            endDate: this.exactEndDateformat,
            exactStartDate: this.exactStartDate,
            exactEndDate: this.exactEndDate,
            association_id: this.association_id,
            rate_id: 0,
            age_group: "",
          };
          this.$set(this.isLoadingArray, index, true);

          axios.post('/associationEvent/createTournament', formData)
            .then(response => {

                if (response.data.statusCode === 200) {
                    
                    this.$root.$emit("fetchTournaments"); // Refresh tournaments
                    this.$refs.closeModalAddTournament.click(); // Close modal
                    this.dataReset(); // Reset form data
                    this.$set(this.isLoadingArray, index, false); // Reset loading state
                } 
                else if (response.data.statusCode === 404) {
                  this.$refs.closeModalAddTournament.click(); // Close modal
                    this.$set(this.isLoadingArray, index, false); // Reset loading state
                    this.$alert(response.data.message, 'Error');
                    this.$router.push({ name: "Teams" });            } 
                else {
                    console.error(response.data.message);
                    this.$set(this.isLoadingArray, index, false); // Reset loading state
                    this.$alert(response.data.message || "An unknown error occurred.");
                }
            })
            .catch(error => {
                // Handle request failure (network/server issue)
                console.error("Request failed: ", error);
                this.$set(this.isLoadingArray, index, false); // Reset loading state
                this.$alert("Failed to create tournament. Please try again.");
            });
          }
    },
  },
  mounted() {

    this.$root.$on("openAddTournamentModal", () => {
      const elem = this.$refs.openModalAddTournament;
      if (elem) {
        elem.click();
      }
      this.initializeDate();
    });
  },
};
</script>
<style scoped>
.event-error {
  color: #ffffff !important;
  font-weight: 400;
  font-size: 10px;
  position: absolute;
  padding: 3px 8px;
  z-index: 99;
  border-radius: 5px;
  background-color: #ff5b66;
  top: 2px;
}

</style>