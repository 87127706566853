var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('button',{ref:"openGameModal",staticClass:"d-none",attrs:{"data-toggle":"modal","data-target":"#GameModal"}}),_c('div',{staticClass:"modal fade",attrs:{"id":"GameModal","tabindex":"-1","role":"dialog","aria-labelledby":"exampleModalLabel","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog product-cart-modal",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content d-block"},[_vm._m(0),_c('div',{staticClass:"modal-body overflow-auto"},[(_vm.showLoader === 1)?_c('div',{staticClass:"card-event mt-3 p-3"},[_c('content-placeholders',{attrs:{"rounded":true}},[_c('content-placeholders-img'),_c('content-placeholders-heading')],1),_c('br'),_c('content-placeholders',{attrs:{"rounded":true}},[_c('content-placeholders-img'),_c('content-placeholders-heading')],1)],1):(_vm.gameList.length === 0 && _vm.showLoader === 0)?_c('div',[_vm._m(1)]):_vm._e(),(_vm.gameList.length > 0 && _vm.showLoader === 0)?_c('div',{staticClass:"live-league-wrap p-3"},_vm._l((_vm.gameList),function(schedule,index){return _c('div',{key:index},[_c('div',{staticClass:"cursor-pointer",attrs:{"data-dismiss":"modal"},on:{"click":function($event){return _vm.callSingleBoxModal(schedule)}}},[_c('div',{staticClass:"schedule-info flex-column flex-md-row",class:{
                  'last-schedule-info': index === _vm.gameList.length - 1,
                },staticStyle:{"gap":"5px"}},[_c('div',[_c('span',{staticClass:"day-date"},[_vm._v(_vm._s(_vm.formatDate(schedule.start_date)))]),_c('div',{staticClass:"content"},[_vm._v(_vm._s(schedule.park?.park_name)+" "),(schedule.field)?_c('span',[_vm._v("-")]):_vm._e(),_vm._v(" "+_vm._s(schedule.field?.field_name))]),(schedule.umpire)?_c('div',{staticClass:"content"},[_vm._v("Umpire: "+_vm._s(schedule.umpire?.umpire_name ? schedule.umpire.umpire_name : 'Not Assigned'))]):_vm._e()]),_c('div',{staticClass:"d-flex align-items-center flex-wrap right-section-popup flex-column flex-md-row",staticStyle:{"gap":"10px"}},[(schedule.game_scores.length == 0)?_c('div',{staticClass:"opponent-data"},[_c('h6',[_vm._v(" "+_vm._s(schedule.opponent_name)+" ")]),_c('div',{staticClass:"custom-avatar",style:({ backgroundColor: _vm.getRandomColor() })},[_vm._v(" "+_vm._s(_vm.getInitials(schedule.opponent_name))+" ")]),_c('span',[_vm._v("-")])]):_vm._e(),(schedule.game_scores.length != 0)?_c('div',[(
                        schedule.game_scores?.some((team) => team.team_type === 1)
                      )?_c('div',{staticClass:"opponent-name"},[_c('h6',[_vm._v(" "+_vm._s(schedule.game_scores?.find( (team) => team.team_type === 1 )?.team_name)+" ")]),(
                          schedule.game_scores?.find(
                            (team) =>
                              team.team_type === 1 &&
                              team.team_name !== _vm.selectedRoomTeam.teamName
                          )
                        )?_c('div',{staticClass:"custom-avatar",style:({ backgroundColor: _vm.getRandomColor() })},[_vm._v(" "+_vm._s(_vm.getInitials( schedule.game_scores?.find( (team) => team.team_type === 1 )?.team_name ))+" ")]):_c('div',{staticClass:"team-image"},[(_vm.imgLoader == 1)?_c('div',{staticClass:"users-notification"},[_c('img',{attrs:{"src":require("../../../assets/loader.gif"),"alt":""}})]):_vm._e(),(_vm.selectedRoomTeam.teamAvatar == '')?_c('img',{attrs:{"src":_vm.team_image,"alt":""},on:{"load":_vm.onImageLoad}}):_vm._e(),(
                            _vm.selectedRoomTeam.teamAvatar != '' &&
                            schedule.game_scores?.find(
                              (team) =>
                                team.team_name === _vm.selectedRoomTeam.teamName
                            )
                          )?_c('img',{attrs:{"src":_vm.url + _vm.selectedRoomTeam.teamAvatar,"alt":""},on:{"load":_vm.onImageLoad}}):_vm._e()]),(
                              schedule.game_scores?.find(
                                (team) =>
                                  team.team_flag == 1 && team.team_type == 2
                              )
                            )?_c('span',[_vm._v(" "+_vm._s(schedule.opponent_score)+" ")]):_vm._e(),(
                              schedule.game_scores?.find(
                                (team) =>
                                  team.team_flag == 1 && team.team_type == 1
                              )
                            )?_c('span',[_vm._v(" "+_vm._s(schedule.team_score)+" ")]):_vm._e(),(
                          schedule.status === 1 &&
                          schedule.game_scores &&
                          schedule.game_scores?.find(
                            (team) =>

                              team.team_type == 1 &&
                              team.batting_flag == 1
                          )
                        )?_c('img',{attrs:{"src":_vm.arrow_left,"alt":"arrow-left"}}):_vm._e(),(schedule.status === 2 && schedule.win_status === 1 && schedule.game_scores[0].team_type == 1)?_c('img',{attrs:{"src":_vm.cup,"alt":"cup"}}):_vm._e(),(schedule.status === 2 && schedule.win_status === 2 && schedule.game_scores[0].team_type == 2)?_c('img',{attrs:{"src":_vm.cup,"alt":"cup"}}):_vm._e()]):_vm._e()]):_vm._e(),_c('div',{staticClass:"center-content"},[_c('span',[_vm._v(_vm._s(_vm._f("formatTimeToAM")(schedule.start_time)))]),(schedule.game_scores.length == 0)?_c('div',{staticClass:"begin-btn"},[_vm._v("Yet to Begin")]):_vm._e(),(schedule.game_scores.length != 0 && schedule.status === 1)?_c('div',{staticClass:"begin-btn inning-btn"},[_vm._v(" Inning: "+_vm._s(schedule.game_scores[0].end_inning_flag))]):_vm._e(),(schedule.game_scores.length != 0 && schedule.status === 2)?_c('div',{staticClass:"begin-btn final-btn"},[_vm._v(" Final")]):_vm._e(),_c('div',{staticClass:"content"},[_vm._v(_vm._s(schedule.name))])]),_c('div',{staticClass:"d-flex flex-row flex-wrap"},[(schedule.game_scores.length != 0)?_c('div',[(
                          schedule.game_scores?.some((team) => team.team_type === 2)
                        )?_c('div',{staticClass:"opponent-name"},[(
                            schedule.status === 1 &&
                            schedule.game_scores &&
                            schedule.game_scores?.find(
                              (team) =>

                                team.team_type == 2 &&
                                team.batting_flag == 1
                            )
                          )?_c('img',{staticClass:"arrow-image",attrs:{"src":_vm.arrow_left,"alt":"arrow-left"}}):_vm._e(),(schedule.status === 2 && schedule.win_status === 2 && schedule.game_scores[0].team_type == 1)?_c('img',{attrs:{"src":_vm.cup,"alt":"cup"}}):_vm._e(),(schedule.status === 2 && schedule.win_status === 1 && schedule.game_scores[0].team_type == 2)?_c('img',{attrs:{"src":_vm.cup,"alt":"cup"}}):_vm._e(),(
                              schedule.game_scores?.find(
                                (team) =>
                                  team.team_flag == 2 && team.team_type == 1
                              )
                            )?_c('span',[_vm._v(" "+_vm._s(schedule.team_score)+" ")]):_vm._e(),(
                              schedule.game_scores?.find(
                                (team) =>
                                  team.team_flag == 2 && team.team_type == 2
                              )
                            )?_c('span',[_vm._v(" "+_vm._s(schedule.opponent_score)+" ")]):_vm._e(),(
                            schedule.game_scores?.find(
                              (team) =>
                                team.team_type === 2 &&
                                team.team_name !== _vm.selectedRoomTeam.teamName
                            )
                          )?_c('div',{staticClass:"custom-avatar",style:({ backgroundColor: _vm.getRandomColor() })},[_vm._v(" "+_vm._s(_vm.getInitials( schedule.game_scores?.find( (team) => team.team_type === 2 )?.team_name ))+" ")]):_c('div',{staticClass:"team-image"},[(_vm.imgLoader == 1)?_c('div',{staticClass:"users-notification"},[_c('img',{attrs:{"src":require("../../../assets/loader.gif"),"alt":""}})]):_vm._e(),(_vm.selectedRoomTeam.teamAvatar == '')?_c('img',{attrs:{"src":_vm.team_image,"alt":""},on:{"load":_vm.onImageLoad}}):_vm._e(),(
                              _vm.selectedRoomTeam.teamAvatar != '' &&
                              schedule.game_scores?.find(
                                (team) =>
                                  team.team_name === _vm.selectedRoomTeam.teamName
                              )
                            )?_c('img',{attrs:{"src":_vm.url + _vm.selectedRoomTeam.teamAvatar,"alt":""},on:{"load":_vm.onImageLoad}}):_vm._e()]),_c('h6',[_vm._v(" "+_vm._s(schedule.game_scores?.find( (team) => team.team_type === 2 )?.team_name)+" ")])]):_vm._e()]):_c('div',{staticClass:"opponent-data"},[_c('span',[_vm._v("-")]),_c('div',{staticClass:"team-image"},[(_vm.imgLoader == 1)?_c('div',{staticClass:"users-notification"},[_c('img',{attrs:{"src":require("../../../assets/loader.gif"),"alt":""}})]):_vm._e(),(_vm.selectedRoomTeam.teamAvatar == '')?_c('img',{attrs:{"src":_vm.team_image,"alt":""},on:{"load":_vm.onImageLoad}}):_vm._e(),(
                            _vm.selectedRoomTeam.teamAvatar != ''
                          )?_c('img',{attrs:{"src":_vm.url + _vm.selectedRoomTeam.teamAvatar,"alt":""},on:{"load":_vm.onImageLoad}}):_vm._e()]),_c('h6',[_vm._v(" "+_vm._s(_vm.selectedRoomTeam.teamName)+" ")])])])]),_c('router-link',{staticClass:"m-0",attrs:{"to":{ name: 'SingleEventDetail', params: { id: _vm.singleGame.event.id } }}},[_c('h6',[_vm._v(" "+_vm._s(_vm.singleGame.event.eventName)+" ")])])],1)])])}),0):_vm._e()])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title",attrs:{"id":"exampleModalLabel"}},[_vm._v("Switch Game")]),_c('button',{staticClass:"close cursor-pointer",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"no-chat-text"},[_c('p',[_vm._v("No game is scheduled yet")])])
}]

export { render, staticRenderFns }