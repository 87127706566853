<template>
  <div>
    <servicesModal />
    <!-- <Navbar /> -->
    <div class="setting-main-wrapper">
      <div class="main-left-menu">
        <!-- <LeftMenu /> -->
      </div>
      <!-- <Banner /> -->
        <!-- <div class="no-gutters">
          <div class="col-12 px-0">
            <CalendarTop :listWhite="listBlue" :calenderBlue="calenderWhite" :tableBlue="tableBlue"/>
          </div>
        </div> -->

        <b-card style="border-radius: 5px" class="calender-card">
          <!--              <b-tabs  >-->
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade show active"
              role="tabpanel"
              aria-labelledby="calendar-tab"
            >
              <div class="embed-responsive">
                <div v-if="!calendar" class="active col-12 p-0">
                  <content-placeholders :rounded="true">
                    <content-placeholders-img />
                    <content-placeholders-heading />
                  </content-placeholders>
                </div>
               

                <div v-else class="active col-12 p-0">
                  <div id="calendar"></div>
                  <FullCalendar
                    ref="fullCalendar"
                    :events="events"
                    :options="calendarOptions"
                    class="demo-app-calendar"
                  >
                  </FullCalendar>
                </div>
                <sweet-modal ref="modal" title="Event Details">
                  <CalendarEventWrapper :singleEvent="singleEvent" :selectedRoomTeam="selectedRoomTeam"/>

                  <div class="col-12 p-0">
                    <div class="event-actions">
                      <ul>
                        <li
                          v-bind:class="[selectMainTab == 0 ? 'active' : '']"
                          v-on:click="selectAttendee()"
                        >
                          Attendees
                        </li>
                        <li
                          v-bind:class="[selectMainTab == 1 ? 'active' : '']"
                          v-on:click="selectGame()"
                        >
                          Games
                        </li>
                      </ul>
                    </div>

                    <div
                      v-if="
                        showEventMemeberCountShimmer == 0 && selectMainTab == 0
                      "
                    >
                      <div class="view-details py-0">
                        <h6 v-if="currentIsFan != '1'">Are you coming?</h6>
                      </div>
                      <div class="event-label pb-0">
                        <div class="col-12 px-0" v-if="currentIsFan != '1'">
                          <ul class="mb-0">
                            <!-- Past -->
                            <!-- <li
                                      v-if="this.eventTypeA == 'past'"
                                      v-bind:class="[
                                        currentUserEventStatus == 1
                                          ? 'active'
                                          : '',
                                      ]"
                                    >
                                      Going
                                    </li>

                                    <li
                                      v-if="this.eventTypeA == 'past'"
                                      v-bind:class="[
                                        currentUserEventStatus == 2
                                          ? 'active'
                                          : '',
                                      ]"
                                    >
                                      Not Going
                                    </li>

                                    <li
                                      v-if="this.eventTypeA == 'past'"
                                      v-bind:class="[
                                        currentUserEventStatus == 3
                                          ? 'active'
                                          : '',
                                      ]"
                                    >
                                      Maybe
                                    </li> -->

                            <!-- Upcoming -->
                            <li
                              class="cursor-p"
                              v-bind:class="[
                                currentUserEventStatus == 1
                                  ? 'active disabled'
                                  : '',
                              ]"
                              v-on:click="selectStatus(1)"
                            >
                              Going
                            </li>

                            <li
                              class="cursor-p"
                              v-bind:class="[
                                currentUserEventStatus == 2 ? 'active' : '',
                              ]"
                              v-on:click="selectStatus(2)"
                            >
                              Not Going
                            </li>

                            <li
                              class="cursor-p"
                              v-bind:class="[
                                currentUserEventStatus == 3 ? 'active' : '',
                              ]"
                              v-on:click="selectStatus(3)"
                            >
                              Maybe
                            </li>
                          </ul>
                        </div>

                        <div class="col-12 px-0">
                          <div class="user-counter">
                            <ul class="counting mb-0">
                              <li>
                                <span>{{ totalGoing }}</span>
                                <span>Going</span>
                              </li>
                              <li>
                                <span>{{ totalNotGoing }}</span>
                                <span>Not Going</span>
                              </li>
                              <li>
                                <span>{{ totalMayBe }}</span>
                                <span>Maybe</span>
                              </li>
                              <li>
                                <span>{{ totalNotRespond }}</span>
                                <span>Not Responded</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!--        =================== new  ==================-->

                    <div v-if="selectMainTab == 0" class="event-actions">
                      <ul>
                        <li
                          v-bind:class="[
                            selectAttendeeTab == 0 ? 'active' : '',
                          ]"
                          v-on:click="filterGoing()"
                        >
                          Going
                        </li>
                        <li
                          v-bind:class="[
                            selectAttendeeTab == 1 ? 'active' : '',
                          ]"
                          v-on:click="filterNotGoing()"
                        >
                          Not Going
                        </li>
                        <li
                          v-bind:class="[
                            selectAttendeeTab == 2 ? 'active' : '',
                          ]"
                          v-on:click="filterMay()"
                        >
                          Maybe
                        </li>
                        <li
                          v-bind:class="[
                            selectAttendeeTab == 3 ? 'active' : '',
                          ]"
                          v-on:click="filterNotResponded()"
                        >
                          Not Responded
                        </li>
                      </ul>
                    </div>

                    <div v-if="selectMainTab == 0">
                      <div v-if="showLoader == 1" class="friends-requests">
                        <content-placeholders :rounded="true">
                          <content-placeholders-heading :img="true" />
                        </content-placeholders>
                        <br />
                        <content-placeholders :rounded="true">
                          <content-placeholders-heading :img="true" />
                        </content-placeholders>
                      </div>

                      <div
                        v-if="showLoader == 0 && membersAttendee.length > 0"
                        class="calendar-user-info"
                      >
                        <div
                          v-for="user in membersAttendee"
                          :key="user.id"
                          class="user-info"
                        >
                          <div class="image-wrapper">
                           <div class="users-notification" v-if="imgLoader == 1">
        <!-- <div class="lds-dual-ring-refresh"></div> -->
        <img src="../../assets/loader.gif" alt="">
    </div>
                            <img
                              @load="onImageLoad"
                              v-if="user.c_id != ''"
                              :src="
                                API_URL + user.c_id + '/profile_picture.jpg'
                              "
                              alt=""
                              onerror="this.src='https://api.whoifollow.tech/user-default2.png'"

                            />
                            <img
                              @load="onImageLoad"
                              v-else
                              alt=""
                              src="../../assets/images/user-default-new.png"
                            />
                          </div>
                          <span class="text-capitalize">{{ user.username }}</span>
                        </div>
                      </div>
                      <div
                        v-if="membersAttendee.length == 0 && showLoader == 0"
                        class="col-12 p-0"
                      >
                        <span>No user found.</span>
                      </div>
                    </div>
                  </div>

                  <!--------------------------------------- Game List ----------------------------->
                  <div v-if="selectMainTab == 1" class="col-12 pl-0">
                    <div
                      v-if="showGameList == 1 || showDefualt == 1"
                      class="games-section p-0"
                    >
                      <div class="game-top border-0 p-0">
                        <div class="game-heading">
                          <h6>Games</h6>
                        </div>
                      </div>
                      <div class="container-fluid no-pdd">
                        <div
                          v-if="showGameShimmer == 0 && gameList.length > 0"
                          class="row"
                        >
                          <div
                            v-for="game in gameList"
                            :key="game.id"
                            class="col-12 col-md-6 pt-3 pr-0"
                          >
                            <div class="game-detail-wrapper">
                              <div class="game-content">
                                <div class="game-name">
                                  <h6>{{ game.name }}</h6>
                                </div>
                                <div class="date-content">
                                  <p>{{ game.exactDateFormat }}</p>
                                </div>
                                <div class="actions-content"></div>

                                <div
                                  class="game-location"
                                  @click="openGoogleMapforGamePark(game)"
                                  v-if="park_name != null || park_name != ''"
                                >
                                  <img
                                    src="../../assets/images/location.png"
                                    class="mr-2"
                                  />
                                  <p>{{ game.park_name }}</p>
                                </div>
                                <div
                                  class="game-location"
                                  v-if="
                                    park_address != null || park_address != ''
                                  "
                                >
                                  <p>
                                    {{ game.park_address }}
                                  </p>
                                </div>
                                <div
                                  class="game-location"
                                  v-if="
                                    park_number != null || park_number != ''
                                  "
                                >
                                  <p>{{ game.park_number }}</p>
                                </div>
                                <div class="opponent-name">
                                  <h5>{{ game.opponent_name }}</h5>
                                </div>
                                <div class="game-description">
                                  <p>{{ game.comment }}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          v-if="showGameShimmer == 0 && gameList.length == 0"
                        >
                          <div class="no-game-section">
                            <div class="no-content">
                              <div class="img">
                                <img
                                  src="../../assets/images/games.png"
                                  alt=""
                                />
                              </div>
                              <p class="text">
                                There are no games added for this event yet
                              </p>
                            </div>
                          </div>
                        </div>

                        <div v-if="showGameShimmer == 1" class="row">
                          <div class="col-12">
                            <div class="friends-requests">
                              <content-placeholders :rounded="true">
                                <content-placeholders-heading :img="true" />
                              </content-placeholders>
                              <br />
                              <content-placeholders :rounded="true">
                                <content-placeholders-heading :img="true" />
                              </content-placeholders>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </sweet-modal>
              </div>
            </div>
          </div>
        </b-card>
    </div>
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
// import listPlugin from "@fullcalendar/list";
//  import $  from "jquery";
import { SweetModal } from "sweet-modal-vue";
import axios from "axios";
import { parseTimestamp } from "../../utils/dates";
import { mapGetters, mapActions } from "vuex";
// import Navbar from "../Header/Navbar.vue";
// import Banner from "../BannerNotify/BannerPage.vue";
// import LeftMenu from "../Home/LeftMenu.vue";
import * as firestoreService from "@/database/firestore";
import servicesModal from "../Teams/ChatRight/ChatEvents/servicesModal.vue";
import CalendarEventWrapper from './CalendarEventWrapper.vue';
// import CalendarTop from './CalenderTop.vue'

export default {
  name: "Calendar",
  components: {
    // Navbar,
    // LeftMenu,
    FullCalendar,
    SweetModal,
    servicesModal,
    // Banner,
    CalendarEventWrapper,
    // CalendarTop
  },
  data: function () {
    return {
      imgLoader: 1,
      currentIsFan: "0",
      calendarOptions: {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,dayGridWeek,timeGridDay",
        },
        nextDayThreshold: "hahaha",
        initialView: "dayGridMonth",
        events: [],
        eventDisplay: "block",
        nowIndicator: true,
        locale: "en-gb",
        eventColor: "#188ef5",
        eventTextColor: "#ffffff",
        eventTimeFormat: {
          // like '14:30:00'
          hour: "2-digit",
          minute: "2-digit",
          // second: "2-digit
          hour12: true,
          meridiem: "short",
        },
        eventOrder: "displayOrder",
        businessHours: true, // display business hours
        editable: false,
        selectable: false,
        selectMirror: false,
        dayMaxEvents: true,
        weekends: true,
        allDaySlot: true,
        dayMaxEventRows: true,
        aspectRatio: 1.6,
        handleWindowResize: true,
        windowResizeDelay: 100,
        eventClick: this.handleEventClick,
      },
      calenderWhite: require("../../assets/images/calendar-white.png"),
      listBlue: require("../../assets/images/list-black.png"),
      tableBlue: require("../../assets/images/line-ellipsis.png"),
      currentEvents: [],
      events: [],
      TeamDetails: [],
      UserDetails: [],
      totalTeams: "",
      avatar: "",
      eventURL: this.$eventAvatarStorageURL,
      eventName: "",
      createdByName: "",
      createdByDate: "",
      association: "",
      teamName: "",
      eventType: "",
      status: "",
      address: "",
      city: "",
      Url: "",
      exactStartDate: "",
      exactEndDate: "",
      allDay: "",
      exactStartTime: "",
      time_zone: "",
      exactEndTime: "",
      eventTypeA: "",
      showEventMemeberCountShimmer: 0,
      currentUserEventStatus: 0,
      showLoader: 1,
      showEventsMember: 0,
      showGameList: 0,
      showDefualt: 1,
      totalGoing: 0,
      totalNotGoing: 0,
      totalMayBe: 0,
      totalNotRespond: 0,
      showGameShimmer: 0,
      UserDetail: [],
      UserRole: [],
      gameList: [],
      selectAttendeeTab: 0,
      membersAttendee: [],
      API_URL: this.$userProfileStorageURL,
      selectedRoomTeam: [],
      currentUserId: "",
      selectMainTab: 0,
      teamID: "",
      eventID: "",
      singleEvent: {},
      firstTimeEvents: [],
      selectedArray: [],
      room_count: 1,
      adult_count: 1,
      exactRoomEndDate: "",
      note: "",
      exactRoomStartDate: "",
      state: ""
    };
  },

  computed: {
    ...mapGetters(["errors"]),
    ...mapGetters("events", ["calendar"]),
    ...mapGetters("auth", ["user"]),
  },

  methods: {
    ...mapActions("events", ["setCalendarData"]),
    ...mapActions("events", ["getCurrentComingStatus"]),

    onImageLoad() {
      this.imgLoader = 0;
    },

    handleEvents(events) {
      this.currentEvents = events;
    },
    handleEventClick(clickInfo) {
      if (clickInfo.event.extendedProps.isTask) {
        
        return; // Exit the function without opening the modal
      }
      this.eventID = clickInfo.event.id;
      this.selectMainTab = 0;
      const formData = new FormData();
      formData.append("id", clickInfo.event.id);
      axios
        .post(
          process.env.VUE_APP_API_URL + "event/calenderEventDetail",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          let data = res.data.data;
          this.singleEvent = res.data.data;
          this.avatar = data.avatar;
          this.eventName = data.eventName;
          this.createdByName = data.createdByName;
          this.createdByDate = data.createdByDate;
          this.association = data.association ? data.association : "";
          this.teamName = data.teamName;
          this.eventType = data.eventType;
          this.status = data.status;
          this.address = data.address;
          this.city = data.city;
          this.Url = data.Url;
          this.exactStartDate = data.exactStartDate;
          this.exactEndDate = data.exactEndDate;
          this.allDay = data.allDay;
          this.exactStartTime = data.exactStartTime;
          this.time_zone = data.time_zone;
          this.exactEndTime = data.exactEndTime;

          this.teamID = data.team_id;
          this.eventID = data.id;

          this.UserDetail = [];
          this.UserRole = [];
          this.UserDetails.forEach((teams) => {
            if (teams.teamID == this.teamID) {
              this.UserDetail = teams.users;
              this.UserRole = teams.userRole;
              this.currentIsFan = teams.currentIsFan;
            }
          });

          this.getAttendee(data.id);
          this.selectedRoomTeam.eventCount = 0;
          this.selectedRoomTeam.eventCount =
            this.selectedRoomTeam.eventCount + 1;
          data.exactStartDate = data.exactStartDate.split(",")[0];
          var checkDateIfPast = parseTimestamp(
            data.endDateForField + "&&" + data.exactStartTime,
            "checkIfPast"
          );
          if (checkDateIfPast == "upComing") {
            this.eventTypeA = "upComing";
          } else {
            this.eventTypeA = "past";
          }
          this.getGames(data.id, data.team_id);

          this.$refs.modal.open();
          const popover = document.querySelector('.fc-popover');
          if (popover) {
            popover.style.display = 'none';
          }
        });
    },
    async selectStatusWithServices(status) {
      status = 1;
      var formData = new FormData();
      formData.append("event_id", this.eventID);
      formData.append("member_status", status);
      formData.append("team_id", this.teamID);
      formData.append("services", this.selectedArray);
      formData.append("room_count", this.room_count);
      formData.append("exactStartDate", this.exactRoomStartDate);
      formData.append("exactEndDate", this.exactRoomEndDate);
      formData.append("note", this.note);
      formData.append("adult_count", this.adult_count);
      await axios
        .post(
          process.env.VUE_APP_API_URL + "event/eventAttendeeSelect",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(() => {
          this.getAttendee(this.eventID);
          this.$refs.modal.open();
        });
    },
    async selectStatus(status) {
      console.log("status", status, this.singleEvent)
      if (status == 1) {
        this.$root.$emit("openServicesModal", this.singleEvent);
        this.$refs.modal.close();
      } else {
        var formData = new FormData();
        formData.append("event_id", this.eventID);
        formData.append("member_status", status);
        formData.append("team_id", this.teamID);
        await axios
          .post(
            process.env.VUE_APP_API_URL + "event/eventAttendeeSelect",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then(() => {
            this.getAttendee(this.eventID);
          });
      }
    },
    async getAttendee(Eventid) {
      this.showEventMemeberCountShimmer = 1;
      this.showLoader = 1;
      var formData = new FormData();
      formData.append("event_id", Eventid);
      await axios
        .post(
          process.env.VUE_APP_API_URL + "event/eventAttendeeListing",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          const eventAttendeeFromDB = response.data.data.attendeeListing;
          const tempMembers = this.UserDetail; //.users;
          const tempMembersRole = this.UserRole;
          this.membersAttendeeComplete = [];
          var $i = 0;
          this.totalGoing = 0;
          this.totalNotGoing = 0;
          this.totalMayBe = 0;
          this.totalNotRespond = 0;
          for (const member of tempMembers) {
            for (const memberRole of tempMembersRole) {
              if (memberRole.id == member.id && memberRole.fan != "1") {
                this.membersAttendeeComplete.push(member);

                this.membersAttendeeComplete[$i].event_attendee_status = 0;

                for (const attendee of eventAttendeeFromDB) {
                  if (member.c_id == attendee.member_id) {
                    this.membersAttendeeComplete[$i].event_attendee_status =
                      attendee.member_status;

                    if (
                      this.membersAttendeeComplete[$i].event_attendee_status ==
                        1 ||
                      this.membersAttendeeComplete[$i].event_attendee_status ==
                        "1"
                    ) {
                      this.totalGoing++;
                    }
                    if (
                      this.membersAttendeeComplete[$i].event_attendee_status ==
                        2 ||
                      this.membersAttendeeComplete[$i].event_attendee_status ==
                        "2"
                    ) {
                      this.totalNotGoing++;
                    }
                    if (
                      this.membersAttendeeComplete[$i].event_attendee_status ==
                        3 ||
                      this.membersAttendeeComplete[$i].event_attendee_status ==
                        "3"
                    ) {
                      this.totalMayBe++;
                    }
                  }
                }

                if (
                  this.membersAttendeeComplete[$i].event_attendee_status == 0 ||
                  this.membersAttendeeComplete[$i].event_attendee_status == "0"
                ) {
                  this.totalNotRespond++;
                }

                if (member.id == this.currentUserId) {
                  this.currentUserEventStatus =
                    this.membersAttendeeComplete[$i].event_attendee_status;
                }

                $i++;
              }
            }
          }
          this.showEventMemeberCountShimmer = 0;
          this.filterGoing();
          this.showLoader = 0;
          this.getCurrentStatusupdate();
        });
    },
    filterGoing: function () {
      this.selectAttendeeTab = 0;
      const filterGoing = this.membersAttendeeComplete;
      this.membersAttendee = [];
      for (const attendee of filterGoing) {
        if (
          attendee.event_attendee_status == 1 ||
          attendee.event_attendee_status == "1"
        ) {
          this.membersAttendee.push(attendee);
        }
      }
    },
    selectAttendee: function () {
      this.selectMainTab = 0;
    },
    selectGame: function () {
      this.selectMainTab = 1;
    },
    filterNotGoing: function () {
      this.selectAttendeeTab = 1;
      const filterGoing = this.membersAttendeeComplete;
      this.membersAttendee = [];
      for (const attendee of filterGoing) {
        if (
          attendee.event_attendee_status == 2 ||
          attendee.event_attendee_status == "2"
        ) {
          this.membersAttendee.push(attendee);
        }
      }
    },
    filterMay: function () {
      this.selectAttendeeTab = 2;
      const filterGoing = this.membersAttendeeComplete;
      this.membersAttendee = [];
      for (const attendee of filterGoing) {
        if (
          attendee.event_attendee_status == 3 ||
          attendee.event_attendee_status == "3"
        ) {
          this.membersAttendee.push(attendee);
        }
      }
    },
    filterNotResponded: function () {
      this.selectAttendeeTab = 3;
      const filterGoing = this.membersAttendeeComplete;
      this.membersAttendee = [];
      for (const attendee of filterGoing) {
        if (
          attendee.event_attendee_status == 0 ||
          attendee.event_attendee_status == "0"
        ) {
          this.membersAttendee.push(attendee);
        }
      }
    },
    showEventsMemberDiv: function () {
      this.showDefualt = 0;
      this.showGameList = 0;
      this.showEventsMember = 1;
    },
    showGameListDiv: function () {
      this.showDefualt = 0;
      this.showGameList = 1;
      this.showEventsMember = 0;
    },
    showDefualtDiv: function () {
      this.showDefualt = 1;
      this.showGameList = 0;
      this.showEventsMember = 0;
    },
    async getGames(singleEvent, selectedRoomTeam) {
      this.showGameShimmer = 1;
      var formData = new FormData();
      formData.append("event_id", singleEvent);
      formData.append("team_id", selectedRoomTeam);
      await axios
        .post(process.env.VUE_APP_API_URL + "game/gameListing", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.gameList = response.data.data;
          this.showGameShimmer = 0;
        });
    },
    async calendarData() {
      // const formData = new FormData();
      // formData.append("sportsRoomID", TeamDetails);
      axios
        .post(process.env.VUE_APP_API_URL + "event/calenderEvent",  {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.calendarOptions.events = res.data.data;
          this.firstTimeEvents = this.calendarOptions.events;
          this.setCalendarData(this.calendarOptions.events);
        });
    },
    async getCurrentStatusupdate() {
      let comingStatus = {
        teamID: this.TeamDetails,
        usersDetail: this.UserDetails,
        users: this.currentUserId,
      };
      console.log("comingStatus", comingStatus)
      this.getCurrentComingStatus(comingStatus);
    },
    openGoogleMapforGamePark(item) {
      const strlatLng = item["park_lat"] + "," + item["park_lng"];
      const urlSuffix = strlatLng ? strlatLng : item["park_address"];

      window.open(
        "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
        "_blank"
      );
    },

    async firebasedata(user_id) {
      this.TeamDetails = [];
      const querySport = firestoreService.roomsSportQuery(user_id);
      const { data, docs } = await firestoreService.getRooms(querySport);
      this.totalTeams = docs[docs.length - 1];
      console.log("teams 1", this.totalTeams)
      data.forEach((teams) => {
        this.TeamDetails.push(teams.id);
        //  Make Array for all Users on Team Start -----------
        const rawUsers = [];
        teams.users.forEach((userId) => {
          const promise = firestoreService.getUser(userId);
          rawUsers.push(promise);
        });
        this.teamUsers = "";
        var currentIsFan = "0";
        Promise.all(rawUsers).then((values) => {
          for (const role of teams.userRole) {
            for (const member of teams.users) {
              if (role.id == member) {
                if (member == this.currentUserId && role.fan == "1") {
                  currentIsFan = "1";
                }
                if (member == this.currentUserId && role.fan != "1") {
                  currentIsFan = "0";
                }
              }
            }
          }
          let teamDeta = {
            teamID: teams.id,
            users: values,
            userRole: teams.userRole,
            currentIsFan: currentIsFan,
          };
          this.UserDetails.push(teamDeta);
        });
      });
      // this.calendarData(this.TeamDetails);
    },

    async getUserChatId() {
        let userId = this.user?.id; // Check if this.user exists and get ID
        if (!userId) {
            userId = localStorage.getItem('userId'); // Get from local storage if not found
        }

        if (!userId) {
            console.error("User ID not found in both state and local storage.");
            return;
        }

        console.log("Current User ID:", userId);

        try {
            const promiseUser = await firestoreService.getUserCid(userId.toString());
            if (promiseUser.data && promiseUser.data.length > 0) {
                this.currentUserId = promiseUser.data[0].id;
                this.firebasedata(this.currentUserId);
            } else {
                console.warn("No chat ID found for user.");
            }
        } catch (error) {
            console.error("Error fetching user chat ID:", error);
        }
    }
  },


  // watch: {
  //   // TeamDetails() {
  //   //   this.calendarData(this.TeamDetails); // watch : undefined
  //   // },
  // },

  // created() {
  //   window.addEventListener("load", function () {
  //     location.href = "/my-events";
  //   });
  // },

  mounted() {
    this.getUserChatId();
    this.calendarData();
    this.calendarOptions.events = this.calendar;
    this.$root.$on(
      "dataForservice",
      (
        data,
        room_count,
        adultQuantity,
        note,
        exactRoomStartDate,
        exactRoomEndDate
      ) => {
        // this.selectedArray = data;
        this.selectedArray = data;
        this.room_count = room_count;
        this.note = note;
        this.adult_count = adultQuantity;
        this.exactRoomEndDate = exactRoomEndDate;
        this.exactRoomStartDate = exactRoomStartDate;
        this.selectStatusWithServices();
      }
    );
  },
};
</script>

<style>
.demo-app {
  display: flex;
  min-height: 100%;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}

.demo-app-main {
  background-color: white;
  padding-top: 95px;
  flex-grow: 1;
  padding-left: 3em;
  padding-right: 3em;
  padding-bottom: 3em;
}

.fc-col-header-cell-cushion,
.fc-theme-standard .fc-list-day-cushion a {
  color: black !important;
  font-size: 15px !important;
  font-weight: 500 !important;
}

.fc .fc-daygrid-day-number {
  color: black !important;
  font-size: 15px;
  font-size: 500 !important;
}

.fc .fc-button-primary {
  background-color: #e5f2fe !important;
  color: #188ef5 !important;
  border: 1px solid #e5f2fe !important;
  text-transform: capitalize !important;
}

.fc .fc-timegrid-axis-cushion {
  text-transform: capitalize;
}

.fc-button:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.fc-button:hover {
  background-color: #188ef5 !important;
  border: 1px solid #188ef5 !important;
  color: #ffffff !important;
}

.fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active {
  background-color: #188ef5 !important;
  border: 1px solid #188ef5 !important;
  color: #ffffff !important;
  cursor: not-allowed;
}

.fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active:hover {
  color: #ffffff !important;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: #b9c6d3 !important;
}

.fc-daygrid-event {
  margin: 0 !important;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end {
  margin-right: 0 !important;
}

.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  margin-left: 0 !important;
}

@media screen and (max-width: 768px) {
  .demo-app-main {
    /* height: 100vh; */
    overflow: auto;
    padding-left: 0.5em;
    padding-right: 0.5em;
  }

  .fc-col-header-cell-cushion,
  .fc-theme-standard .fc-list-day-cushion a {
    font-size: 10px !important;
    font-weight: normal !important;
  }

  /*.fc-list-event {*/
  /*  font-size: 10px !important;*/
  /*}*/
  .fc-view-harness {
    min-height: 276px !important;
  }

  .fc-toolbar-chunk {
    margin-bottom: 10px;
  }

  .fc .fc-toolbar-title {
    font-size: 1rem;
  }

  .fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 5px;
  }

  .fc .fc-toolbar {
    flex-direction: column;
  }

  .fc .fc-daygrid-day-bottom {
    margin-top: -10px !important;
  }

  .fc .fc-daygrid-day-frame {
    min-height: 75px;
  }

  .sweet-modal.is-mobile-fullscreen {
    max-height: 100% !important;
    height: calc(100vh - 58px) !important;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@media screen and (max-width: 460px) {
  .fc .fc-daygrid-day-bottom {
    font-size: 0.6rem;
    padding: 2px 0 !important;
  }
  .fc-col-header-cell-cushion,
  .fc-theme-standard .fc-list-day-cushion a {
    font-size: 8.5px !important;
    font-weight: normal !important;
  }
}

/*===============TOGGLE ==========*/
.event-tab22 ul {
  list-style-type: none;
  display: flex;
  margin-bottom: 0;
}

.event-tab22 ul li {
  color: #c4c4c4;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 10px;
}

.event-tab22 ul li:first-child {
  border-radius: 5px 0 0 5px !important;
  display: flex;
  align-items: center;
  gap: 10px;
}

.event-tab22 ul li:last-child {
  border-radius: 0 5px 5px 0 !important;
  display: flex;
  align-items: center;
  gap: 10px;
}

.event-tab22 .nav-tabs {
  border-bottom: 0 !important;
}

.event-tab22 .nav-tabs .nav-item.show .nav-link,
.event-tab22 .nav-tabs .nav-link.active {
  background-color: #188ef5 !important;
  border-radius: inherit !important;
}

.event-tab22 .nav-tabs .nav-link img {
  width: 20px;
}

.event-tab22 .nav-tabs .nav-link {
  border: 0 !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e5f2fe;
}

/*==========calendar==========*/

.event-details-wrapper {
  padding-top: 10px;
  overflow-x: hidden;
  z-index: 99;
}

.event-details-top {
  display: flex;
  align-items: center;
}

.event-details-top .back-arrow {
  color: #188ef5;
  font-size: 20px;
  margin-right: 15px;
  cursor: pointer;
  padding: 0 5px;
}

.event-details-top .back-arrow:hover {
  background-color: #188ef5;
  color: #ffffff;
  border-radius: 5px;
}

.event-details-top h6 {
  margin: 0;
  text-transform: uppercase;
  color: #a7a6a6;
  font-weight: 500;
}

.event-image-name {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
}

.event-image-name .image {
  min-width: 120px;
  max-width: 120px;
  overflow: hidden;
  border-radius: 5px;
  margin-right: 10px;
}

.event-image-name .image img {
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}
.created-by,
.date {
  font-size: 14px;
  font-weight: 500;
}

.created-by .heading,
.date .heading {
  color: #a7a6a6;
  margin-right: 3px;
  font-weight: 400;
}

.event-description {
  padding-bottom: 15px;
}

.event-label h6 {
  color: #333333;
  font-weight: 400;
  margin: 10px 5px;
  font-size: 16px;
}

.disabled {
  pointer-events: none;
  opacity: 0.6;
}
.cursor-p {
  cursor: pointer;
}

.event-label ul li.active {
  background-color: #188ef5;
  color: #ffffff;
}

.event-label ul li.active:before {
  content: "✓";
  position: absolute;
  top: -10px;
  background-color: #ff5b66;
  width: 15px;
  height: 15px;
  font-size: 10px;
  border-radius: 5px;
  text-align: center;
}

.view-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/*.view-details ul li {*/
/*  cursor: pointer;*/
/*}*/

.view-details span {
  font-weight: 500;
  font-size: 14px;
  text-align: right;
  color: #188ef5;
  cursor: pointer;
}

/* .user-counter .counting {
  display: block;
  align-items: center;
  padding-bottom: 8px;
}

.user-counter .counting li span {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.user-counter .counting li span:first-child {
  font-size: 14px;
  font-weight: 500;
  color: #333333;
  margin-bottom: 5px;
}

.user-counter .counting li span:last-child {
  font-weight: 400;
  font-size: 13px;
  color: #b4b3b3; */
/* overflow: hidden; */
/* text-overflow: ellipsis; */
/* display: -webkit-box; */
/* -webkit-line-clamp: 1; number of lines to show */
/* -webkit-box-orient: vertical; */
/* } */

.games-section {
  padding: 12px 0;
}

.game-content-section {
  max-width: 350px;
  overflow-x: auto;
}

.game-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.game-top .game-heading h6 {
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}

.game-top .game-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.c-side {
  align-items: flex-start !important;
}

.date-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.date-content p {
  font-weight: 500;
  font-size: 10px;
  color: #ff5b66;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
}

.actions-content {
  width: 12px;
  position: relative;
}

.calender-card {
  margin-top: 15px;
}
</style>
