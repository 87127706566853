<template>
  <div class="history-wrapper">
    <div>
      <div class="history-heading">
        <h6>
          Cart
          <div class="cart-items" v-if="cartItemCount == 1">
            <span>{{ cartItemCount }} Item{{ cartItemCount > 1 ? "S" : "" }}</span>
          </div>
        </h6>
        <EditCart />
      </div>
      <div class="history-wrapper-content">
        <div class="history-content" v-for="item in cart" :key="item.product.id">
          <div class="history-info">
            <div class="item-info-wrapper">
              <div class="item-image">
                <div class="users-notification" v-if="imgLoader == 1">
                  <!-- <div class="lds-dual-ring-refresh"></div> -->
                  <img src="../../../assets/loader.gif" alt="">
                </div>
                <img @load="onImageLoad" v-if="item.product.products.image != null"
                  :src="API_URL + item.product.products.image" alt="" />
                <img @load="onImageLoad" v-else src="../../../assets/images/gallery.png" alt="" />
              </div>
              <div class="item-name">
                <span class="category">{{ item.product.products.name }}</span>
                <div class="item-quantity">
                  <span>{{ item.quantity }}x</span>
                  <span>${{ (item.product.products.price).toFixed(2) }} / Piece</span>
                </div>
              </div>
            </div>
            <span>${{ (item.quantity * item.product.products.price).toFixed(2) }}</span>
          </div>
        </div>
      </div>
    </div>
    <div>
      <!-- <div class="history-code">
        <div class="code-heading">
          <span>Promocode</span>
        </div>
        <div class="code-content">
          <span>enter code</span>
        </div>
      </div> -->
      <div class="history-total">
        <div class="total-heading">
          <div>Subtotal</div>
          <div>Tax <span class="tax">({{ taxPercentage }}%)</span></div>
          <div>Shipping <span class="tax">({{ shippingCost }}%)</span></div>
          <div>Discount</div>
          <div>Total Payable</div>
        </div>
        <div class="total-content">
          <div>${{ cartTotalPrice.toFixed(2) }}</div>
          <div>${{ taxAmount }}</div>
          <div>${{ shippingAmount }}</div>
          <div>$0</div>
          <div>${{ totalPayable }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import EditCart from "../Order/EditCart.vue";
import $ from "jquery";
export default {
  components: { EditCart },
  name: "ItemHistory",

  computed: {
  ...mapState("cart", ["cart"]),
  ...mapGetters("cart", ["cartTotalPrice", "cartItemCount", "shopConfigData"]),

  taxPercentage() {
    return this.shopConfigData?.tax_percentage ?? 0;
  },
  shippingCost() {
    return this.shopConfigData?.shipping_cost ?? 0;
  },
  taxAmount() {
    return ((this.cartTotalPrice / 100) * this.taxPercentage).toFixed(2);
  },
  shippingAmount() {
    return ((this.cartTotalPrice / 100) * this.shippingCost).toFixed(2);
  },
  totalPayable() {
    return (
      this.cartTotalPrice +
      parseFloat(this.taxAmount) +
      parseFloat(this.shippingAmount)
    ).toFixed(2);
  }
},

  data() {
    return {
      API_URL: this.$productImageStorageURL,
      imgLoader: 1,
    };
  },

  methods: {
    onImageLoad() {
      this.imgLoader = 0;
    },
  },

  mounted() {
    $(window).ready(function(){
    $(window).scrollTop(0);
});
  },
};

</script>
