var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contact-us-form"},[_c('div',{staticClass:"sign-in-form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.Contact(0)}}},[_c('h4',{staticClass:"mb-3"},[_vm._v("Contact Us")]),(_vm.errorMessage != null)?_c('p',{staticClass:"tag-line",staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e(),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.fname.$model),expression:"$v.details.fname.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                        'is-invalid': _vm.validationStatus(_vm.$v.details.fname),
                         filled: _vm.$v.details.fname.$model.trim() !== ''
                    },attrs:{"id":"fname","spellcheck":"false","autocomplete":"off","readonly":!!_vm.user,"type":"text","required":""},domProps:{"value":(_vm.$v.details.fname.$model)},on:{"keyup":_vm.errorNull,"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.fname, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('span',{staticClass:"placeholder"},[_vm._v("First Name")]),(!_vm.$v.details.fname.required)?_c('div',{staticClass:"invalid-tooltip"},[_vm._v(" First name is required ")]):_vm._e(),(!_vm.$v.details.fname.maxLength)?_c('div',{staticClass:"invalid-tooltip"},[_vm._v(" Maximum 18 characters allowed. ")]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.lname.$model),expression:"$v.details.lname.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                        'is-invalid': _vm.validationStatus(_vm.$v.details.lname),
                         filled: _vm.$v.details.lname.$model.trim() !== ''
                    },attrs:{"id":"lname","spellcheck":"false","autocomplete":"off","readonly":!!_vm.user,"required":"","type":"text"},domProps:{"value":(_vm.$v.details.lname.$model)},on:{"keyup":_vm.errorNull,"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.lname, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('span',{staticClass:"placeholder"},[_vm._v("Last Name")]),(!_vm.$v.details.lname.required)?_c('div',{staticClass:"invalid-tooltip"},[_vm._v(" Last name is required ")]):_vm._e(),(!_vm.$v.details.lname.maxLength)?_c('div',{staticClass:"invalid-tooltip"},[_vm._v(" Maximum 18 characters allowed. ")]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.email.$model),expression:"$v.details.email.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                  'is-invalid': _vm.validationStatus(_vm.$v.details.email),
                  filled: _vm.$v.details.email.$model.trim() !== '' // Add class when value exists
                },attrs:{"id":"email","spellcheck":"false","autocomplete":"off","readonly":!!_vm.user,"required":"","type":"text"},domProps:{"value":(_vm.$v.details.email.$model)},on:{"keyup":_vm.errorNull,"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.email, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('span',{staticClass:"placeholder"},[_vm._v("Email")]),(!_vm.$v.details.email.required)?_c('div',{staticClass:"invalid-tooltip"},[_vm._v(" Email is required ")]):_vm._e(),(!_vm.$v.details.email.email)?_c('div',{staticClass:"invalid-tooltip"},[_vm._v(" Provide valid email ")]):_vm._e()]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"form-group phone-code w-25 pr-2"},[_c('b-form-select',{attrs:{"options":_vm.codeList},on:{"keyup":_vm.errorNull},scopedSlots:_vm._u([{key:"first",fn:function(){return undefined},proxy:true}]),model:{value:(_vm.phonedetails.mob_code),callback:function ($$v) {_vm.$set(_vm.phonedetails, "mob_code", $$v)},expression:"phonedetails.mob_code"}})],1),_c('div',{staticClass:"form-group flex-grow-1"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.phonedetails.mob),expression:"phonedetails.mob",modifiers:{"trim":true}},{name:"phone-mask",rawName:"v-phone-mask"}],staticClass:"form-control",class:{
                            'is-invalid': _vm.validationStatus(_vm.$v.phonedetails.mob),
                            filled: _vm.$v.phonedetails.mob.$model.trim() !== ''
                        },attrs:{"id":"mob_phone","spellcheck":"false","autocomplete":"off","required":"","type":"text"},domProps:{"value":(_vm.phonedetails.mob)},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"delete",[8,46],$event.key,["Backspace","Delete","Del"]))return null;return _vm.handleDelete.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"backspace",undefined,$event.key,undefined))return null;return _vm.handleDelete.apply(null, arguments)}],"keyup":_vm.errorNull,"input":function($event){if($event.target.composing)return;_vm.$set(_vm.phonedetails, "mob", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('span',{staticClass:"placeholder"},[_vm._v("Mobile Number")]),(!_vm.$v.phonedetails.mob.required)?_c('div',{staticClass:"invalid-tooltip"},[_vm._v(" Mobile number is required ")]):_vm._e(),(!_vm.$v.phonedetails.mob.validPhone)?_c('div',{staticClass:"invalid-tooltip"},[_vm._v(" Please enter a valid 10-digit phone number ")]):_vm._e()])]),_c('div',{staticClass:"form-group"},[_c('textarea',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.note.$model),expression:"$v.note.$model",modifiers:{"trim":true}}],staticClass:"form-control pt-3 mt-2 mb-0 add-notes",attrs:{"type":"text","id":"note","rows":"4","placeholder":"Comments","maxlength":"200"},domProps:{"value":(_vm.$v.note.$model)},on:{"keyup":_vm.errorNull,"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.note, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',{staticClass:"event-btns-wrapper"},[_c('button',{staticClass:"btn btn-primary w-100",attrs:{"type":"button","disabled":_vm.isFormInvalid || _vm.isLoadingArray[0]},on:{"click":function($event){return _vm.Contact(0)}}},[(_vm.isLoadingArray[0])?_c('div',{staticClass:"lds-dual-ring"}):_c('span',[_vm._v("Continue")])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }