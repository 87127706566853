<template>
  <div>
    <TeamLineUpModal
      :selectedRoomTeam="selectedRoomTeam"
      :singleGame="singleGame"
    />
    <div class="lineup" @click="callModal()">
      <small> Manage Lineup</small>
    </div>
  </div>
</template>

<script>
import TeamLineUpModal from "./TeamLineUpModal.vue";
export default {
  name: "TeamLineUp",
  components: { TeamLineUpModal },
  props: {
    selectedRoomTeam: {
      type: Object,
    },
    singleGame: {
      type: Object,
    },
  },
  methods: {
    callModal: function () {
      this.$root.$emit("openTeamLineUpModal", this.singleGame);
    },
  },
};
</script>

<style>
</style>
