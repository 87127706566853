<template>
  <div>
    <EditTournament :association_id="association_id" :eventId="eventId" :eventExactStartDate="eventExactStartDate" :eventExactEndDate="eventExactEndDate" :ageOptions="ageOptions" :ratingOptions="ratingOptions" />
    <div class="opt edit-btn m-0" ref="openEllipsisTournament">
      <img
        src="../../assets/images/ellipsis-h.png"
        alt=""
        @click="toggleMenu"
        v-click-outside="hideMenu"
      />
      <div
        class="sub-setting-options edit-event-options"
        v-if="showMenu"
        :style="{ top: dynamicTop }"
        ref="subSettingOptions"
      >
        <ul class="d-block pb-0">
          <li class="cursor-pointer" @click="callModal()">Update Division</li>
        </ul>
      </div>
    </div>
  </div>
</template>
  
  <script>
import EditTournament from './Modals/EditTournament.vue';
export default {
  name: "EditTournamentEllipsis",
  components: {
    EditTournament
  },
  data() {
    return {
      showMenu: false,
    };
  },
  watch: {
    showMenu(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          const ulElement = this.$refs.subSettingOptions
            ? this.$refs.subSettingOptions.querySelector("ul")
            : null;
          const liCount = ulElement ? ulElement.children.length : 0;

          if (liCount === 1) {
            this.dynamicTopValue = "-20px";
          } else {
            this.dynamicTopValue = "-100px";
          }
        });
      }
    },
  },
  computed: {
    dynamicTop() {
      return this.dynamicTopValue;
    },
  },
  props: {
    tournament: {
      type: Object,
      required: true
    },
    eventId: {
      // Define umpires prop to receive the umpire data
      type: Number,
    },
    association_id: {
      type: Number,
    },
    eventExactStartDate: {
      type: String,
    },
    eventExactEndDate: {
      type: String,
    },
    ratingOptions: {
      type: Array,
    },
    ageOptions: {
      type: Array,
    },    
  },
  methods: {
    getLiCount() {
      if (this.$refs.subSettingOptions) {
        const ulElement = this.$refs.subSettingOptions.querySelector("ul");
        return ulElement ? ulElement.children.length : 0;
      }
      return 0;
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    hideMenu() {
      this.showMenu = false;
    },
    callModal() {
      this.$root.$emit("openTournamentEditModal", this.tournament);
    },
  },
  mounted() {
    this.$root.$on("openEditTournamentEllipsi", () => {
      var elem = this.$refs.openEllipsisTournament;
      if (elem) {
        elem.click();
      }
    });
  },
};
</script>
  
  <style scoped>


.sub-setting-options {
  position: absolute;
  right: 95% !important;
  top: -27px!important;
}

.sub-setting-options ul li {
  text-align: start;
}

</style>
  