<template>
  <!-- Modal -->
  <div>
    <div
      class="modal fade"
      id="GoPaymentModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Pay Now</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="crudClear"
              ref="closeEventModalPay"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form onsubmit="return false;" id="form1">
              <!--      Make your own form or copy this one -->
              <div class="group">
                <div>
                  <label>First Name *</label>
                  <input
                    name="cardholder-first-name"
                    class="field input-box w-100"
                    placeholder="First Name"
                    v-model="firstname"
                    @keyup="validateCrud"
                  />
                </div>
                <div>
                  <label>Last Name *</label>
                  <input
                    name="cardholder-last-name"
                    class="field input-box w-100"
                    placeholder="Last Name"
                    @keyup="validateCrud"
                    v-model="lastname"
                  />
                </div>
                <div>
                  <label>Mobile *</label>
                  <input
                    name="phone"
                    class="field input-box w-100"
                    placeholder="+1000000000000"
                    @keyup="validateCrud"
                    v-model="phone"
                  />
                </div>
              </div>
              <div class="group">
                <label>
                  <span>Card *</span>
                  <div
                    class="card-content d-lg-flex align-items-center justify-content-between w-100"
                  >
                    <div
                      id="card-element"
                      class="d-flex align-items-center my-2 w-100 w-lg-50"
                    >
                      <div
                        name="staxjs-number"
                        id="staxjs-number"
                        style="
                          width: 80%;
                          font-size: 1rem;
                          height: 40px;
                          padding: 10px;
                          border: 1px solid rgb(204, 204, 204);
                          margin-right: 8px;
                          border-radius: 5px;
                          text-align: center;
                        "
                      ></div>
                      <div
                        name="cvv"
                        id="staxjs-cvv"
                        style="
                          width: 18%;
                          font-size: 1rem;
                          height: 40px;
                          padding: 10px;
                          border: 1px solid rgb(204, 204, 204);
                          border-radius: 5px;
                          text-align: center;
                        "
                      ></div>
                    </div>
                    <div
                      class="validity d-flex align-items-center w-100 w-lg-50 mt-2 mt-lg-0 justify-content-start ml-0 ml-lg-2"
                    >
                      <div>
                        <input
                          name="month"
                          size="3"
                          maxlength="2"
                          placeholder="MM"
                          @keyup="validateCrud"
                          v-model="month"
                          style="
                            width: 100%;
                            font-size: 1rem;
                            height: 40px;
                            padding: 10px;
                            border: 1px solid rgb(204, 204, 204);
                            margin-right: 5px;
                            border-radius: 5px;
                            text-align: center;
                          "
                        />
                      </div>
                      <div><i class="mx-2 fa-1x">/</i></div>
                      <div>
                        <input
                          name="year"
                          size="5"
                          @keyup="validateCrud"
                          maxlength="4"
                          placeholder="YYYY"
                          v-model="year"
                          style="
                            width: 100%;
                            font-size: 1rem;
                            height: 40px;
                            border: 1px solid rgb(204, 204, 204);
                            border-radius: 5px;
                            text-align: center;
                          "
                        />
                      </div>
                    </div>
                  </div>
                </label>
              </div>
              <div class="form-group">
                <div class="form-check mb-2">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="gridCheck"
                    v-model="checkbox"
                    @change="validateCrud"
                  />
                  <label class="form-check-label" for="gridCheck"
                    >I accept Terms & Conditions</label
                  >
                </div>
              </div>
              <div class="event-btns-wrapper justify-content-end">
                <button
                  id="paybutton"
                  type="submit"
                  class="btn main-btn w-100"
                  block
                >
                  <div v-if="showLoader === 1" class="lds-dual-ring-refresh"></div>
                  <span v-else  class="fee-price">Pay $ {{ fee.price }}</span>
                </button>
              </div>
              <div class="outcome position-relative">
                <div class="error"></div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div
      data-target="#GoPaymentModal"
      data-toggle="modal"
      ref="openPaymentModal"
    ></div>
  </div>
</template>

<script src="https://staxjs.staxpayments.com/stax.js?nocache=2"></script>
<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
export default {
  name: "GoPayment",
  // props: {
  //   fee: Object,
  // },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  data() {
    return {
      fee: {},
      errorMessage: "",
      showLoader: 0,
      total: 26,
      firstname: "",
      lastname: "",
      month: "",
      year: "",
      phone: "",
      address_1: "100 S Orange Ave",
      address_2: "Suite 400",
      address_city: "Orlando",
      address_state: "FL",
      address_zip: "32811",
      address_country: "USA",
      send_receipt: false,
      validate: false,
      checkbox: "",
      StaxPayKey: this.$staxPayKey,
      meta: {
        reference: "invoice-reference-num", // optional - will show up in emailed receipts
        memo: "notes about this transaction", // optional - will show up in emailed receipts
        otherField1: "other-value-1", // optional - we don't care
        otherField2: "other-value-2", // optional - we don't care
        subtotal: 0, // optional - will show up in emailed receipts
        tax: 0, // optional - will show up in emailed receipts, and used for L2 processing. Accepts the tax in dollar amount. To qualify for L2 processing rates, the tax dollar amount must be equivalent to 0.1% and 30% of the transaction's total.
        poNumber: "7649", // customer code used for L2 processing.
        shippingAmount: 0, // the shipping amount for the transaction used for L2 processing
        lineItems: [
          // optional - will show up in emailed receipts
          {
            id: "optional-fm-catalog-item-id",
            item: "Demo Item",
            details: "this is a regular demo item",
            quantity: 0,
            price: 0,
          },
        ],
        invoice_merchant_custom_fields: [
          {
            id: "dc4b-6c74-00dd-fab1-fe00", // Required, must be a unique string.
            name: "External ID", // The name of the custom field that will be displayed to your customers and users; this will appear above the field as a label.
            placeholder: "Ex. #123", // The placeholder for the field; this is the faint text that will appear in the entry box of your custom field to help guide your users before they enter in the value when creating an Invoice.
            required: true, // Determines if this field is required to be filled by the user (not customer) when first creating an Invoice.
            type: "text", // Input type. Only 'text' is supported.
            value: "123456789", // The value that will appear when viewing the Invoice or Invoice Email. For the merchant, this will also appear when viewing the Invoice via the Invoices or Edit Invoice pages.
            visible: true, // This determines if the field is visible when viewing the Invoice or Invoice Email. If false, will only appear in merchant-facing pages such as the Invoices or Edit Invoice pages.
          },
        ],
      },
    };
  },
  methods: {
    ...mapActions("auth", ["getUserData"]),
    crudClear() {
      this.firstname = "";
      this.lastname = "";
      this.phone = "";
      this.month = "";
      this.year = "";
      this.errorMessage = "";
      this.checkbox = "";
      var errorElement = document.querySelector(".error");
      errorElement.classList.remove("visible");
    },
    validateCrud() {
      var payButton = document.querySelector("#paybutton");
      if (
        this.firstname == "" ||
        this.lastname == "" ||
        this.phone == "" ||
        this.month == "" ||
        this.year == ""
      ) {
        payButton.disabled = true;
        payButton.style.opacity = "0.4";
      }
      if (document.getElementById("gridCheck").checked) {
        payButton.disabled = false;
        payButton.style.opacity = "1";
      } else {
        payButton.disabled = true;
        payButton.style.opacity = "0.4";
      }
    },
  },
  mounted() {
    this.$root.$on("openGoPayment", (product) => {
      this.fee = product;
      var elem = this.$refs.openPaymentModal;
      elem.click();
      this.crudClear();
    });

    var payButton = document.querySelector("#paybutton");

    // Init StaxJs SDK
    var staxJs = new StaxJs(this.StaxPayKey, {
      number: {
        id: "staxjs-number",
        placeholder: "0000 0000 0000 0000",
      },
      cvv: {
        id: "staxjs-cvv",
        placeholder: "000",
      },
    });

    // tell staxJs to load in the card fields
    staxJs
      .showCardForm()
      .then((handler) => {
        // for testing!
        handler.setTestPan("");
        handler.setTestCvv("");
        var form = document.querySelector("#form1");
        form.querySelector("input[name=month]").value = "";
        form.querySelector("input[name=year]").value = "";
        form.querySelector("input[name=cardholder-first-name]").value;
        form.querySelector("input[name=cardholder-last-name]").value;
        form.querySelector("input[name=phone]").value = "";
      })
      .catch((err) => {
       
        // reinit form
      });

    staxJs.on("card_form_complete", (message) => {
      // activate pay button
      payButton.disabled = false;
      payButton.style.opacity = "1";
    });

    staxJs.on("card_form_incomplete", (message) => {
      // deactivate pay button
      payButton.disabled = true;
      payButton.style.opacity = "0.4";
    });

    document.querySelector("#paybutton").onclick = () => {
      this.showLoader = 1;
      payButton.disabled = true;
      var errorElement = document.querySelector(".error");
      errorElement.classList.remove("visible");
      var form = document.querySelector("#form1");
      const getRandomId = (min = 1000, max = 500000) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        const num = Math.floor(Math.random() * (max - min + 1)) + min;
        return num;
      };
      var extraDetails = {
        total: this.fee.price, // 1$
        firstname: form.querySelector("input[name=cardholder-first-name]")
          .value,
        lastname: form.querySelector("input[name=cardholder-last-name]").value,
        company: "WHIIFOLLOW",
        email: this.user.email,
        month: form.querySelector("input[name=month]").value,
        year: form.querySelector("input[name=year]").value,
        phone: form.querySelector("input[name=phone]").value,
        address_1: "whoifollow",
        address_2: "",
        address_city: "California",
        address_state: "california",
        address_zip: "52000",
        address_country: "USA",
        url: "https://app.staxpayments.com/#/bill/",
        method: "card",
        // validate is optional and can be true or false.
        // determines whether or not stax.js does client-side validation.
        // the validation follows the sames rules as the api.
        // check the api documentation for more info:
        // https://staxpayments.com/api-documentation/
        validate: false,
        // meta is optional and each field within the POJO is optional also
        meta: {
          reference: getRandomId(), // optional - will show up in emailed receipts
          memo: "This is user invoice", // optional - will show up in emailed receipts
          subtotal: this.fee.price, // optional - will show up in emailed receipts
          tax: 0, // optional - will show up in emailed receipts
          lineItems: [
            // optional - will show up in emailed receipts
            {
              id: "optional-fm-catalog-item-id",
              item: "SUBSCRIPTION FEE",
              details: "This is Subscription fee for Year",
              quantity: 1,
              price: this.fee.price,
            },
          ],
        },
      };

      // call pay api
      staxJs
        .pay(extraDetails)
        .then((result) => {
          if (result.id) {
            result["first_name"] = this.firstname;
            result["last_name"] = this.lastname;
            result["mob"] = this.phone;
            result["email"] = this.user.email;
            result["total"] = this.fee.price;
            result["pay_percentage"] = this.fee.pay_percentage;
            result["pack_name"] = this.fee.pack_name;
            result["pack_id"] = this.fee.id;
            return axios
              .post(process.env.VUE_APP_API_URL + "SubFeecharge", result, {})
              .then((response) => {
                if (response.data.statusCode == 200) {
                  var elem = this.$refs.closeEventModalPay;
                  elem.click();
                  this.$alert("Your subscription has been activated successfully.", "Success", {
                    confirmButtonText: "OK",
                    type: "success",
                    }).then(() => {
                    // This block runs when the user clicks "OK" or closes the modal
                    this.getUserData();
                    this.$router.push({ name: "Teams" });
                    });
                } else {
                  this.errorMessage = response.data.message;
                  payButton.disabled = false;
                  payButton.style.opacity = "1";
                }
                this.showLoader = 0;
              });
          }
        })
        .catch((err) => {
          this.showLoader = 0;
          payButton.disabled = false;
          payButton.style.opacity = "1";
          // if a transaction did occur, but errored, the error will be in the message of the first child transactoin
          if (err.message) {
            errorElement.textContent = err.message;
          } else if (err.payment_attempt_message) {
            errorElement.textContent = err.payment_attempt_message;
          } else {
            // else, there may have been a validation error - and tokenization failed
            // err can contain an object where each key is a field name that points to an array of errors
            // such as {mobile_number: ['The mobile number is invalid']}
            if (err.total == "The total must be at least .01.") {
              errorElement.textContent = "Subscription Fee is Invalid!";
            } else if (
              err.phone ==
              "The mobile field is required when customer id is not present."
            ) {
              errorElement.textContent = "Mobile Number is Required!";
            } else if (err.status == 0) {
              errorElement.textContent =
                "Please Check Your Card Information and Try Again or Contact Support";
            } else {
              errorElement.textContent =
                typeof err === "object"
                  ? Object.keys(err)
                      .map((k) => err[k].join(" "))
                      .join(" ")
                  : JSON.stringify(err);
            }
          }

          errorElement.classList.add("visible");
        });
      errorElement.textContent = "";
    };
  },
};
</script>

<style>
.input-box {
  height: 45px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  border: 1px solid #ced4da;
  font-size: 1rem;
  height: 50px;
  color: #495055;
  width: 100%;
  padding: 3px 15px;
  border-radius: 0.7rem;
  outline: none;
  margin-bottom: 12px;
}

.error {
  color: #e4584c;
}

.success,
.loader {
  display: none;
  font-size: 13px;
}

.success.visible,
.error.visible {
  display: inline;
}

.lds-dual-ring-refresh:after {
  width: 23px;
  height: 23px;
  border: 3px solid #fff;
  border-color: #fff #fff #fff transparent;
}
.main-btn{
  min-height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
