<template>
  <div>
    <Navbar />
    <servicesModal :singleEvent="singleEvent"/>
    <CreateEventDetailModal :selectedRoomTeam="selectedTeam" />

    <div class="setting-main-wrapper">
      <div class="main-left-menu">
        <LeftMenu />
      </div>
      <Banner />
      <div class="shop-side p-md-0">
        <LeftMenuSide :openTabSet="openTabSet" />
        <div class="row no-gutters content-wrap-info">
          <div :class="{ 'event-section-wrap': true, 'no-live-today': !showLiveToday }">
            <div class="event-section">
              <!-- Sticky Header and Filters -->
              <div :class="['sticky-header', { scrolled: isScrolled }]">
                <div class="d-flex align-items-center justify-content-between flex-wrap">
                  <h6>My Events</h6>
                  <div class="add-new-team-btn position-relative">

                    <button type="button" @click="toggleAddEventDropdowns()" v-click-outside="hideTeamPopup">
                      <img :src="add" alt="add-team" />
                      <span>Add Event</span>
                    </button>
                    <div v-if="dropdownOpen" class="sub-setting-options">
                      <div v-if="isLoadingTeams" class="lds-dual-ring-media"></div>
                      <div v-else>
                      <div
                        v-for="team in adminTeamsArray"
                        :key="team.teamName"
                        class="dropdown-item"
                        @click="openAddEvent(team)"
                      >
                        {{ team.teamName }}
                      </div>
                      </div>
                    </div>

                    <!-- Add Event Modal -->
                    
                  </div>
                </div>
                <div class="filter-top">
                  <MyEventsFilter :eventTypes="eventTypes" :isLoadingAssociation="isLoadingAssociation" :eventYears="eventYears" :associationOptions="associationOptions"
                    :teamsArray="teamsArray" :isLoadingTeams="isLoadingTeams" :isLoadingTypes="isLoadingTypes" @filters-changed="handleFiltersChanged" />
                </div>
              </div>
              <div class="event-section-content">
                <div class="no-event-wrap" v-if="(!eventsDetails || eventsDetails.length === 0) && showLoader === 0">
                  <img src="../../../assets/Svg/no-data.svg" alt />
                  <p class="header">No events found.</p>
                  <p class="info">We couldn't find any events. Broaden your search by tweaking the filters above.</p>
                </div>
                <div class="products-list-shim" v-if="eventsDetails.length == 0 && showLoader == 1">
                  <content-placeholders :rounded="true">
                    <content-placeholders-img />
                    <content-placeholders-heading />
                  </content-placeholders>
                  <content-placeholders class="mt-3" :rounded="true">
                    <content-placeholders-img />
                    <content-placeholders-heading />
                  </content-placeholders>
                </div>

                <div class="event-info-wrapper" v-if="eventsDetails.length > 0">
                  <!-- <router-link to="/my-event/details" class="mx-0"> -->
                    <div class="event-info-sec" v-for="(event, index) in eventsDetails" :key="index">
                      <div class="event-sec-top" @click="redirectToEvent(event)">
                        <div class="sec-date-name">
                          <div class="date-time-zone d-flex">
                            <span>{{ formatDateRange(event?.exactStartDate, event?.exactEndDate) }}</span>
                            <span class="ml-1">({{ formatTimeZone(event?.time_zone)}})</span>
                          </div>
                          <span>{{ event?.eventName }}</span>
                        </div>
                        <div class="loc-follow w-100">
                          <div class="loc-content" v-if="event && event.status === '2' && event.Url">
                            <img :src="link" alt="link" @click.stop="openInNewTab(event.Url)" />
                            <span @click.stop="openInNewTab(event.Url)">{{ event.Url }}</span>
                          </div>
                            <div class="loc-content" v-else-if="formatLocation(event)">
                                <img :src="location" alt="location" @click.stop="openGoogleMap($event, event)" />
                                <span  @click.stop="openGoogleMap($event, event)" >{{ formatLocation(event) }}</span>
                            </div>

                            <div class="attendee-sec w-100">
                              <!-- Dropdown Button -->
                              <button 
                                type="button" 
                                @click.stop="toggleDropdown(event.id)" 
                                v-click-outside="closeDropdown"  
                                class="dropdown-btn" 
                                :class="{ 'selected': selectedOptions[event.id] !== 'Are you going?' }"
                              >
                                <span>{{ selectedOptions[event.id] }}</span>
                              </button>


                              <!-- Dropdown Menu -->
                              <div v-if="showDropdowns[event.id]" class="attendee-dropdown">
                                <button 
                                  v-for="option in options" 
                                  :key="option.value" 
                                  @click.stop="selectOption(event.id, option)"
                                  class="dropdown-option w-100"
                                  :class="{ 'selected': selectedOptions[event.id] === option.label }"
                                >
                                  <span>{{ option.label }}</span>
                                </button>
                              </div>
                            </div>
                        </div>
                      </div>
                      <div class="event-sec-details" @click="redirectToEvent(event)">
                        <div class="event-sec-left">
                          <!-- Conditionally display association and event type -->
                          <span class="asso-tour">
                            {{ event.association ? event.association : '' }}
                            {{ event.association && event.eventType ? ' - ' : '' }}
                            {{ event.eventType }}
                          </span>
                          <span class="director team-navigation" @click="showSchedule(event?.team_name?.team_id)">{{ event?.team_name?.team_name }}</span>
                          <div>
                              <MatesEventData :members="event.event_attendee_all" />
                          </div>

                        </div>

                      <div class="event-sec-right">
                        <div class="weather-sec" >
                          <WeatherData v-if="isEventWithinFiveDays(event)" :eventId="event.id" />
                        </div>
                        <div class="play-info">
                          <div class="game-play">
                            <span>Games</span>
                            <span>{{ event.eventRecord.total_games }}</span>
                          </div>
                          <div class="game-win">
                            <span>Won</span>
                            <span>{{ event.eventRecord.win }}</span>
                          </div>
                          <div class="game-loss">
                            <span>Lost</span>
                            <span>{{ event.eventRecord.lost }}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div v-if="eventsDetails.length" v-observe-visibility="handleScrolledToButtom" >
                      </div>
                      <!-- </router-link> -->
                      <div class="p-3" v-if="showLoader1">
                        <content-placeholders :rounded="true">
                          <content-placeholders-heading :img="false" />
                        </content-placeholders>
                      </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
          <div class="live-today" :class="{'d-none': isApiLoaded && gameScores.length === 0}">
            <MyEventLiveToday :gameScores="gameScores" :total_game="total_game" :showGameLoader="showGameLoader"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from "axios";
// import { mapActions } from "vuex";
import * as firestoreService from "@/database/firestore";
import { mapActions, mapGetters } from "vuex";

import { EventBus } from "@/eventBus";

import Navbar from "@/components/Header/Navbar.vue";
import Banner from "@/components/BannerNotify/BannerPage.vue";
import LeftMenu from "@/components/Home/LeftMenu.vue";
import LeftMenuSide from "@/components/NewGameTime/LeftMenuSide.vue";
import MyEventsFilter from "@/components/NewGameTime/Events/MyEventsFilter.vue";
import MyEventLiveToday from "@/components/NewGameTime/MyEventLiveToday.vue";
import servicesModal from "@/components/Teams/ChatRight/ChatEvents/servicesModal.vue";
import MatesEventData from "@/components/NewGameTime/Events/MatesEventData.vue";
import WeatherData from "@/components/NewGameTime/Events/WeatherData.vue";
import CreateEventDetailModal from "@/components/Teams/ChatRight/ChatEvents/CreateEventDetailModal.vue";
export default {
  name: "eventsDataList",
  components: {
    Navbar,
    LeftMenu,
    Banner,
    LeftMenuSide,
    MyEventsFilter,
    MyEventLiveToday,
    MatesEventData,
    servicesModal,
    WeatherData,
    CreateEventDetailModal
  },
  data() {
    return {
      activeAccordionId: -1,
      activeAccordionIndex: -1,
      associationOptions: [],
      // eventsOptions: [],
      eventTypes: [],
      eventYears: [],
      singleEvent: {},
      openTabSet: 1,
      location: require("../../../assets/Svg/location.svg"),
      unfollow: require("../../../assets/Svg/unfollow.svg"),
      follow: require("../../../assets/Svg/follow.svg"),
      add: require("../../../assets/Svg/add.svg"),
      link: require("../../../assets/images/link.png"),
      showLiveToday: true,
      filter: {
        name: "",
        association: "",
        selectedYear: "2025",
        eventType: "",
        eventStatus: false,
      },
      TeamA: [],
      sortKey: "",
      iconUrl: "https:",
      sortBy: "",
      reverse: false,
      totalTeams: "",
      teamUsers: "",
      currentIsFan: "0",
      eventsDetails: [],
      UserDetails: [],
      page: 1,
      lastPage: 1,
      showLoader: 1,
      showLoader1: false,
      eventsData: [],
      showGameShimmer: 0,
      gameList: [],
      teamsArray: [],
      isLoadingArrayFilter: [],
      messages: [],
      isLoadingAssociation: false,
      isLoadingTeam: false,
      filterApplied: false,
      previousGoing: false,
      newGameProps: {},
      currentIsAdmin: "0",
      currentTeamIsActive: 1,
      selectedRoomTeam: {},
      currentUserId: "",
      userId: localStorage.getItem("userId"),
      allUsers: [],
      teamLoader: 0,
      // eventTypesUsed: [],
      // allEventTypesUsed: [],
      isScrolled: false,
      // showDropdown: false,
      // selectedOption: "Are you going?",
      options: [
        { label: "Going", value: "going", status: "1" },
        { label: "Not Going", value: "not-going", status: "2" },
        { label: "Maybe", value: "maybe", status: "3" }
      ],
      selectedOptions: {}, // Stores selectedOption for each event by event.id
      showDropdowns: {},
      adminTeamsArray: [],
      showGameLoader: 0,
      gameScores:[],
      gameScoresOngoing:[],
      gameScoresFinal:[],
      total_game: '',
      selectedArray: [],
      room_count: 1,
      adult_count: 1,
      exactRoomEndDate: "",
      note: "",
      exactRoomStartDate: "",
      isApiLoaded: true,

      dropdownOpen: false,
      selectedTeam: {},
      isLoadingTeams: false,
      isLoadingTypes: false
    };
  },


  computed: {
    ...mapGetters(["errors"]),
    ...mapGetters("events", ["event"]),


  },

  methods: {
    ...mapActions("events", ["setEventListData"]),
    ...mapActions("events", ["getCurrentComingStatus"]),

    toggleAddEventDropdowns() {
      this.dropdownOpen = !this.dropdownOpen;
    },


    hideTeamPopup() {
      this.dropdownOpen = false;
    },


   openAddEvent(team) {
 
      this.selectedTeam = team;
      console.log(this.selectedTeam)
      this.dropdownOpen = false; // Close dropdown after selecting
      EventBus.$emit("reset-forms"); 
    },

    redirectToEvent(item) {
      const eventId = item.id;
      this.$router.push({ name: 'SingleEventDetail', params: { id: eventId } });
      localStorage.setItem("eventOrigin", "Events")
    },

    showSchedule(teamId) {
      this.$router.push(`/my-team/events/${teamId}`);
    },
    
    handleScroll() {
      let scrollTop = document.querySelector('.shop-side')?.scrollTop || 0;
      this.isScrolled = scrollTop > 5;
    },
    initializeSelectedOptions(eventId, loggedInUserAttendee) {
      if (!loggedInUserAttendee) {
        this.$set(this.selectedOptions, eventId, "Are you going?");
      } else {
        switch (loggedInUserAttendee.member_status) {
          case "1":
            this.$set(this.selectedOptions, eventId, "Going");
            break;
          case "2":
            this.$set(this.selectedOptions, eventId, "Not Going");
            break;
          case "3":
            this.$set(this.selectedOptions, eventId, "Maybe");
            break;
          default:
            this.$set(this.selectedOptions, eventId, "Are you going?");
        }
      }
    },
    isEventWithinFiveDays(event) {
        if (!event?.startDate || !event?.endDate) return false;

        if (event && event.status === '2' && event.Url) {
            console.log("Skipping weather data fetch for online event.");
            return;
          }

        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const startDate = new Date(event.startDate);
        const endDate = new Date(event.endDate);
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(0, 0, 0, 0);

        // Calculate differences in days
        const daysFromTodayToStart = (startDate - today) / (1000 * 60 * 60 * 24);
        const daysFromTodayToEnd = (endDate - today) / (1000 * 60 * 60 * 24);

        // Check if event starts or ends within 5 days
        const startsWithinFiveDays = daysFromTodayToStart >= 0 && daysFromTodayToStart <= 5;
        const endsWithinFiveDays = daysFromTodayToEnd >= 0 && daysFromTodayToEnd <= 5;
        const ongoingToday = today >= startDate && today <= endDate;

        // **EXTRA CONDITION**: If event duration is too long, exclude it
        const eventDuration = (endDate - startDate) / (1000 * 60 * 60 * 24);
        if (eventDuration > 10) return false; // Example: Ignore events longer than 10 days

        return startsWithinFiveDays || endsWithinFiveDays || ongoingToday;
      },

     toggleDropdown(eventId) {
      // Toggle dropdown visibility for the specific event
      this.$set(this.showDropdowns, eventId, !this.showDropdowns[eventId]);
    },
    closeDropdown(eventId) {
      if (eventId && this.showDropdowns[eventId]) {
        this.$set(this.showDropdowns, eventId, false);
      } else {
        // Close all dropdowns as a fallback
        Object.keys(this.showDropdowns).forEach(id => {
          this.$set(this.showDropdowns, id, false);
        });
      }
    },
    selectOption(eventId, option) {
    this.singleEvent = {};
    if (option.status !== "1") {
        this.$set(this.selectedOptions, eventId, option.label);
    }
    this.closeDropdown(eventId);

    const userId = localStorage.getItem("userId");
    const statusValue = this.getStatusValue(option.value);

    // Remap eventsDetails to ensure reactivity
    this.eventsDetails = this.eventsDetails.map(event => {
        if (event.id === eventId) {
            // Update loggedInUserAttendee status
            event.loggedInUserAttendee = event.loggedInUserAttendee || {};
            this.previousGoing = event.loggedInUserAttendee?.member_status === "1" || event.loggedInUserAttendee?.member_status === 1;

            // Update event_attendee_all: remove attendee if status is 2 or 3
            if (statusValue == '2' || statusValue == '3') {
                event.event_attendee_all = event.event_attendee_all.filter(attendee => attendee.member_id !== userId);
                event.loggedInUserAttendee.member_status = statusValue;
            } else {
                event.event_attendee_all = event.event_attendee_all.map(attendee => {
                    if (attendee.member_id === userId) {
                        return { ...attendee, member_status: statusValue };
                    }
                    return attendee;
                });
            }

            this.singleEvent = event;
        }
        return event;
    });

    const updatedEvent = this.eventsDetails.find(e => e.id === eventId);
    if (updatedEvent) {
        this.selectStatus(updatedEvent.team_id, updatedEvent.id, option.status, this.singleEvent);
    }
},
    getStatusValue(value) {
      // Convert option value to memberStatus value
      switch (value) {
        case "going":
          return "1";
        case "not-going":
          return "2";
        case "maybe":
          return "3";
        default:
          return null;
      }
    },
    toggleAccordion(event, eventId) {
      this.newGameProps = { event };
      if (this.activeAccordionId === eventId) {
        this.eventsDetails.forEach((eventDetail) => {
          if (eventDetail.id === eventId) {
            eventDetail._showDetails = false;
          }
        });
        this.activeAccordionId = -1;
      } else {
        this.eventsDetails.forEach((eventDetail) => {
          eventDetail._showDetails = eventDetail.id === eventId;
        });
        this.activeAccordionId = eventId;
        this.getTeamData(event);
        this.getGames(event);
      }
    },
    formatDateWeather(date) {
      const options = { month: 'short', day: 'numeric' };
      const datePart = new Date(date).toLocaleDateString(undefined, options);
      const yearPart = new Date(date).getFullYear();
      return { date: datePart, year: yearPart };
    },
    handleFiltersChanged(filters) {
      this.filter = {
        ...this.filter,
        name: filters.name.join(','),
        association: filters.association.join(','),
        eventType: filters.eventType,
        eventStatus: filters.eventStatus,
        selectedYear: filters.year,
      };
      this.page = 1;
      this.eventsDetails = [];
      this.calendarData();
    },
    openInNewTab(url) {
        if (url) {
        window.open(url, '_blank', 'noopener,noreferrer');
        }
    },
    async getGames(event) {
      this.showGameShimmer = 1;
      this.gameList = [];
      var formData = new FormData();
      formData.append("event_id", event.id);
      formData.append("team_id", event.team_id);
      await axios
        .post(process.env.VUE_APP_API_URL + "game/gameListing", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.gameList = response.data.data;
          event.showGameList = true;
          this.showGameShimmer = 0;
        });
    },
    formatDateRange(startDate, endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);

      const startDateString = start.toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
      });
      const endDateString = end.toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
      });
      const startYear = start.getFullYear();
      const endYear = end.getFullYear();

      if (startYear === endYear) {
          return `${startDateString} to ${endDateString}, ${startYear}`;
      } else {
          return `${startDateString}, ${startYear} to ${endDateString}, ${endYear}`;
      }
    },
    formatTimeZone(timeZone) {
      if (timeZone && typeof timeZone === 'string') {
        return timeZone.replace(/\(UTC[^)]+\)\s*/, "").trim(); // Removes text like "(UTC-06:00)"
      }
      return timeZone; // Return the original value if it's not a valid string
    },
    hasValidLocation(item) {
      // Check if any of the address, city, or state fields are valid
      return (
        (item.status == 1 &&
          item.address != null &&
          item.address.trim() !== "" &&
          item.address !== "null") ||
        (item.city != null && item.city.trim() !== "" && item.city !== "null") ||
        (item.state != null && item.state.trim() !== "" && item.state !== "null")
      );
    },
    // formatLocation(item) {
    //   // Combine address, city, and state into a readable location string
    //   const parts = [
    //     item.city ? item.city.trim() : "",
    //     item.state ? item.state.trim() : "",
    //   ];
    //   return parts.filter(Boolean).join(", ");
    // },

    formatLocation(event) {
            let location = '';

            if (event?.city) {
                // Add comma if address exists, else just city
                location += (location ? ', ' : '') + event.city;
            }

            if (event?.state) {
                // Add comma if address or city exists, else just state
                location += (location ? ', ' : '') + event.state;
            }

            return location;
        },

    async firebasedata(user_id) {
      this.TeamDetails = [];
      this.Team = [];
      const querySport = firestoreService.roomsSportQuery(user_id);
      const { data, docs } = await firestoreService.getRooms(querySport);
      this.totalTeams = docs[docs.length - 1];
      data.forEach((teams) => {
        this.TeamDetails.push(teams.id);
        this.TeamA.push(teams);
        //  Make Array for all Users on Team Start -----------
        const rawUsers = [];
        teams.users.forEach((userId) => {
          const promise = firestoreService.getUser(userId);
          rawUsers.push(promise);
        });
        this.teamUsers = "";
        var currentIsFan = "0";
        Promise.all(rawUsers).then((values) => {
          for (const role of teams.userRole) {
            for (const member of teams.users) {
              if (role.id == member) {
                if (member == this.currentUserId && role.fan == "1") {
                  currentIsFan = "1";
                }
                if (member == this.currentUserId && role.fan != "1") {
                  currentIsFan = "0";
                }
              }
            }
          }
          let teamDeta = {
            teamID: teams.id,
            users: values,
            userRole: teams.userRole,
            currentIsFan: currentIsFan,
          };

          this.UserDetails.push(teamDeta);
        });
        //  Make Array for all Users on Team Start -----------
      });

      this.setEventListData(this.TeamDetails);

      this.calendarData();

      let comingStatus = {
        teamID: this.TeamDetails,
        usersDetail: this.UserDetails,
        users: user_id,
      };
      this.getCurrentComingStatus(comingStatus);
    },
    async getUserChatIdWithRetry() {
      const userId = localStorage.getItem("userId");

      const promiseUser = await firestoreService.getUserCid(userId);

      this.currentUserId = promiseUser.data[0].id;

      this.firebasedata(this.currentUserId);

      // setTimeout(() => {
      //   this.getUserChatIdWithRetry();
      // }, 1000);
    },
    async getUserChatId() {
      await this.getUserChatIdWithRetry();
    },

    async calendarData() {
      this.page = 1;
      // Set the default filter if not already set
      if (this.filter.eventStatus === null) {
        this.filter.eventStatus = "true"; // Default to upcoming events
      }

      this.showLoader = 1;
      this.showLoader1 = true;
      this.isLoadingTypes = true;
      const formData = new FormData();
      formData.append("user_id", localStorage.getItem("userId"));
      formData.append("eventStatus", this.filter.eventStatus);
      formData.append("eventType", this.filter.eventType); // Add event type filter
      formData.append("association", this.filter.association); // Add association filter // Add event status filter
      formData.append("teamName", this.filter.name); // Add association filter // Add event status filter
      formData.append("selectedYear", this.filter.selectedYear); // Add association filter // Add event status filter
      // if (
      //   this.filter.name ||
      //   this.filter.association ||
      //   this.filter.eventType
      // ) {
      //   this.page = 1;
      //   this.eventsDetails = [];
      //   this.filterApplied = true;
      // }

      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL +
          `event/calenderEventListss?page=${this.page}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );


        if (response?.data?.statusCode === 200) {
          this.commonEventTypes = response.data.data.eventTypes;
          const newEventData = response.data.data.listing.data;
          this.eventTypes = response.data.data.eventTypes;
          this.eventYears = response.data.data.eventYears;
          // If it's the first page, clear eventsDetails array
          // if (this.page === 1) {
          //  this.eventsDetails = [];
          //}

          // Concatenate newly fetched data with existing data
          this.eventsDetails = this.eventsDetails.concat(newEventData);
          this.eventsDetails.forEach(event => {
            this.initializeSelectedOptions(event?.id, event?.loggedInUserAttendee);
          });

          // Filter events based on selected eventType
          if (this.filter.eventType) {
            this.eventsDetails = this.eventsDetails.filter(event => {
              // Ensure we are filtering by eventType only, not location
              return event.eventType === this.filter.eventType;
            });
          }

          this.eventsDetails.sort((a, b) => {
            const dateAStart = new Date(a.exactStartDate);
            const dateBStart = new Date(b.exactStartDate);
            const dateAEnd = new Date(a.exactEndDate);
            const dateBEnd = new Date(b.exactEndDate);

            // Compare start dates first
            if (dateAStart.getTime() !== dateBStart.getTime()) {
              return dateAStart - dateBStart;
            } else {
              // If start dates are the same, compare end dates
              return dateAEnd - dateBEnd;
            }
          });
          this.filterOption = false;
          this.total = response.data.data.listing.total;
          this.lastPage = response.data.data.listing.last_page;
          this.noEvent = this.eventsDetails.length > 0 ? 0 : 1;
        } else {
          this.showLoader = 0;
          this.showLoader1 = false;
          this.isLoadingTypes = false;
          console.error(
            "Error:",
            response.data.message || "Unknown error occurred"
          ); this.errorMessage = response.data.message || "Unknown error occurred";
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
        this.errorMessage = "Failed to fetch data";
      } finally {
        this.showLoader = 0;
        this.showLoader1 = false;
        this.isLoadingTypes = false;
      }
    },
    async fetchAllTeamsFromFirestore() {
  try {
    this.isLoadingTeams = true;
    var UserId = localStorage.getItem("userId");
    const promiseUser = await firestoreService.getUserCid(UserId.toString());

    if (promiseUser.data[0]?.id !== undefined) {
      var currentUserId = promiseUser.data[0].id;

      // Fetch all rooms
      const roomsData = await firestoreService.getAllRooms();
      console.log("Rooms Data:", roomsData);

      if (Array.isArray(roomsData.data) && roomsData.data.length > 0) {
        // ✅ Filter all teams where the user is a member & the teamType is "sports"
        const allTeams = roomsData.data.filter((room) => {
          return (
            Array.isArray(room.userRole) &&
            room.userRole.some((user) => user.id === currentUserId)
          );
        });

        console.log("currentUserId:", currentUserId);
        console.log("All Teams (Before Filtering):", allTeams);

        // ✅ Filter admin teams & exclude archived ones (status = 2)
        const adminTeams = roomsData.data.filter((room) => {
          return (
            Array.isArray(room.userRole) &&
            room.userRole.some(
              (user) =>
                user.id === currentUserId &&
                Number(user.admin) === 1 &&
                Number(user.status) !== 2 // ✅ Exclude archived teams
            )
          );
        });

        console.log("Admin Teams (Before Filtering):", adminTeams);

        // ✅ Remove teams where teamName is missing or empty
        const filteredAllTeams = allTeams.filter(
          (team) => team.teamName && team.teamName.trim() !== ""
        );
        const filteredAdminTeams = adminTeams.filter(
          (team) => team.teamName && team.teamName.trim() !== ""
        );

        console.log("Filtered All Teams:", filteredAllTeams);
        console.log("Filtered Admin Teams:", filteredAdminTeams);

        // ✅ Sort alphabetically by `teamName`
        filteredAllTeams.sort((a, b) => a.teamName.localeCompare(b.teamName));
        filteredAdminTeams.sort((a, b) => a.teamName.localeCompare(b.teamName));

        // ✅ Assign filtered & sorted teams
        this.teamsArray = filteredAllTeams;
        this.adminTeamsArray = filteredAdminTeams;

        return filteredAllTeams.length > 0 ? filteredAllTeams : console.log("User is not associated with any teams.");
      } else {
        console.error("No data found or data is not an array");
      }
    }
  } catch (error) {
    console.error("Error fetching teams from Firestore:", error);
  } finally {
    this.isLoadingTeams = false; // ✅ Stop loading when data fetching is done
  }
},




    clearEventType() {
      this.filter.eventType = "";
      this.calendarData();
    },

    clearAssociation() {
      this.filter.association = "";
      this.calendarData();
    },

    clearTeam() {
      this.filter.name = "";
      this.calendarData();
    },

    getAssociation() {
      this.isLoadingAssociation = true
      axios
        .get(
          `${process.env.VUE_APP_API_URL}getAssociations?search=${""}`,
          this.details,
          {}
        )
        .then((response) => {
          this.associationOptions = [];
          this.associationOptions = response.data.data.associations;
          this.isLoadingAssociation = false
        });
    },

    // getEventsType() {
    //   axios
    //     .get(`${process.env.VUE_APP_API_URL}event/getEventTypes?search=${""}`)
    //     .then((response) => {
    //       this.eventsOptions = response.data.data.map(
    //         (element) => element.name
    //       );
    //     })
    //     .catch((error) => {
    //       console.error("Error fetching event types:", error);
    //     });
    // },

    handleScrolledToButtom(isVisible) {
      if (this.page >= this.lastPage) {
        return;
      }
      if (isVisible) {
        this.page++;
        this.calendarData2();
      }
    },
    async calendarData2() {
      // Set the default filter if not already set
      if (this.filter.eventStatus === null) {
        this.filter.eventStatus = "true"; // Default to upcoming events
      }

      this.showLoader = 1;
      this.showLoader1 = true;
      this.isLoading = true;
      const formData = new FormData();
      // formData.append("sportsRoomID", this.TeamDetails);
      formData.append("user_id", localStorage.getItem("userId"));
      formData.append("selectedYear", this.filter.selectedYear); // Add association filter // Add event status filter
      formData.append("eventStatus", this.filter.eventStatus);
      formData.append("eventType", this.filter.eventType); // Add event type filter
      formData.append("association", this.filter.association); // Add association filter // Add event status filter
      formData.append("teamName", this.filter.name); // Add association filter // Add event status filter
      // if (
      //   this.filter.name ||
      //   this.filter.association ||
      //   this.filter.eventType
      // ) {
      //   this.page = 1;
      //   this.eventsDetails = [];
      //   this.filterApplied = true;
      // }

      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL +
          `event/calenderEventListss?page=${this.page}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );


        if (response.data.statusCode === 200) {
          this.commonEventTypes = response.data.data.eventTypes;
          const newEventData = response.data.data.listing.data;
          this.eventTypes = response.data.data.eventTypes;
          this.eventYears = response.data.data.eventYears;
          // this.eventTypesUsed = [...new Set(newEventData.map(event => event.eventType))];
          // this.allEventTypesUsed = [...new Set([...this.allEventTypesUsed, ...this.eventTypesUsed])];

          // if (!this.filterApplied) {
          //   this.commonEventTypes = this.allEventTypesUsed.filter(eventType =>
          // this.eventsOptions.includes(eventType)
          // );
          // }

          // If it's the first page, clear eventsDetails array
          //if (this.page === 1) {
          //  this.eventsDetails = [];
          //}

          // Concatenate newly fetched data with existing data
          this.eventsDetails = this.eventsDetails.concat(newEventData);
          this.eventsDetails.forEach(event => {
            this.initializeSelectedOptions(event?.id, event?.loggedInUserAttendee);
          });


          // Filter events based on selected eventType
          if (this.filter.eventType) {
            this.eventsDetails = this.eventsDetails.filter(event => {
              // Ensure we are filtering by eventType only, not location
              return event.eventType === this.filter.eventType;
            });
          }

          this.eventsDetails.sort((a, b) => {
            const dateAStart = new Date(a.exactStartDate);
            const dateBStart = new Date(b.exactStartDate);
            const dateAEnd = new Date(a.exactEndDate);
            const dateBEnd = new Date(b.exactEndDate);

            // Compare start dates first
            if (dateAStart.getTime() !== dateBStart.getTime()) {
              return dateAStart - dateBStart;
            } else {
              // If start dates are the same, compare end dates
              return dateAEnd - dateBEnd;
            }
          });
          this.filterOption = false;
          this.total = response.data.data.listing.total;
          this.lastPage = response.data.data.listing.last_page;
          this.noEvent = this.eventsDetails.length > 0 ? 0 : 1;
        } else {
          console.error(
            "Error:",
            response.data.message || "Unknown error occurred"
          ); this.errorMessage = response.data.message || "Unknown error occurred";
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
        this.errorMessage = "Failed to fetch data";
      } finally {
        this.showLoader = 0;
        this.showLoader1 = false;
        this.isLoading = false;
      }
    },
    openGoogleMap(event, item) {
      event.preventDefault(); // Prevents <router-link> navigation
      event.stopPropagation(); // Stops event bubbling
      const strlatLng = item["lan"] + "," + item["long"];
      const urlSuffix = strlatLng
        ? strlatLng
        : item["address"] + ", " + item["city"] + ", " + item["zipCode"];
      window.open(
        "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
        "_blank"
      );
    },
    // callModal(game, event) {
    //   var combineUrl = event.team_id + "-" + game.guid;
    //   const urlToOpen = `/score-game/${combineUrl}`;
    //   window.location.href = urlToOpen;
    // },
    togglePastEventsFilter() {
      this.eventsDetails = [];
      this.page = 1;
      this.calendarData();
    },

    async getTeamData(event) {
      this.teamLoader = 1;
      const rawRoom = [];
      const promise = await firestoreService.getRoomById(event.team_id);
      rawRoom.push(promise);

      const foundRoom = await Promise.all(rawRoom);
      let usersData = {};
      Object.keys(foundRoom).forEach((key) => {
        const teamData = foundRoom[key];
        usersData = teamData.users;
        teamData.users = {};
        this.selectedRoomTeam = teamData;
      });

      // check role if team exits or user is member
      let roles = this.selectedRoomTeam.userRole;
      if (usersData != undefined) {
        var admincount = 0;
        for (const userRole of roles) {
          if (userRole.id == this.currentUserId) {
            admincount++;
          }
        }
        if (admincount == 0) {
          this.$router.push({ name: "PageNotFound" });
        }
      } else {
        this.$router.push({ name: "PageNotFound" });
      }

      await this.makeUserArray(usersData);
      const users = [];
      usersData.forEach((userId) => {
        const foundUser = this.allUsers.find((user) => user?._id === userId);
        if (foundUser) {
          users.push(foundUser);
        }
      });

      this.selectedRoomTeam.users = users;

      // assign admin role
      let tempMembers = this.selectedRoomTeam.users;

      // Set Users Role and Find if current User is admin
      for (const role of roles) {
        for (const member of tempMembers) {
          if (role.id == member.id) {
            member.adminRole = role.admin;
            member.fanRole = role.fan;
            member.roleStatus = role.status;
            member.uniform = role.uniform;
            member.bat_size = role.bat_size;
            member.bat_details = role.bat_details;
            member.hat_size = role.hat_size;
            member.shirt_size = role.shirt_size;
            member.pant_size = role.pant_size;
          }
          if (
            member.id == this.currentUserId &&
            member.adminRole == "1"
          ) {
            this.currentIsAdmin = "1";
          }
          if (
            member.id == this.currentUserId &&
            member.adminRole == "0"
          ) {
            this.currentIsAdmin = "0";
          }
          if (
            member.id == this.currentUserId &&
            member.fanRole == "1"
          ) {
            this.currentIsFan = "1";
          }
          if (
            member.id == this.currentUserId &&
            member.fanRole != "1"
          ) {
            this.currentIsFan = "0";
          }

          // assign current user team status
          if (
            member.id == this.currentUserId &&
            member.roleStatus == 1
          ) {
            this.currentTeamIsActive = 1;
          }
          if (
            member.id == this.currentUserId &&
            member.roleStatus == 2
          ) {
            this.currentTeamIsActive = 2;
          }
        }
      }

      this.selectedRoomTeam.roomId = this.selectedRoomTeam.id;
      this.teamLoader = 0;
    },
    getLiveScore() {
    this.showGameLoader = 1;
    this.gameScoresOngoing = [];
    this.gameScoresFinal = [];
    this.isApiLoaded = false;

    axios
        .post(process.env.VUE_APP_API_URL + "event/fetchGamesForUser", {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        .then((res) => {
            if (res.data.statusCode === 200) {
                this.gameScores = res.data.data.listing || [];
                this.total_game = res.data.data.total_games || 0;

                if (this.gameScores.length > 0) {
                    this.event_name = this.gameScores[0].event_name;
                    this.count = 0;

                    this.gameScores.forEach((gameScore) => {
                        if (gameScore.game_status !== 2) {
                            this.gameScoresOngoing.push(gameScore);
                            this.count++;
                        } else {
                            this.gameScoresFinal.push(gameScore);
                        }
                    });
                }
            } else {
                console.error("Error:", res.data.message || "Failed to fetch games.");
            }
        })
        .catch((error) => {
            console.error("API Error:", error.response?.data?.message || error.message);
        })
        .finally(() => {
            this.showGameLoader = 0;
            this.isApiLoaded = true;
        });
},

    async makeUserArray(users) {
      const roomUserIds = users;

      const rawUsers = [];
      roomUserIds.forEach((userId) => {
        const promise = firestoreService.getUser(userId);
        rawUsers.push(promise);
      });
      this.allUsers = [...this.allUsers, ...(await Promise.all(rawUsers))];
    },
    async selectStatusWithServices(status) {
    status = 1;
    var formData = new FormData();
    formData.append("event_id", this.singleEvent.id);
    formData.append("member_status", status);
    formData.append("services", this.selectedArray);
    formData.append("team_id", this.singleEvent.team_id);
    formData.append("room_count", this.room_count);
    formData.append("exactStartDate", this.exactRoomStartDate);
    formData.append("exactEndDate", this.exactRoomEndDate);
    formData.append("note", this.note);
    formData.append("adult_count", this.adult_count);

    if (this.userMember_id) {
        formData.append("member_id", this.userMember_id);
    }

    try {
        const response = await axios.post(
            process.env.VUE_APP_API_URL + "event/eventAttendeeSelect",
            formData,
            { headers: { "Content-Type": "multipart/form-data" } }
        );

        if (response.data.statusCode === 200) {
            const userId = this.userMember_id || localStorage.getItem("userId");

            // Create the new attendee object
            const selectedServices = Array.isArray(this.selectedArray) ? this.selectedArray.join(",") : null;

            const newAttendee = {
                id: localStorage.getItem("userId"), // Temporary unique ID
                event_id: this.singleEvent.id,
                team_id: this.singleEvent.team_id,
                member_id: userId,
                member_status: status,
                services: selectedServices,
                room_count: this.room_count,
                exactStartDate: this.exactRoomStartDate,
                exactEndDate: this.exactRoomEndDate,
                note: this.note,
                adult_count: this.adult_count
            };
            // Remap eventsDetails to ensure reactivity
            this.eventsDetails = this.eventsDetails.map(event => {
                if (event.id === this.singleEvent.id) {
                    event.event_attendee_all = [...event.event_attendee_all, newAttendee];
                }
                if (event.loggedInUserAttendee) {
                    event.loggedInUserAttendee.member_status = "1";
                } else {
                    event.loggedInUserAttendee = { member_status: "1" };
                }
                return event;
            });
            this.$set(this.selectedOptions, this.singleEvent.id, "Going");


            // this.$toast.success(response.data.message || "Travel details updated successfully.");
        }
    } catch (error) {
        console.error("Error updating travel details:", error);
    }
},

    openEditTravel() {
      if (this.Services) {
        this.$root.$emit("openEditServicesModal", {
          event: this.singleEvent,
          services: this.Services,
        });
      }
    },
    async selectStatus(team_id, event_id, member_status, singleEvent) {

      if (this.previousGoing && member_status == 1) {
        return;
      }
      if (member_status == 1) {
        this.$root.$emit("openServicesModal", singleEvent);
      } else {
        this.editTravel = false;
        
        // Create form data
        var formData = new FormData();
        formData.append("event_id", event_id);
        formData.append("member_status", member_status);
        formData.append("team_id", team_id);

        try {
          await axios.post(
            process.env.VUE_APP_API_URL + "event/eventAttendeeSelect",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          // this.getAttendee(); // Refresh attendee list after success
        } catch (error) {
          console.error("Error updating status:", error);
        }
      }
    },

  },
mounted() {
  this.getLiveScore();
    this.calendarData();
    let scrollContainer = document.querySelector('.shop-side'); // Update selector to match your container
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", this.handleScroll);
    }
    // this.getUserChatIdWithRetry();
    this.getAssociation();

    this.$root.$on("fetchEventsonMyEventsPage", () => {
      this.page = 1;
      this.eventsDetails = [];
      this.calendarData();
    });

    // this.getEventsType();
    this.fetchAllTeamsFromFirestore();
    this.$root.$on("refreshEventGame", (event) => {
      this.getGames(event);
    });
    this.$root.$on("openTabSetting", (id) => {
      this.openTabSet = id;
    });
    this.$root.$on(
    "attendeesServiceForMyEvent",
    (
      data,
      room_count,
      adultQuantity,
      note,
      exactRoomStartDate,
      exactRoomEndDate
    ) => {
      this.selectedArray = data;
      this.room_count = room_count;
      this.note = note;
      this.adult_count = adultQuantity;
      this.exactRoomEndDate = exactRoomEndDate;
      this.exactRoomStartDate = exactRoomStartDate;
      this.selectStatusWithServices();
    }
  );

  },
  beforeUnmount() {
    let scrollContainer = document.querySelector('.shop-side');
    if (scrollContainer) {
      scrollContainer.removeEventListener("scroll", this.handleScroll);
    }
  },

};
</script>
<style scoped>
.attendee-dropdown .dropdown-option:nth-child(1) {
    margin-bottom: 0px;
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
}

.attendee-dropdown .dropdown-option:nth-child(2) {
    padding-top: 16px;
    padding-right: 20px;
    padding-left: 20px;
    margin-bottom: 0px;

}

.attendee-dropdown .dropdown-option:nth-child(3) {
    padding-top: 16px;
    padding-bottom: 20px;
    padding-right: 20px;
    padding-left: 20px;
    margin-bottom: 0px;

}
</style>
