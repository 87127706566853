<template>
  <div>
    <servicesModal :singleEvent="singleEvent" />
    <div class="send-reminder-div" v-if="currentIsAdmin === '1'">     
      <div v-if="showReminderLoader" class="d-flex justify-content-end">
        <img src="../../assets/loader.gif" alt="" width="35"/>
      </div>
    <button v-else @click="sendReminder" class="send-reminder-btn">Send Reminder</button>
  </div>
    <div class="attendee-sec-top">
      <h6>Are you going?</h6>
      <div class="attendee-btns" v-if="showGoingLoader || singleEvent">
        <button
          type="button"
          :class="{
            active: singleEvent?.loggedInUserAttendance?.member_status == 1,
          }"
          @click="selectStatus(1)"
        >
          <span>Going</span>
        </button>
        <button
          type="button"
          :class="{
            active: singleEvent?.loggedInUserAttendance?.member_status == 2,
          }"
          @click="selectStatus(2)"
        >
          <span>Not Going</span>
        </button>
        <button
          type="button"
          :class="{
            active: singleEvent?.loggedInUserAttendance?.member_status == 3,
          }"
          @click="selectStatus(3)"
        >
          <span>Maybe</span>
        </button>
      </div>
      <div v-else>
        <content-placeholders :rounded="true">
          <content-placeholders-text
            :lines="2"
            class="content-margin-reaction"
          />
        </content-placeholders>
      </div>
    </div>
    <!-- <div class="event-actions">
      <content-placeholders :rounded="true">
        <content-placeholders-text :lines="2" class="content-margin-reaction" />
      </content-placeholders>
    </div> -->
    <div class="event-actions" >
      <ul
        class="border-0"
        :class="{
          'justify-content-between':
            totalGoing > 0 &&
            totalNotGoing > 0 &&
            totalMayBe > 0 &&
            totalNotRespond > 0,
          'justify-content-start': !(
            totalGoing > 0 &&
            totalNotGoing > 0 &&
            totalMayBe > 0 &&
            totalNotRespond > 0
          ),
        }"
      >
        <li
          v-if="totalGoing > 0"
          class="team-member mb-0 team-member-attendees-side"
          v-bind:class="[selectAttendeeTab == 0 ? 'active' : '']"
          v-on:click="filterGoing()"
        >
          Going <span class="nav-counter-team">{{ totalGoing }}</span>
        </li>

        <li
          v-if="totalNotGoing > 0"
          class="team-member mb-0 team-member-attendees-side"
          v-on:click="filterNotGoing()"
          v-bind:class="[selectAttendeeTab == 1 ? 'active' : '']"
        >
          Not Going <span class="nav-counter-team">{{ totalNotGoing }}</span>
        </li>

        <li
          v-if="totalMayBe > 0"
          class="team-member mb-0 team-member-attendees-side"
          v-on:click="filterMay()"
          v-bind:class="[selectAttendeeTab == 2 ? 'active' : '']"
        >
          Maybe <span class="nav-counter-team">{{ totalMayBe }}</span>
        </li>

        <li
          v-if="totalNotRespond > 0"
          class="team-member mb-0 team-member-attendees-side"
          v-bind:class="[selectAttendeeTab == 3 ? 'active' : '']"
          v-on:click="filterNotResponded()"
        >
          Not Responded
          <span class="nav-counter-team">{{ totalNotRespond }}</span>
        </li>
      </ul>
    </div>

    <div class="team-members" v-if="showGoing && membersGoing.length > 0">
      <div class="team-info d-block" v-if="membersGoing.length > 0">
        <div class="team-details" v-for="user in membersGoing" :key="user.id">
          <div class="d-flex align-items-center">
            <div class="team-image mr-2 position-relative">
              <div class="users-notification" v-if="imgLoader == 1">
                <img src="../../assets/loader.gif" alt="" />
              </div>
              <img
                @load="onImageLoad"
                v-if="user.c_id != ''"
                 :src="`${API_URL}${user?.c_id}/profile_picture.jpg?v=${time}`"
                onerror="this.src='https://api.whoifollow.tech/user-default2.png'"
                alt=""
              />
              <img
                @load="onImageLoad"
                v-else
                src="../../assets/Svg/member.svg"
                alt=""
              />
            </div>
            <div class="shirt-no">
              <img src="../../assets/Svg/shirt.svg" alt="Image" />
              <span>{{ user.uniform ? user.uniform : "-" }}</span>
            </div>
            <div class="team-name-role">
              <h6>
                {{ user.username }}
                <span v-if="user.nickname"
                  >({{ user.nickname }})</span
                >
              </h6>
              <span v-if="user.adminRole == '1'">Admin</span>
              <p v-if="user.adminRole == '0'"></p>
              <span v-if="user.fan == '1' || user.fanRole == '1'">Fan</span>
            </div>
          </div>
          <AttendeesOptions
            :currentIsAdmin="currentIsAdmin"
            :user="user"
            :selectedRoomTeam="selectedRoomTeam"
            :singleEvent="singleEvent"
          />
        </div>
      </div>
      <!-- <div class="delivered-to-wrapper" v-if="membersGoing.length == 0">
        <div class="message-reader-detail">
          <span>No User found.</span>
        </div>
      </div> -->
    </div>

    <div class="team-members" v-if="showNotGoing && membersNotGoing.length > 0">
      <div class="team-info d-block" v-if="membersNotGoing.length > 0">
        <div
          class="team-details"
          v-for="user in membersNotGoing"
          :key="user.id"
        >
          <div class="d-flex align-items-center">
            <div class="team-image mr-2 position-relative">
              <div class="users-notification" v-if="imgLoader == 1">
                <img src="../../assets/loader.gif" alt="" />
              </div>
              <img
                @load="onImageLoad"
                 v-if="user.c_id != ''"
                 :src="`${API_URL}${user?.c_id}/profile_picture.jpg?v=${time}`"
                onerror="this.src='https://api.whoifollow.tech/user-default2.png'"
                alt=""
              />
              <img
                @load="onImageLoad"
                v-else
                src="../../assets/Svg/member.svg"
                alt=""
              />
            </div>
            <div class="shirt-no">
              <img src="../../assets/Svg/shirt.svg" alt="Image" />
              <span>{{ user.uniform ? user.uniform : "-" }}</span>
            </div>
            <div class="team-name-role">
              <h6>
                {{ user.username }}
                <span v-if="user.nickname">({{ user.nickname }})</span>
              </h6>
              <span v-if="user.adminRole == '1'">Admin</span>
              <p v-if="user.adminRole == '0'"></p>
              <span v-if="user.fan == '1' || user.fanRole == '1'">Fan</span>
            </div>
          </div>
          <AttendeesOptions
            :currentIsAdmin="currentIsAdmin"
            :user="user"
            :selectedRoomTeam="selectedRoomTeam"
            :singleEvent="singleEvent"
          />
        </div>
      </div>
      <!-- <div class="delivered-to-wrapper" v-if="membersNotGoing.length == 0">
        <div class="message-reader-detail">
          <span>No User found.</span>
        </div>
      </div> -->
    </div>

    <div class="team-members" v-if="showMayBe">
      <div class="team-info d-block" v-if="membersMayBe.length > 0">
        <div class="team-details" v-for="user in membersMayBe" :key="user.id">
          <div class="d-flex align-items-center">
            <div class="team-image mr-2 position-relative">
              <div class="users-notification" v-if="imgLoader == 1">
                <img src="../../assets/loader.gif" alt="" />
              </div>
              <img
                @load="onImageLoad"
                 v-if="user.c_id != ''"
                 :src="`${API_URL}${user?.c_id}/profile_picture.jpg?v=${time}`"
                onerror="this.src='https://api.whoifollow.tech/user-default2.png'"
                alt=""
              />
              <img
                @load="onImageLoad"
                v-else
                src="../../assets/Svg/member.svg"
                alt=""
              />
            </div>
            <div class="shirt-no">
              <img src="../../assets/Svg/shirt.svg" alt="Image" />
              <span>{{ user.uniform ? user.uniform : "-" }}</span>
            </div>
            <div class="team-name-role">
              <h6>
                {{ user.username }}
                <span v-if="user.nickname">({{ user.nickname }})</span>
              </h6>
              <span v-if="user.adminRole == '1'">Admin</span>
              <p v-if="user.adminRole == '0'"></p>
              <span v-if="user.fan == '1' || user.fanRole == '1'">Fan</span>            </div>
          </div>
          <AttendeesOptions
            :currentIsAdmin="currentIsAdmin"
            :user="user"
            :selectedRoomTeam="selectedRoomTeam"
            :singleEvent="singleEvent"
          />
        </div>
      </div>
      <!-- <div class="delivered-to-wrapper" v-if="membersMayBe.length == 0">
        <div class="message-reader-detail">
          <span>No User found.</span>
        </div>
      </div> -->
    </div>

    <div class="team-members" v-if="showNotResponded">
      <div class="team-info d-block" v-if="membersNotRespond.length > 0">
        <div
          class="team-details"
          v-for="user in membersNotRespond"
          :key="user.id"
        >
          <div class="d-flex align-items-center">
            <div class="team-image mr-2 position-relative">
              <div class="users-notification" v-if="imgLoader == 1">
                <img src="../../assets/loader.gif" alt="" />
              </div>
              <img
                @load="onImageLoad"
                 v-if="user.c_id != ''"
                 :src="`${API_URL}${user?.c_id}/profile_picture.jpg?v=${time}`"
                onerror="this.src='https://api.whoifollow.tech/user-default2.png'"
                alt=""
              />
              <img
                @load="onImageLoad"
                v-else
                src="../../assets/Svg/member.svg"
                alt=""
              />
            </div>
            <div class="shirt-no">
              <img src="../../assets/Svg/shirt.svg" alt="Image" />
              <span>{{ user.uniform ? user.uniform : "-" }}</span>
            </div>
            <div class="team-name-role">
              <h6>
                {{ user.username }}
                <span v-if="user.nickname">({{ user.nickname }})</span>
              </h6>
              <span v-if="user.adminRole == '1'">Admin</span>
              <p v-if="user.adminRole == '0'"></p>
              <span v-if="user.fan == '1' || user.fanRole == '1'">Fan</span>
            </div>
          </div>
          <AttendeesOptions
            :currentIsAdmin="currentIsAdmin"
            :user="user"
            :selectedRoomTeam="selectedRoomTeam"
            :singleEvent="singleEvent"
          />
        </div>
      </div>
      <!-- <div class="delivered-to-wrapper" v-if="membersNotRespond.length == 0">
        <div class="message-reader-detail">
          <span>No User found.</span>
        </div>
      </div> -->
    </div>

     <div class="event-actions" v-else-if="showLoader">
      <content-placeholders :rounded="true">
        <content-placeholders-text :lines="2" class="content-margin-reaction" />
      </content-placeholders>
    </div>
    <FlashMessage
      :position="'right bottom'"
      style="z-index: 99999999 !important"
    ></FlashMessage>
    
  </div>
</template>

<script>
import AttendeesOptions from "./AttendeesOptions.vue";
import servicesModal from "../../components/Teams/ChatRight/ChatEvents/servicesModal.vue";

import axios from "axios";
export default {
  name: "AttendeesInfo",
  components: {
    AttendeesOptions,
    servicesModal,
  },
  data() {
    return {
      imgLoader: 1,
      showLoader: 1,
      showGoingLoader: 0,
      attendingList: [],
      totalGoing: 0,
      totalNotGoing: 0,
      totalMayBe: 0,
      totalNotRespond: 0,
      eventInvitedList: [],
      membersAttendeeComplete: [],
      API_URL: this.$userProfileStorageURL,
      time: this.$time,
      currentUserEventStatus: 0,
      membersGoing: [],
      membersNotGoing: [],
      membersMayBe: [],
      membersNotRespond: [],
      finalArray: [],
      selectedArray: [],
      room_count: 1,
      adult_count: 1,
      exactRoomEndDate: "",
      note: "",
      exactRoomStartDate: "",
      userMember_id: "",
      previousGoing: false,
      selectAttendeeTab: 0,
      showGoing: true,
      showNotGoing: false,
      showMayBe: false,
      showNotResponded: false,
      isGoing: false,
      isNotGoing: false,
      isMaybe: false,
      showReminderLoader: false,
    };
  },
  props: {
    selectedRoomTeam: {
      type: Object,
    },
    singleEvent: {
      type: Object,
    },
    currentUserId: {
      type: String,
    },
    currentIsAdmin: {
      type: String,
    },
  },
  watch: {
  selectedRoomTeam: {
    handler(newVal) {
     console.log(newVal);

      // If new data is received, call getAttendee
      if (newVal && Object.keys(newVal).length) {
        this.getAttendee();
      }
    },
    deep: true,
    immediate: true,
  },
    singleEvent: {
      handler(newVal) {
        
        this.previousGoing =
          newVal.loggedInUserAttendance?.member_status == "1";
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    memberStatus() {
      return this.singleEvent?.loggedInUserAttendance?.member_status || null;
    },
  },
  methods: {
    async sendReminder () {
      this.showReminderLoader = true
      // Create form data
      var formData = new FormData();
        formData.append("event_id", this.singleEvent.id);
      try {
          await axios.post(
            process.env.VUE_APP_API_URL + "event/sendRemainderToTeammates",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          this.flashMessage.success({
                title: "Success",
                message: "Reminder sent successfully!",
                time: 5000,
                blockClass: "custom-block-class"
              });
        } catch (error) {
          console.error("Error sending reminder:", error);
        } finally {
          this.showReminderLoader = false;
        }
    },
    async selectStatus(member_status) {
      if (this.previousGoing && member_status == 1) {
        return;
      }
      if (member_status == 1) {
        this.$root.$emit("openServicesModal", this.singleEvent);
      } else {
        this.showGoingLoader = 1;
        this.editTravel = false;

        // Create form data
        var formData = new FormData();
        formData.append("event_id", this.singleEvent.id);
        formData.append("member_status", member_status);
        formData.append("team_id", this.singleEvent.team_id);

        try {
          await axios.post(
            process.env.VUE_APP_API_URL + "event/eventAttendeeSelect",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          this.showGoingLoader = 0;
          this.setMemberStatus(member_status);
          this.getAttendee();

          // this.getAttendee(); // Refresh attendee list after success
        } catch (error) {
          console.error("Error updating status:", error);
          this.showGoingLoader = 0;
        }
      }
    },
    openEditTravel() {
      if (this.Services) {
        this.$root.$emit("openEditServicesModal", {
          event: this.singleEvent,
          services: this.Services,
        });
      }
    },
    // Set member_status when a button is clicked
    setMemberStatus(status) {
      if (!this.singleEvent.loggedInUserAttendance) {
        this.$set(this.singleEvent, "loggedInUserAttendance", {
          member_status: status,
        });
      } else {
        this.$set(
          this.singleEvent.loggedInUserAttendance,
          "member_status",
          status
        );
      }
    },
    onImageLoad() {
      this.imgLoader = 0;
    },
    filterGoing: function () {
      this.showGoing = true;
      this.showNotGoing = false;
      this.showMayBe = false;
      this.showNotResponded = false;
      this.selectAttendeeTab = 0;
    },
    filterNotGoing: function () {
      this.showNotGoing = true;
      this.showGoing = false;
      this.showMayBe = false;
      this.showNotResponded = false;
      this.selectAttendeeTab = 1;
    },
    filterMay: function () {
      this.showMayBe = true;
      this.showNotGoing = false;
      this.showGoing = false;
      this.showNotResponded = false;
      this.selectAttendeeTab = 2;
    },
    filterNotResponded: function () {
      this.showNotResponded = true;
      this.showNotGoing = false;
      this.showGoing = false;
      this.showMayBe = false;
      this.selectAttendeeTab = 3;
    },
    
    // async getAttendee() {
    //   this.showLoader = 1;
    //   this.showGoingLoader = 1;
    //   var formData = new FormData();
    //   formData.append("event_id", this.$route.params.id);

    //   try {
    //     const response = await axios.post(
    //       process.env.VUE_APP_API_URL + "event/eventAttendeeListing",
    //       formData,
    //       {
    //         headers: {
    //           "Content-Type": "multipart/form-data",
    //         },
    //       }
    //     );

    //     const eventAttendeeFromDB = Array.isArray(response.data.data.attendeeListing) ? response.data.data.attendeeListing : [];
    //     // const eventNotAttendeeFromDB = Array.isArray(response.data.data.notAttendeeListing) ? response.data.data.notAttendeeListing : [];
    //     this.eventInvitedList = response.data.data.inviteListing || [];
    //     const tempMembers = Array.isArray(this.selectedRoomTeam.users) ? this.selectedRoomTeam.users : [];
    //     const tempMembersRole = Array.isArray(this.selectedRoomTeam.userRole) ? this.selectedRoomTeam.userRole : [];
    //     this.eventGoingForpdf = [];

    //     // Add Uniform ID to Going Members
    //     for (const memberRole of tempMembers) {
    //       for (const memberRoles of tempMembersRole) {
    //         if (memberRoles.id == memberRole.id && memberRoles.fan != "1") {
    //           for (const attendeeRole of eventAttendeeFromDB) {
    //             if (
    //               memberRole.c_id == attendeeRole.member_id &&
    //               (attendeeRole.member_status == 1 || attendeeRole.member_status == "1")
    //             ) {
    //               const foundUser = tempMembersRole.find(
    //                 (user) => user?.id === memberRole.id
    //               );
    //               if (foundUser) {
    //                 attendeeRole.uniform = foundUser.uniform;
    //                 this.eventGoingForpdf.push(attendeeRole);
    //               }
    //             }
    //           }
    //         }
    //       }
    //     }

    //     this.membersAttendeeComplete = [];
    //     var $i = 0;
    //     this.totalGoing = 0;
    //     this.totalNotGoing = 0;
    //     this.totalMayBe = 0;
    //     this.totalNotRespond = 0;

    //     for (const member of tempMembers) {
    //       for (const memberRole of tempMembersRole) {
    //         if (memberRole.id == member.id && memberRole.fan != "1") {
    //           this.membersAttendeeComplete.push(member);
    //           this.membersAttendeeComplete[$i].event_attendee_status = 0;

    //           for (const attendee of eventAttendeeFromDB) {
    //             if (member.c_id == attendee.member_id) {
    //               this.membersAttendeeComplete[$i].event_attendee_status =
    //                 attendee.member_status;
    //               if (
    //                 this.membersAttendeeComplete[$i].event_attendee_status == 1 ||
    //                 this.membersAttendeeComplete[$i].event_attendee_status == "1"
    //               ) {
    //                 this.totalGoing++;
    //               }
    //               if (
    //                 this.membersAttendeeComplete[$i].event_attendee_status == 2 ||
    //                 this.membersAttendeeComplete[$i].event_attendee_status == "2"
    //               ) {
    //                 this.totalNotGoing++;
    //               }
    //               if (
    //                 this.membersAttendeeComplete[$i].event_attendee_status == 3 ||
    //                 this.membersAttendeeComplete[$i].event_attendee_status == "3"
    //               ) {
    //                 this.totalMayBe++;
    //               }
    //             }
    //           }


    //           if (
    //             this.membersAttendeeComplete[$i].event_attendee_status == 0 ||
    //             this.membersAttendeeComplete[$i].event_attendee_status == "0"
    //           ) {
    //             this.totalNotRespond++;
    //           }

    //           if (member.id == this.currentUserId) {
    //             this.currentUserEventStatus =
    //               this.membersAttendeeComplete[$i].event_attendee_status;
    //           }
    //           $i++;
    //         }
    //       }
    //     }
    //     this.showLoader = 0;
    //     this.showGoingLoader = 0;
    //     this.populateLists();
    //   } catch (error) {
    //     console.error("Error fetching attendee data:", error);
    //     this.showLoader = 0;
    //     this.showGoingLoader = 0;
    //   }
    // },

    
    
    async getAttendee() {
      this.showLoader = 1;
      this.showGoingLoader = 1;
      var formData = new FormData();
      formData.append("event_id", this.$route.params.id);

      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL + "event/eventAttendeeListing",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const eventAttendeeFromDB = Array.isArray(response.data.data.attendeeListing) ? response.data.data.attendeeListing : [];
        this.eventInvitedList = response.data.data.inviteListing || [];
        const tempMembers = Array.isArray(this.selectedRoomTeam.users) ? this.selectedRoomTeam.users : [];
        const tempMembersRole = Array.isArray(this.selectedRoomTeam.userRole) ? this.selectedRoomTeam.userRole : [];
        this.eventGoingForpdf = [];

        // Add Uniform ID to Going Members
        for (const memberRole of tempMembers) {
          for (const memberRoles of tempMembersRole) {
            if (memberRoles.id == memberRole.id && memberRoles.fan != "1") {
              for (const attendeeRole of eventAttendeeFromDB) {
                if (
                  memberRole.c_id == attendeeRole.member_id &&
                  (attendeeRole.member_status == 1 || attendeeRole.member_status == "1")
                ) {
                  const foundUser = tempMembersRole.find(
                    (user) => user?.id === memberRole.id
                  );
                  if (foundUser) {
                    attendeeRole.uniform = foundUser.uniform;
                    this.eventGoingForpdf.push(attendeeRole);
                  }
                }
              }
            }
          }
        }

        this.membersAttendeeComplete = [];
        var $i = 0;
        this.totalGoing = 0;
        this.totalNotGoing = 0;
        this.totalMayBe = 0;
        this.totalNotRespond = 0;
        console.log(tempMembers, "tempMembers 1")
        console.log(tempMembersRole, "tempMembersRole 1")

        for (const member of tempMembers) {
          for (const memberRole of tempMembersRole) {
            if (memberRole.id == member.id) {
              this.membersAttendeeComplete.push(member);
              console.log(this.membersAttendeeComplete, "membersAttendeeComplete 1")
              this.membersAttendeeComplete[$i].event_attendee_status = 0;

              for (const attendee of eventAttendeeFromDB) {
                if (member.c_id == attendee.member_id) {
                  console.log(this.membersAttendeeComplete[$i],"member")
                  console.log(attendee.member_status,"member db")
                  this.membersAttendeeComplete[$i].event_attendee_status =
                    attendee.member_status;
                  if (
                    this.membersAttendeeComplete[$i].event_attendee_status == 1 ||
                    this.membersAttendeeComplete[$i].event_attendee_status == "1"
                  ) {
                    this.totalGoing++;
                  }
                  if (
                    this.membersAttendeeComplete[$i].event_attendee_status == 2 ||
                    this.membersAttendeeComplete[$i].event_attendee_status == "2"
                  ) {
                    this.totalNotGoing++;
                  }
                  if (
                    this.membersAttendeeComplete[$i].event_attendee_status == 3 ||
                    this.membersAttendeeComplete[$i].event_attendee_status == "3"
                  ) {
                    this.totalMayBe++;
                  }
                }
              }

              if (
                this.membersAttendeeComplete[$i].event_attendee_status == 0 ||
                this.membersAttendeeComplete[$i].event_attendee_status == "0" ||
                this.membersAttendeeComplete[$i].event_attendee_status === undefined ||
                this.membersAttendeeComplete[$i].event_attendee_status === null
              ) {
                this.totalNotRespond++;
              }

              if (member.id == this.currentUserId) {
                this.currentUserEventStatus =
                  this.membersAttendeeComplete[$i].event_attendee_status;
              }
              $i++;
            }
          }
        }
        this.showLoader = 0;
        this.showGoingLoader = 0;
        this.populateLists();
       // Collect IDs from all response categories
       console.log(this.membersAttendeeComplete, "All members before filtering");
        console.log(this.membersGoing, "Going List");
        console.log(this.membersNotGoing, "Not Going List");
        console.log(this.membersMayBe, "May Be List");
        console.log(this.membersNotRespond, "Not Responded List");

        // Collect IDs from all response categories
        const teamIds = [
          ...this.membersGoing.map(member => member.c_id),
          ...this.membersNotGoing.map(member => member.c_id),
          ...this.membersMayBe.map(member => member.c_id),
          ...this.membersNotRespond.map(member => member.c_id)
        ].filter(id => {
          if (!id) {
            console.warn("Found undefined/null c_id:", id);
          }
          return id;
        });

        console.log(teamIds.length, "Final teamIds count before sending");
        console.log(teamIds, "Final teamIds before sending"); // Remove any null or undefined values

      if (teamIds.length > 0) {
          axios.post(process.env.VUE_APP_API_URL + "chat/getTeammatesNickName", {
              teammates_id: teamIds
          })
          .then(response => {
              if (response.data && response.data.data) {
                  const nicknamesMap = new Map();

                  // Store nicknames in a Map (if null, do not assign anything)
                  response.data.data.forEach(user => {
                      if (user.nick_name !== null) {
                          nicknamesMap.set(user.user_id, user.nick_name);
                      }
                  });

                  // Function to update nickname field in member lists
                  const updateNicknames = (list) => {
                      return list.map(member => ({
                          ...member,
                          nickname: nicknamesMap.has(Number(member.c_id)) ? nicknamesMap.get(Number(member.c_id)) : null
                      }));
                  };

                  // Update each list with the fetched nicknames
                  this.membersGoing = updateNicknames(this.membersGoing);
                  this.membersNotGoing = updateNicknames(this.membersNotGoing);
                  this.membersMayBe = updateNicknames(this.membersMayBe);
                  this.membersNotRespond = updateNicknames(this.membersNotRespond);
              }
          })
          .catch(error => {
              console.error("Error fetching teammate nicknames:", error);
          });
      }

      } catch (error) {
        console.error("Error fetching attendee data:", error);
        this.showLoader = 0;
        this.showGoingLoader = 0;
      }
    },


    populateLists() {
      console.log(this.membersAttendeeComplete, "this.membersAttendeeComplete")
      this.membersGoing = this.membersAttendeeComplete.filter(
        (attendee) => attendee.event_attendee_status == 1
      );
      this.membersNotGoing = this.membersAttendeeComplete.filter(
        (attendee) => attendee.event_attendee_status == 2
      );
      this.membersMayBe = this.membersAttendeeComplete.filter(
        (attendee) => attendee.event_attendee_status == 3
      );
      this.membersNotRespond = this.membersAttendeeComplete.filter(
        (attendee) =>
          attendee.event_attendee_status == 0 ||
          attendee.event_attendee_status == "0" ||
          attendee.event_attendee_status === undefined ||
          attendee.event_attendee_status === null
      );
    },
    async selectStatusWithServices(status) {
      status = 1;
      var formData = new FormData();
      formData.append("event_id", this.singleEvent.id);
      formData.append("member_status", status);
      formData.append("services", this.selectedArray);
      formData.append("team_id", this.selectedRoomTeam.roomId);
      formData.append("room_count", this.room_count);
      formData.append("exactStartDate", this.exactRoomStartDate);
      formData.append("exactEndDate", this.exactRoomEndDate);
      formData.append("note", this.note);
      formData.append("adult_count", this.adult_count);
      if (
        this.userMember_id !== null &&
        this.userMember_id !== undefined &&
        this.userMember_id !== ""
      ) {
        formData.append("member_id", this.userMember_id);
      }
      // formData.append("member_id", this.userMember_id);

      await axios
        .post(
          process.env.VUE_APP_API_URL + "event/eventAttendeeSelect",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(() => {
          this.setMemberStatus(status);
          this.getAttendee();
        });
    },
  },
  mounted() {
    if (this.selectedRoomTeam && Object.keys(this.selectedRoomTeam).length) {
      this.getAttendee();
    };
    this.$root.$on("showAttendeesList", () => {
      this.getAttendee();
    });
    this.$root.$on(
      "attendeesData",
      (
        data,
        user_id,
        room_count,
        adultQuantity,
        note,
        exactRoomStartDate,
        exactRoomEndDate
      ) => {
        this.selectedArray = data;
        this.userMember_id = user_id;
        this.room_count = room_count;
        this.note = note;
        this.adult_count = adultQuantity;
        this.exactRoomEndDate = exactRoomEndDate;
        this.exactRoomStartDate = exactRoomStartDate;
        this.selectStatusWithServices();
      }
    );
  },
};
</script>