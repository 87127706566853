<template>
  <div>
    <a data-toggle="modal" data-target="#uploadExcelFileModal" ref="openModalXXl"> </a>
    <!-- Modal -->
    <div
      class="modal fade"
      id="uploadExcelFileModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="uploadExcelFileModalLabel"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="uploadExcelFileModalLabel">Upload Scoresheet</h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeEventModal"
              @click="closeMain"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="container-fluid no-pdd">
              <form @drop="onFileDrop" @dragover.prevent>
                <div class="form-group">
                  <div class="drop-zone" id="file-drop-zone" @click="openFileInput">
                    <div class="upload" v-if="!file">
                      <p>Drag and drop a file here or click</p>
                      <img class="pt-4" src="../../../assets/images/upload.png" />
                    </div>
                    <div class="upload" v-else>
                      <p>{{ file.name }}</p>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <input
                    type="file"
                    ref="fileInput"
                    style="display: none"
                    @change="onFileChange"
                  />
                </div>
              </form>
            </div>
          </div>
          <div class="event-btns-wrapper p-3 justify-content-end">
           
            <button
              v-if="this.isDisabled == false"
              type="button"
              class="main-btn w-25"
              @click="uploadFile(singleGame, 0)"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Upload</span>
            </button>
            <button
              v-else-if="this.isDisabled == true"
              type="button"
              class="main-btn btn-primary w-25"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Upload</span>
            </button>

            <button
                type="button"
                class="main-btn btn-primary w-25"
                v-on:click="resetForm()"
                v-bind:class="{ 'disabled': isLoadingArray[0] }"
            >
                <span>Remove file</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  components: {},
  name: "NewGame",
  data() {
    return {
      showloader: true,
      file: null,
      fileName: "",
      singleGame: {},
      isLoadingArray: [],
      isDisabled: false,
    };
  },

  methods: {
    onFileChange(event) {
      const selectedFile = event.target.files[0];
      this.file = selectedFile;
    },

    isExcelFile(file) {
      // Check if the file type is Excel (.xls or .xlsx)
      return /\.(xls|xlsx)$/i.test(file.name);
    },
    resetForm() {
      if (this.file) {
        this.file = null;
      this.fileName = "";
      this.$refs.fileInput.value = null;
      } else {
        this.$alert("No file selected", "Please select file first.");
      }
      
    },
    closeMain() {
      var elem = this.$refs.closeEventModal;
      elem.click();
      this.file = null;
      this.fileName = "";
      this.$refs.fileInput.value = null;
    },
    openFileInput() {
      this.$refs.fileInput.click();
    },
    onFileDrop(event) {
      event.preventDefault();
      if (event.dataTransfer.files.length > 0) {
        this.file = event.dataTransfer.files[0];
      }
    },
    async uploadFile(singleGame, index) {
      if (this.file) {
        this.isDisabled = true;
        const formData = new FormData();
        formData.append("scoresheet", this.file);
        formData.append("gameId", singleGame.id);
        this.$set(this.isLoadingArray, index, true);
        await axios
          .post(`${process.env.VUE_APP_API_URL}game/uploadGameScoreSheet`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            if (response.data.statusCode === 200) {
              this.$set(this.isLoadingArray, index, false);
              this.isDisabled = false;
              this.$root.$emit("showSingleBoxScore");
              this.closeMain();
              this.$alert("Scoresheet uploaded successfully", "Scoresheet uploaded!");
            } else {
              this.isDisabled = false;
              this.$set(this.isLoadingArray, index, false);
              this.closeMain();
              this.$alert("Unable to upload scoresheet file.Please upload another file", "Upload Failed!");
            }
          })
          .catch((error) => {
            this.$set(this.isLoadingArray, index, false);
            this.isDisabled = false;
            console.error("Error uploading game scoresheet: ", error);
          });
      } else {
        this.isDisabled = false;
        this.$alert("No file selected", "Please select  file before uploading.");
      }
    },
  },

  mounted() {
    this.$root.$on("opneUploadExcelModal", (data) => {
      this.singleGame = data;
      var elem = this.$refs.openModalXXl;
      if (elem) {
        elem.click();
      }
    });
  },
};
</script>
<style scoped>
.drop-zone {
  height: 150px;
  border-radius: 12px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  background-color: #e5f2fe;
}

.drop-zone p {
  margin: 0;
}

.dragInput {
  width: 100%;
  border: none;
  padding: 8px;
  border-bottom: 1px solid #ccc;
}

.dragInput:focus {
  outline: none;
}

.upload {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.upload img {
  width: 30px;
}
.main-btn {
  font-weight: 400;
  background: #e2f5fe !important;
  border-radius: 5px;
  padding: 6px 8px;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  cursor: pointer;
  font-size: 14px;
  border: none;
  outline: none;
  color: black;
}
.label-type {
  font-size: 10px;
  font-weight: 600;
  color: #6c757d;
}

.all-day {
  font-size: 1rem;
}

.error {
  color: #ffffff !important;
  font-weight: 400;
  font-size: 12px;
  position: absolute;
  top: -20px;
  padding: 5px 10px;
  z-index: 99;
  border-radius: 5px;
  background-color: #ff5b66;
}

.event-error {
  color: #ffffff !important;
  font-weight: 400;
  font-size: 10px;
  position: absolute;
  padding: 3px 8px;
  z-index: 99;
  border-radius: 5px;
  background-color: #ff5b66;
  top: 2px;
}

a {
  font-size: 12px;
  font-weight: 500;
  color: #188ef5 !important;
  cursor: pointer;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: url("../../../assets/images/calendar-input.png");
  cursor: pointer;
  width: 20px;
  height: 20px;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: url("../../../assets/images/clock-input.png");
  cursor: pointer;
  width: 20px;
  height: 20px;
}

div.form-group .add-notes {
  font-size: 14px;
}

.form-group select {
  font-size: 14px !important;
  min-height: 45px !important;
  display: block !important;
  padding: 8px 40px 8px 8px !important;
  border-radius: 5px !important;
  border: 1px solid #e8e8e8 !important;
  background: #fff !important;
  border-radius: 5px !important;
}

.button-overlay {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  transform: translate(0, -100%);
  background: transparent;
  border: none;
  z-index: 99;
  height: 50px;
}
</style>
