<template>
    <div class="contact-us-form">
        <div class="sign-in-form">
            <form @submit.prevent @keyup.enter="Contact(0)">
                <h4 class="mb-3">Contact Us</h4>
                <p v-if="errorMessage != null" class="tag-line" style="color: red">
                    {{ errorMessage }}
                </p>
                <div class="form-group">
                    <input id="fname" class="form-control" v-model.trim="$v.details.fname.$model" spellcheck="false"
                        autocomplete="off" :class="{
                            'is-invalid': validationStatus($v.details.fname),
                             filled: $v.details.fname.$model.trim() !== ''
                        }" :readonly="!!user" type="text" required v-on:keyup="errorNull" />

                    <span class="placeholder">First Name</span>
                    <div v-if="!$v.details.fname.required" class="invalid-tooltip">
                        First name is required
                    </div>
                    <div v-if="!$v.details.fname.maxLength" class="invalid-tooltip">
                        Maximum 18 characters allowed.
                    </div>
                </div>
                <div class="form-group">
                    <input id="lname" class="form-control" v-model.trim="$v.details.lname.$model" spellcheck="false"
                        autocomplete="off" :class="{
                            'is-invalid': validationStatus($v.details.lname),
                             filled: $v.details.lname.$model.trim() !== ''
                        }" :readonly="!!user" required type="text" v-on:keyup="errorNull" />

                    <span class="placeholder">Last Name</span>
                    <div v-if="!$v.details.lname.required" class="invalid-tooltip">
                        Last name is required
                    </div>
                    <div v-if="!$v.details.lname.maxLength" class="invalid-tooltip">
                        Maximum 18 characters allowed.
                    </div>
                </div>
                <div class="form-group">
                    <input id="email" class="form-control" v-model.trim="$v.details.email.$model" spellcheck="false"
                        autocomplete="off" :class="{
                      'is-invalid': validationStatus($v.details.email),
                      filled: $v.details.email.$model.trim() !== '' // Add class when value exists
                    }" :readonly="!!user" required type="text" v-on:keyup="errorNull" />

                    <span class="placeholder">Email</span>
                    <div v-if="!$v.details.email.required" class="invalid-tooltip">
                        Email is required
                    </div>
                    <div v-if="!$v.details.email.email" class="invalid-tooltip">
                        Provide valid email
                    </div>
                </div>
                <div class="d-flex">
                    <!-- Code Select -->
                    <div class="form-group phone-code w-25 pr-2">
                        <b-form-select v-model="phonedetails.mob_code" :options="codeList" v-on:keyup="errorNull">
                            <template #first></template>
                        </b-form-select>
                    </div>
                    <div class="form-group flex-grow-1">
                        <input id="mob_phone" class="form-control" v-model.trim="phonedetails.mob" spellcheck="false"
                            autocomplete="off" :class="{
                                'is-invalid': validationStatus($v.phonedetails.mob),
                                filled: $v.phonedetails.mob.$model.trim() !== ''
                            }" v-phone-mask @keydown.delete="handleDelete"
       @keydown.backspace="handleDelete" required type="text"  v-on:keyup="errorNull" />
                        <span class="placeholder">Mobile Number</span>
                        <div v-if="!$v.phonedetails.mob.required" class="invalid-tooltip">
                            Mobile number is required
                        </div>
                        <div v-if="!$v.phonedetails.mob.validPhone" class="invalid-tooltip">
                            Please enter a valid 10-digit phone number
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <textarea type="text" class="form-control pt-3 mt-2 mb-0 add-notes" id="note" rows="4"
                        v-model.trim="$v.note.$model" @keyup="errorNull" placeholder="Comments" maxlength="200"
                        ></textarea>
                    <!-- <div v-if="!$v.note.required" class="invalid-tooltip">
                        Comments are required
                    </div> -->
                </div>
                <div class="event-btns-wrapper">
                    <button type="button" class="btn btn-primary w-100" @click="Contact(0)"
                        :disabled="isFormInvalid || isLoadingArray[0]">
                        <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
                        <span v-else>Continue</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import {
    required,
    email,
    maxLength,
} from "vuelidate/lib/validators";
import { mapGetters } from "vuex";


export default {
    name: "ContactForm",
    data: function () {
        return {
            errorMessage: null,
            phonedetails: {
                mob: "",
                mob_code: "+1",
            },
            codeList: [
                { value: "+92", text: "Pak (+92)" },
                { value: "+1", text: "USA (+1)" },
            ],
            isLoadingArray: [],
            details: {
                fname: "",
                lname: "",
                email: "",
            },
            note: "",
        }
    },
    computed: {
        ...mapGetters("auth", ["user"]),
        isFormInvalid() {
            return this.$v.$invalid
        }
    },
 
    validations: {
        details: {
            email: { required, email },
            fname: { required, maxLength: maxLength(18) },
            lname: { required, maxLength: maxLength(18) },
        },
        phonedetails: {
            mob: {
                required,
                validPhone: (value) => /^\d{10}$/.test(value.replace(/\D/g, ''))
            },
        },
        note: { required },
    },
    watch: {
        'phonedetails.mob'(newVal) {
        // Auto-format while typing (but preserve cursor position)
        const cursorPos = this.$refs.phoneInput?.selectionStart || 0;
        const formatted = this.formatPhoneNumber(newVal);
        
        if (formatted !== newVal) {
            this.phonedetails.mob = formatted;
            
            // Adjust cursor position after formatting
            this.$nextTick(() => {
                let newCursorPos = cursorPos;
                
                // If cursor was right before a formatting character, move it forward
                if (formatted[cursorPos] === '(' || formatted[cursorPos] === ')' || formatted[cursorPos] === ' ' || formatted[cursorPos] === '-') {
                    newCursorPos++;
                }
                
                this.$refs.phoneInput?.setSelectionRange(newCursorPos, newCursorPos);
            });
        }
    },
        user: {
            immediate: true,
            handler(newUser) {
                if (newUser) {
                    this.details.fname = newUser.fname || "";
                    this.details.lname = newUser.lname || "";
                    this.details.email = newUser.email || "";
                    if (newUser.mob) {
                        const cleaned = newUser.mob.replace(/\D/g, '');
                        if (cleaned.length === 10) {
                            this.phonedetails.mob = this.formatPhoneNumber(cleaned);
                        } else {
                            this.phonedetails.mob = newUser.mob;
                        }
                    } else {
                        this.phonedetails.mob = "";
                    }
                }
            },
        },
    },
    mounted(){
        if (this.inputValue.trim() !== "") {
      this.$refs.inputField.classList.add("filled");
    }
    },
    methods: {
        validationStatus: function (validation) {
            return typeof validation != "undefined" ? validation.$error : false;
        },
        handleDelete(event) {
        const input = event.target;
        const cursorPos = input.selectionStart;
        const value = this.phonedetails.mob;

        // Check if cursor is near a formatting character: '(', ')', ' ', '-'
        const formattingChars = ['(', ')', ' ', '-'];
        const isAfterFormattingChar = 
            (cursorPos > 0 && formattingChars.includes(value[cursorPos - 1])) ||
            (event.key === 'Delete' && cursorPos < value.length && formattingChars.includes(value[cursorPos]));

        if (isAfterFormattingChar) {
            event.preventDefault(); // Prevent default deletion
            
            let newValue;
            if (event.key === 'Backspace') {
                // Backspace: Remove character before cursor
                newValue = value.substring(0, cursorPos - 1) + value.substring(cursorPos);
                this.phonedetails.mob = newValue;
                
                // Move cursor back by 1
                this.$nextTick(() => {
                    input.setSelectionRange(cursorPos - 1, cursorPos - 1);
                });
            } else if (event.key === 'Delete') {
                // Delete: Remove character after cursor
                newValue = value.substring(0, cursorPos) + value.substring(cursorPos + 1);
                this.phonedetails.mob = newValue;
                
                // Keep cursor at the same position
                this.$nextTick(() => {
                    input.setSelectionRange(cursorPos, cursorPos);
                });
            }
        }
    },

    formatPhoneNumber(phoneNumber) {
        if (!phoneNumber) return "";
        
        // Remove all non-digit characters
        const cleaned = phoneNumber.replace(/\D/g, '');
        
        // Don't format if length is too short (during deletion)
        if (cleaned.length < 3) return cleaned;
        
        // Format as (XXX) XXX-XXXX
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        return match ? `(${match[1]}) ${match[2]}-${match[3]}` : cleaned;
    },


        errorNull: function () {
            this.errorMessage = null;
            this.$v.$touch();
        },
        async Contact(index) {
            this.$v.$touch();
            if (this.isFormInvalid) return;

            this.$set(this.isLoadingArray, index, true);

            const payload = {
                ...this.details,
                ...this.phonedetails,
                note: this.note,
                mob: this.phonedetails.mob.replace(/\D/g, '') // Clean phone number
            };

            try {
                const response = await axios.post(
                    process.env.VUE_APP_API_URL + "event/emailForFounderSubscription",
                    payload
                );

                if (response.data.statusCode === 404 || response.data.statusCode === 403) {
                    this.$alert(response.data.message, "Error");
                } else {
                    this.$alert("Inquiry received successfully", "Success", {
                        confirmButtonText: "OK",
                    }).then(() => {
                        this.$router.push("/");
                    });
                }
            } catch (error) {
                console.error("Error:", error);
                this.$alert("Something went wrong", "Error");
            } finally {
                this.$set(this.isLoadingArray, index, false);
            }
        }
    }
}
</script>