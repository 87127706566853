<template>
  <div>
    <input class="d-none" data-target="#BoxViewScoresModal" data-toggle="modal" ref="openBoxViewScoresModal" />
    <div class="modal fade" id="BoxViewScoresModal" tabindex="-1" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Box Score</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" ref="closeKeepScoresModal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body pb-0">
            <!-- Start -->
            <div>
              <div class="container-fluid no-pdd">
                <div class="">
                  <div class="row no-gutters game-detail-wrapper border-0 h-100">
                    <div class="game-content col-12">

                      <div class="d-block d-md-none">

                        <div class="game-name justify-content-between mb-1">
                          <div class="tournament-delayed-tag" v-if="gameScore.game_delayed == 1">
                            <p>Delayed</p>
                          </div>
                          <div>
                            <span>{{ gameScore.delayed_reason }}</span>
                          </div>
                        </div>

                        <div class="d-flex justify-content-between mb-1">
                          <router-link :to="{ name: 'AssociationSingleEventDetail', params: { id: gameScore.guid } }"
                            class="m-0">
                            <div class="game_name11 " @click="closeModal()">
                              {{ gameScore.event_name }}
                            </div>
                          </router-link>

                          <router-link :to="{name: 'Tournament', params: { id: gameScore.tournamentguid } }"
                            class="m-0">
                            <div class="game_name11" @click="closeModal()">
                              {{ gameScore.tournament_name }}
                            </div>
                          </router-link>
                        </div>

                        <div class="game-name mb-1">
                          <h6>{{ gameScore.game_name }}</h6>
                        </div>

                        <div class="event-date mb-1">
                          <img src="../../assets/images/calendar-event.png" />
                          <div class="content">
                            <p>
                              Scheduled:
                              {{ gameScore.date | formatDateOfBirth }}
                              {{ gameScore.start_time }} - Actual Start Time
                              {{ gameScore.actual_start_time }} - Time Limit:
                              {{ gameScore.time_limit }} Minutes
                            </p>
                          </div>
                        </div>

                        <div class="parkname" v-if="gameScore.field || gameScore.park">
                          <p class="d-flex">
                            <img class="mr-2" src="../../assets/images/maximize.png" />

                            <span v-if="gameScore.park">
                              {{ gameScore.park.park_name }}
                            </span>

                            <span v-if="gameScore.field">
                              - {{ gameScore.field.field_name }}
                            </span>
                          </p>
                        </div>

                      </div>

                      <div class="d-none d-md-block">

                        <div class="d-flex justify-content-between">
                          <div class="game-name justify-content-between w-75">
                            <div class="tournament-delayed-tag" v-if="gameScore.game_delayed == 1">
                              <p>Delayed</p>
                            </div>
                            <div class="w-100 d-flex">
                              <h6 class="pr-2">{{ gameScore.game_name }}</h6>
                              <span>{{ gameScore.delayed_reason }}</span>
                            </div>
                          </div>
                          <router-link :to="{ name: 'AssociationSingleEventDetail', params: { id: gameScore.guid } }">
                            <div class="game_name11 " @click="closeModal()">
                              {{ gameScore.event_name }}
                            </div>
                          </router-link>
                        </div>

                        <div class="d-flex justify-content-between">
                          <div class="event-date w-75">
                            <img src="../../assets/images/calendar-event.png" />
                            <div class="content">
                              <p>
                                Scheduled:
                                {{ gameScore.date | formatDateOfBirth }}
                                {{ gameScore.start_time }} - Actual Start Time
                                {{ gameScore.actual_start_time }} - Time Limit:
                                {{ gameScore.time_limit }} Minutes
                              </p>
                            </div>
                          </div>

                          <router-link :to="{name: 'Tournament', params: { id: gameScore.tournamentguid } }">
                            <div class="game_name11" @click="closeModal()">
                              {{ gameScore.tournament_name }}
                            </div>
                          </router-link>

                        </div>

                        <div class="parkname" v-if="gameScore.field || gameScore.park">
                          <p class="d-flex">
                            <img class="mr-2" src="../../assets/images/maximize.png" />

                            <span v-if="gameScore.park">
                              {{ gameScore.park.park_name }}
                            </span>

                            <span v-if="gameScore.field">
                              - {{ gameScore.field.field_name }}
                            </span>
                          </p>
                        </div>

                      </div>

                      <div v-if="gameScore.park" @click="openGoogleMapforGamePark(gameScore.park)"
                        class="mb-2 mt-2 cursor-pointer parkname">
                        <p v-if="
                            gameScore.park.address != '' &&
                              gameScore.park.address != 'null' &&
                              gameScore.park.address != null
                          ">
                          <!-- <img
                              src="../../assets/images/location.png"
                              class="mr-2"
                            /> -->
                          {{ gameScore.park.address }}
                        </p>
                        <p v-else>
                          <!-- <img
                              src="../../assets/images/location.png"
                              class="mr-2"
                            /> -->
                          {{ gameScore.park.park_name }}
                        </p>
                      </div>
                      <!-- <div class="opponent-name">
                          <h5>{{ gameScore.opponent_name }}</h5>
                        </div> -->
                      <div v-if="
                          gameScore.comment != null &&
                            gameScore.comment != '' &&
                            gameScore.comment != 'null'
                        " class="game-description">
                        <p>{{ gameScore.comment }}</p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div class="modal-tables d-flex align-items-centered" v-if="gameScore != []">
              <table class="table table-teams mb-0">
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <!-- <th>Inning</th> -->
                  </tr>
                </thead>
                <tbody v-for="score in gameScore.score" :key="score.id + 1000">
                  <tr v-if="score.team_type == 1">
                    <th scope="row">
                      <div class="team-name d-flex align-items-center">
                        <div class="user-img mr-2">
                          <div class="users-notification" v-if="imgLoader == 1">
                            <img src="../../assets/loader.gif" alt="" />
                          </div>
                          <img @load="onImageLoad" v-if="score.team.team_avatar"
                           :src="url + score.team.team_avatar" alt="" style="border: 2px solid white;" />
                          <img @load="onImageLoad" v-else alt="" src="../../assets/team-avatar.png" />
                        </div>
                        #{{ score.teams.team_sr_no }} {{ score.team.team_name }}
                      </div>

                      <!-- <div class="team-type">Visiting Team</div> -->
                    </th>
                    <th>Runs</th>
                  </tr>
                  <tr v-if="score.team_type == 1">
                    <th scope="row"></th>
                    <th>HR</th>
                  </tr>
                </tbody>
                <tbody v-for="score in gameScore.score" :key="score.id + 2000">
                  <tr v-if="score.team_type == 2">
                    <th scope="row">
                      <div class="team-name d-flex align-items-center">
                        <div class="user-img mr-2">
                          <div class="users-notification" v-if="imgLoader == 1">
                            <img src="../../assets/loader.gif" alt="" />
                          </div>
                          <img @load="onImageLoad" v-if="score.team.team_avatar"
                           :src="url + score.team.team_avatar" alt="" style="border: 2px solid white;" />
                          <img @load="onImageLoad" v-else alt="" src="../../assets/team-avatar.png" />
                        </div>
                        #{{ score.teams.team_sr_no }} {{ score.team.team_name }}
                      </div>
                      <!-- <div class="team-type">Home Team</div> -->
                    </th>
                    <th>Runs</th>
                  </tr>
                  <tr v-if="score.team_type == 2">
                    <th scope="row"></th>
                    <th>HR</th>
                  </tr>
                </tbody>
              </table>
              <InningTournament />
            </div>
            <!-- End -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InningTournament from "./InningTournament.vue";
export default {
  name: "InningTournamentModal",
  components: {
    InningTournament,
  },
  data: function() {
    return {
      url: this.$groupAvatarStorageURL,
      imgLoader: 1,
      gameScore: [],
    };
  },
  methods: {
    onImageLoad() {
      this.imgLoader = 0;
    },

    closeModal() {
      var elem = this.$refs.closeKeepScoresModal;
      if (elem) {
        elem.click();
      }
    },
    openGoogleMapforGamePark(item) {
      const strlatLng = item["lat"] + "," + item["lng"];
      const urlSuffix = strlatLng ? strlatLng : item["address"];

      window.open(
        "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
        "_blank"
      );
    },
  },
  mounted() {
    this.$root.$on("openBoxViewScoresModal", (score) => {
      this.gameScore = [];
      this.gameScore = score;
      var elem = this.$refs.openBoxViewScoresModal;
      if (elem) {
        elem.click();
      }
    });
  },
};
</script>

<style scoped>
.modal-tables {
  width: 100%;
  background-color: #ffffff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}
.table {
  text-align: center;
  font-size: 13px;
  background-color: #e5f2fe;
}
.table-teams {
  width: 40%;
}

.table-teams {
  -webkit-border-radius: 5px 0 0 5px;
  -moz-border-radius: 5px 0 0 5px;
  -ms-border-radius: 5px 0 0 5px;
  -o-border-radius: 5px 0 0 5px;
  border-radius: 5px 0 0 5px;
}

.table.table-teams tbody tr:nth-child(1) {
  height: 40px !important;
  border-right: 0 !important;
}

.table thead th,
.table tbody td {
  height: 40px;
}

.table tbody + tbody {
  border: none !important;
}
.table.table-teams .team-name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.game_name11 {
  border-right: none;
  font-size: 14px;
  color: #188ef5 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
  text-align: left;
}
 .parkname p{
    color: #557491!important;
 }

.date-date {
  font-size: 14px;
  text-align: left;
  color: #dc3545 !important;
  font-weight: 400;
}

@media screen and (max-width: 576px) {
  /* .table {
    font-size: 8px;
  } */

  .table th {
    padding: 0.75rem 0.3rem;
  }

  .table-teams {
    width: 50%;
  } 
  .table-teams th {
   padding-top : 15px!important;
   
  }
}

@media screen and (min-width: 768px) {
  .modal-dialog {
    max-width: 70% !important;
  }
}
@media screen and (max-width: 380px) {
  .date-date {
    font-size: 9px;
  }

  .table.table-teams thead tr:nth-child(1) {
    height: 40px !important;
  }
  .table.table-teams tbody tr:nth-child(1) {
    height: 40px !important;
  }
  .table.table-teams tbody tr:nth-child(2) {
    height: 40px !important;
  }
}
.table-teams.table-bordered thead tr th:nth-child(1) {
  border-right: 0 !important;
  border-left: 0 !important;
}
.table-teams.table-bordered thead tr th:nth-child(2) {
  border-left: 0 !important;
  border-right: 0 !important;
}

.table-teams.table-bordered tbody tr div:nth-child(1) {
  border-bottom: 0 !important;
}
.table-teams thead tr th {
  border-bottom: 0 !important;
}

.date-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.date-content p {
  font-weight: 500;
  font-size: 10px;
  color: #ff5b66;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
}

.game-name  {
  display: flex;
  gap: 5px;
}
.game-name h6 {
  color: #254c72 !important;
}
.game-name span {
  color: #ff5b66 !important;
}

.event-date {
    min-height: 35px!important;
}
</style>
