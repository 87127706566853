<template>
  <div class="opt edit-btn m-0">
    <img
      src="../../../assets/images/ellipsis-h.png"
      alt=""
      @click="toggleMenu"
      v-click-outside="hideMenu"
    />
    <div class="sub-setting-options edit-event-options" v-if="showMenu" :style="{ top: dynamicTop }" ref="subSettingOptions">
      <ul class="d-block pb-0">
          
        <router-link v-if="(clickedGame.team_one && clickedGame.team_two && clickedGame.team_one.team_data && clickedGame.team_two.team_data && clickedGame.team_one.team_data.team_name && clickedGame.team_two.team_data.team_name)"
             class="score-redirect" :to="{ name: 'TournamentScores', params: { guid: clickedGame.guid } }">
            <li>Scoring</li>
        </router-link>
        <li class="cursor-pointer" @click="callModal(clickedGame)" v-if="currentIsAdmin == '1'">Edit Game</li>
        <li class="cursor-pointer" @click="deleteGame(clickedGame)" v-if="currentIsAdmin == '1'" >Delete Game</li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "TournamentEllipsis",
  props: {
    clickedGame: {
      typeof: Object,
    },
    currentIsUmpire: {
      type: String,
    },
    currentIsAdmin: {
      type: String,
    },
    association_id: {
      type: Number,
    },
  },

  data() {
    return {
      showMenu: false,
      UserId : localStorage.getItem("userId"),
      dynamicTopValue: '0',
    };
  },
  watch: {
    showMenu(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          const ulElement = this.$refs.subSettingOptions ? this.$refs.subSettingOptions.querySelector('ul') : null;
          const liCount = ulElement ? ulElement.children.length : 0;
          
          if (liCount === 1) {
            this.dynamicTopValue = '-20px';
          } else {
            this.dynamicTopValue = '-100px';
          }
        });
      } 
    },
  },
  computed: {
    dynamicTop() {
      return this.dynamicTopValue;
    }
  },
  methods: {
    getLiCount() {
      if (this.$refs.subSettingOptions) {
        const ulElement = this.$refs.subSettingOptions.querySelector('ul');
        return ulElement ? ulElement.children.length : 0;
      }
      return 0;
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    hideMenu() {
      this.showMenu = false;
    },
    callModal(clickedGame) {
      this.$root.$emit("openTournamentEditGameModal1", clickedGame)
    },

    async deleteGame() {
      let confirmationMessage = `About to delete game ${this.clickedGame.game_name}. Continue ?`;

      if (this.clickedGame.team_one && this.clickedGame.team_two &&
          this.clickedGame.team_one.team_data && this.clickedGame.team_two.team_data &&
          this.clickedGame.team_one.team_data.team_name && this.clickedGame.team_two.team_data.team_name) {
        confirmationMessage = `About to delete game ${this.clickedGame.game_name} (${this.clickedGame.team_one.team_data.team_name} vs ${this.clickedGame.team_two.team_data.team_name}). Continue ?`;
      }

      this.$confirm(confirmationMessage).then(() => {
        var url = window.location.pathname;
        var segment_array = url.split("/");
        const Guid = segment_array.pop();
        var formData = new FormData();
        formData.append("game_id", this.clickedGame.id);
        formData.append("association_id", this.association_id);
        formData.append("tournament_guid", Guid);
        let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,

        color: "#ffffff",
        backgroundColor: "#333",
        opacity: 0.6,
      });

        axios
          .post(process.env.VUE_APP_API_URL + "tournaments/deleteTournamentGame", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(response => {
            loader.hide();
            if (response.data.statusCode === 200) {
              this.$root.$emit("RefreshTournamentGames");
              loader.hide();

            } else if (response.data.statusCode === 403) {
              this.$alert(response.data.message, 'Error');
              this.$router.push({ name: "Teams" });
              loader.hide();
            } else {
            this.$alert(response.data.message, 'Delete game').then(() => {
              this.$root.$emit("RefreshTournamentGames");
              loader.hide();
            });
          }
          })
          .catch(error => {
            console.error("An error occurred while deleting the game:", error);     
            loader.hide();       
          });
      });
    },

  },
  mounted() {
  },
};
</script>

<style scoped>
.sub-setting-options {
  position: absolute;
  right: 95% !important;
}

.sub-setting-options ul li {
  text-align: start;
}
</style>
