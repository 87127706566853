<template>
    <div>
        <GameListModal :selectedRoomTeam="selectedRoomTeam" :singleGame="singleGame"/>
        <div class="box-score-top-container">
            <div class="left-section flex-row">
                <div class="switch-game cursor-pointer" @click="openGameModal">
                    <span class="content">Switch Game</span>
                    <h6 class="text-capitalize">{{ singleGame.name }}</h6>
                </div>
                <div class="opponent-data" v-if="scores.length == 0">
                    <h6>
                        {{
                            singleGame.opponent_name
                        }}
                    </h6>
                    <div class="custom-avatar" :style="{ backgroundColor: getRandomColor() }">
                        {{ getInitials(singleGame.opponent_name) }}
                    </div>
                    <span>-</span>
                </div>
                <div v-if="scores.length != 0">
                    <div class="opponent-name" v-if="
                        scores?.some((team) => team.team_type === 1)
                    ">

                        <h6>
                            {{
                                scores?.find(
                                    (team) => team.team_type === 1
                                )?.team_name
                            }}
                        </h6>
                        <div class="custom-avatar" v-if="
                            scores?.find(
                                (team) =>
                                    team.team_type === 1 &&
                                    team.team_name !== selectedRoomTeam.teamName
                            )
                        " :style="{ backgroundColor: getRandomColor() }">
                            {{
                                getInitials(
                                    scores?.find(
                                        (team) => team.team_type === 1
                                    )?.team_name
                                )
                            }}
                        </div>
                        <div class="team-image" v-else>
                            <div class="users-notification" v-if="imgLoader == 1">
                                <img src="../../assets/loader.gif" alt="" />
                            </div>
                            <img @load="onImageLoad" v-if="selectedRoomTeam.teamAvatar == ''" :src="team_image"
                                alt="" />
                            <img @load="onImageLoad" v-if="
                                selectedRoomTeam.teamAvatar != '' &&
                                scores?.find(
                                    (team) =>
                                        team.team_name === selectedRoomTeam.teamName
                                )
                            " :src="url + selectedRoomTeam.teamAvatar" alt="" />
                        </div>
                        <span>{{ receivedScores.visitScore }}</span>
                        <img :src="arrow_left" alt="arrow-left" v-if="
                            singleGame.status === 1 &&
                            scores &&
                            scores?.find(
                                (team) =>

                                    team.team_type == 1 &&
                                    team.batting_flag == 1
                            )
                        " />
                        <img :src="cup" alt="cup"
                            v-if="singleGame.status === 2 && singleGame.win_status === 1 && scores[0].team_type == 1" />
                        <img :src="cup" alt="cup"
                            v-if="singleGame.status === 2 && singleGame.win_status === 2 && scores[0].team_type == 2" />
                    </div>
                </div>
            </div>
            <div class="center-content">
                <span>{{ singleGame.start_time | formatTimeToAM }}</span>
                <div class="begin-btn" v-if="singleGame.game_scores.length == 0">Yet to Begin</div>
                <div class="begin-btn inning-btn" v-if="singleGame.game_scores.length != 0 && singleGame.status === 1">
                    Inning:
                    {{ scores[0].end_inning_flag }}</div>
                <div class="begin-btn final-btn" v-if="singleGame.game_scores.length != 0 && singleGame.status === 2">
                    Final</div>
            </div>
            <div class="right-section flex-md-row">
                <div v-if="scores.length != 0">
                    <div class="opponent-name" v-if="
                        scores?.some((team) => team.team_type === 2)
                    ">
                        <img :src="arrow_left" class="arrow-image" alt="arrow-left" v-if="
                            singleGame.status === 1 &&
                            scores &&
                            scores?.find(
                                (team) =>

                                    team.team_type == 2 &&
                                    team.batting_flag == 1
                            )
                        " />
                        <img :src="cup" alt="cup"
                            v-if="singleGame.status === 2 && singleGame.win_status === 2 && scores[0].team_type == 1" />
                        <img :src="cup" alt="cup"
                            v-if="singleGame.status === 2 && singleGame.win_status === 1 && scores[0].team_type == 2" />
                        <span>{{ receivedScores.homeScore }}</span>
                        <div class="custom-avatar" v-if="
                            scores?.find(
                                (team) =>
                                    team.team_type === 2 &&
                                    team.team_name !== selectedRoomTeam.teamName
                            )
                        " :style="{ backgroundColor: getRandomColor() }">
                            {{
                                getInitials(
                                    scores?.find(
                                        (team) => team.team_type === 2
                                    )?.team_name
                                )
                            }}
                        </div>
                        <div class="team-image" v-else>
                            <div class="users-notification" v-if="imgLoader == 1">
                                <img src="../../assets/loader.gif" alt="" />
                            </div>
                            <img @load="onImageLoad" v-if="selectedRoomTeam.teamAvatar == ''" :src="team_image"
                                alt="" />
                            <img @load="onImageLoad" v-if="
                                selectedRoomTeam.teamAvatar != '' &&
                                scores?.find(
                                    (team) =>
                                        team.team_name === selectedRoomTeam.teamName
                                )
                            " :src="url + selectedRoomTeam.teamAvatar" alt="" />
                        </div>
                        <h6>
                            {{
                                scores?.find(
                                    (team) => team.team_type === 2
                                )?.team_name
                            }}
                        </h6>
                    </div>
                </div>
                <div class="opponent-data" v-else>
                    <span>-</span>
                    <div class="team-image">
                        <div class="users-notification" v-if="imgLoader == 1">
                            <img src="../../assets/loader.gif" alt="" />
                        </div>
                        <img @load="onImageLoad" v-if="selectedRoomTeam.teamAvatar == ''" :src="team_image" alt="" />
                        <img @load="onImageLoad" v-if="
                            selectedRoomTeam.teamAvatar != ''
                        " :src="url + selectedRoomTeam.teamAvatar" alt="" />
                    </div>
                    <h6>
                        {{
                            selectedRoomTeam.teamName
                        }}
                    </h6>
                </div>
                <router-link class="m-0" :to="{ name: 'SingleEventDetail', params: { id: singleGame.event.id } }">
                    <h6>
                        {{ singleGame.event.eventName }}
                    </h6>
                </router-link>
            </div>
        </div>
        <div class="back-to-event-div">
            <router-link class="m-0" :to="{ name: 'SingleEventDetail', params: { id: singleGame.event.id } }">
                <h6>
                    <img src="../../assets/Svg/back-arrow.svg" alt="back-arrow" class="mr-1"/>Back to Event - <span>{{ singleGame.event.eventName }}</span>
                </h6>
            </router-link>

        </div>
    </div>
</template>

<script>
import GameListModal from "./SubLeft/GameListModal.vue";
import { EventBus } from "@/eventBus"; // Import EventBus
export default {
    name: "BoxScoreTop",
    components: {
        GameListModal,
    },
    data() {
        return {
            arrow_left: require("../../assets/Svg/arrow-left.svg"),
            imgLoader: 1,
            cup: require("../../assets/Svg/cup.svg"),
            url: this.$groupAvatarStorageURL,
            team_image: require("../../assets/Svg/people.svg"),
            receivedScores: { homeScore: 0, visitScore: 0 }
        }
    },
    props: {
        scores: {
            type: Array,
        },
        singleGame: {
            type: Object,
        },
        selectedRoomTeam: {
            type: Object,
        },
    },
    methods: {

        onImageLoad() {
            this.imgLoader = 0;
        },
        openGameModal: function () {
            this.$root.$emit("openGamesDataModal", this.singleGame);
        },
        getInitials(name) {
            return name ? name.charAt(0).toUpperCase() : "?";
        },
        getRandomColor() {
            const colors = [
                "#FCE4EC",
                "#E3F2FD",
                "#F1F8E9",
                "#FFF8E1",
                "#EDE7F6",
                "#FFEBEE",
                "#F9FBE7",
                "#FFF3E0",
                "#ECEFF1",
                "#FFECB3",
            ];
            return colors[Math.floor(Math.random() * colors.length)];
        },
    },
    created() {
        EventBus.$on("scoreUpdated", (data) => {
            this.receivedScores = data;
        });
    },
}
</script>