<template>
    <div class="opt edit-btn my-0">
      <img src="../../../assets/images/ellipsis-h.png" alt="" @click="toggleMenu" v-click-outside="hideMenu" />
      <div class="sub-setting-options edit-event-options" v-if="showMenu" >
        <ul class="d-block pb-0">
          <li class="cursor-pointer" @click="deleteReply">Delete Reply</li>
        </ul>
      </div>
    </div>
</template>

<script>
// import axios from "axios";
export default {
  name: "EllipsisMenu",
  props: {
    reply: {
      type: Object,
      required: true,
    },
    showReplyLoader: {
      type: Number,
      default: 1,
    },
  },

  data() {
    return {
      showMenu: false,
    };
  },
  methods: {
    deleteReply() {
      this.$emit('deleteReply', this.reply);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    hideMenu() {
      this.showMenu = false;
    },
  },
};
</script>

<style scoped>
/* Component-specific styles go here */
</style>
