<template>
    <div>
        <InningTournamentModal />
        <div class="live-section" v-if="showGameLoader === 1">
            <div class="bg-white rounded p-3">
                <content-placeholders :rounded="true">
                    <content-placeholders-img />
                    <content-placeholders-heading />
                </content-placeholders>
            </div>
            <div class="bg-white rounded p-3">
                <content-placeholders :rounded="true">
                    <content-placeholders-img />
                    <content-placeholders-heading />
                </content-placeholders>
            </div>

        </div>
        <div class="live-section d-flex justify-content-center"
            v-else-if="gameScores.length === 0 && showGameLoader === 0">
            <div class="no-chat-text mb-3 py-3 mt-0 rounded">
                <p class="text-center">No live games at the moment.</p>
            </div>
        </div>
        <div v-if="gameScores.length > 0 && showGameLoader === 0" class="live-section">
            <div class="live-top">
                <h6>Live Today</h6>
                <button v-if="adjustedTotalGames > 0" @click="ShowLiveGame()">See All </button>
            </div>
            <div class="live-info" v-for="(gameScore) in gameScores" :key="gameScore.id">
                <div class="info-sec-content">
                    <div class="live-inner-top">
                        <div class="live-inner-left">
                            <div class="delayed-tag" v-if="gameScore.game_delayed == 1">
                                <span>Delayed</span>
                            </div>
                            <span class="delayed-tag live-tag" v-else-if="gameScore.game_status == 1">Live</span>
                            <div class="tooltip-container" v-if="gameScore.game_delayed == 1">
                                <img :src="delayed" alt="delayed" />
                                <span class="tooltip-text">{{ gameScore.delayed_reason }}</span>
                            </div>


                            <span class="pool-text">{{ gameScore.game_name }}</span>
                        </div>
                        <div class=live-count>{{ gameScore.end_inning_flag }}</div>
                    </div>
                    <div class="live-center-info">
                        <span class="live-time">Started at <span>{{ gameScore.date | formatDateWeather }}</span><span
                                v-if="gameScore.actual_start_time">, {{ gameScore.actual_start_time }} </span> </span>
                        <!-- <div
                            v-if="gameScore.game_delayed == 1 && gameScore.delayed_reason !== '' && gameScore.delayed_reason !== null">
                            <span>{{ gameScore.delayed_reason }}</span>
                        </div> -->
                        <div class="w-100" @click="callBoxViewModal(gameScore)" >
                            <div class="w-100" v-for="score in gameScore.score" :key="score.id + 1000">
                                <div class="live-teams" v-if="score.team_type == 1">
                                    <div class="w-100 row no-gutters align-items-center">
                                        <div class="col-9 p-0">
                                            <div class="live-teams-info">
                                                <div class="team-info-sec">
                                                    <div class="users-notification" v-if="imgLoader == 1">
                                                        <img src="../../assets/loader.gif"
                                                            alt="team-image" />
                                                    </div>
                                                    <div class="team-image">
                                                        <img @load="onImageLoad" v-if="score?.team?.team_avatar"
                                                            :src="url + score?.team?.team_avatar" alt="team-image"
                                                            />
                                                        <img @load="onImageLoad" v-else alt="" :src="team_image" />
                                                    </div>

                                                    <!-- <img :src="team_image" alt="team-image" /> -->
                                                    <span class="name-info"> #{{ score?.id }}: {{
                                                        score?.team_name }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-3 p-0">
                                            <div class="live-teams-scores">
                                                <div class="score-info-sec">
                                                    <span> {{ gameScore.visitingRuns }}</span>
                                                </div>
                                                <img :src="arrow_left" alt="arrow-left"
                                                    v-if="gameScore.visitingBatting == 1" />
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>

                            <div class="w-100 mt-2" v-for="score in gameScore.score" :key="score.id + 2000">
                                <div class="live-teams" v-if="score.team_type == 2">
                                    <div class="w-100 row no-gutters align-items-center">
                                        <div class="col-9 p-0">
                                            <div class="live-teams-info">
                                                <div class="team-info-sec">
                                                    <div class="users-notification" v-if="imgLoader == 1">
                                                        <img src="../../assets/loader.gif" alt="team-image" />
                                                    </div>
                                                    <div class="team-image">
                                                        <img @load="onImageLoad" v-if="score?.team?.team_avatar"
                                                            :src="url + score?.team?.team_avatar" alt="team-image"
                                                            />
                                                        <img @load="onImageLoad" v-else alt="" :src="team_image" />
                                                    </div>
                                                    <!-- <img :src="team_image" alt="team-image" /> -->
                                                    <span class="name-info"> #{{ score?.id }}: {{
                                                        score?.team_name
                                                        }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-3 p-0">
                                            <div class="live-teams-scores">
                                                <div class="score-info-sec">
                                                    <span> {{ gameScore.homeRuns }}</span>
                                                </div>
                                                <img :src="arrow_left" alt="arrow-left"
                                                    v-if="gameScore.homeBatting == 1" />
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>

                            <div>
                                <p>
                                    <!-- <span> {{ gameScore.tournament_name }} </span> -->
                                    <span>  {{ gameScore.event_name }}</span>
                                </p>
                                <div class="live-ground-sec" v-if="gameScore.field || gameScore.park">
                                    <img :src="ground" alt="ground" />
                                    <p>
                                        <span v-if="gameScore.park">{{ gameScore.park.park_name }} </span>
                                        <span v-if="gameScore.field"> - {{ gameScore.field.field_name }}</span>
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <!-- <div v-if="adjustedTotalGames > 0">
                <p class="text-center">+{{ adjustedTotalGames }} games in progress today</p>
            </div> -->
            
        </div>
    </div>

</template>

<script>
import InningTournamentModal from "../Tournaments/InningTournamentModal.vue";
export default {
    name: 'MyEventLiveToday',
    data() {
        return {
            video: require("../../assets/Svg/video.svg"),
            team_image: require("../../assets/Svg/people.svg"),
            arrow_left: require("../../assets/Svg/arrow-left.svg"),
            ground: require("../../assets/Svg/ground.svg"),
            delayed: require("../../assets/Svg/delayed.svg"),
            url: this.$groupAvatarStorageURL,
            imgLoader: 1,
        }
    },
    components: { InningTournamentModal },
    props: {
        gameScores: {
            type: Array,
            required: true
        },
        total_game: {
            type: [String, Number], // Can be a string or number
            required: false, // Set to false if it's optional
            default: ''
        },
        showGameLoader: {
            type: Number,
        }
    },
    computed: {
    adjustedTotalGames() {
      return Math.max(0, this.total_game - 4);
    }
  },
    methods: {
        onImageLoad() {
            this.imgLoader = 0;
        },
        ShowLiveGame() {
            this.$router.push({ name: "AllLiveGames" });
        },
        callBoxViewModal(score) {
            this.$root.$emit("openBoxViewScoresModal", score);
            this.$root.$emit("openBoxViewInningModal", score);
        },
    }
}
</script>
<style scoped>
.team-info-sec img, .users-notification img {
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 100px;
}

.tooltip-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.tooltip-text {
    visibility: hidden;
    background-color: black;
    color: white;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
    position: absolute;
    bottom: 120%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}
</style>