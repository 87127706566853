var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('StreamingToggle'),_c('KeepScores'),_c('p',{staticClass:"live-stream-content d-none"},[_vm._v(" This will publish the boxscores for live stream and live stream channel for this game will be available for users to view. ")]),(_vm.gameActionLoader == 0 && this.scores.length == 0 && _vm.currentIsAdmin == '1')?_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-12 p-0"},[_c('div',{staticClass:"live-stream-tags my-0 d-flex justify-content-center align-items-center",on:{"click":_vm.callStartGameModal}},[_c('img',{attrs:{"src":require("../../assets/Svg/timer-start-blue.svg"),"alt":""}}),_c('h6',{staticClass:"mb-0"},[_vm._v("Start Game")]),_c('p',[_vm._v("Hit Start Game above, select the home team and keep your inning by inning score.")])])])]):_vm._e(),(_vm.gameActionLoader == 1)?_c('content-placeholders',{staticClass:"post-view",attrs:{"rounded":true}},[_c('content-placeholders-text',{attrs:{"lines":6}})],1):_vm._e(),(_vm.gameActionLoader == 0 && _vm.gameFetchedright.status == 2 && _vm.currentIsAdmin == '1')?_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-12 p-6"},[_c('div',{staticClass:"live-stream-tags my-0 d-flex justify-content-center align-items-center",on:{"click":function($event){return _vm.restartGame()}}},[_c('img',{attrs:{"src":require("../../assets/Svg/timer-start-blue.svg"),"alt":""}}),_c('h6',[_vm._v("Restart Game")]),_c('p',[_vm._v("Hit Restart Game above, select the home team and keep your inning by inning score.")])])])]):_vm._e(),_c('div',{staticClass:"d-flex flex-wrap flex-column flex-md-row",staticStyle:{"gap":"20px"}},[(
      _vm.showShimmer == 0 &&
      _vm.gameFetchedright.status != 2 &&
      _vm.currentIsAdmin == '1' &&
      this.scores.length != 0
    )?_c('div',{staticClass:"score w-50"},[_c('div',{staticClass:"inning-counter"},[_c('div',{staticClass:"d-flex align-items-start",staticStyle:{"gap":"5px"}},[_c('span',[_vm._v("Inning")]),_c('span',[_vm._v(_vm._s(_vm.totalInnings))])]),_c('p',{staticClass:"team-name"},[_vm._v(_vm._s(_vm.currentBattingTeam))])]),_c('div',{staticClass:"team-position"},[_c('div',{staticClass:"team-score-hr"},[_c('div',{staticClass:"team-score"},[_c('span',{staticClass:"head"},[_vm._v("Runs")]),_c('div',{staticClass:"product-inc-dec"},[_c('div',{staticClass:"product-decrement d-block",on:{"click":function($event){return _vm.runsDecrement()}}},[_c('img',{attrs:{"src":require("../../assets/Svg/minus.svg"),"alt":""}})]),_c('div',{staticClass:"product-value"},[_c('span',[_vm._v(_vm._s(_vm.runs))])]),_c('div',{staticClass:"product-increment",on:{"click":function($event){return _vm.runsIncrement()}}},[_c('img',{attrs:{"src":require("../../assets/Svg/plus.svg"),"alt":""}})])])]),_c('div',{staticClass:"team-hr"},[_c('span',{staticClass:"head"},[_vm._v("HR")]),_c('div',{staticClass:"product-inc-dec"},[_c('div',{staticClass:"product-decrement d-block",on:{"click":function($event){return _vm.hrDecrement()}}},[_c('img',{attrs:{"src":require("../../assets/Svg/minus.svg"),"alt":""}})]),_c('div',{staticClass:"product-value"},[_c('span',[_vm._v(_vm._s(_vm.hr))])]),_c('div',{staticClass:"product-increment",on:{"click":function($event){return _vm.hrIncrement()}}},[_c('img',{attrs:{"src":require("../../assets/Svg/plus.svg"),"alt":""}})])])])])])]):_vm._e(),(_vm.showShimmer == 1)?_c('content-placeholders',{staticClass:"score mt-0 w-50",attrs:{"rounded":true}},[_c('content-placeholders-text',{attrs:{"lines":4}})],1):_vm._e(),(
      _vm.gameActionLoader == 0 &&
      this.scores.length != 0 &&
      _vm.gameFetchedright.status != 2 &&
      _vm.currentIsAdmin == '1'
    )?_c('div',{staticClass:"inning-section"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between w-100",staticStyle:{"gap":"10px"}},[_c('div',{staticClass:"inning-tags",on:{"click":_vm.endHalfInning}},[_c('h6',[_vm._v("End Half Inning")])]),(_vm.gameFetchedright.status != 2 && _vm.currentIsAdmin == '1')?_c('div',{staticClass:"inning-tags",on:{"click":_vm.swapHomeVisitor}},[_c('h6',[_vm._v("Swap Home / Visitor")])]):_vm._e(),(_vm.gameFetchedright.status != 2 && _vm.currentIsAdmin == '1')?_c('div',{staticClass:"inning-tags",on:{"click":_vm.deleteLastInning}},[_c('h6',[_vm._v("Delete Last Inning")])]):_vm._e()]),(_vm.gameFetchedright.status != 2 && _vm.currentIsAdmin == '1')?_c('div',{staticClass:"end-game-tags",on:{"click":_vm.endGame}},[_c('h6',[_vm._v("End Game")])]):_vm._e()]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }