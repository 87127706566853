<template>
  <div>
    <button class="d-none" data-toggle="modal" data-target="#GameModal" ref="openGameModal"></button>
    <div class="modal fade" id="GameModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog product-cart-modal" role="document">
        <div class="modal-content d-block">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Switch Game</h5>
            <button type="button" class="close cursor-pointer" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body overflow-auto">
            <div v-if="showLoader === 1" class="card-event mt-3 p-3">
              <content-placeholders :rounded="true">
                <content-placeholders-img />
                <content-placeholders-heading />
              </content-placeholders>

              <br />

              <content-placeholders :rounded="true">
                <content-placeholders-img />
                <content-placeholders-heading />
              </content-placeholders>
            </div>
            <div v-else-if="gameList.length === 0 && showLoader === 0">
              <div class="no-chat-text">
                <p>No game is scheduled yet</p>
              </div>
            </div>
            <div v-if="gameList.length > 0 && showLoader === 0" class="live-league-wrap p-3">
              <div v-for="(schedule, index) in gameList" :key="index">
                <div class="cursor-pointer" data-dismiss="modal" @click="callSingleBoxModal(schedule)">
                  <div class="schedule-info flex-column flex-md-row" style="gap: 5px;" :class="{
                    'last-schedule-info': index === gameList.length - 1,
                  }">
                    <div>
                      <span class="day-date">{{ formatDate(schedule.start_date) }}</span>
                      <div class="content">{{ schedule.park?.park_name }} <span v-if="schedule.field">-</span> {{
                        schedule.field?.field_name }}</div>
                      <div class="content" v-if="schedule.umpire">Umpire: {{ schedule.umpire?.umpire_name ?
                        schedule.umpire.umpire_name : 'Not Assigned' }}</div>
                    </div>
                    <div class="d-flex align-items-center flex-wrap right-section-popup flex-column flex-md-row" style="gap: 10px;">
                      <div class="opponent-data" v-if="schedule.game_scores.length == 0">
                        <h6>
                          {{
                            schedule.opponent_name
                          }}
                        </h6>
                        <div class="custom-avatar" :style="{ backgroundColor: getRandomColor() }">
                          {{ getInitials(schedule.opponent_name) }}
                        </div>
                        <span>-</span>
                      </div>
                      <div v-if="schedule.game_scores.length != 0">
                        <div class="opponent-name" v-if="
                          schedule.game_scores?.some((team) => team.team_type === 1)
                        ">

                          <h6>
                            {{
                              schedule.game_scores?.find(
                                (team) => team.team_type === 1
                              )?.team_name
                            }}
                          </h6>
                          <div class="custom-avatar" v-if="
                            schedule.game_scores?.find(
                              (team) =>
                                team.team_type === 1 &&
                                team.team_name !== selectedRoomTeam.teamName
                            )
                          " :style="{ backgroundColor: getRandomColor() }">
                            {{
                              getInitials(
                                schedule.game_scores?.find(
                                  (team) => team.team_type === 1
                                )?.team_name
                              )
                            }}
                          </div>
                          <div class="team-image" v-else>
                            <div class="users-notification" v-if="imgLoader == 1">
                              <img src="../../../assets/loader.gif" alt="" />
                            </div>
                            <img @load="onImageLoad" v-if="selectedRoomTeam.teamAvatar == ''" :src="team_image"
                              alt="" />
                            <img @load="onImageLoad" v-if="
                              selectedRoomTeam.teamAvatar != '' &&
                              schedule.game_scores?.find(
                                (team) =>
                                  team.team_name === selectedRoomTeam.teamName
                              )
                            " :src="url + selectedRoomTeam.teamAvatar" alt="" />
                          </div>
                          <span
                              v-if="
                                schedule.game_scores?.find(
                                  (team) =>
                                    team.team_flag == 1 && team.team_type == 2
                                )
                              "
                            >
                              {{ schedule.opponent_score }}
                            </span>
                            <span
                              v-if="
                                schedule.game_scores?.find(
                                  (team) =>
                                    team.team_flag == 1 && team.team_type == 1
                                )
                              "
                            >
                              {{ schedule.team_score }}
                            </span>
                          <img :src="arrow_left" alt="arrow-left" v-if="
                            schedule.status === 1 &&
                            schedule.game_scores &&
                            schedule.game_scores?.find(
                              (team) =>

                                team.team_type == 1 &&
                                team.batting_flag == 1
                            )
                          " />
                          <img :src="cup" alt="cup"
                            v-if="schedule.status === 2 && schedule.win_status === 1 && schedule.game_scores[0].team_type == 1" />
                          <img :src="cup" alt="cup"
                            v-if="schedule.status === 2 && schedule.win_status === 2 && schedule.game_scores[0].team_type == 2" />
                        </div>
                      </div>
                      <div class="center-content">
                        <span>{{ schedule.start_time | formatTimeToAM }}</span>
                        <div class="begin-btn" v-if="schedule.game_scores.length == 0">Yet to Begin</div>
                        <div class="begin-btn inning-btn"
                          v-if="schedule.game_scores.length != 0 && schedule.status === 1">
                          Inning:
                          {{ schedule.game_scores[0].end_inning_flag }}</div>
                        <div class="begin-btn final-btn"
                          v-if="schedule.game_scores.length != 0 && schedule.status === 2">
                          Final</div>
                          <div class="content">{{ schedule.name }}</div>
                      </div>
                      <div class="d-flex flex-row flex-wrap">
                        <div v-if="schedule.game_scores.length != 0">
                          <div class="opponent-name" v-if="
                            schedule.game_scores?.some((team) => team.team_type === 2)
                          ">
                            <img :src="arrow_left" class="arrow-image" alt="arrow-left" v-if="
                              schedule.status === 1 &&
                              schedule.game_scores &&
                              schedule.game_scores?.find(
                                (team) =>

                                  team.team_type == 2 &&
                                  team.batting_flag == 1
                              )
                            " />
                            <img :src="cup" alt="cup"
                              v-if="schedule.status === 2 && schedule.win_status === 2 && schedule.game_scores[0].team_type == 1" />
                            <img :src="cup" alt="cup"
                              v-if="schedule.status === 2 && schedule.win_status === 1 && schedule.game_scores[0].team_type == 2" />
                              <span
                              v-if="
                                schedule.game_scores?.find(
                                  (team) =>
                                    team.team_flag == 2 && team.team_type == 1
                                )
                              "
                            >
                              {{ schedule.team_score }}
                            </span>
                            <span
                              v-if="
                                schedule.game_scores?.find(
                                  (team) =>
                                    team.team_flag == 2 && team.team_type == 2
                                )
                              "
                            >
                              {{ schedule.opponent_score }}
                            </span>
                            <div class="custom-avatar" v-if="
                              schedule.game_scores?.find(
                                (team) =>
                                  team.team_type === 2 &&
                                  team.team_name !== selectedRoomTeam.teamName
                              )
                            " :style="{ backgroundColor: getRandomColor() }">
                              {{
                                getInitials(
                                  schedule.game_scores?.find(
                                    (team) => team.team_type === 2
                                  )?.team_name
                                )
                              }}
                            </div>
                            <div class="team-image" v-else>
                              <div class="users-notification" v-if="imgLoader == 1">
                                <img src="../../../assets/loader.gif" alt="" />
                              </div>
                              <img @load="onImageLoad" v-if="selectedRoomTeam.teamAvatar == ''" :src="team_image"
                                alt="" />
                              <img @load="onImageLoad" v-if="
                                selectedRoomTeam.teamAvatar != '' &&
                                schedule.game_scores?.find(
                                  (team) =>
                                    team.team_name === selectedRoomTeam.teamName
                                )
                              " :src="url + selectedRoomTeam.teamAvatar" alt="" />
                            </div>
                            <h6>
                              {{
                                schedule.game_scores?.find(
                                  (team) => team.team_type === 2
                                )?.team_name
                              }}
                            </h6>
                          </div>
                        </div>
                        <div class="opponent-data" v-else>
                          <span>-</span>
                          <div class="team-image">
                            <div class="users-notification" v-if="imgLoader == 1">
                              <img src="../../../assets/loader.gif" alt="" />
                            </div>
                            <img @load="onImageLoad" v-if="selectedRoomTeam.teamAvatar == ''" :src="team_image"
                              alt="" />
                            <img @load="onImageLoad" v-if="
                              selectedRoomTeam.teamAvatar != ''
                            " :src="url + selectedRoomTeam.teamAvatar" alt="" />
                          </div>
                          <h6>
                            {{
                              selectedRoomTeam.teamName
                            }}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <router-link class="m-0" :to="{ name: 'SingleEventDetail', params: { id: singleGame.event.id } }">
                      <h6>
                        {{ singleGame.event.eventName }}
                      </h6>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/eventBus"; // Import EventBus
import $ from "jquery";
import axios from "axios";
export default {
  components: {},
  name: "GameListModal",

  data() {
    return {
      gameList: [], // Dummy game list
      schedule: "",
      showLoader: 1,
      arrow_left: require("../../../assets/Svg/arrow-left.svg"),
      imgLoader: 1,
      cup: require("../../../assets/Svg/cup.svg"),
      url: this.$groupAvatarStorageURL,
      team_image: require("../../../assets/Svg/people.svg"),
      receivedScores: { homeScore: 0, visitScore: 0 }
    };
  },
  props: {
    selectedRoomTeam: {
      type: Object,
    },
    singleGame: {
      type: Object
    }
  },
  methods: {
    async getGames() {
      this.showLoader = 1;
      var formData = new FormData();
      formData.append("event_id", this.schedule.event.id);
      formData.append("team_id", this.schedule.team_id);
      await axios
        .post(process.env.VUE_APP_API_URL + "game/gameListing", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.gameList = response.data.data;
          console.log(this.gameList)
          this.showLoader = 0;
        });
    },
    callSingleBoxModal(schedule) {
      var combineUrl = `${schedule.team_id}-${schedule.guid}`;
      this.$router.push({
        name: "SingleBoxScore",
        params: { guid: combineUrl },
      });
      // Emit an event to indicate that the single box score is being shown
      this.$root.$emit("showSingleBoxScoreForGameSwitch");
      this.$root.$emit("HitEmitTeamsHistory", schedule.id);
      this.$root.$emit("HitfetchTeamPositionUponEmit", {
        fieldConfigId: schedule.field_configuration_id,
        scheduleId: schedule.id,
      });
    },
    formatDate(date) {
      return new Intl.DateTimeFormat("en-US", {
        weekday: "short",
        month: "short",
        day: "2-digit",
        year: "numeric"
      }).format(new Date(date));
    },
    onImageLoad() {
      this.imgLoader = 0;
    },
    getInitials(name) {
      return name ? name.charAt(0).toUpperCase() : "?";
    },
    getRandomColor() {
      const colors = [
        "#FCE4EC",
        "#E3F2FD",
        "#F1F8E9",
        "#FFF8E1",
        "#EDE7F6",
        "#FFEBEE",
        "#F9FBE7",
        "#FFF3E0",
        "#ECEFF1",
        "#FFECB3",
      ];
      return colors[Math.floor(Math.random() * colors.length)];
    },
  },
  mounted() {
    $(window).on("popstate", function () {
      $(".modal-backdrop").remove();
      $("body").removeClass("modal-open");
    });
    this.$store.commit("setErrors", {});
    this.$root.$on("openGamesDataModal", (game) => {
      this.schedule = game;
      this.getGames();
      var elem = this.$refs.openGameModal;
      if (elem) {
        elem.click();
      }
    });
  },
  created() {
        EventBus.$on("scoreUpdated", (data) => {
            this.receivedScores = data;
        });
    },
};
</script>
