<template>
  <div>
    <div class="opt position-relative mt-0 cursor-pointer" @click="openAttendeeOptions" v-click-outside="onClickOutside" v-if="currentIsAdmin != 0">
      <img src="../../assets/Svg/ellipse.svg" alt="" />
      <div class="sub-setting-options" v-if="attendeesOptions">
        <ul class="d-block pb-0">
          <li v-if="currentIsAdmin != 0 && user.event_attendee_status != 0" @click="editAttendance(user)" class="cursor-pointer">
            Edit Attendance
          </li>
          <li v-if="currentIsAdmin != 0 && user.event_attendee_status == 0" @click="markAttendance(user)" class="text-danger cursor-pointer">
            Mark Attendance
          </li>
        </ul>
      </div>
    </div>
    <EditEventAttendessModal :selectedRoomTeam="selectedRoomTeam" :singleEvent="singleEvent" />
</div>

</template>

<script>
import EditEventAttendessModal from "../Teams/ChatRight/ChatEvents/EditEventAttendessModal.vue";

export default {
  name: "AttendeesOptions",
  components: {
    EditEventAttendessModal
  },
  data() {
    return {
      attendeesOptions: false,
    };
  },
  props: {
    selectedRoomTeam: {
      type: Object,
    },
    singleEvent: {
      type: Object,
    },
    currentIsAdmin: {
      type: String,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  methods: {
    openAttendeeOptions() {
      this.attendeesOptions = !this.attendeesOptions;
    },
    onClickOutside() {
      this.attendeesOptions = false;
    },
    editAttendance: function (user) {
      this.$root.$emit("openEditAttendeesModal", user);
      var elem = this.$refs.openModalEventClose;
      if (elem) {
        elem.click();
      }
    },
    markAttendance: function (user) {
      this.$root.$emit("openEditMarkAttendeesModal", user);
      var elem = this.$refs.openModalEventClose;
      if (elem) {
        elem.click();
      }
    },
  },
};
</script>