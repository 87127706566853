<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#manageUmpires"
      ref="openModalManageUmpires"
    ></button>
    <div class="modal fade" tabindex="-1" role="dialog" id="manageUmpires">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title m-0">Manage Umpires</h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeManageUmpires"
            >
              <img src="../../../assets/close.png" />
            </button>
          </div>
          <div class="modal-body">
            <div class="teammates-lineup-wrapper">
              <div class="available-teammates">
                <h6>Available Umpires</h6>
                <div class="teammates-info">
                  <draggable
                    class="list-group"
                    :list="availableUmpires"
                    group="umpires"
                    handle=".handle"
                    @change="handleAvailableUmpires"
                    v-if="showLoaderAvailableUmpires == 0"
                  >
                    <div
                      class="list-group-item cursor-none"
                      v-for="(element, index) in availableUmpires"
                      :key="index"
                    >
                      <div class="shirt-no-wrapper">
                        <small>{{ index + 1 }}.</small>
                        <p class="name">{{ element.umpire.name }}</p>
                        <!-- <small>1 Field</small> -->
                      </div>
                      <div class="handle cursor-pointer">
                        <img
                          src="../../../assets/images/line-ellipsis.png"
                          alt=""
                          title="Click an icon to drag and drop the option."
                        />
                      </div>
                    </div>
                  </draggable>
                  <div
                    class="no-line-up"
                    v-if="
                      showLoaderAvailableUmpires == 0 &&
                        availableUmpires.length == 0
                    "
                  >
                    <div class="img">
                      <img src="../../../assets/images/teammates.png" alt="" />
                    </div>
                    <p class="text">No available umpires found.</p>
                  </div>
                  <div
                    class="lds-dual-ring-media"
                    v-if="showLoaderAvailableUmpires == 1"
                  ></div>
                </div>
              </div>
              <div class="team-lineup">
                <h6>Umpires Selected</h6>
                <div class="teammates-info position-relative">
                  <draggable
                    class="list-group"
                    :list="selectedUmpires"
                    group="umpires"
                    handle=".handle"
                    @change="handleSelectedUmpires"
                    v-if="showLoaderSelectedUmpires == 0"
                  >
                    <div
                      class="list-group-item cursor-none"
                      v-for="(element, index) in selectedUmpires"
                      :key="element.position_index"
                    >
                      <div class="shirt-no-wrapper">
                        <small>{{ index + 1 }}.</small>
                        <p class="name">{{ element.umpire.name }}</p>
                        <!-- <small>3 Field</small> -->
                      </div>
                      <div class="handle cursor-pointer">
                        <img
                          src="../../../assets/images/line-ellipsis.png"
                          alt=""
                          title="Click an icon to drag and drop the option."
                        />
                      </div>
                    </div>
                  </draggable>
                  <div
                    class="no-line-up"
                    v-if="
                      showLoaderSelectedUmpires == 0 &&
                        selectedUmpires.length == 0
                    "
                  >
                    <div class="img">
                      <img src="../../../assets/images/teammates.png" alt="" />
                    </div>
                    <p class="text">No selected umpires found.</p>
                  </div>
                  <div
                    class="lds-dual-ring-media"
                    v-if="showLoaderSelectedUmpires == 1"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div class="event-btns-wrapper p-3 justify-content-end">
            <button
              v-if="this.isDisabled == false"
              type="button"
              class="btn btn-primary w-25"
              block
              @click="manageUmpires(0)"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Save</span>
            </button>
            <button
              v-else-if="this.isDisabled == true"
              type="button"
              class="btn btn-primary-disabled w-25"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Save</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import axios from "axios";
export default {
  name: "ManageUmpires",
  components: {
    draggable,
  },
  props: {
    eventId: {
      type: Number,
    },
    association_id: {
      type: Number,
    },
  },
  data() {
    return {
      isDisabled: true,
      isLoadingArray: [],
      selectedUmpires: [],
      showLoaderSelectedUmpires: 1,
      availableUmpires: [],
      showLoaderAvailableUmpires: 1,
    };
  },
  watch: {
  selectedUmpires: function(newValue) {
    if (newValue.length > 0 || this.availableUmpires.length > 0) {
      this.isDisabled = false;
    } else {
      this.isDisabled = true;
    }
  },
  availableUmpires: function(newValue) {
    if (newValue.length > 0 || this.selectedUmpires.length > 0) {
      this.isDisabled = false;
    } else {
      this.isDisabled = true;
    }
  },
},

  methods: {
    closeModal(){
      var elem = this.$refs.closeManageUmpires;
        if (elem) {
          elem.click();
        }
    },
    async manageUmpires(index) {
      this.$set(this.isLoadingArray, index, true);

      try {
        const url = `${process.env.VUE_APP_API_URL}associationEvent/saveSelectedUmpires`;
        const response = await axios.post(url, {
          event_id: this.eventId,
          selectedUmpires: this.selectedUmpires,
          association_id: this.association_id,
        });

        // Handle response based on the status code
        if (response.data.statusCode === 200) {
          // Success: status code 200 (OK)
          this.$root.$emit("selectedUmpireEmit");
          this.closeModal();
          
        } else if (response.data.statusCode === 404) {
            this.closeModal();
            this.$alert(response.data.message, 'Error');
            this.$router.push({ name: "Teams" });
            } else {
          // If status code is not 200, handle it as an error
          console.error("Unexpected status code:", response.status);
        }

      } catch (error) {
        if (error.response) {
          // Error: API responded with a status other than 2xx
          console.error("Error saving selected umpires:", error.response.status, error.response.data.message);
        } else {
          // Other errors (e.g., network issues)
          console.error("Error saving selected umpires:", error.message);
        }

      } finally {
        this.$set(this.isLoadingArray, index, false);
      }
    },
    handleSelectedUmpires(evt) {
      if (evt.removed) {
        const index = this.selectedUmpires.indexOf(evt.removed.element);
        if (index > -1) {
          this.selectedUmpires.splice(index, 1);
        }
      }
    },

    async getUmpires() {
      this.showLoaderSelectedUmpires = 1;
      try {
        const url = `${process.env.VUE_APP_API_URL}associationEvent/getManageUmpires?event_id=${this.eventId}&association_id=${this.association_id}`;
        const response = await axios.get(url);
        if (response.data.statusCode === 200) {
          this.selectedUmpires = Object.values(response.data.data.umpires);
          this.showLoaderSelectedUmpires = 0;
        } else if (response.data.statusCode === 404) {
          this.closeModal();
          this.$alert(response.data.message, 'Error');
          this.$router.push({ name: "Teams" });
          this.showLoaderAvailableTeams = 0;

        } else {
          console.error(
            "Failed to fetch umpires:",
            response.data.message || "Unknown error occurred"
          );
          this.showLoaderSelectedUmpires = 0;
        }
      } catch (error) {
        this.showLoaderSelectedUmpires = 0;
        console.error("Failed to fetch umpires:", error);
      }
    },
    async getUmpiresForAssociation() {
  this.showLoaderAvailableUmpires = 1;
  try {
    const url = `${process.env.VUE_APP_API_URL}association/getUsersByAssociationAndRole?association_id=${this.association_id}&eventId=${this.eventId}`;
    const response = await axios.get(url);
    if (response.data.statusCode === 200) {
      this.availableUmpires = Object.values(response.data.data.users);
      this.showLoaderAvailableUmpires = 0;
    } else if (response.data.statusCode === 404) {
      this.closeModal();
      this.$alert(response.data.message, 'Error');
      this.$router.push({ name: "Teams" });
      this.showLoaderAvailableTeams = 0;

    } else {
      console.error(
        "Failed to fetch umpires:",
        response.data.message || "Unknown error occurred"
      );
      this.showLoaderAvailableUmpires = 0;
    }
  } catch (error) {
    this.showLoaderAvailableUmpires = 0;
    console.error("Failed to fetch umpires:", error);
  }
},


    handleAvailableUmpires(evt) {
      if (evt.added) {
        this.selectedUmpires.push(evt.added.element);
      }
    },
  },

  mounted() {
    this.$root.$on("openManageUmpiresModal", () => {
      this.getUmpires();
      this.getUmpiresForAssociation();
      var elem = this.$refs.openModalManageUmpires;
      if (elem) {
        elem.click();
      }
    });
  },
};
</script>
