<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#newHotel"
      ref="openModalNewHotel"
      @click="dataReset()"
    ></button>
    <div class="modal fade" tabindex="-1" role="dialog" id="newHotel">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title m-0">New Hotel</h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeNewHotel"
            >
              <img src="../../../assets/close.png" />
            </button>
          </div>
          <div class="modal-body">
            <form @keyup.enter="saveHotel(0)" @submit.prevent>
              <div class="profile-preview">
                <div class="profile-photo-edit" @click="openinput()">
                  <!-- <img
                    v-if="user.picture == 'yes'"
                    :src="API_URL + user.id + '/profile_picture.jpg?v=' + time"
                    alt=""
                  /> -->
                  <img
                    src="../../../assets/images/sponsor.png"
                    class="user-default"
                    alt=""
                  />

                  <form>
                    <input
                      type="file"
                      id="vue-file-upload-input-hotel-photo"
                      @change="onFileChange"
                      class="d-none"
                      accept="image/png, image/gif, image/jpeg"
                    />

                    <div id="preview" class="new-profile-photo">
                      <clipper-basic
                        :src="photoUrl"
                        preview="preview"
                        :border="4"
                        :outline="0"
                        :corner="false"
                        :ratio="1"
                        :wrap-ratio="1"
                         :init-width="100"
                        :init-height="100"
                        ref="clipper"
                      ></clipper-basic>
                    </div>

                    <div class="profile-photo-edit-icon-wrapper">
                      <img src="../../../assets/images/edit.png" alt="" />
                    </div>
                  </form>
                </div>
                <div class="preview-image">
                  <div v-if="photoUrl">
                    <clipper-preview name="preview"></clipper-preview>
                  </div>
                  <div class="no-image-wrapper" v-else>
                    <div slot="placeholder">No image</div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="name">Hotel Name</label>
                <input
                  id="name"
                  class="form-control"
                  v-model.trim="$v.details.name.$model"
                  spellcheck="false"
                  autocomplete="off"
                  :class="{
                    'is-invalid': validationStatus($v.details.name),
                  }"
                  trim
                  type="text"
                  required
                  v-on:keyup="errorNull"
                />
                <div v-if="!$v.details.name.required && this.details.name" class="invalid-tooltip">
                  Hotel name is required
                </div>
                <div v-if="!$v.details.name.maxLength" class="invalid-tooltip">
                  Maximum 250 characters allowed.
                </div>
              </div>
              <div class="row no-gutters">
                <div class="col-12 col-md-6 pr-0 pr-md-2">
                  <div class="form-group">
                    <label for="url">Website URL</label>
                    <input
                      type="text"
                      class="form-control"
                      id="url"
                      v-model="url"
                      @keyup="errorNull"
                      @input="change($event)"
                      @change="change($event)"
                      required
                    />

                    <div class="error-sponser" v-if="!isValid">
                      URL is Invalid
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6">
  <div class="form-group">
    <label for="mob_phone">Contact Number</label>
    <input
      id="mob_phone"
      class="form-control"
      v-model.trim="$v.details.mob.$model"
      v-phone-mask
      spellcheck="false"
      autocomplete="off"
      placeholder="(XXX) XXX-XXXX"
      :class="{
        'is-invalid': validationStatus($v.details.mob),
      }"
      trim
      required
      type="text"
      v-on:keyup="errorNull"
    />
    <div v-if="!$v.details.mob.required && this.details.mob" class="invalid-tooltip">
      Mobile number is required
    </div>
    <div v-if="!$v.details.mob.minLength" class="invalid-tooltip">
      Minimum length should be 11
    </div>
    
  </div>
</div>

              </div>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <gmap-autocomplete
                      :value="location"
                      placeholder="Enter an address, zipcode, or location"
                      @place_changed="setPlace"
                      ref="autocompleteRef"
                    >
                    </gmap-autocomplete>
                  </div>
                </div>
                <div class="col-12">
                  <google-map
                    :center="marker.position"
                    :zoom="18"
                    map-style-id="roadmap"
                    :options="mapOptions"
                    style="width: 100vmin; height: 40vmin; margin-bottom: 10px"
                    ref="mapRef"
                    id="map"
                    @click="handleMapClick"
                  >
                    <google-maps-marker
                      :position="marker.position"
                      :clickable="true"
                      :draggable="true"
                      @drag="handleThrottledMarkerDrag"
                      @click="panToMarker"
                    ></google-maps-marker>
                  </google-map>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      id="street"
                      v-model="street"
                      @keyup="errorNull"
                      required
                      placeholder="Street Address"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-4 pr-md-2">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      id="city"
                      v-model="city"
                      @keyup="errorNull"
                      required
                      placeholder="City"
                    />
                  </div>
                </div>
                <div class="col-12 col-md-4 px-md-0">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      id="state"
                      v-model="state"
                      @keyup="errorNull"
                      required
                      placeholder="State"
                    />
                  </div>
                </div>
                <div class="col-12 col-md-4 pl-md-2">
                  <div class="form-group">
                    <input
                      type="number"
                      class="form-control"
                      id="zipCode"
                      v-model="zipCode"
                      @keyup="errorNull"
                      required
                      placeholder="ZipCode"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="event-btns-wrapper p-3 justify-content-end">
            <button
              v-if="this.isDisabled == false"
              type="button"
              class="btn btn-primary w-25"
              block
              @click="saveHotel(0)"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Save</span>
            </button>
            <button
              v-else-if="this.isDisabled == true"
              type="button"
              class="btn btn-primary-disabled w-25"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Save</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    <script>
import {
  required,
  maxLength,
  minLength,
} from "vuelidate/lib/validators";
import { clipperBasic, clipperPreview } from "vuejs-clipper";
import _ from 'lodash';
import axios from "axios";
export default {
  name: "NewHotel",
  components: {
    clipperBasic,
    clipperPreview,
  },
  props: {
    eventId: {
      type: Number,
    },
    association_id: {
      type: Number,
    },
  },
  data() {
    return {
      isDisabled: true,
      isLoadingArray: [],
      details: {
        name: "",
        mob: "",
      },
      url: "",
      photoUrl: null,
      isValid: true,
      hotelPhoto: null,
      location: null,
      marker: { position: { lat: 10, lng: 10 } },
      markers: [],
      center: { lat: 10, lng: 10 },
      mapOptions: {
        disableDefaultUI: false,
      },
      city: "",
      state: "",
      zipCode: "",
      street: "",
    };
  },
  validations: {
    details: {
      name: { required, maxLength: maxLength(250) },
      mob: { required,  minLength: minLength(11) },
    },
  },

  methods: {
    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    handleThrottledMarkerDrag: _.throttle(function (event) {
      this.handleMarkerDrag(event);
    }, 50),
    handleMarkerDrag(e) {
      window.test = this;
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      var geocoder = new window.google.maps.Geocoder();
      var latlng = this.marker.position;
      geocoder.geocode({ location: latlng }, function (results, status) {
        if (status === "OK") {
          if (results[1]) {
            for (let i = 0; i < results.length; i++) {
              // eslint-disable-next-line
              window.test.location = results[2].formatted_address;
              if (results[i]?.types[0] == "locality") {
                for (let j = 0; j < results[i].address_components.length; j++) {
                  if (
                    results[i].address_components[j]?.types[0] == "locality"
                  ) {
                    window.test.city =
                      results[i].address_components[j].long_name;
                  }
                }
                // this.city = results[i].long_name;
              }
              if (
                results[i]?.types[0] == "premise" ||
                results[i]?.types[0] == "street_address" ||
                results[i]?.types[0] == "route" ||
                results[i]?.types[0] == "neighborhood"
              ) {
                for (let j = 0; j < results[i].address_components.length; j++) {
                  if (
                    results[i].address_components[j]?.types[0] == "route" ||
                    results[i].address_components[j]?.types[0] ==
                      "street_address" ||
                    results[i].address_components[j]?.types[0] == "premise"
                  ) {
                    window.test.street =
                      results[i].address_components[j].long_name;
                  }
                }
              }
              if (results[i]?.types[0] == "administrative_area_level_1") {
                for (let j = 0; j < results[i].address_components.length; j++) {
                  if (
                    results[i].address_components[j]?.types[0] ==
                    "administrative_area_level_1"
                  ) {
                    window.test.state =
                      results[i].address_components[j].long_name;
                  }
                }
              }
              if (results[i]?.types[0] == "postal_code") {
                for (let j = 0; j < results[i].address_components.length; j++) {
                  if (
                    results[i].address_components[j]?.types[0] == "postal_code"
                  ) {
                    window.test.zipCode =
                      results[i].address_components[j].long_name;
                  }
                }
              }
            }
          } else {
            window.alert("No results found");
          }
        }
      });
      this.errorNull();
    },
    errorNull: function () {
  if ((this.details.name == "" && this.url == "" && this.details.mob == "" && this.city == "") 
      || this.state == "" || this.zipCode == "" || this.street == "" 
      || this.state == null || this.city == null || this.street == null || this.zipCode == null 
      || this.$v.$invalid) {
    this.isDisabled = true;
  } else {
    this.isDisabled = false;
  }
},
    setPlace(place) {
      if (!place) return;
      this.$refs.autocompleteRef.$el.classList.add("custom-dropdown-open");
      this.marker.position = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      this.street = place.formatted_address;
      this.location = place.formatted_address;
      
      const addressComponents = place.address_components;

      for (const component of addressComponents) {
        const types = component.types;
        if (types.includes("locality")) {
          this.city = component.long_name;
        } else if (types.includes("administrative_area_level_1")) {
          this.state = component.short_name;
        } else if (types.includes("postal_code")) {
          this.zipCode = component.long_name;
        }
      }
      this.panToMarker();
    },
    panToMarker() {
      this.$refs.mapRef.panTo(this.marker.position);
    },
    handleMapClick(e) {
      window.test = this;
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      var geocoder = new window.google.maps.Geocoder();
      var latlng = this.marker.position;
      geocoder.geocode({ location: latlng }, function (results, status) {
        if (status === "OK") {
          if (results[0]) {
            for (let i = 0; i < results.length; i++) {
              // eslint-disable-next-line
              window.test.location = results[2].formatted_address;
              if (results[i]?.types[0] == "locality") {
                for (let j = 0; j < results[i].address_components.length; j++) {
                  if (
                    results[i].address_components[j]?.types[0] == "locality"
                  ) {
                    window.test.city =
                      results[i].address_components[j].long_name;
                  }
                }
                // this.city = results[i].long_name;
              }
              if (
                results[i]?.types[0] == "premise" ||
                results[i]?.types[0] == "street_address" ||
                results[i]?.types[0] == "route"
              ) {
                for (let j = 0; j < results[i].address_components.length; j++) {
                  if (
                    results[i].address_components[j]?.types[0] == "route" ||
                    results[i].address_components[j]?.types[0] ==
                      "street_address" ||
                    results[i].address_components[j]?.types[0] == "premise"
                  ) {
                    window.test.street =
                      results[i].address_components[j].long_name;
                  }
                }
              }
              if (results[i]?.types[0] == "administrative_area_level_1") {
                for (let j = 0; j < results[i].address_components.length; j++) {
                  if (
                    results[i].address_components[j]?.types[0] ==
                    "administrative_area_level_1"
                  ) {
                    window.test.state =
                      results[i].address_components[j].long_name;
                  }
                }
              }
              if (results[i]?.types[0] == "postal_code") {
                for (let j = 0; j < results[i].address_components.length; j++) {
                  if (
                    results[i].address_components[j]?.types[0] == "postal_code"
                  ) {
                    window.test.zipCode =
                      results[i].address_components[j].long_name;
                  }
                }
              }
            }
          } else {
            window.alert("No results found");
          }
        }
      });
      this.errorNull();
    },
    dataReset: function () {
      this.details.name = "";
      this.details.mob = "";
      this.url = "";
      this.$v.$reset();
      this.state = "";
      this.location = null;
      this.zipCode = "";
      this.street = "";
      this.city = "";
      this.isDisabled = true;
      this.hotelPhoto = null;
      this.photoUrl = null;
      this.marker.position = { lat: 10, lng: 10 }; // reset marker position if necessary
      document.getElementById("vue-file-upload-input-hotel-photo").value = "";
    },

    change(e) {
      const url = e.target.value;
      this.isURLValid(url);
    },
    isURLValid(inputUrl) {
  var regx = new RegExp(
    "^(http(s)?:\\/\\/)?" +                // protocol
    "(localhost|" +                         // localhost
    "(([a-zA-Z\\d]([a-zA-Z\\d-]{0,61}[a-zA-Z\\d])?\\.?)+[a-zA-Z]{2,})" + // domain name
    "|((2(5[0-5]|[0-4]\\d)|1\\d\\d|[1-9]\\d|\\d)(\\.(2(5[0-5]|[0-4]\\d)|1\\d\\d|[1-9]\\d|\\d)){3})" + // IP address
    ")(:\\d{2,5})?" +                       // port (optional)
    "(\\/\\S*)?$",                          // path (optional)
    "i"
  );
  this.isValid = regx.test(inputUrl);
},

    openinput: function () {
      document.getElementById("vue-file-upload-input-hotel-photo").click();
    },
    onFileChange(e) {
      var fileInput = document.getElementById(
        "vue-file-upload-input-hotel-photo"
      );
      var file_ = fileInput.value;
      var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
      if (!allowedExtensions.exec(file_)) {
        alert(
          "Please upload file having extensions .jpeg/.jpg/.png/.gif only."
        );
        fileInput.value = "";
        return false;
      } else {
        this.isDisabled = false;
        this.hotelPhoto = e.target.files[0];
        
        this.compressImage(this.hotelPhoto , 0.5, (compressedImage) => {
         
          this.photoUrl = URL.createObjectURL(compressedImage);
          this.hotelPhoto  = compressedImage;
          this.errorNull();
        });
      }
    },
    compressImage(file, quality, callback) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          const maxWidth = 800;
          const maxHeight = 800;
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > maxWidth) {
              height = Math.round((height * maxWidth) / width);
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width = Math.round((width * maxHeight) / height);
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);
          canvas.toBlob(
            (blob) => {
              
              callback(blob);
            },
            "image/jpeg",
            quality
          );
        };
      };
    },
    // dataURItoBlob: function (dataURI) {
    //   // convert base64/URLEncoded data component to raw binary data held in a string
    //   var byteString;
    //   if (dataURI.split(",")[0].indexOf("base64") >= 0)
    //     byteString = atob(dataURI.split(",")[1]);
    //   else byteString = unescape(dataURI.split(",")[1]);

    //   // separate out the mime component
    //   var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    //   // write the bytes of the string to a typed array
    //   var ia = new Uint8Array(byteString.length);
    //   for (var i = 0; i < byteString.length; i++) {
    //     ia[i] = byteString.charCodeAt(i);
    //   }

    //   return new Blob([ia], { type: mimeString });
    // },

    closeModal(){
      this.dataReset();
      var elem = this.$refs.closeNewHotel;
        if (elem) {
          elem.click();
        }
    },
    async saveHotel(index) {
      // Set loading indicator
      this.$set(this.isLoadingArray, index, true);

      // Create a new FormData object
      let formData = new FormData();

      // Append data to the FormData object
      formData.append('event_id', this.eventId);
      formData.append('association_id', this.association_id);
      formData.append('name', this.details.name);
      formData.append('website_url', this.url);
      formData.append('address_description', this.location);
      formData.append('phone_number', this.details.mob);
      formData.append('latitude', this.marker.position.lat);
      formData.append('longitude', this.marker.position.lng);
      formData.append('street_address', this.street);
      formData.append('city', this.city);
      formData.append('state', this.state);
      formData.append('zip', this.zipCode);

      if (this.hotelPhoto) {
        formData.append("image_url", this.hotelPhoto, this.hotelPhoto.name);
      }

      try {
        // Make the API request
        const response = await axios.post('/associationEvent/addEventHotel', formData);

        // Handle response based on statusCode
        if (response.data.statusCode === 200) {
          // Successful response
          
          this.$root.$emit("hotelAddedEmit");
          this.closeModal();
        } else if (response.data.statusCode === 404) {
          // Handle unauthorized access
          this.$alert(response.data.message, 'Error');
          this.$router.push({ name: "Teams" });
          this.closeModal();
        } else {
          // Handle other statuses
          console.error("Error: ", response.data.message || "Unknown error occurred");
          this.$alert(response.data.message || "An error occurred while adding the hotel", 'Error');
        }
      } catch (error) {
        // Handle network errors
        console.error("Network Error: ", error);
        this.$alert('An error occurred while adding the hotel. Please try again later.', 'Network Error');
      } finally {
        // Remove loading indicator
        this.$set(this.isLoadingArray, index, false);
      }
    }



  },

  mounted() {
    this.$root.$on("openNewHotelModal", () => {
      var elem = this.$refs.openModalNewHotel;
      if (elem) {
        elem.click();
      }
    });
  },
};
</script>
  
  <style scoped>
  .vue-map-container,
.vue-map-container .vue-map {
  width: 100% !important;
}
.profile-preview {
  display: flex;
  gap: 10px;
  align-items: baseline;
}

.profile-photo-edit {
  width: 200px;
  height: 200px;
  position: relative;
  text-align: center;
  border: 2px solid #f1f3f6;
  cursor: pointer;
}
.error-sponser{
        left: auto;
        margin-top: 15px;
color: white;
border-radius: 5px;
        right: 5%;
        background-color: #ff5b66 !important;
        transform: translate(-5%, -50%);
        -webkit-transform: translate(-5%, -50%);
        -moz-transform: translate(-5%, -50%);
        -ms-transform: translate(-5%, -50%);
        -o-transform: translate(-5%, -50%);
        width: max-content;
}
.profile-photo-edit .user-default {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}

.profile-photo-edit img,
.new-profile-photo img {
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}

.profile-photo-edit-icon-wrapper {
  position: absolute;
  top: 76.5%;
  background-color: #e5f2fe;
  right: 3.8%;
  width: 35px;
  height: 35px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}

.profile-photo-edit-icon-wrapper img {
  width: 20px;
}
</style>
  
    