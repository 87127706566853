<template>
  <div>
    <ChatSystem :currentUserId="currentUserId" :rooms="rooms" />
    <Navbar />
    <div class="setting-main-wrapper">
      <div class="main-left-menu">
        <LeftMenu />
      </div>
      <Banner />

      <div class="shop-side p-md-0">
        <LeftMenuSide :openTabSet="openTabSet" />
        <div class="row no-gutters content-wrap-info">
          <div class="event-section-wrap team-section-wrap">
            <div class="event-section">
              <div :class="['sticky-header', { scrolled: isScrolled }]">
                <div class="d-flex align-items-center justify-content-between flex-wrap">
                  <h6>My Teams</h6>
                  <div class="add-new-team-btn">
                    <div class="form-inline bg-white">
                      <button>
                        <img class="search-image" :src="search" alt="search" />
                      </button>
                      <!-- <input v-model="searchTerm" aria-label="Search" class="form-control border-0 bg-white"
                        placeholder="Search" type="search" v-on:keyup.enter="searchValue" /> -->
                      <input v-model="filter.name" aria-label="Search" @input="debouncedGetFilters()"
                        class="form-control border-0 bg-white" placeholder="Search" type="search"
                        v-on:keyup.enter="searchValue" />
                    </div>
                    <button @click="callModal()">
                      <img :src="add" alt="add-team" />
                      <span>Add Team</span>
                    </button>
                  </div>
                </div>
                <div class="filter-top">
                  <MyTeamsFilter :ageOptions="ageOptions" :isLoadingArrayAge="isLoadingArrayAge"
                    @filters-changed="handleFiltersChanged" />
                </div>
              </div>

              <div class="event-section-content">

                <div class="no-event-wrap" v-if="rooms.length == 0 && teamInfoLoader == 0">
                  <img src="../../../assets/Svg/no-data.svg" alt />
                  <p class="header">No teams found.</p>
                  <p class="info">We couldn't find any teams. Broaden your search by tweaking the filters above.
                  </p>
                </div>
                <ChatShimmers class="px-3 pb-0 pt-2 " v-if="rooms.length == 0 && teamInfoLoader == 1" />

                <div class="event-info-wrapper" v-if="rooms.length > 0">
                  <div class="event-info-sec cursor-pointer" v-for="team in rooms" :key="team.teamData.id">
                    <div class="event-sec-top team-sec-top" @click="showSchedule(team.teamData.team_id)">
                      <div class="team-sec-left">
                        <div class="image-team">
                          <img src="../../../assets/loader.gif" alt="" v-if="imgLoader == 1" />
                          <img @load="onImageLoad"
                            v-if="team.teamData.team_avatar === '' || team.teamData.team_avatar === null"
                            src="../../../assets/Svg/team-avatar.svg" alt="" />
                          <img @load="onImageLoad"
                            v-if="team.teamData.team_avatar !== '' && team.teamData.team_avatar !== null"
                            :src="url + team.teamData.team_avatar" alt="" />

                        </div>
                        <div class="sec-date-name">
                          <h6>{{ team.teamData.team_name }}</h6>
                          <span v-if="team.teamData.team_type == 'sports'">{{ team.teamData.age_group }} - {{
                            team.teamData.gender }}</span>
                          <span v-if="team.teamData.team_type == 'sports'">{{ team?.teamData.sport_type }}</span>
                          <span v-if="team?.teamData?.team_type && team.teamData?.team_type !== 'sports'">
                            {{ team.teamData.team_type.charAt(0).toUpperCase() + team.teamData.team_type.slice(1) }}
                          </span>
                        </div>
                      </div>
                      <div class="team-mates-wrap">
                        <div class="team-mates-info">
                          <MatesData :members="team.teamData.team_members" />
                        </div>

                        <div class="event-sec-right" v-if="team.teamData.team_type == 'sports'">
                          <div class="play-info">
                            <div class="game-play">
                              <span>Games</span>
                              <span>{{ team.total_games }}</span>
                            </div>
                            <div class="game-win">
                              <span>Won</span>
                              <span>{{ team.win }}</span>
                            </div>
                            <div class="game-loss">
                              <span>Lost</span>
                              <span>{{ team.lost }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="rooms.length" v-observe-visibility="handleScrolledToButtom"></div>
                  <div class="p-3" v-if="teamInfoLoader">
                    <content-placeholders :rounded="true">
                      <content-placeholders-heading :img="false" />
                    </content-placeholders>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Header/Navbar.vue";
import Banner from "@/components/BannerNotify/BannerPage.vue";
import LeftMenu from "@/components/Home/LeftMenu.vue";
import LeftMenuSide from "@/components/NewGameTime/LeftMenuSide.vue";
import MyTeamsFilter from "@/components/NewGameTime/Teams/MyTeamsFilter.vue";
import * as firestoreService from "../../../database/firestore";
// import { formatTimestamp } from "../../../utils/dates";
// import Play from "@/components/GameTime/Play.vue";
import MatesData from "@/components/NewGameTime/Teams/MatesData.vue";
import ChatShimmers from "@/components/Teams/chatShimmers.vue";
import ChatSystem from "../../Teams/ChatSystem.vue";
import axios from "axios";
export default {
  name: "MyTeams",
  components: {
    Navbar,
    LeftMenu,
    Banner,
    LeftMenuSide,
    MyTeamsFilter,
    // Play,
    MatesData,
    ChatShimmers,
    ChatSystem
  },
  data() {
    return {
      imgLoader: 1,
      openTabSet: 2,
      url: this.$groupAvatarStorageURL,
      searchTerm: "",
      search: require("../../../assets/Svg/Search.svg"),
      teams: require("../../../assets/Svg/team.svg"),
      add: require("../../../assets/Svg/add.svg"),
      default: require("../../../assets/images/user-default-new.png"),
      teamIds: [],
      currentUserId: "",
      foundCurrentUser: [],
      teamCurrentUserArray: [],
      tapArchive: 1,
      userId: localStorage.getItem("userId"),
      userName: localStorage.getItem("userName"),
      roomsPerPage: 15,
      rooms: [],
      chatTypeCheck: "team",
      roomId: "",
      roomAvatar: [],
      startRooms: null,
      endRooms: null,
      roomsLoaded: false,
      allUsers: [],
      time: this.$time,
      noChatIcon: false,
      formattedRooms: [],
      teamInfoLoader: 1,
      ageOptions: [],
      selectedAges: [],
      selectedGender: '',
      selectedType: '',
      isScrolled: false,
      filter: {
        name: "",
      },
      page: 1,
      lastPage: 1,
      isLoadingArrayAge: false,
    };
  },

  methods: {
    newLists() {
      this.foundCurrentUser = [];
      this.teamCurrentUserArray = [];
      this.tapArchive = 1;
      this.roomsPerPage = 15;
      this.chatTypeCheck = "team";
      this.roomId = "";
      this.roomAvatar = [];
      this.time = this.$time;
      this.noChatIcon = false;
      this.formattedRooms = [];
      this.teamInfoLoader = 1;
      this.rooms = [];
      this.tapArchive = 1;
      this.roomDataCount = 0;
      this.allUsersRef = [];
      this.startRooms = null;
      this.endRooms = null;
      this.roomsLoaded = false;
      this.allUsers = [];
      this.messages = [];
    },
    handleScroll() {
      let scrollTop = document.querySelector('.shop-side')?.scrollTop || 0;
      this.isScrolled = scrollTop > 5;
    },

    handleFiltersChanged(filters) {
      console.log(filters)
      this.selectedAges = filters.ages // Extract age names
      this.selectedGender = filters.gender;
      this.selectedType = filters.type;
      this.rooms = [];
      this.page = 1;
      this.teamInfoLoader = 1;
      this.fetchMyTeamsData()
    },
    onImageLoad() {
      this.imgLoader = 0;
    },
    showSchedule(teamId) {
      this.$router.push(`/my-team/events/${teamId}`);
    },
    // async fetchMoreRooms() {
    //   this.newLists();
    //   if (this.currentUserId == "") {
    //     const promiseUser = await firestoreService.getUserCid(this.userId);
    //     this.foundCurrentUser = promiseUser;
    //     if (this.foundCurrentUser.data[0] != undefined) {
    //       this.currentUserId = this.foundCurrentUser.data[0].id;
    //     }
    //   }

    //   if (this.currentUserId != "") {
    //     try {
    //       if (this.endRooms && !this.startRooms) {
    //         this.roomsLoaded = true;
    //         return;
    //       }
    //       if (this.currentUserId != "") {
    //         const query = firestoreService.roomsGameTimeQuery(
    //           this.currentUserId
    //         );

    //         const { data, docs } = await firestoreService.getRooms(query);
    //         this.roomsLoaded =
    //           data.length === 0 || data.length < this.roomsPerPage;
    //         if (this.startRooms) this.endRooms = this.startRooms;
    //         this.startRooms = docs[docs.length - 1];
    //         await this.makeUserArray(data);

    //         const roomList = {};
    //         data.forEach((room) => {
    //           this.teamIds.push(room.id);
    //           roomList[room.id] = { ...room, users: [] };
    //           room.users.forEach((userId) => {
    //             const foundUser = this.allUsers.find(
    //               (user) => user?._id === userId
    //             );
    //             if (foundUser) {
    //               var chechUrl;
    //               var url =
    //                 this.$userProfileStorageURL +
    //                 foundUser.c_id +
    //                 "/profile_picture.jpg?v=" +
    //                 this.time;
    //               this.urlExist(url, (exists) => {
    //                 if (exists) {
    //                   chechUrl = "yes";
    //                   foundUser.avatar = url;
    //                   console.log(chechUrl);
    //                 } else {
    //                   chechUrl = "no";
    //                   foundUser.avatar =   require("../../../assets/images/user-default-new.png");
    //                 }
    //               });
    //               roomList[room.id].users.push(foundUser);
    //             }
    //           });
    //         });

    //         const formattedRooms = [];
    //         this.formattedRooms = [];

    //         Object.keys(roomList).forEach((key) => {
    //           const room = roomList[key];
    //           const roomUnreadCount = room.userCount.filter(
    //             (user) => user.id == this.currentUserId
    //           );

    //           if (room.chatType == "team") {
    //             room.roomName = room.teamName;
    //             this.roomAvatar =
    //               room.teamAvatar !== ""
    //                 ? this.$groupAvatarStorageURL + room.teamAvatar
    //                 :  require("../../../assets/images/team-avatar.png");
    //           }

    //           if (room.chatType == this.chatTypeCheck) {
    //             // Find if team archive
    //             this.teamCurrentUserArray = [];
    //             if (room.chatType == "team") {
    //               this.teamCurrentUserArray = room.userRole.filter(
    //                 (user) => user.id == this.currentUserId
    //               );
    //             }

    //             if (
    //               (this.teamCurrentUserArray.length > 0 &&
    //                 this.tapArchive == this.teamCurrentUserArray[0].status) ||
    //               room.chatType != "team"
    //             ) {
    //               formattedRooms.push({
    //                 ...room,
    //                 roomId: key,
    //                 unreadCount:
    //                   roomUnreadCount[0] === undefined
    //                     ? 0
    //                     : roomUnreadCount[0].count == "0" ||
    //                       roomUnreadCount[0].count == 0
    //                     ? ""
    //                     : roomUnreadCount[0].count,
    //                 avatar: this.roomAvatar,
    //                 index: room.lastUpdated.seconds,
    //                 lastMessage: {
    //                   content: "",
    //                   timestamp: formatTimestamp(
    //                     new Date(room.lastUpdated.seconds * 1000),
    //                     room.lastUpdated
    //                   ),
    //                 },
    //               });
    //             }
    //           }
    //         });

    //         this.rooms = this.rooms.concat(formattedRooms);
    //         this.rooms.sort((a, b) => b.lastUpdated - a.lastUpdated);
    //         if (!this.rooms.length) {
    //           this.noChatIcon = true;
    //         } else {
    //           this.noChatIcon = false;
    //         }
    //       }

    //       this.$root.$emit("gameTimeTeams", this.rooms, this.noChatIcon);
    //       this.$root.$emit("gameTimeLiveCard", this.teamIds);
    //       this.teamInfoLoader = 0;
    //     } catch (error) {
    //       this.teamInfoLoader = 0;
    //     } finally {
    //       this.teamInfoLoader = 0;
    //     }
    //   } else {
    //     this.teamInfoLoader = 0;
    //     this.$alert("Please check your internet connection. User not found.");
    //   }
    // },
    debouncedGetFilters() {
      clearTimeout(this.debounceTimer); // Clear previous timer

      this.debounceTimer = setTimeout(() => {
        this.showLoader = 1;
        this.showLoader1 = true;
        this.isLoading = true;
        this.rooms = [];
        this.page = 1;
        this.teamInfoLoader = 1;
        this.fetchMyTeamsData();
      }, 800);
    },
    handleScrolledToButtom(isVisible) {
      console.log(this.page)
      console.log(this.lastPage)
      if (this.page >= this.lastPage) {
        return;
      }
      if (isVisible) {
        this.page++;
        this.fetchMyTeamsData();
      }
    },
    async fetchMyTeamsData() {
      this.teamInfoLoader = 1;
      const formData = new FormData();
      formData.append("user_id", localStorage.getItem("userId"));
      formData.append("name", this.filter.name);
      if (this.selectedAges && this.selectedAges.length) formData.append("ages", JSON.stringify(this.selectedAges));
      if (this.selectedGender) formData.append("gender", this.selectedGender);
      if (this.selectedType) formData.append("type", this.selectedType);

      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL +
          `adminTeam/getMyTeams?page=${this.page}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );


        if (response.data.statusCode === 200) {
          if (this.page === 1) {
            this.rooms = [];
          }
          this.rooms.push(...response.data.data.data);
          this.lastPage = response.data.data.last_page;
          console.log(this.rooms, "kkdfk")
          this.$root.$emit("gameTimeTeams", this.rooms, this.noChatIcon);
          this.$root.$emit("gameTimeLiveCard", this.teamIds);
          this.teamInfoLoader = 0;

        } else {
          console.error(
            "Error:",
            response.data.message || "Unknown error occurred"
          ); this.errorMessage = response.data.message || "Unknown error occurred";
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
        this.errorMessage = "Failed to fetch data";
      } finally {
        this.teamInfoLoader = 0;
        this.showLoader = 0;
        this.showLoader1 = false;
        this.isLoading = false;
      }
    },

    // URL exist ----------------------------------------------------------------
    urlExist(url, callback) {
      const img = new Image();
      img.src = url;

      if (img.complete) {
        callback(true);
      } else {
        img.onload = () => {
          callback(true);
        };

        img.onerror = () => {
          callback(false);
        };
      }
    },
    callModal: function () {
      console.log("heheh")
      this.$root.$emit("openAddTeamModal", true);
    },
    async makeUserArray(data) {
      const roomUserIds = [];
      data.forEach((room) => {
        room.users.forEach((userId) => {
          const foundUser = this.allUsers.find((user) => user?._id === userId);
          if (!foundUser && roomUserIds.indexOf(userId) === -1) {
            roomUserIds.push(userId);
          }
        });
      });

      const rawUsers = [];
      roomUserIds.forEach((userId) => {
        const promise = firestoreService.getUser(userId);
        rawUsers.push(promise);
      });
      this.allUsers = [...this.allUsers, ...(await Promise.all(rawUsers))];
    },
    getAgeGroup() {
      this.isLoadingArrayAge = true
      axios
        .get(`${process.env.VUE_APP_API_URL}getAgeGroup`)
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.ageOptions = response.data.data.list;
          } else {
            this.errorMessage = response.data.message;
          }
          this.isLoadingArrayAge = false
        });
    },
  },
  mounted() {
    this.$root.$on("openTabSetting", (id) => {
      this.openTabSet = id;
    });
    this.getAgeGroup();
    this.fetchMyTeamsData();

    // this.fetchMoreRooms();
    this.$root.$on("showTeamListGameTime", () => {
      this.teamInfoLoader = 1;
      this.rooms = [];
      this.page = 1;
      this.fetchMyTeamsData();
    });
    let scrollContainer = document.querySelector('.shop-side'); // Update selector to match your container
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", this.handleScroll);
    }
  },
  beforeUnmount() {
    let scrollContainer = document.querySelector('.shop-side');
    if (scrollContainer) {
      scrollContainer.removeEventListener("scroll", this.handleScroll);
    }
  },
};
</script>
