<template>
  <div>
    <div class="team-info-wrap events-list-main">
      <div class="row m-0">
        <div
          class="col-md-3 px-1 form-group control-group mb-0 d-flex align-items-center justify-content-end"
        >
          <select
            class=""
            id="eventType"
            v-model="filter.name"
            @change="calendarData()"
            :disabled="isLoading"
          >
            <option value="" selected>Select Team</option>
            <option v-for="team in teamsArray" :value="team.id" :key="team.id">
              {{ team.teamName }}
            </option>
          </select>
          <span
            :style="{ display: isLoading ? 'none' : 'block' }"
            v-if="filter.name"
            class="clear-icon"
            @click="clearTeam()"
            ><img src="../../assets/cross-icon.png"
          /></span>
        </div>
        <div
          class="col-md-3 px-1 form-group control-group mb-0 d-flex align-items-center justify-content-end"
        >
          <select
            class=""
            v-model="filter.association"
            @change="calendarData()"
            :disabled="isLoading"
          >
            <option value="" selected>Select Association</option>
            <option
              v-for="option in associationOptions"
              :value="option"
              :key="option"
            >
              {{ option }}
            </option>
          </select>
          <span
            :style="{ display: isLoading ? 'none' : 'block' }"
            v-if="filter.association"
            class="clear-icon"
            @click="clearAssociation()"
            ><img src="../../assets/cross-icon.png"
          /></span>
        </div>
        <div
          class="col-md-3 px-1 form-group control-group mb-0 d-flex align-items-center justify-content-end"
        >
          <select
            class=""
            v-model="filter.eventType"
            @change="calendarData()"
            :disabled="isLoading"
          >
            <option value="" selected>Select Event Type</option>
            <option
              v-for="option in eventTypes"
              :value="option"
              :key="option"
            >
              {{ option }}
            </option>
          </select>
          <span
            :style="{ display: isLoading ? 'none' : 'block' }"
            v-if="filter.eventType"
            class="clear-icon"
            @click="clearEventType()"
            ><img src="../../assets/cross-icon.png"
          /></span>
        </div>
        <div class="col-md-3 px-1 form-toggle control-group mb-0">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <span>Show All Events</span>
            </div>
            <toggle-button
              class="m-0"
              v-model="filter.eventStatus"
              :value="filter.eventStatus"
              :sync="true"
              :width="34"
              @change="togglePastEventsFilter()"
              :disabled="isLoading"
            />
          </div>
        </div>
      </div>

      <b-row class="no-gutters" v-if="eventsDetails.length > 0">
        <div class="col-12 products-column mt-3">
          <div class="event-card">
            <div class="table-responsive mb-0">
              <b-table
                :items="eventsDetails"
                :fields="fields"
                hover
                responsive
                class="b-datatable"
                :sort-by.sync="sortBy"
              >
                <!-- Custom slot for the action column -->
                <template v-slot:cell(action)="data">
                  <div class="content action-column text-center">
                    <img
                      src="../../assets/chevron-up.png"
                      class="cursor-pointer"
                      v-if="activeAccordionId === data.item.id"
                      @click="closeToggleAccordion(data.item.id)"
                    />
                    <img
                      src="../../assets/chevron-down.png"
                      class="cursor-pointer"
                      v-else
                      @click="toggleAccordion(data.item, data.item.id)"
                    />
                  </div>
                </template>

                <template v-slot:cell(location)="data">
                  <div
                    class="event-location"
                    v-if="
                      (data.item.status == 1 &&
                        data.item.address != null &&
                        data.item.address != '' &&
                        data.item.address != 'null') ||
                        (data.item.city != null &&
                          data.item.city != '' &&
                          data.item.city != 'null') ||
                        (data.item.state != null &&
                          data.item.state != '' &&
                          data.item.state != 'null')
                    "
                  >
                    <img
                      class="mr-2"
                      src="../../assets/images/location.png"
                      @click="openGoogleMap(data.item)"
                    />
                    <span class="content" @click="openGoogleMap(data.item)">
                      {{ data.item.address }} {{ data.item.city }},
                      {{ data.item.state }}
                    </span>
                  </div>
                  <div v-else>
                    <!-- Display the medium property -->
                    Online Event
                  </div>
                </template>
                <template v-slot:cell(state)="data">
                  <div class="event-location" v-if="data.item.state !== null">
                    {{ data.item.state }}
                  </div>
                  <div v-else>
                    Online Event
                  </div>
                </template>

                <!-- Row details slot for displaying games -->
                <template v-slot:row-details="row">
                  <b-container v-if="row.item._showDetails">
                    <b-row class="my-2">
                      <b-col class="col-12">
                        <div
                          class="row no-gutters"
                          v-if="showGameShimmer === 1"
                        >
                          <div class="col-12 p-0">
                            <content-placeholders :rounded="true">
                              <content-placeholders-heading :img="false" />
                            </content-placeholders>
                            <br />
                            <content-placeholders :rounded="true">
                              <content-placeholders-heading :img="false" />
                            </content-placeholders>
                          </div>
                        </div>
                        <b-table
                          v-else-if="gameList && gameList.length > 0"
                          :items="gameList"
                          :fields="gameFields"
                          responsive="sm"
                          head-variant="light"
                          class="nested-table"
                        >
                        
                        <template v-slot:thead-top>
                          <b-tr class="add-game-tr">
                            <b-td colspan="4">
                              <div class="products-list-shim" v-if="teamLoader == 1">
                                <content-placeholders :rounded="true">
                                  <content-placeholders-text :lines="2" />
                                </content-placeholders>
                              </div>

                              <div v-else>
                                <div class="custom-header-column">
                                  <h6>Games</h6>
                                </div>
                              </div>
                            </b-td>

                            <b-td colspan="4">
                              <div class="custom-header-column d-flex justify-content-end" v-if="currentIsAdmin == '1' && selectedRoomTeam.teamType == 'sports' && currentTeamIsActive == 1 && teamLoader == 0">
                                <NewGame :newGameProps="newGameProps" />
                              </div>
                            </b-td>
                          </b-tr>
                        </template>

                        <template v-slot:cell(game_action)="game">
                              <td class="content text-center">
                                  <router-link :to="`/score-game/${game.item.team_id}-${game.item.guid}`">
                                      <img
                                          class="eye-icon cursor-pointer"
                                          src="../../assets/images/eye_see.png"
                                          alt="eye"
                                      />
                                  </router-link>
                              </td>
                          </template>
                          <template v-slot:cell(name)="data">
                            <div
                              class="live-league position-relative mb-0"
                              v-if="data.item.game_live == 1"
                            >
                              <div class="inner-sec">
                                <div class="live-tag">
                                  <span>Live</span>
                                </div>
                              </div>
                            </div>
                            <span class="content">{{ data.item.name }}</span>
                            
                          </template>
                          <template v-slot:cell(start_date)="data">
                            <td class="content px-2">
                              <p>
                                {{ data.item.start_date | formatDateOfBirth }}
                              </p>
                              <p>{{ data.item.start_time | formatTimeToAM }}</p>
                            </td>
                          </template>
                          <template v-slot:cell(park_name)="data">
                            <td class="content px-2">
                              <p>{{ data.item.park_name }}</p>
                              <p
                                class="content"
                                v-if="data.item.park_address !== null"
                              >
                                <img
                                  src="../../assets/images/location.png"
                                  class="mr-2"
                                  @click="openGoogleMap(data.item)"
                                />
                                {{ data.item.park_address }}
                              </p>
                            </td>
                          </template>
                          <template v-slot:cell(opponent_name)="data">
                            <td class="content">
                              {{ data.item.opponent_name }}
                              <p
                                class="content"
                                v-if="
                                  data.item.comment !== null &&
                                    data.item.comment !== '' &&
                                    data.item.comment !== 'null'
                                "
                              >
                                {{ data.item.comment }}
                              </p>
                            </td>
                          </template>
                        </b-table>
                        <div
                          v-if="showGameShimmer == 0 && gameList.length == 0"
                        >
                        <div class="products-list-shim" v-if="teamLoader == 1">
                          <content-placeholders :rounded="true">
                            <content-placeholders-text :lines="2" />
                          </content-placeholders>
                        </div>

                        <div class="d-flex justify-content-between" v-else>
                          <h6>Games</h6>
                          <NewGame
                            v-if="currentIsAdmin == '1' && selectedRoomTeam.teamType == 'sports' && currentTeamIsActive == 1 && teamLoader == 0"
                            :newGameProps="newGameProps"
                          />
                        </div>

                        
                            <hr>
                          <div class="no-game-section rounded">
                            <div class="no-content">
                              <div class="img">
                                <img
                                  src="../../assets/images/games.png"
                                  alt=""
                                />
                              </div>
                              <p class="text">
                                There are no games added for this event yet
                              </p>
                            </div>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </b-container>
                </template>
              </b-table>
              <content-placeholders :rounded="true" v-if="showLoader1">
                <content-placeholders-heading :img="false" />
              </content-placeholders>
            </div>
          </div>
        </div>
      </b-row>

      <div
        v-if="eventsDetails.length"
        v-observe-visibility="handleScrolledToButtom"
      ></div>
    </div>
    <div
      class="no-products-in-shop"
      v-if="(!eventsDetails || eventsDetails.length === 0) && showLoader === 0"
    >
      <img src="../../assets/images/no-product.png" alt />
      <p class="header">No event found.</p>
      <p class="info">
        There are no events to display in the selected category.
      </p>
      <!-- <button
        v-if="!isDisabledReset"
        type="button"
        class="cancel-btn"
        @click="clearFilter(0)"
        style="width: auto; padding: 8px 12px;"
      >
        <span>Reset</span>
      </button> -->
    </div>
    <div
      class="products-list-shim mt-3"
      v-if="eventsDetails.length == 0 && showLoader == 1"
    >
      <content-placeholders :rounded="true">
        <content-placeholders-img />
        <content-placeholders-heading />
      </content-placeholders>
      <content-placeholders class="mt-3" :rounded="true">
        <content-placeholders-img />
        <content-placeholders-heading />
      </content-placeholders>
    </div>
  </div>
</template>
<script>
import axios from "axios";
// import { mapActions } from "vuex";
import * as firestoreService from "@/database/firestore";
import { mapActions, mapGetters } from "vuex";
import NewGame from "../Teams/Games/NewGame.vue";
// import { parseTimestamp } from "../../utils/dates";

export default {
  name: "eventsDataList",
  components: { NewGame },
  data() {
    return {
      activeAccordionId: -1,
      activeAccordionIndex: -1,
      associationOptions: [],
      // eventsOptions: [],
      eventTypes: [],
      fields: [
        {
          key: "startDateForField",
          label: "Date",
          sortable: true,
          formatter: (value, key, item) => {
              const startDate = new Date(item.exactStartDate);
              const endDate = new Date(item.exactEndDate);
              const startDateString = startDate.toLocaleDateString("en-US", {
                  month: "short",
                  day: "numeric",
              });
              const endDateString = endDate.toLocaleDateString("en-US", {
                  month: "short",
                  day: "numeric",
              });
              const startYearString = startDate.getFullYear();
              const endYearString = endDate.getFullYear();

              if (startYearString === endYearString) {
                  return `${startDateString} to ${endDateString}, ${startYearString}`;
              } else {
                  return `${startDateString}, ${startYearString} to ${endDateString}, ${endYearString}`;
              }
          },
        },
        { key: "eventName", label: "Event Name", sortable: true },
        { key: "team_name.team_name", label: "Team Name", sortable: true },
        { key: "association", label: "Association", sortable: true },
        { key: "eventType", label: "Event Type", sortable: true },
        {
          key: "location",
          label: "Location",
          sortable: true,
          formatter: (value, key, item) => {
            
            return `${item.address || ""}, ${item.city || ""}, ${item.state ||
              ""}`;
          },
          sortBy: (item) => {
           
            const location =
              (item.address || "") +
              " " +
              (item.city || "") +
              " " +
              (item.state || "");
            return location.toLowerCase(); 
          },
        },
        { key: "action", label: "Action", class: "text-center" },
        // Add more fields as needed
      ],
      gameFields: [
        { key: "name", label: "Game Name" }, // Example field
        { key: "start_date", label: "Start Date" }, // Example field
        // Example field
        {
          key: "park_name",
          label: "Park Name",
        },

        { key: "opponent_name", label: "Opponent Name" },

        {
          key: "game_action",
          label: "Action",
          // class: "d-flex justify-content-center",
        }, 
      ],
      filter: {
        name: "",
        association: "",
        eventType: "",
        eventStatus: false,
      },
      TeamA: [],
      sortKey: "",
      sortBy: "",
      reverse: false,
      totalTeams: "",
      teamUsers: "",
      currentIsFan: "0",
      eventsDetails: [],
      UserDetails: [],
      page: 1,
      lastPage: 1,
      showLoader: 1,
      showLoader1: false,
      eventsData: [],
      showGameShimmer: 0,
      gameList: [],
      teamsArray: [],
      isLoadingArrayFilter: [],
      messages: [],
      isLoading: false,
      filterApplied: false,
      newGameProps: {},
      currentIsAdmin: "0",
      currentTeamIsActive: 1,
      selectedRoomTeam: {},
      currentUserId:"",
      userId: localStorage.getItem("userId"),
      allUsers:[],
      teamLoader: 0,
      // eventTypesUsed: [],
      // allEventTypesUsed: [],
    };
  },

  mounted() {
    this.getUserChatIdWithRetry();
    this.getAssociation();
    // this.getEventsType();
    this.fetchAllTeamsFromFirestore();
    this.$root.$on("refreshEventGame", (event) => {
      this.getGames(event);
    });

  },
  computed: {
    ...mapGetters(["errors"]),
    ...mapGetters("events", ["event"]),

    sortedEvents() {
      const events = this.eventsDetails.slice();
      if (this.sortKey === "exactStartDate") {
        events.sort((a, b) => {
          const dateA = new Date(a[this.sortKey]);
          const dateB = new Date(b[this.sortKey]);

          if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) {
            return 0; // handle invalid dates
          }

          return this.reverse
            ? dateB.getTime() - dateA.getTime()
            : dateA.getTime() - dateB.getTime();
        });
      } else if (this.sortKey === "location") {
        events.sort((a, b) => {
          const locationA =
            (a.address || "") + " " + (a.city || "") + " " + (a.state || "");

          const locationB =
            (b.address || "") + " " + (b.city || "") + " " + (b.state || "");

          return this.reverse
            ? locationB.localeCompare(locationA)
            : locationA.localeCompare(locationB);
        });
      } else if (this.sortKey === "team_name") {
        events.sort((a, b) => {
          const teamNameA = a[this.sortKey].team_name || "";
          const teamNameB = b[this.sortKey].team_name || "";

          return this.reverse
            ? teamNameB.localeCompare(teamNameA)
            : teamNameA.localeCompare(teamNameB);
        });
      } else if (this.sortKey) {
        events.sort((a, b) => {
          const fieldA = a[this.sortKey];
          const fieldB = b[this.sortKey];

          if (typeof fieldA === "string" && typeof fieldB === "string") {
            return this.reverse
              ? fieldB.localeCompare(fieldA)
              : fieldA.localeCompare(fieldB);
          } else if (typeof fieldA === "number" && typeof fieldB === "number") {
            return this.reverse ? fieldB - fieldA : fieldA - fieldB;
          } else {
            return 0; // handle other types of sorting if needed
          }
        });
      }
      return events;
    },
  },
  watch: {
  sortBy: function(newSortBy, oldSortBy) {
    if (newSortBy !== oldSortBy) {
      // Close the accordion when sorting is applied
      this.closeToggleAccordion(this.activeAccordionId);
    }
  }
},

  methods: {
    ...mapActions("events", ["setEventListData"]),
    ...mapActions("events", ["getCurrentComingStatus"]),
    toggleAccordion(event, eventId) {
      this.newGameProps = { event };
      if (this.activeAccordionId === eventId) {
        this.eventsDetails.forEach((eventDetail) => {
          if (eventDetail.id === eventId) {
            eventDetail._showDetails = false;
          }
        });
        this.activeAccordionId = -1;
      } else {
        this.eventsDetails.forEach((eventDetail) => {
          eventDetail._showDetails = eventDetail.id === eventId;
        });
        this.activeAccordionId = eventId;
        this.getTeamData(event);
        this.getGames(event);
      }
    },

    closeToggleAccordion(eventId) {
      this.eventsDetails.forEach((eventDetail) => {
        if (eventDetail.id === eventId) {
          eventDetail._showDetails = false;
        }
      });
      this.activeAccordionId = -1;
    },
    async getGames(event) {
      this.showGameShimmer = 1;
      this.gameList = [];
      var formData = new FormData();
      formData.append("event_id", event.id);
      formData.append("team_id", event.team_id);
      await axios
        .post(process.env.VUE_APP_API_URL + "game/gameListing", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.gameList = response.data.data;
          event.showGameList = true;
          this.showGameShimmer = 0;
        });
    },
    sortBys(sortKey) {
      if (this.sortKey === sortKey) {
        this.reverse = !this.reverse;
      } else {
        this.sortKey = sortKey;
        this.reverse = false;
      }
    },
    async firebasedata(user_id) {
      this.TeamDetails = [];
      this.Team = [];
      const querySport = firestoreService.roomsSportQuery(user_id);
      const { data, docs } = await firestoreService.getRooms(querySport);
      this.totalTeams = docs[docs.length - 1];
      data.forEach((teams) => {
        this.TeamDetails.push(teams.id);
        this.TeamA.push(teams);
        //  Make Array for all Users on Team Start -----------
        const rawUsers = [];
        teams.users.forEach((userId) => {
          const promise = firestoreService.getUser(userId);
          rawUsers.push(promise);
        });
        this.teamUsers = "";
        var currentIsFan = "0";
        Promise.all(rawUsers).then((values) => {
          for (const role of teams.userRole) {
            for (const member of teams.users) {
              if (role.id == member) {
                if (member == this.currentUserId && role.fan == "1") {
                  currentIsFan = "1";
                }
                if (member == this.currentUserId && role.fan != "1") {
                  currentIsFan = "0";
                }
              }
            }
          }
          let teamDeta = {
            teamID: teams.id,
            users: values,
            userRole: teams.userRole,
            currentIsFan: currentIsFan,
          };

          this.UserDetails.push(teamDeta);
        });
        //  Make Array for all Users on Team Start -----------
      });

      this.setEventListData(this.TeamDetails);

      this.calendarData();

      let comingStatus = {
        teamID: this.TeamDetails,
        usersDetail: this.UserDetails,
        users: user_id,
      };
      this.getCurrentComingStatus(comingStatus);
    },
    async getUserChatIdWithRetry() {
      const userId = localStorage.getItem("userId");

      const promiseUser = await firestoreService.getUserCid(userId);

      this.currentUserId = promiseUser.data[0].id;

      this.firebasedata(this.currentUserId);

      // setTimeout(() => {
      //   this.getUserChatIdWithRetry();
      // }, 1000);
    },
    async getUserChatId() {
      await this.getUserChatIdWithRetry();
    },

    async calendarData() {
      this.page = 1;
      // Set the default filter if not already set
      if (this.filter.eventStatus === null) {
        this.filter.eventStatus = "true"; // Default to upcoming events
      }

      this.showLoader = 1;
      this.showLoader1 = true;
      this.isLoading = true;
      const formData = new FormData();
      formData.append("sportsRoomID", this.TeamDetails);
      formData.append("eventStatus", this.filter.eventStatus);
      formData.append("eventType", this.filter.eventType); // Add event type filter
      formData.append("association", this.filter.association); // Add association filter // Add event status filter
      formData.append("teamName", this.filter.name); // Add association filter // Add event status filter
      // if (
      //   this.filter.name ||
      //   this.filter.association ||
      //   this.filter.eventType
      // ) {
      //   this.page = 1;
      //   this.eventsDetails = [];
      //   this.filterApplied = true;
      // }

      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL +
            `event/calenderEventLists?page=${this.page}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );


        if (response.data.statusCode === 200) {
          this.commonEventTypes = response.data.data.eventTypes;
          const newEventData = response.data.data.listing.data;
          this.eventTypes = response.data.data.eventTypes;
          // this.eventTypesUsed = [...new Set(newEventData.map(event => event.eventType))];
          // this.allEventTypesUsed = [...new Set([...this.allEventTypesUsed, ...this.eventTypesUsed])];

          // if (!this.filterApplied) {
          //   this.commonEventTypes = this.allEventTypesUsed.filter(eventType =>
          // this.eventsOptions.includes(eventType)
          // );
          // }

          // If it's the first page, clear eventsDetails array
          if (this.page === 1) {
            this.eventsDetails = [];
          }

          // Concatenate newly fetched data with existing data
          this.eventsDetails = this.eventsDetails.concat(newEventData);

// Filter events based on selected eventType
if (this.filter.eventType) {
  this.eventsDetails = this.eventsDetails.filter(event => {
    // Ensure we are filtering by eventType only, not location
    return event.eventType === this.filter.eventType;
  });
}

          this.eventsDetails.sort((a, b) => {
              const dateAStart = new Date(a.exactStartDate);
              const dateBStart = new Date(b.exactStartDate);
              const dateAEnd = new Date(a.exactEndDate);
              const dateBEnd = new Date(b.exactEndDate);
              
              // Compare start dates first
              if (dateAStart.getTime() !== dateBStart.getTime()) {
                  return dateAStart - dateBStart;
              } else {
                  // If start dates are the same, compare end dates
                  return dateAEnd - dateBEnd;
              }
          });
            this.filterOption = false;
            this.total = response.data.data.total;
            this.lastPage = response.data.data.listing.last_page;
            this.noEvent = this.eventsDetails.length > 0 ? 0 : 1;
        } else {
            console.error(
                "Error:",
                response.data.message || "Unknown error occurred"
            );this.errorMessage =response.data.message || "Unknown error occurred";
        }
    } catch (error) {
        console.error("Failed to fetch data:", error);
        this.errorMessage = "Failed to fetch data";
    } finally {
        this.showLoader = 0;
        this.showLoader1 = false;
        this.isLoading = false;
    }
    },
    async fetchAllTeamsFromFirestore() {
      try {
        var UserId = localStorage.getItem("userId");
        const promiseUser = await firestoreService.getUserCid(
          UserId.toString()
        );

        if (promiseUser.data[0]?.id !== undefined) {
          var currentUserId = promiseUser.data[0].id;

          // Assuming getAllRooms fetches all rooms and returns an object with a 'data' array
          const roomsData = await firestoreService.getAllRooms();

          if (Array.isArray(roomsData.data) && roomsData.data.length > 0) {
            const allTeams = roomsData.data.filter((room) => {
              // Filter and collect all teams associated with the user
              return (
                room.userRole &&
                room.userRole.some((user) => user.id === currentUserId) &&
                room.teamType === "sports"
              );
            });

            if (allTeams.length > 0) {
              this.teamsArray = allTeams;
              // Return or utilize the allTeams array as needed
              return allTeams;
            } 
          } 
        }
      } catch (error) {
        console.error("Error fetching teams from Firestore:", error);
        // Handle errors appropriately
      }
    },
    clearEventType() {
      this.filter.eventType = "";
      this.calendarData();
    },
    clearAssociation() {
      this.filter.association = "";
      this.calendarData();
    },
    clearTeam() {
      this.filter.name = "";
      this.calendarData();
    },
    getAssociation() {
      axios
        .get(`${process.env.VUE_APP_API_URL}getAssociation?search=${""}`)
        .then((response) => {
          this.associationOptions = response.data.data.association.map(
            (element) => element.association_name
          );
        })
        .catch((error) => {
          console.error("Error fetching associations:", error);
        });
    },
    // getEventsType() {
    //   axios
    //     .get(`${process.env.VUE_APP_API_URL}event/getEventTypes?search=${""}`)
    //     .then((response) => {
    //       this.eventsOptions = response.data.data.map(
    //         (element) => element.name
    //       );
    //     })
    //     .catch((error) => {
    //       console.error("Error fetching event types:", error);
    //     });
    // },

    handleScrolledToButtom(isVisible) {
      if (this.page >= this.lastPage) {
        return;
      }
      if (isVisible) {
        this.page++;
        this.calendarData2();
      }
    },
    async calendarData2() {
      // Set the default filter if not already set
      if (this.filter.eventStatus === null) {
        this.filter.eventStatus = "true"; // Default to upcoming events
      }

      this.showLoader = 1;
      this.showLoader1 = true;
      this.isLoading = true;
      const formData = new FormData();
      formData.append("sportsRoomID", this.TeamDetails);
      formData.append("eventStatus", this.filter.eventStatus);
      formData.append("eventType", this.filter.eventType); // Add event type filter
      formData.append("association", this.filter.association); // Add association filter // Add event status filter
      formData.append("teamName", this.filter.name); // Add association filter // Add event status filter
      // if (
      //   this.filter.name ||
      //   this.filter.association ||
      //   this.filter.eventType
      // ) {
      //   this.page = 1;
      //   this.eventsDetails = [];
      //   this.filterApplied = true;
      // }

      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL +
            `event/calenderEventLists?page=${this.page}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );


        if (response.data.statusCode === 200) {
          this.commonEventTypes = response.data.data.eventTypes;
          const newEventData = response.data.data.listing.data;
          this.eventTypes = response.data.data.eventTypes;
          // this.eventTypesUsed = [...new Set(newEventData.map(event => event.eventType))];
          // this.allEventTypesUsed = [...new Set([...this.allEventTypesUsed, ...this.eventTypesUsed])];

          // if (!this.filterApplied) {
          //   this.commonEventTypes = this.allEventTypesUsed.filter(eventType =>
          // this.eventsOptions.includes(eventType)
          // );
          // }

          // If it's the first page, clear eventsDetails array
          if (this.page === 1) {
            this.eventsDetails = [];
          }

          // Concatenate newly fetched data with existing data
          this.eventsDetails = this.eventsDetails.concat(newEventData);

// Filter events based on selected eventType
if (this.filter.eventType) {
  this.eventsDetails = this.eventsDetails.filter(event => {
    // Ensure we are filtering by eventType only, not location
    return event.eventType === this.filter.eventType;
  });
}

          this.eventsDetails.sort((a, b) => {
              const dateAStart = new Date(a.exactStartDate);
              const dateBStart = new Date(b.exactStartDate);
              const dateAEnd = new Date(a.exactEndDate);
              const dateBEnd = new Date(b.exactEndDate);
              
              // Compare start dates first
              if (dateAStart.getTime() !== dateBStart.getTime()) {
                  return dateAStart - dateBStart;
              } else {
                  // If start dates are the same, compare end dates
                  return dateAEnd - dateBEnd;
              }
          });
            this.filterOption = false;
            this.total = response.data.data.total;
            this.lastPage = response.data.data.listing.last_page;
            this.noEvent = this.eventsDetails.length > 0 ? 0 : 1;
        } else {
            console.error(
                "Error:",
                response.data.message || "Unknown error occurred"
            );this.errorMessage =response.data.message || "Unknown error occurred";
        }
    } catch (error) {
        console.error("Failed to fetch data:", error);
        this.errorMessage = "Failed to fetch data";
    } finally {
        this.showLoader = 0;
        this.showLoader1 = false;
        this.isLoading = false;
      }
    },
    openGoogleMap(item) {
     
      const strlatLng = item["lan"] + "," + item["long"];
      const urlSuffix = strlatLng
        ? strlatLng
        : item["address"] + ", " + item["city"] + ", " + item["zipCode"];

      window.open(
        "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
        "_blank"
      );
    },
    // callModal(game, event) {
    //   var combineUrl = event.team_id + "-" + game.guid;
    //   const urlToOpen = `/score-game/${combineUrl}`;
    //   window.location.href = urlToOpen;
    // },
    togglePastEventsFilter() {
      this.eventsDetails = [];
      this.page = 1;
      this.calendarData();
    },

    async getTeamData(event)
    {
      this.teamLoader=1;
      const rawRoom = [];
      const promise = await firestoreService.getRoomById(event.team_id);
      rawRoom.push(promise);

      const foundRoom = await Promise.all(rawRoom);
      let usersData={}; 
      Object.keys(foundRoom).forEach((key) => {
        const teamData = foundRoom[key];
        usersData=teamData.users;
        teamData.users = {};
        this.selectedRoomTeam=teamData;
      });

       // check role if team exits or user is member
      let roles = this.selectedRoomTeam.userRole;
      if (usersData != undefined) {
        var admincount = 0;
        for (const userRole of roles) {
          if (userRole.id == this.currentUserId) {
            admincount++;
          }
        }
        if (admincount == 0 ) {
          this.$router.push({ name: "PageNotFound" });
        }
      } else {
        this.$router.push({ name: "PageNotFound" });
      }

      await this.makeUserArray(usersData);
      const users=[];
      usersData.forEach((userId) => {
        const foundUser = this.allUsers.find((user) => user?._id === userId);
        if (foundUser) {
          users.push(foundUser);
        }
      });

      this.selectedRoomTeam.users=users;
      
      // assign admin role
      let tempMembers = this.selectedRoomTeam.users;

      // Set Users Role and Find if current User is admin
      for (const role of roles) {
        for (const member of tempMembers) {
          if (role.id == member.id) {
            member.adminRole = role.admin;
            member.fanRole = role.fan;
            member.roleStatus = role.status;
            member.uniform = role.uniform;
            member.bat_size = role.bat_size;
            member.bat_details = role.bat_details;
            member.hat_size = role.hat_size;
            member.shirt_size = role.shirt_size;
            member.pant_size = role.pant_size;
          }
          if (
            member.id == this.currentUserId &&
            member.adminRole == "1"
          ) {
            this.currentIsAdmin = "1";
          }
          if (
            member.id == this.currentUserId &&
            member.adminRole == "0"
          ) {
            this.currentIsAdmin = "0";
          }
          if (
            member.id == this.currentUserId &&
            member.fanRole == "1"
          ) {
            this.currentIsFan = "1";
          }
          if (
            member.id == this.currentUserId &&
            member.fanRole != "1"
          ) {
            this.currentIsFan = "0";
          }

          // assign current user team status
          if (
            member.id == this.currentUserId &&
            member.roleStatus == 1
          ) {
            this.currentTeamIsActive = 1;
          }
          if (
            member.id == this.currentUserId &&
            member.roleStatus == 2
          ) {
            this.currentTeamIsActive = 2;
          }
        }
      }

      this.selectedRoomTeam.roomId=this.selectedRoomTeam.id;
      this.teamLoader=0;
    },

    async makeUserArray(users) {
      const roomUserIds = users;

      const rawUsers = [];
      roomUserIds.forEach((userId) => {
        const promise = firestoreService.getUser(userId);
        rawUsers.push(promise);
      });
      this.allUsers = [...this.allUsers, ...(await Promise.all(rawUsers))];
    },

  },
};
</script>

<style scoped>
.inner-sec {
  margin-top: 0px;
  margin-left: 0px;
}
.table thead th {
  min-width: 150px;
}
.sort-icon {
  width: 14px;
  margin-left: 2px;
  padding-bottom: 2px;
  cursor: pointer;
}
.chat-filter-options {
  background: #cce6fd;
  border-radius: 5px;
  height: 35px;
  padding: 0 5px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.eye-icon {
  width: 30px;
  margin-left: 40px;
}
.filter-counter {
  background: #254c72;
  color: #fff;
  width: 16px;
  height: 16px;
  border-radius: 100px;
  margin: 0 5px;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.blocker {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  bottom: 0;
  right: 0;
  content: " ";
  background: rgba(0, 0, 0, 0.5);
}
.pinned-options {
  top: 9%;
  left: inherit;
  margin-right: -13%;
}
sort-icon {
  width: 14px;
  margin-left: 2px;
  padding-bottom: 2px;
  cursor: pointer;
}
.no-game-section {
  height: 150px !important;
}
.form-toggle {
  height: 50px !important;
  width: 100%;
  padding: 10px !important;
  border-radius: 5px;
  outline: none;
  resize: none;
  box-shadow: none;
  transition: none;
  -webkit-transition: none;
}
.form-group select {
  border: 1px solid #ced4da !important;
}
.nested-table {
  border: 1px solid #dee2e6;
  border-radius: 10px; /* Add the desired border color and width */
}
</style>
