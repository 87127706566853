<template>
  <div>
    <Navbar />
    <div class="setting-main-wrapper">
      <div class="main-left-menu">
        <LeftMenu />
      </div>
      <Banner />
      <div class="shop-side pr-3">
        <TaskInput @add-task="addTask" :panel="panel"/>
        <TaskList  @panel-state="handlePanel"/>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/Header/Navbar.vue";
import LeftMenu from "../components/Home/LeftMenu.vue";
import Banner from "../components/BannerNotify/BannerPage.vue";
import TaskInput from "../components/Tasks/TaskInput.vue";
import TaskList from "../components/Tasks/TaskList.vue";
import axios from "axios";
export default {
  name: "Tasks",
  components: {
    Navbar,
    LeftMenu,
    Banner,
    TaskInput,
    TaskList,
  },
  data() {
    return {
      tasks: [], 
      completedTasks: [], 
      panel: false,
      showLoader1: 0,
      
    };
  },
  methods: {
    addTask(newTask) {
      this.tasks.push(newTask); 
    },
    async fetchTasks() {
      try {
        this.showLoader1 = 1;
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}userTasks/getTasks`
        );
        
        this.tasks = response.data.data.incompleteTasks;
        this.completedTasks = response.data.data.completedTasks;
        this.showLoader1 = 0; 
      } catch (error) {
        this.showLoader = 0;
        console.error("Error fetching tasks:", error);
      }
    },
    handleFileUpload(event,task) {
    const file = event.target.files[0]; 
    if (file) {
      const formData = new FormData();
      formData.append("fileURL", file);
      formData.append("task_id", this.taskId); // Add the task_id to associate the file with a task
     
      // Make an API request to upload the file
      axios.post(`${process.env.VUE_APP_API_URL}userTasks/updateTa`, {
          task_id: task.id,
          note: this.note,
        })
      .then(response => {
        console.log('File uploaded successfully:', response.data);
      })
      .catch(error => {
        console.error('Error uploading file:', error);
      });
    }
  },
    handlePanel(panel) {
        this.panel = panel
    }
  },
  mounted() {
    
  },
  
  
};
</script>