<template>
  <div class="filter-container">
    <!-- Year Dropdown -->
    <div class="filter-cotent">
      <div class="dropdown-wrapper" v-click-outside="() => closeDropdown('type')">
        <button class="dropdown-button" :class="{
          'has-value': selectedType
        }" @click="toggleDropdown('type')">
          <span>{{ selectedType || 'Team Type' }}</span>
          <img :src="arrow_down" alt="arrow-down" />
        </button>
        <div class="dropdown-menu-content" v-show="activeDropdown === 'type'">
          <div v-for="type in teamTypes" :key="type" class="dropdown-item" @click="selectType(type)">
            <span>{{ type }}</span>
          </div>
        </div>
      </div>

      <!-- Organization Dropdown (Multi-select) -->
      <div class="dropdown-wrapper" v-click-outside="() => closeDropdown('age')">
        <button class="dropdown-button" :class="{
          'has-value': selectedAges.length > 0
        }" @click="toggleDropdown('age')">
          <span>{{ selectedAges.length >= 1 ? selectedAges[0] : 'Age Groups' }}</span>
          <div class="badge-wrapper" v-if="selectedAges.length > 1">
            <span class="counter-badge">+{{ selectedAges.length - 1 }}</span>
          </div>
          <img :src="arrow_down" alt="arrow-down" />
        </button>
        <div class="dropdown-menu-content" v-show="activeDropdown === 'age'">
          <div v-if="isLoadingArrayAge == true" class="lds-dual-ring-refresh"></div>
          <div v-if="isLoadingArrayAge == false">
            <div class="search-sec">
              <img :src="search_icon" alt="search-icon" class="search-icon" />
              <input type="text" v-model="searchQuery.age" placeholder="Search Group Age" class="search-input" />
            </div>
            <div class="dropdown-menu-content-inner">
              <div v-if="filteredAgeOptions.length === 0 && isLoadingArrayAge == false" class="no-results">No results
                found
              </div>
              <div v-else>
                <div v-for="age in filteredAgeOptions" :key="age.name" class="dropdown-item"
                  :class="{ 'selected': selectedAges.includes(age.name) }" @click="toggleAgeSelection(age)">
                  <input type="checkbox" :checked="selectedAges.includes(age.name)" class="checkbox circular-checkbox">
                  <span> {{ age.name }} </span>
                </div>
              </div>
            </div>
            <!-- Clear Selection Button -->
            <button class="clear-btn" @click.stop="clearSelection('age')" v-if="searchQuery.age || selectedAges.length > 0">
              Clear Selection
            </button>
          </div>
        </div>
      </div>

      <div class="dropdown-wrapper" v-click-outside="() => closeDropdown('gender')">
        <button class="dropdown-button" :class="{
          'has-value': selectedGender
        }" @click="toggleDropdown('gender')">
          <span>{{ selectedGender || 'Gender' }}</span>
          <img :src="arrow_down" alt="arrow-down" />
        </button>
        <div class="dropdown-menu-content" v-show="activeDropdown === 'gender'">
          <div v-for="gender in genders" :key="gender" class="dropdown-item" @click="selectGender(gender)">
            <span>{{ gender }}</span>
          </div>
        </div>
      </div>

    </div>
    <button class="reset-button" @click="resetFilters" v-if="isResetVisible">Reset Filters</button>
  </div>
</template>

<script>
export default {
  name: 'MyTeamsFilter',
  directives: {
    'click-outside': {
      bind(el, binding) {
        el.clickOutsideEvent = function (event) {
          if (!(el === event.target || el.contains(event.target))) {
            binding.value(event);
          }
        };
        document.addEventListener('click', el.clickOutsideEvent);
      },
      unbind(el) {
        document.removeEventListener('click', el.clickOutsideEvent);
      },
    },
  },
  props: {
    ageOptions: {
      type: Array
    },
    isLoadingArrayAge: {
      type: Boolean
    },
  },
  data() {
    return {
      activeDropdown: null,
      selectedAges: [],
      selectedGender: '',
      selectedType: 'Sports',
      ages: ['30 Older', '40 Older', '50 Older'],
      genders: [
        'Male',
        'Female',
        'Coed'
      ],
      teamTypes: [
        'Work',
        'Sports',
        'Family'
      ],
      arrow_down: require("../../../assets/Svg/arrow-down.svg"),
      search_icon: require("../../../assets/Svg/Search.svg"),
      searchQuery: {
        age: '',
      }
    };
  },
  computed: {
    filteredAgeOptions() {
      return this.ageOptions.filter((ageName) =>
        ageName.name.toLowerCase().includes(this.searchQuery.age.toLowerCase())
      );
    },
    isResetVisible() {
      return (
        this.selectedAges.length > 0 ||
        this.selectedGender !== '' ||
        this.selectedType !== 'Sports' // Reset only visible if changed from 'Sports'
      );
    },
  },
  methods: {
    toggleDropdown(dropdownName) {
      this.activeDropdown = this.activeDropdown === dropdownName ? null : dropdownName;
    },
    closeDropdown(dropdownName) {
      if (this.activeDropdown === dropdownName) {
        this.activeDropdown = null;
      }
    },
    toggleAgeSelection(age) {
      const ageName = age.name; // Get age name from object
      const index = this.selectedAges.indexOf(ageName);
      if (index === -1) {
        this.selectedAges.push(ageName);
      } else {
        this.selectedAges.splice(index, 1);
      }
      this.emitFilters();
      // this.closeDropdown('age'); 

    },
    emitFilters() {
      this.$emit('filters-changed', {
        ages: this.selectedAges,
        gender: this.selectedGender,
        type: this.selectedType,
      });
    },
    selectGender(gender) {
      this.selectedGender = gender;
      this.emitFilters();
      this.closeDropdown('gender');
    },
    selectType(type) {
      this.selectedType = type;
      this.emitFilters();
      this.closeDropdown('type');
    },
    resetFilters() {
      this.selectedAges = [],
        this.selectedGender = '';
      this.selectedType = 'Sports'; // Reset to default 'Sports'
      this.emitFilters();
    },
    clearSelection(type) {
      if (type === 'age') {
        this.selectedAges = [];
        this.searchQuery.age = '';
      }
      this.emitFilters();
    },
  },
  mounted() {
    // Emit default filters when component loads
    this.emitFilters();
  },
};
</script>