<template>
  <div class="d-flex justify-content-center align-items-center vh-100 bg-light">
    <div class="card shadow-lg p-4 text-center" style="max-width: 450px; width: 100%">
      <h2 class="card-title text-primary">Payment Confirmation</h2>

      <div v-if="loading" class="mt-3">
        <div class="spinner-border text-primary" role="status"></div>
        <p class="mt-2">Loading...</p>
      </div>

      <div v-else-if="error" class="mt-3 text-danger fw-bold">
        {{ error }}
      </div>

      <div v-else class="mt-3">
        <p class="h5 text-success fw-bold">✅ Plan Activated!</p>

        <p class="mt-2"><strong>Plan:</strong> <span class="text-primary">{{ packageName }}</span></p>
        <!-- 
        <p class="mt-2"><strong>Payment Status:</strong> {{ paymentStatus }}</p>

        <p class="mt-2"><strong>Amount Paid:</strong> {{ amount }} {{ currency }}</p>

        <p class="mt-2"><strong>Email:</strong> {{ decryptedEmail }}</p> -->

        <div class="mt-4">
          <button @click="goToHome" class="btn btn-primary">
            Go to Who i Follow →
          </button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      paymentStatus: null,
      amount: null,
      currency: null,
      decryptedEmail: null,
      packageName: null, // Plan name
      error: null,
      loading: true,
    };
  },
  methods: {
    goToHome() {
      this.$router.push({ name: "Home" }).then(() => {
        window.location.reload();
      });
    }
  },

  async mounted() {
    const sessionId = new URLSearchParams(window.location.search).get("session_id");

    if (!sessionId) {
      this.error = "Invalid session ID.";
      this.loading = false;
      return;
    }

    try {
      const response = await axios.post(`${process.env.VUE_APP_API_URL}getPaymentDetails`, {
        session_id: sessionId,
      });

      if (response.data.success) {
        this.paymentStatus = response.data.payment_status;
        this.amount = response.data.amount_total;
        this.currency = response.data.currency;
        this.decryptedEmail = response.data.email;
        this.packageName = response.data.package_name; // Capture plan name
      } else {
        this.error = "Failed to fetch payment details.";
      }
    } catch (error) {
      console.error("❌ Error:", error);
      this.error = "Something went wrong.";
    } finally {
      this.loading = false;
    }
  },
};
</script>
