<template>
  <div class="btn-ellipsis-div">
    
    <div class="opt mt-0 position-relative cursor-pointer" @click="openGameOption"
        v-click-outside="onClickOutside">
      <img
        src="../../assets/Svg/ellipse.svg"
        alt=""
      />
      <div class="sub-setting-options edit-event-options" v-if="gameOption">
        <ul class="mb-0 border-bottom-0 pb-0 d-block">
          <li class="del-game" @click="callEditModal()">Edit Game</li>
          <li class="del-game"  @click="callModal()">Scoring</li>
          <li class="del-game" @click="callModalLineup()">Lineup</li>
          <li class="del-game"  @click="deleteGame()">Delete Game</li>
        </ul>
      </div>
    </div>
    <div class="dropdown" v-if="singleGame.score_sheet_url != null" @click="openScoreSheetOption" v-click-outside="onClickOutside">
                  <button class="lineup lineup-btn" type="button" id="dropdownMenuButton"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span>Scoresheet</span>
                  </button>
                  <div class="dropdown-menu sub-setting-options score-sheet-options" aria-labelledby="dropdownMenuButton"
                    v-if="singleGame.score_sheet_url != null">
                    <ul class="mb-0">
                      <li class="del-game" @click="viewScoresheet(singleGame.score_sheet_url)">
                        View Scoresheet
                      </li>

                      <li class="del-game" @click="deleteScoresheet()">
                        Delete Scoresheet
                      </li>
                    </ul>
                  </div>
                </div>
  </div>
</template>

<script>

import axios from "axios";
export default {
  name: "GameOptions",
  components: {
    // EditGame,
  },
  data() {
    return {
      gameOption: false,
      eventAlertOptions: [],
      errorMessage: "",
    };
  },
  props:{
    singleGame: {
        type: Object
    },
  
    selectedRoomTeam: {
      type: Object,
    },
  },
  methods: {
    openGameOption(event) {
       event.preventDefault();
      event.stopPropagation();
      this.gameOption = !this.gameOption;
      if (this.gameOption) {
      // Manually close the scoresheet dropdown
      this.closeScoreSheetDropdown();
    }
    },
    openScoreSheetOption(event) {
  event.preventDefault();
  event.stopPropagation();

  // Close game options if open
  if (this.gameOption) {
    this.gameOption = false;
  }

  // Manually toggle Bootstrap dropdown
  this.$nextTick(() => {
    const dropdown = event.currentTarget.querySelector(".dropdown-menu");
    if (dropdown.classList.contains("show")) {
      dropdown.classList.remove("show");
    } else {
      dropdown.classList.add("show");
    }
  });
},
    closeScoreSheetDropdown() {
    this.$nextTick(() => {
      const dropdownMenu = document.querySelector('.dropdown-menu.show');
      if (dropdownMenu) {
        dropdownMenu.classList.remove('show');
      }
    });
  },
    async viewScoresheet(url) {
      try {
        if (url) {
          const fullUrl = `${process.env.VUE_APP_API_URL_STORAGE_PDF}${url}`;
          window.open(fullUrl, "_blank");
        } else {
          console.error("PDF URL not available in the response");
        }
      } catch (error) {
        console.error("Error fetching PDF URL:", error);
      }
    },
    async deleteScoresheet() {
      try {
        let loader = this.$loading.show({
          // Optional parameters
          container: this.fullPage ? null : this.$refs.formContainer,

          color: "#ffffff",
          backgroundColor: "#333",
          opacity: 0.6,
        });
        await axios.get(
          `${process.env.VUE_APP_API_URL}game/deleteGameScoreSheet/${this.singleGame.id}`
        );
        loader.hide();
        this.singleGame.score_sheet_url = null;
        this.$root.$emit("showSingleBoxScore");
      } catch (error) {
        // Handle errors
        console.error("Error deleting scoresheet:", error);
      }
    },
    getEventAlert() {
      axios
        .get(`${process.env.VUE_APP_API_URL}getEventAlert`)
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.eventAlertOptions = response.data.data.alerts;
          } else {
            this.errorMessage = response.data.message;
          }
        });
    },
    onClickOutside() {
      this.gameOption = false;
      this.closeScoreSheetDropdown();
    },
    callEditModal: function() {
      this.$root.$emit("checkUpdateGame", this.singleGame, this.eventAlertOptions);
    },
    callModal() {
      // if (this.singleGame.opponent_flag == null) {
      //   this.$root.$emit("openSelectHomeTeamModal", this.singleGame);
      // } else {
      var combineUrl = this.selectedRoomTeam.id + "-" + this.singleGame.guid;
      const urlToOpen = `/score-game/${combineUrl}`;
      window.location.href = urlToOpen;
      // }
    },
    callModalLineup: function() {
      this.$root.$emit("openTeamLineUpModal", this.singleGame);
    },
    async deleteGame() {
      this.$confirm(
        "About to remove this game. Continue ?",
        "Remove Game."
      ).then(() => {
        var formData = new FormData();
        formData.append("id", this.singleGame.id);

        axios
          .post(process.env.VUE_APP_API_URL + "game/delete", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(() => {
            this.$root.$emit("showGameList");
            this.$root.$emit("showEventPageGames");
            
          });
      });
    },
  },
  mounted() {
    console.log('hehehheh')
    this.getEventAlert();
   
  },
};
</script>