<template>
  <div>
    <Navbar />
    <div class="setting-main-wrapper">
      <div class="main-left-menu">
        <LeftMenu />
      </div>
      <Banner />
      <div class="shop-side p-md-0">
        <div class="shop-left-menu p-0">
          <LeftSearch :openTab="openTab" :searchText="searchTerm" />
        </div>
        <div class="shop-page-products pb-0 mt-3 pl-0">
          <div class="row no-gutters">
            <div class="col-12 col-lg-8 pr-lg-3">
              <All
                v-if="showLoader == 0 && showLoaderPeople == 0"
                :openTab="openTab"
                :searchProfiles="searchProfiles"
                :searchText="searchTerm"
                :posts="searchFetchPost"
                :searchFetchProfile="searchFetchProfile"
              />

              <!-- Tabs loader -->
              <content-placeholders
                class="post-view"
                :rounded="true"
                v-if="showLoaderPeople == 1"
              >
                <content-placeholders-heading :img="true" />
              </content-placeholders>
              <!-- <br v-if="showLoaderPeople == 1" /> -->
              <content-placeholders
                class="post-view"
                :rounded="true"
                v-if="showLoaderPeople == 1"
              >
                <content-placeholders-heading :img="true" />
              </content-placeholders>
              <!-- <br v-if="showLoaderPeople == 1" /> -->
              <content-placeholders
                class="post-view"
                :rounded="true"
                v-if="showLoaderPeople == 1"
              >
                <content-placeholders-heading :img="true" />
              </content-placeholders>
              <!-- <br v-if="showLoaderPeople == 1" /> -->
              <content-placeholders
                class="post-view"
                :rounded="true"
                v-if="showLoaderPeople == 1"
              >
                <content-placeholders-heading :img="true" />
              </content-placeholders>
              <!-- <br v-if="showLoaderPeople == 1" /> -->

              <!--  Posts  -->
              <div
                v-if="
                  (openTab == 1 || openTab == 3) &&
                  showLoaderPeople == 0 &&
                  showLoader == 0
                "
              >
                <post
                  v-for="post in searchFetchPost"
                  :key="post.id"
                  :posts="post"
                  :openTab="openTab"
                />
              </div>
              <div
                class="see-all-seen-post-wrapper"
                v-if="
                  searchFetchPost.length == 0 &&
                  (openTab == 1 || openTab == 3) &&
                  showLoaderPeople == 0 &&
                  showLoader == 0
                "
              >
                <p>No posts found.</p>
              </div>

              <!-- ------------------------------------------------- -->
              <content-placeholders
                class="post-view"
                :rounded="true"
                v-if="showLoader == 1"
              >
                <content-placeholders-heading :img="true" />
                <content-placeholders-text :lines="3" />
              </content-placeholders>
              <!-- <br v-if="showLoader == 1" /> -->
              <content-placeholders
                class="post-view"
                :rounded="true"
                v-if="showLoader == 1"
              >
                <content-placeholders-heading :img="true" />
                <content-placeholders-text :lines="3" />
              </content-placeholders>
              <!-- <br v-if="showLoader == 1" /> -->
              <content-placeholders
                class="post-view"
                :rounded="true"
                v-if="showLoader == 1"
              >
                <content-placeholders-heading :img="true" />
                <content-placeholders-text :lines="3" />
              </content-placeholders>

              <!-- ------------------------------------------------- -->
              <content-placeholders
                class="post-view"
                :rounded="true"
                v-if="showLoaderPost == 1"
              >
                <content-placeholders-heading :img="true" />
                <content-placeholders-text :lines="3" />
              </content-placeholders>
              <!-- <br v-if="showLoaderPost == 1" /> -->
              <content-placeholders
                class="post-view"
                :rounded="true"
                v-if="showLoaderPost == 1"
              >
                <content-placeholders-heading :img="true" />
                <content-placeholders-text :lines="3" />
              </content-placeholders>
            </div>

            <div class="col-12 col-lg-4 d-none"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Navbar from "../components/Header/Navbar.vue";
import LeftMenu from "../components/Home/LeftMenu.vue";
import LeftSearch from "../components/Search/LeftSearch.vue";
import All from "../components/Search/SearchRight/All.vue";
import axios from "axios";
import Post from "../components/Search/SearchRight/Post.vue";
import Banner from "../components/BannerNotify/BannerPage.vue";

export default {
  name: "Search",
  components: {
    Navbar,
    LeftMenu,
    LeftSearch,
    All,
    Post,
    Banner
  },
  data() {
    return {
      openTab: 1,
      searchTerm: "",
      page: 1,
      active: 1,
      showLoader: 1,
      showLoaderPost: 0,
      showLoaderPeople: 0,
      searchFetchPost: [],
      searchProfiles: [],
      searchFetchProfile: [],
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.getSearchValue();
    this.fetchSearch();
    this.$root.$on("handleScrolledToButtomSearch", () => {
      this.handleScrolledToButtom();
    });
    this.$root.$on("openTabSearchSelection", (id, profileLoad, postLoad) => {
      this.page = 1;
      this.searchFetchPost = [];
      this.searchProfiles = [];
      this.searchFetchProfile = [];
      this.openTab = id;

      if (profileLoad == 1) {
        this.showLoaderPeople = profileLoad;
        this.showLoaderPost = 0;
        this.showLoader = 0;
        this.active = 0;
      } else if (postLoad == 1) {
        this.active = 1;
      } else {
        this.active = 1;
      }

      if (id == 1) {
        this.active = 1;
        this.showLoader = 1;
        this.showLoaderPost = 0;
        this.showLoaderPeople = 0;
      }
      if (id == 2) {
        this.active = 1;
      }
      this.fetchSearch();
    });
  },
  methods: {
    getSearchValue() {
      var linkUrl = window.location.pathname;
      var segment_array = linkUrl.split("/");
      this.searchTerm = segment_array.pop();
      this.searchTerm = this.searchTerm.replace("2022", " ");
      this.$root.$emit("navBarSearchText", this.searchTerm);
    },
    async fetchSearch() {
      if (this.openTab == 1 || this.openTab == 2) {
        if (this.active == 1 || this.active == 0) {
          this.showLoaderPeople = 1;
        } else {
          this.showLoaderPost = 1;
        }
      }
      if (this.openTab == 3 || this.openTab == 4 || this.openTab == 1) {
        if (this.active == 1 || this.active == 0) {
          this.showLoader = 1;
        } else {
          this.showLoaderPost = 1;
        }
      }

      let searchResults = await axios.get(
        `search?search=${this.searchTerm}&page=${this.page}`
      );
      if (searchResults.data.statusCode == 200) {
        this.searchFetchPost.push(...searchResults.data.data.posts.data);
        this.searchProfiles = searchResults.data.data.profiles;
        console.log("searchProfiles", this.searchProfiles)
        this.searchFetchProfile.push(
          ...searchResults.data.data.allprofiles.data
        );

        if (this.openTab == 1 || this.openTab == 3) {
          this.lastPage = searchResults.data.data.posts.last_page;
        } else {
          this.lastPage = searchResults.data.data.allprofiles.last_page;
        }
        this.noPost = searchResults.data.data.posts.data.length > 0 ? 0 : 1;
        this.active = 2;
        this.showLoaderPeople = 0;
        this.showLoader = 0;
        this.showLoaderPost = 0;
      } else {
        this.active = 2;
        this.errorMessage = searchResults.data.message;
        this.showLoaderPeople = 0;
        this.showLoader = 0;
        this.showLoaderPost = 0;
      }
    },
    handleScrolledToButtom() {
      if (this.page >= this.lastPage) {
        return;
      }
      this.page++;
      this.fetchSearch();
    },
  },
};
</script>
<style>
.navbar-expand-lg .navbar-nav .nav-link {
  padding-bottom: 20px;
}
.search-wrapper {
  background-color: #e5f2fe;
  padding-top: 85px;
  position: relative;
}
.left-side {
  position: fixed;
}
.right-side {
  position: absolute;
  background-color: #e5f2fe;
  left: 24%;
}
</style>
