<template>
  <div>
    <GameListModal />
    <div class="single-box-score-wrap">

      <div class="single-box-score-right-side">
        <div class="order-history-right-side mt-3 mt-md-0 p-0">
          <RightSide :currentIsAdmin="currentIsAdmin" :gameFetchedright="singleGame"
            :selectedRoomTeam="selectedRoomTeam" @passData="GetData($event)" @passHrData="GetHrData($event)"
            :totalInnings="totalInnings" :currentBattingTeam="currentBattingTeam" :currentTeamType="currentTeamType"
            :showShimmer="showShimmer" :homeTeamName="homeTeamName" :visitTeamName="visitTeamName"
            :currentInningHomeHR="currentInningHomeHRArray" :currentInningHomeScore="currentInningHomeScoreArray"
            :currentInningVisitHR="currentInningVisitHRArray" :currentInningVisitScore="currentInningVisitScoreArray"
            :scores="scores" :configDataFromChild="configDataFromChild" :fetchConfig="fetchConfig"
            :gameLineUps="gameLineUps" />
        </div>

        <content-placeholders :rounded="true" v-if="showShimmer == 1" class="mt-3">
          <content-placeholders-text :lines="6" />
        </content-placeholders>
        <div class="inning-sec" v-if="showShimmer == 0">
          <div class="teams-info-sec">
            <table class="table mb-0">
              <thead>
                <tr>
                  <th class="invisible">#</th>
                </tr>
              </thead>
              <tbody>
                <tr class="text-left">
                  <td scope="col" class="team-data-sec" v-if="this.scores.length != 0">
                    <div
                        class="opponent-name"
                        v-if="
                          this.scores?.some((team) => team.team_type === 1)
                        "
                      >
                        <div
                          class="custom-avatar"
                          v-if="
                            this.scores?.find(
                              (team) =>
                                team.team_type === 1 &&
                                team.team_name !== selectedRoomTeam.teamName
                            )
                          "
                          :style="{ backgroundColor: getRandomColor() }"
                        >
                          {{
                            getInitials(
                              this.scores?.find(
                                (team) => team.team_type === 1
                              )?.team_name
                            )
                          }}
                        </div>
                        <div class="team-image" v-else>
                          <div class="users-notification" v-if="imgLoader == 1">
                            <img src="../../assets/loader.gif" alt="" />
                          </div>
                          <img
                            @load="onImageLoad"
                            v-if="selectedRoomTeam.teamAvatar == ''"
                            :src="team_image"
                            alt=""
                          />
                          <img
                            @load="onImageLoad"
                            v-if="
                              selectedRoomTeam.teamAvatar != '' &&
                              this.scores?.find(
                                (team) =>
                                  team.team_name === selectedRoomTeam.teamName
                              )
                            "
                            :src="url + selectedRoomTeam.teamAvatar"
                            alt=""
                          />
                        </div>
                        <h6>
                          {{
                            this.scores?.find(
                              (team) => team.team_type === 1
                            )?.team_name
                          }}
                        </h6>
                      </div>

                      <div
                        class="opponent-name"
                        v-if="
                          this.scores?.some((team) => team.team_type === 2)
                        "
                      >
                        <div
                          class="custom-avatar"
                          v-if="
                            this.scores?.find(
                              (team) =>
                                team.team_type === 2 &&
                                team.team_name !== selectedRoomTeam.teamName
                            )
                          "
                          :style="{ backgroundColor: getRandomColor() }"
                        >
                          {{
                            getInitials(
                              this.scores?.find(
                                (team) => team.team_type === 2
                              )?.team_name
                            )
                          }}
                        </div>
                        <div class="team-image" v-else>
                          <div class="users-notification" v-if="imgLoader == 1">
                            <img src="../../assets/loader.gif" alt="" />
                          </div>
                          <img
                            @load="onImageLoad"
                            v-if="selectedRoomTeam.teamAvatar == ''"
                            :src="team_image"
                            alt=""
                          />
                          <img
                            @load="onImageLoad"
                            v-if="
                              selectedRoomTeam.teamAvatar != '' &&
                              this.scores?.find(
                                (team) =>
                                  team.team_name === selectedRoomTeam.teamName
                              )
                            "
                            :src="url + selectedRoomTeam.teamAvatar"
                            alt=""
                          />
                        </div>
                        <h6>
                          {{
                            this.scores?.find(
                              (team) => team.team_type === 2
                            )?.team_name
                          }}
                        </h6>
                      </div>
                   
                  </td>
                  
                </tr>
                
              </tbody>
            </table>
          </div>
          <div class="inning-info-sec">
            <table class="table table-float-right" v-if="this.scores.length != 0 && totalInnings > 1">
              <thead>
                <tr>
                  <th class="text-center" scope="col" v-for="n in totalInnings - 1" :key="n">
                    {{ n }}
                  </th>
                  <th class="text-center" scope="col">{{ totalInnings }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center" v-for="inning in previousInningVisitScore" :key="inning.id + 1000">
                    <div class="cursor-pointer" @click="
                      callPeviousScoreUpdateModal(
                        inning.inning_no,
                        inning.game_score_id,
                        inning.game_id
                      )
                      ">
                      {{ inning.score == null ? "0" : inning.score }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div :class="currentTeamType == 1 ? 'span' : ''">
                      <div v-if="currentTeamType === 1">
                        {{ currentInningVisitScore }}
                      </div>
                      <div v-else class="cursor-pointer" @click="
                        callPeviousScoreUpdateModal(
                          currentInningVisitScoreArray.inning_no,
                          currentInningVisitScoreArray.game_score_id,
                          currentInningVisitScoreArray.game_id
                        )
                        ">
                        {{ currentInningVisitScore }}
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="text-center" v-for="inning in previousInningHomeScore" :key="inning.id + 2000">
                    <div class="cursor-pointer" @click="
                      callPeviousScoreUpdateModal(
                        inning.inning_no,
                        inning.game_score_id,
                        inning.game_id
                      )
                      ">
                      {{ inning.score == null ? "0" : inning.score }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div :class="currentTeamType == 2 ? 'span' : ''">
                      <div>{{ currentInningHomeScore }}</div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="table table-float-right" v-if="this.scores.length != 0 && totalInnings == 1">
              <thead>
                <tr>
                  <th scope="col" class="text-center">1</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center">
                    <div :class="currentTeamType == 1 ? 'span' : ''">
                      <div v-if="currentTeamType === 1">
                        {{ currentInningVisitScore }}
                      </div>
                      <div v-else class="cursor-pointer" @click="
                        callPeviousScoreUpdateModal(
                          currentInningVisitScoreArray.inning_no,
                          currentInningVisitScoreArray.game_score_id,
                          currentInningVisitScoreArray.game_id
                        )
                        ">
                        {{ currentInningVisitScore }}
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="text-center">
                    <div :class="currentTeamType == 2 ? 'span' : ''">
                      <div>{{ currentInningHomeScore }}</div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="inning-score">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col" class="text-center">Runs</th>
                  <th scope="col" class="text-center">HR</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td scope="col" class="text-center">
                    {{ totalVisitScore }}
                  </td>
                  <td scope="col" class="text-center">{{ totalVisitHR }}</td>
                </tr>
                <tr>
                  <td scope="col" class="text-center">
                    {{ totalHomeScore }}
                  </td>
                  <td scope="col" class="text-center">{{ totalHomeHR }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="lineup-top-sec">
          <TeamSelect :homeTeamName="homeTeamName" :visitTeamName="visitTeamName"
            :selectedRoomTeam="selectedRoomTeam" />
          <div class="team-actions-line-up-wrapper">
            <!-- <TeamActions /> -->
            <div class="d-flex justify-content-md-end" v-if="currentIsAdmin == '1'">
                <button class="lineup" @click="openFileUploadModal(singleGame)"
                  v-if="singleGame.score_sheet_url == null">
                  Scoresheet
                </button>
                <div class="dropdown" v-else>
                  <button class="lineup" type="button" id="dropdownMenuButton"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span>Scoresheet</span>
                  </button>
                  <div class="dropdown-menu sub-setting-options" aria-labelledby="dropdownMenuButton"
                    v-if="singleGame.score_sheet_url != null">
                    <ul class="mb-0">
                      <li class="del-game" @click="viewScoresheet(singleGame.score_sheet_url)">
                        View Scoresheet
                      </li>

                      <li class="del-game" @click="deleteScoresheet()">
                        Delete Scoresheet
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            <TeamLineUp v-if="currentIsAdmin == '1'" :singleGame="singleGame" :selectedRoomTeam="selectedRoomTeam" />
          </div>
        </div>
        <TeamsHistory :currentIsAdmin="currentIsAdmin" :singleGame="singleGame" :selectedRoomTeam="selectedRoomTeam" />
      </div>

      <div class="single-box-score-left-side">
        <div class="single-score-table single-box-score-wrapper">
          <div class="sec-top">
            <div class="left-part" v-if="showShimmer == 0">
              <div class="left-part-top">
                <h4>Details</h4>
                <div class="d-flex align-items-center justify-content-between" style="gap: 5px;">
                  <div class="game-details">
                    <NewGame
                      v-if="
                        currentIsAdmin == '1' &&
                        selectedRoomTeam.teamType == 'sports'
                      "
                      :selectedRoomTeam ="selectedRoomTeam"
                      :singleGame ="singleGame"
                    />
                  </div>
                  <div class="opt position-relative cursor-pointer m-0" v-if="currentIsAdmin == '1'">
                    <img src="../../assets/images/ellipsis-h.png" alt="" @click="openGameOptions"
                      v-click-outside="onClickOutside" />
                    <div class="sub-setting-options" v-if="gameOptions">
                      <ul class="mb-0">
                        <li style="border-bottom: 1px solid #CDCDD0;" class="del-game" data-toggle="modal" data-target="#exampleModalEdit"
                          @click="checkUpdateBoxScore">
                          Edit Game
                        </li>

                        <li class="del-game" @click="deleteGame()">Delete Game</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="game-info-section">
                <div class="field-data" v-if="singleGame.umpire_name">
                  <img src="../../assets/Svg/security-user.svg" alt="">
                  <p>
                    <span>Umpire:</span>
                    <span v-if="singleGame.umpire_name">{{ singleGame.umpire_name }}
                    </span>
                    <span v-else>-</span>
                  </p>
                </div>
                <div class="field-data">
                  <img src="../../assets/Svg/maximize.svg" alt="">
                  <p>
                    <span>Field:</span>
                    <span v-if="singleGame.park_name && singleGame.field">
                      {{ singleGame.field ? singleGame.field.field_name : "" }} - {{ singleGame.park_name }}
                    </span>
                  </p>
                </div>
                <div class="field-data">
                  <img src="../../assets/Svg/calendar.svg" />
                  <p>
                    <span>Scheduled:</span>
                    <span>{{ formattedDate }}
                    </span>
                  </p>
                </div>
                <div class="field-data">
                  <img src="../../assets/Svg/clock.svg" />
                  <p>
                    <span>Actual Start Time:</span>
                    <span v-if="singleGame.actual_start_time">{{ singleGame.actual_start_time | formatTimeToAM }}
                    </span>
                    <span v-else>-</span>
                  </p>
                </div>
                <div class="field-data">
                  <img src="../../assets/Svg/timer-start-outline.svg" />
                  <p>
                    <span>Time Limit:</span>
                    <span v-if="singleGame.time_limit">{{ singleGame.time_limit }} minutes
                    </span>
                    <span v-else>-</span>
                  </p>
                </div>
              </div>
            </div>
            <!-- <div class="right-part" v-if="showShimmer == 0">
              <router-link :to="{ name: 'SingleEventDetail', params: { id: singleGame.event.id } }">
                <h4 class="text-capitalize text-md-right cursor-pointer text-primary">
                  {{ singleGame.event.eventName }}
                </h4>
              </router-link>
              <p class="mt-2 text-md-right text-capitalize">
                {{ visitTeamName }} <span class="text-lowercase" v-if="visitTeamName && homeTeamName">vs</span>
                {{ homeTeamName }}
              </p> -->
              
            <!-- </div> -->
          </div>


          <div class="field-configuration">
            <div class="configuration-top d-flex align-items-center justify-content-between flex-wrap mb-3">
              <h4>Field Configuration</h4>
              <div class="lineup" @click="callModalFields()" v-if="currentIsAdmin == '1'">
                <small>Change Configuration</small>
              </div>
            </div>
            <div class="text-center" :class="{ 'd-none': canvaLoader == 0 }">
              <canvas ref="configCanvas" width="458" height="360"
                v-if="this.scores.length != 0"></canvas>
            </div>
            <div class="game-ground" v-if="this.scores.length != 0" :class="{ 'd-none': canvaLoader == 0 }">
              <img ref="groundImage" :src="require('../../assets/images/ground.jpg')" alt="" @load="loadGroundImage"
                style="display: none" />
            </div>
            <div class="no-event-wrap" v-else :class="{ 'd-none': canvaLoader == 0 }">
              <img src="../../assets/Svg/maximize-grey.svg" alt="" />
              <p class="header">No Field Configuration</p>
              <p class="info">No field associated with this game. Field configuration will appear here once it is
                configured. </p>
            </div>

            <content-placeholders :rounded="true" v-if="canvaLoader == 0">
              <content-placeholders-text :lines="6" />
            </content-placeholders>
          </div>
        </div>
      </div>

      <!-- Update Score Modal -->
      <div data-target="#AddScoresModal" data-toggle="modal" ref="openAddScores" @click="openUpdateScoreModal()"></div>
      <div class="modal fade" id="AddScoresModal" tabindex="-1" aria-labelledby="AddScoresModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="AddScoresModalLabel">
                Update Scores
              </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" ref="closeAddScoresModal">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="game-name">
                <span style="font-size: 15px">
                  {{ updateScoreTeamNameFlag }}
                  <b>({{
                    updateScoreTeamTypeFlag == 1
                      ? "Visiting Team"
                      : "Home Team"
                  }})</b>
                </span>
              </div>
              <div class="game-name">
                <span style="font-size: 15px" >
                  Inning<b> ({{ updateScoreInningNoFlag }})</b>
                </span>
              </div>
              <br />

              <form @keyup.enter="savePreviousScore(0)" @submit.prevent>
                <div class="form-group" id="fieldset-1" label-for="score">
                  <input id="updateScoreRunFlag" autocomplete="off" type="text" v-model="updateScoreRunFlag"
                    maxlength="2" required @keyup="errorNull()" @keypress="validateNumber" @input="handleInput" />

                  <span class="placeholder">Enter Runs</span>
                </div>
                <div class="form-group" id="fieldset-1" label-for="hr">
                  <input id="updateScoreHRFlag" autocomplete="off" type="text" v-model="updateScoreHRFlag" maxlength="2"
                    required @keyup="errorNull()" @keypress="validateNumber" @input="handleInput" />

                  <span class="placeholder">Enter HR</span>
                </div>
              </form>
            </div>
            <div class="modal-footer sign-in">
              <b-button class="auth-btn font-weight-normal text-white" style="width: 100px"
                v-if="isDisabledUpdateScore == true" :disabled="true">
                <span>Save </span>
              </b-button>
              <b-button v-else class="auth-btn font-weight-normal text-white" style="width: 100px"
                :disabled="isLoadingArrayScore[0]" @click="savePreviousScore(0)">
                <div v-if="isLoadingArrayScore[0]" class="lds-dual-ring"></div>
                <span v-else> Save </span>
              </b-button>
            </div>
          </div>
        </div>
      </div>

      <!-- Update Score Modal -->
    </div>
    <UploadSheetModal />
    <AddConfiguration :selectedRoomTeam="selectedRoomTeam" :singleGame="singleGame"
      @config-updated="updateConfigData" />
    <EditGame :singleGame="singleGame" />
  </div>
</template>

<script>
import axios from "axios";
import EditGame from "../../components/Teams/Games/EditGame.vue";
import RightSide from "./RightSide.vue";
import TeamsHistory from "./SubLeft/TeamsHistory.vue";
import TeamSelect from "./SubLeft/TeamSelect.vue";
// import TeamActions from "./SubLeft/TeamActions.vue";
import TeamLineUp from "./SubLeft/TeamLineUp.vue";
import AddConfiguration from "./SubLeft/AddConfiguration.vue";
import UploadSheetModal from "../../components/Teams/Games/UploadSheetModal.vue";
import GameListModal from "./SubLeft/GameListModal.vue";
import NewGame from "../Teams/Games/NewGame.vue";

import { EventBus } from "@/eventBus";
export default {
  name: "LeftSide",
  data() {
    return {
      gameOptions: false,
      currentRuns: 0,
      hr: 0,
      gameScores: [],
      toggleButton: false,
      previousInningVisitScore: [],
      previousInningVisitHR: [],
      previousInningHomeScore: [],
      previousInningHomeHR: [],
      currentInningHomeHRArray: {},
      currentInningHomeScoreArray: {},
      currentInningVisitHRArray: {},
      currentInningVisitScoreArray: {},
      currentInningVisitScore: 0,
      currentInningVisitHR: 0,
      currentInningHomeScore: 0,
      currentInningHomeHR: 0,
      homeTeamName: "",
      visitTeamName: "",
      totalHomeHR: 0,
      totalVisitHR: 0,
      totalHomeScore: 0,
      totalVisitScore: 0,
      totalInnings: 1,
      currentBattingTeam: "",
      currentTeamType: 0,
      updateScoreRunFlag: 0,
      updateScoreHRFlag: 0,
      updateScoreInningNoFlag: 0,
      updateScoreGameIdFlag: 0,
      updateScoreGameScoreIdFlag: 0,
      updateScoreTeamNameFlag: "",
      updateScoreTeamTypeFlag: 0,
      isLoadingArrayScore: [],
      isDisabledUpdateScore: true,
      gameList: {},
      configDataFromChild: [],
      fetchConfig: [],
      gameLineUps: [],
      canvaLoader: 0,
      isGroundImageLoaded: false,
      unUsualdata: {},
      canvasWidth: 458, // Default width
      canvasHeight: 360, // Default height
      team_image: require("../../assets/Svg/people.svg"),
      imgLoader: 1,
      url: this.$groupAvatarStorageURL,
      eventAlertOptions: [],
      errorMessage: "",
    };
  },
  props: {
    currentIsAdmin: {
      type: String,
    },
    singleGame: {
      type: Object,
    },
    selectedRoomTeam: {
      type: Object,
    },
    showShimmer: {
      type: Number,
    },
    scores: {
      type: Array,
    },
  },

  components: {
    RightSide,
    GameListModal,
    EditGame,
    TeamsHistory,
    TeamSelect,
    // TeamActions,
    TeamLineUp,
    AddConfiguration,
    UploadSheetModal,
    NewGame
  },

  computed: {
    watchGameLineUps: function () {
      // This computed property will be automatically re-evaluated whenever gameLineUps changes
      return this.gameLineUps;
    },
    formattedDate() {
      if (!this.singleGame || !this.singleGame.exactDateFormat || !this.singleGame.start_date) return "";

      // Extract date from start_date (YYYY-MM-DD)
      const baseDate = new Date(this.singleGame.start_date);
      if (isNaN(baseDate)) return this.singleGame.exactDateFormat; // Fallback if start_date is invalid

      // Extract time from exactDateFormat (e.g., "Mar 08, 10:00 AM")
      const timePart = this.singleGame.exactDateFormat.match(/(\d{1,2}):(\d{2})\s*(AM|PM)/i);
      if (!timePart) return this.singleGame.exactDateFormat; // Fallback if time format is incorrect

      const [hours, minutes, period] = timePart.slice(1); // Extract only needed values
      let hour = parseInt(hours, 10);
      const minute = parseInt(minutes, 10);

      // Convert 12-hour format to 24-hour format
      if (period.toUpperCase() === "PM" && hour !== 12) {
        hour += 12;
      } else if (period.toUpperCase() === "AM" && hour === 12) {
        hour = 0;
      }

      // Set the correct date and time
      baseDate.setHours(hour, minute, 0, 0);

      // Format with weekday
      return new Intl.DateTimeFormat("en-US", {
        weekday: "short",
        month: "short",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      }).format(baseDate);
    }
  },

  watch: {
    singleGame(newValue) {
      this.scoringCalculation(newValue.game_scores);
    },
    "configDataFromChild.configField"(newConfigField) {
      console.log("configField changed:", newConfigField);
    },
    "fetchConfigDataFromChild.configField"(newConfigField) {
      console.log("configField fetched:", newConfigField);
    },
    configDataFromChild: "plotConfigData",
    fetchConfig: "plotConfigData",
    watchGameLineUps: function (newGameLineUps, oldGameLineUps) {
      this.unUsualdata = newGameLineUps;
      this.unUsualdata = oldGameLineUps;
      this.plotConfigData();
    },
    gameLineUps: {
      handler: function (newGameLineUps, oldGameLineUps) {
        this.unUsualdata = newGameLineUps;
        this.unUsualdata = oldGameLineUps;
        this.canvaLoader = 1;
      },
      deep: true
    }
  },

  methods: {
    onImageLoad() {
      this.imgLoader = 0;
    },
    getInitials(name) {
      return name ? name.charAt(0).toUpperCase() : "?";
    },
    getRandomColor() {
      const colors = [
        "#FCE4EC",
        "#E3F2FD",
        "#F1F8E9",
        "#FFF8E1",
        "#EDE7F6",
        "#FFEBEE",
        "#F9FBE7",
        "#FFF3E0",
        "#ECEFF1",
        "#FFECB3",
      ];
      return colors[Math.floor(Math.random() * colors.length)];
    },
    resizeCanvas() {
      this.$nextTick(() => {
        const parent = this.$refs.configCanvas?.parentElement;
        console.log(parent)
        if (parent) {
          this.canvasWidth = parent.clientWidth;
          this.canvasHeight = parent.clientHeight;
        }
      });
    },
    
    getEvent(singleGame) {
      this.$router.push({
        name: "SingleEventDetail",
        params: { id: singleGame.event.id },
      });
    },
    updateConfigData(config) {
      console.log(config)
      this.configDataFromChild = config;
      console.log(this.configDataFromChild)
    },
    callModalFields: function () {
      this.$root.$emit("AddConfiguration", this.singleGame);
    },
    openGameModal: function () {
      this.$root.$emit("openGamesDataModal", this.singleGame);
    },
    validateNumber(event) {
      const char = String.fromCharCode(event.keyCode);
      if (!/^\d+$/.test(char)) {
        event.preventDefault();
      }
    },
    handleInput(event) {
      this[event.target.id] = event.target.value.replace(/\D/g, '');
    },
    openUpdateScoreModal() {
      this.errorNull();
    },
    errorNull: function () {
      if (this.updateScoreRunFlag !== "" && this.updateScoreHRFlag !== "") {
        this.isDisabledUpdateScore = false;
      } else {
        this.isDisabledUpdateScore = true;
      }
    },
    // isNumber: function() {
    //   if (
    //     /^[0-9]\d*$/.test(this.updateScoreHRFlag) &&
    //     /^[0-9]\d*$/.test(this.updateScoreRunFlag)
    //   ) {
    //     this.isDisabledUpdateScore = false;
    //   } else {
    //     this.isDisabledUpdateScore = true;
    //   }
    // },
    openGameOptions() {
      this.gameOptions = !this.gameOptions;
    },
    onClickOutside() {
      this.gameOptions = false;
    },

    GetData(data) {
      if (this.currentTeamType == 1) {
        this.totalVisitScore =
          this.totalVisitScore - this.currentInningVisitScore;
        this.currentInningVisitScore = data;
        this.totalVisitScore =
          this.totalVisitScore + this.currentInningVisitScore;
      } else {
        this.totalHomeScore = this.totalHomeScore - this.currentInningHomeScore;
        this.currentInningHomeScore = data;
        this.totalHomeScore = this.totalHomeScore + this.currentInningHomeScore;
      }
      console.log("runs", this.totalHomeScore, this.totalVisitScore)

      
    },
    GetHrData(data) {
      if (this.currentTeamType == 1) {
        this.totalVisitHR = this.totalVisitHR - this.currentInningVisitHR;
        this.currentInningVisitHR = data;
        this.totalVisitHR = this.totalVisitHR + this.currentInningVisitHR;
      } else {
        this.totalHomeHR = this.totalHomeHR - this.currentInningHomeHR;
        this.currentInningHomeHR = data;
        this.totalHomeHR = this.totalHomeHR + this.currentInningHomeHR;
      }
    },

    openFileInput() {
      this.$refs.scoresheetInput.click();
    },

    async viewScoresheet(url) {
      try {
        if (url) {
          const fullUrl = `${process.env.VUE_APP_API_URL_STORAGE_PDF}${url}`;
          window.open(fullUrl, "_blank");
        } else {
          console.error("PDF URL not available in the response");
        }
      } catch (error) {
        console.error("Error fetching PDF URL:", error);
      }
    },
    async deleteScoresheet() {
      try {
        let loader = this.$loading.show({
          // Optional parameters
          container: this.fullPage ? null : this.$refs.formContainer,

          color: "#ffffff",
          backgroundColor: "#333",
          opacity: 0.6,
        });
        await axios.get(
          `${process.env.VUE_APP_API_URL}game/deleteGameScoreSheet/${this.singleGame.id}`
        );
        loader.hide();
        this.$root.$emit("showSingleBoxScore");
      } catch (error) {
        // Handle errors
        console.error("Error deleting scoresheet:", error);
      }
    },

    checkUpdateBoxScore() {
      this.$root.$emit("checkUpdateGame", this.singleGame, this.eventAlertOptions);
    },
    openFileUploadModal(singleGame) {
      this.$root.$emit("opneUploadExcelModal", singleGame);
    },

    openGoogleMapforGamePark(item) {
      const strlatLng = item["park_lat"] + "," + item["park_lng"];
      const urlSuffix = strlatLng ? strlatLng : item["park_address"];

      window.open(
        "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
        "_blank"
      );
    },

    async deleteGame() {
      this.$confirm(
        "About to remove this game. Continue ?",
        "Remove Game."
      ).then(() => {
        var formData = new FormData();
        formData.append("id", this.singleGame.id);

        axios
          .post(process.env.VUE_APP_API_URL + "game/delete", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(() => {
            const eventId = this.singleGame.event.id;
            this.$router.push({ name: 'SingleEventDetail', params: { id: eventId } });
          });
      });
    },

    callPeviousScoreUpdateModal(inning_no, game_score_id, game_id) {
      this.isDisabledUpdateScore = true;
      this.updateScoreGameIdFlag = game_id;
      this.updateScoreInningNoFlag = inning_no;
      this.updateScoreGameScoreIdFlag = game_score_id;

      this.gameScores.forEach((gameS) => {
        if (gameS.id == game_score_id) {
          this.updateScoreTeamNameFlag = gameS.team_name;
          this.updateScoreTeamTypeFlag = gameS.team_type;
          gameS.game_innings.forEach((inning) => {
            // Previous Inning Score
            if (inning.inning_no == inning_no) {
              if (inning.inning_type == 1) {
                this.updateScoreHRFlag = inning.score;
              }
              if (inning.inning_type == 2) {
                this.updateScoreRunFlag = inning.score;
              }
            }
          });
        }
      });

      if (this.singleGame.status != 2 && this.currentIsAdmin == "1") {
        var elem = this.$refs.openAddScores;
        if (elem) {
          elem.click();
        }
      }
    },

    async savePreviousScore(index) {
      if (this.isDisabledUpdateScore != true) {
        this.$set(this.isLoadingArrayScore, index, true);
        var formData = new FormData();
        formData.append("game_id", this.updateScoreGameIdFlag);
        formData.append("inning_no", this.updateScoreInningNoFlag);
        formData.append("game_score_id", this.updateScoreGameScoreIdFlag);
        formData.append("run_score", this.updateScoreRunFlag);
        formData.append("hr_score", this.updateScoreHRFlag);
        await axios
          .post(
            process.env.VUE_APP_API_URL + "game/updatePreviousScore",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            if (response.data.statusCode == 404) {
              alert(response.data.message);
            }
            this.$set(this.isLoadingArrayScore, 0, false);
            var elem = this.$refs.closeAddScoresModal;
            if (elem) {
              elem.click();
            }
            this.$root.$emit("showSingleBoxScore");
          });
      }
    },

    async scoringCalculation(gameScores) {
      this.gameScores = [];
      this.totalInnings = 1;
      this.previousInningHomeScore = [];
      this.previousInningHomeHR = [];
      this.currentInningHomeHRArray = [];
      this.currentInningHomeScoreArray = [];
      this.totalHomeScore = 0;
      this.totalHomeHR = 0;
      this.currentInningHomeScore = 0;
      this.currentInningHomeHR = 0;
      this.homeScoreId = 0;
      this.homeHRId = 0;

      this.previousInningVisitScore = [];
      this.previousInningVisitHR = [];
      this.currentInningVisitHRArray = [];
      this.currentInningVisitScoreArray = [];
      this.totalVisitScore = 0;
      this.totalVisitHR = 0;
      this.currentInningVisitScore = 0;
      this.currentInningVisitHR = 0;
      this.visitScoreId = 0;
      this.visitHRId = 0;
      this.gameScores = gameScores;

      if (this.gameScores.length > 0) {
        const inning = this.gameScores[0].game_innings.length;
        this.totalInnings = inning / 2;

        // Visiting
        this.gameScores.forEach((gameS) => {
          if (gameS.team_type == 1) {
            this.visitTeamName = "";
            this.visitTeamName = gameS.team_name;
            this.currentBattingTeam =
              gameS.batting_flag == 1 ? gameS.team_name : "";
            this.currentTeamType =
              gameS.batting_flag == 1 ? gameS.team_type : 0;
            gameS.game_innings.forEach((inning) => {

              // Previous Inning Score
              if (inning.inning_type == 2 && inning.inning_status == 1) {
                this.totalVisitScore =
                  inning.score != null
                    ? parseInt(this.totalVisitScore) + parseInt(inning.score)
                    : this.totalVisitScore;
                this.previousInningVisitScore.push(inning);
              }
              // Previous Inning HR
              else if (inning.inning_type == 1 && inning.inning_status == 1) {
                this.totalVisitHR =
                  inning.score != null
                    ? parseInt(this.totalVisitHR) + parseInt(inning.score)
                    : this.totalVisitHR;
                this.previousInningVisitHR.push(inning);
              }
              // Current Inning Score
              if (inning.inning_type == 2 && inning.inning_status == 2) {
                this.visitScoreId = inning.id;
                this.currentInningVisitScore = inning.score;
                this.totalVisitScore =
                  inning.score != null
                    ? parseInt(this.totalVisitScore) + parseInt(inning.score)
                    : this.totalVisitScore;
                this.currentInningVisitScoreArray = inning;
              }
              // Current Inning HR
              else if (inning.inning_type == 1 && inning.inning_status == 2) {
                this.visitHRId = inning.id;
                this.currentInningVisitHR = inning.score;
                this.totalVisitHR =
                  inning.score != null
                    ? parseInt(this.totalVisitHR) + parseInt(inning.score)
                    : this.totalVisitHR;
                this.currentInningVisitHRArray = inning;
              }
            });
          }
        });

        // Home
        this.gameScores.forEach((gameS) => {
          if (gameS.team_type == 2) {
            this.homeTeamName = "";
            this.homeTeamName = gameS.team_name;
            this.currentBattingTeam =
              gameS.batting_flag == 1
                ? gameS.team_name
                : this.currentBattingTeam;
            this.currentTeamType =
              gameS.batting_flag == 1 ? gameS.team_type : this.currentTeamType;
            gameS.game_innings.forEach((inning) => {
              // Previous Inning Score
              if (inning.inning_type == 2 && inning.inning_status == 1) {
                this.totalHomeScore =
                  inning.score != null
                    ? parseInt(this.totalHomeScore) + parseInt(inning.score)
                    : this.totalHomeScore;
                this.previousInningHomeScore.push(inning);
              }
              // Previous Inning HR
              else if (inning.inning_type == 1 && inning.inning_status == 1) {
                this.totalHomeHR =
                  inning.score != null
                    ? parseInt(this.totalHomeHR) + parseInt(inning.score)
                    : this.totalHomeHR;
                this.previousInningHomeHR.push(inning);
              }
              //  Current Inning Score
              if (inning.inning_type == 2 && inning.inning_status == 2) {
                this.homeScoreId = inning.id;
                this.currentInningHomeScore = inning.score;
                this.totalHomeScore =
                  inning.score != null
                    ? parseInt(this.totalHomeScore) + parseInt(inning.score)
                    : this.totalHomeScore;
                this.currentInningHomeScoreArray = inning;
              }
              // Current Inning HR
              else if (inning.inning_type == 1 && inning.inning_status == 2) {
                this.homeHRId = inning.id;
                this.currentInningHomeHR = inning.score;
                this.totalHomeHR =
                  inning.score != null
                    ? parseInt(this.totalHomeHR) + parseInt(inning.score)
                    : this.totalHomeHR;
                this.currentInningHomeHRArray = inning;
              }
            });
          }
        });
      }

      this.$root.$emit(
        "updateScoreHr",
        this.currentTeamType == 1
          ? this.currentInningVisitHR
          : this.currentInningHomeHR,
        this.currentTeamType == 1
          ? this.currentInningVisitScore
          : this.currentInningHomeScore
      );

      EventBus.$emit("scoreUpdated", {
        homeScore: this.totalHomeScore,
        visitScore: this.totalVisitScore
      });
    },
    fetchFieldUponEmit(fieldConfigId) {
      if (
        this.selectedRoomTeam.teamType == "sports" ||
        !this.selectedRoomTeam.sportType
      ) {
        axios
          .post(
            process.env.VUE_APP_API_URL +
            `game/savesFieldConfigPositions?configField_id=${fieldConfigId}`
          )
          .then((response) => {
            if (response.data.statusCode == 200) {
              this.fetchConfig = response.data.data.GamePositionConfigType;
              this.fetchTeamPosition();
            } else {
              this.errorMessage = response.data.message;
            }
          });
      } else {
        this.$alert("Sport type is not selected yet.");
      }
    },
    fetchField() {
      if (
        this.selectedRoomTeam.teamType == "sports" ||
        !this.selectedRoomTeam.sportType
      ) {
        axios
          .post(
            process.env.VUE_APP_API_URL +
            `game/savesFieldConfigPositions?configField_id=${this.singleGame.field_configuration_id}`
          )
          .then((response) => {
            if (response.data.statusCode == 200) {
              this.fetchConfig = response.data.data.GamePositionConfigType;
              this.fetchTeamPosition();
            } else {
              this.errorMessage = response.data.message;
            }
          });
      } else {
        this.$alert("Sport type is not selected yet.");
      }
    },
    fetchTeamPosition() {
      var formData = new FormData();
      formData.append("gameId", this.singleGame.id);
      axios
        .post(process.env.VUE_APP_API_URL + "game/getGameLineups", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.gameLineUps = response.data.data.gameLineUps;
          this.$root.$emit("RefreshPlotConfigData", this.gameLineUps);
        });
    },
    fetchTeamPositionUponEmit(scheduleId) {
      var formData = new FormData();
      formData.append("gameId", scheduleId);
      axios
        .post(process.env.VUE_APP_API_URL + "game/getGameLineups", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.gameLineUps = response.data.data.gameLineUps;
          this.$root.$emit("RefreshPlotConfigData", this.gameLineUps);
        });
    },
    getEventAlert() {
      axios
        .get(`${process.env.VUE_APP_API_URL}getEventAlert`)
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.eventAlertOptions = response.data.data.alerts;
          } else {
            this.errorMessage = response.data.message;
          }
        });
    },
    loadGroundImage() {
      this.isGroundImageLoaded = true;
      this.plotConfigData();
    },
    plotConfigData() {
      if (!this.isGroundImageLoaded) {
        return;
      }

      const configData =
        this.configDataFromChild.length > 0
          ? this.configDataFromChild
          : this.fetchConfig;

      const canvas = this.$refs.configCanvas;
      const ctx = canvas.getContext("2d");

      ctx.clearRect(0, 0, canvas.width, canvas.height);

      const groundImage = this.$refs.groundImage;
      ctx.drawImage(groundImage, 0, 0, canvas.width, canvas.height);



      configData.forEach((configItem) => {
        // Check if position array and position_name property exist
        if (configItem && configItem.position_name) {
          const matchingGameLineUp = this.gameLineUps.find((gameLineUpItem) => {
            if (gameLineUpItem.position) {
              const matchingPositions = gameLineUpItem.position.filter(
                (pos) => pos.position_name === configItem.position_name
              );
              return matchingPositions.length > 0;
            }
            return false;
          });

          if (matchingGameLineUp) {

            console.log("matchingGameLineUp", matchingGameLineUp)
            ctx.fillStyle = "#ff0000";
            ctx.fillRect(configItem.x_axis, configItem.y_axis, 18, 15);

            ctx.fillStyle = "#ffffff";
            ctx.font = "7px";
            const textToDisplay = `${configItem.position_name} - ${matchingGameLineUp?.user_profile?.fname || ''} ${matchingGameLineUp?.user_profile?.lname || ''}`;
            ctx.fillText(
              textToDisplay,
              configItem.x_axis + 3,
              configItem.y_axis + 10
            );
          }
        }
      });

    },
  },
  mounted() {
    this.getEventAlert();
    this.scoringCalculation(this.singleGame.game_scores);
    this.$root.$on("checkUpdateEdit", () => {
      var elem = this.$refs.getUpdate;
      elem.click();
    });
    this.fetchField();
    this.$root.$on("HitfetchTeamPosition", () => {
      this.fetchTeamPosition();
    });

    this.$root.$on("saveNewPlotAndShow", (data) => {
      console.log(data[0].field_configuration_id)
      this.fetchFieldUponEmit(data[0].field_configuration_id);
    });
    this.$root.$on("HitfetchTeamPositionUponEmit", (data) => {
      this.fetchTeamPositionUponEmit(data.scheduleId);
      this.fetchFieldUponEmit(data.fieldConfigId);
    });
    if (!this.isGroundImageLoaded) {
      this.loadGroundImage();
    }
    this.$root.$on("showCanvasLoader", (showLoader) => {
      if (showLoader == 1) {
        this.canvaLoader = 0;
      }
    });
    this.resizeCanvas();
    window.addEventListener("resize", this.resizeCanvas);

    // this.$root.$on("passScoresToSingleBoxScore", (gameScores) => {
    //   this.scoringCalculation(gameScores);
    // });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeCanvas);
  },
};
</script>

<style scoped>

@media (max-width: 576px) {
  canvas {
    width: 440px;
    height: 350px;
  }
}

@media (max-width: 500px) {
  canvas {
    width: 400px;
    height: 300px;
  }
}

@media (max-width: 500px) {
  canvas {
    width: 400px;
    height: 300px;
  }
}

@media (max-width: 460px) {
  canvas {
    width: 350px;
    height: 300px;
  }
}

@media (max-width: 400px) {
  canvas {
    width: 300px;
    height: 300px;
  }
}

@media (max-width: 370px) {
  canvas {
    width: 270px;
    height: 270px;
  }
}

@media (max-width: 340px) {
  canvas {
    width: 240px;
    height: 240px;
  }
}

.table thead th {
  border-bottom: none !important;
}

.table-float-right {
  width: 50% !important;
  margin-left: auto !important;
}

.scoresheet-btn {
  font-weight: 400;
  background: #e2f5fe !important;
  border-radius: 5px;
  padding: 6px 8px;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  cursor: pointer;
  font-size: 14px;
  border: none;
  outline: none;
  color: black;
}

.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: none !important;
}

@media screen and (min-width: 768px) {
  #AddScoresModal .modal-content {
    max-width: 300px;
  }
}

@media screen and (max-width: 480px) {
  .teams-sec {
    flex-direction: column !important;
    flex-wrap: wrap !important;
  }
}
</style>
