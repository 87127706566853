<template>
    <div>
        <button
        class="d-none"
        data-toggle="modal"
        data-target="#manageaddParks"
        ref="openAddManageParks"
        ></button>

      <div class="modal fade" tabindex="-1" role="dialog" id="manageaddParks">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h6 class="modal-title m-0">Add Park</h6>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                ref="closeManageAddParks"
              >
                <img src="../../../assets/close.png" />
              </button>
            </div>
            <div class="modal-body pt-2">
            <span
              class="label-type error event-error mt-2"
              v-if="errorMessage != ''"
            >
              Park name already added.
            </span>
            <br v-if="errorMessage != ''" />
            <div class="container-fluid no-pdd mt-2">
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      v-model="parkName"
                      @keyup="errorNull"
                      required
                    />
                    <span class="placeholder">Park Name</span>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <gmap-autocomplete
                      :value="address"
                      placeholder="Enter an address, or location"
                      @place_changed="setPlace"
                      ref="autocompleteRef"
                    >
                    </gmap-autocomplete>
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      v-model="city"
                      
                    />
                    <span class="placeholder">City</span>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      v-model="state"
                      
                    />
                    <span class="placeholder">State</span>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      v-model="zipCode"
                     
                    />
                    <span class="placeholder">Zip Code</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="event-btns-wrapper px-3 pt-0 pb-3 justify-content-end">
            <button
              v-if="!isDisabledGame"
              type="button"
              class="btn btn-primary w-100"
              v-on:click="createPark(0)"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Add Park</span>
            </button>
            <button
              v-else
              type="button"
              class="btn btn-primary-disabled w-100"
              disabled
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Add Park</span>
            </button>
          </div>
          </div>
        </div>
      </div>
    </div>
  </template>

<script>
import axios from "axios";

export default {
  name: "NewPark",
  props: {
    selectedRoomTeam: Object,
    singleEvent: Object,
    currentIsAdmin: String,
  },
  data() {
    return {
      userName: localStorage.getItem("userName"),
      isDisabledGame: true,
      address: "",
      parkName: "",
      number: "",
      city: "",
      state: "",
      zipCode: "",
      marker: { position: { lat: 10, lng: 10 } },
      errorMessage: "",
      isLoadingArray: [],
    };
  },

  methods: {
    setPlace(place) {
      if (!place) return;
      this.marker.position = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      this.address = place.formatted_address;
      const addressComponents = place.address_components;

      for (const component of addressComponents) {
        const types = component.types;
        if (types.includes("locality")) {
          this.city = component.long_name;
        } else if (types.includes("administrative_area_level_1")) {
          this.state = component.short_name;
        } else if (types.includes("postal_code")) {
          this.zipCode = component.long_name;
        }
      }
      this.errorNull();
    },

    errorNull() {
      this.isDisabledGame = !(this.address && this.parkName);
      this.errorMessage = "";
    },

    async createPark(index) {
      this.isDisabledGame = true;
      this.$set(this.isLoadingArray, index, true);

      var formData = new FormData();
      formData.append("address", this.address);
      formData.append("park_name", this.parkName);
      formData.append("lat", this.marker.position.lat);
      formData.append("lng", this.marker.position.lng);
      formData.append("number", this.number);
      formData.append("city", this.city);
      formData.append("state", this.state);
      formData.append("zip_code", this.zipCode);

      await axios
        .post(process.env.VUE_APP_API_URL + "game/parkCreate", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.$refs.closeManageAddParks.click();
            this.$set(this.isLoadingArray, index, false);
            this.$root.$emit("openManageParksModal");
          } else {
            this.errorMessage = response.data.message;
            this.$set(this.isLoadingArray, index, false);
            this.isDisabledGame = true;
          }
        });
    },
  },

  mounted() {
    this.$root.$on("openAddParksModal", () => {
      var elem = this.$refs.openAddManageParks;
      if (elem) {
        elem.click();
      } else {
        console.error("Ref 'openAddManageParks' is not found!");
      }
    });
  },
};
</script>
