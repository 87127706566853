<template>
    <div>
        <Navbar />
        <div class="setting-main-wrapper">
            <div class="main-left-menu">
                <LeftMenu />
            </div>
            <Banner />

            <div class="shop-side p-md-0">
                <LeftMenuSide :openTabSet="openTabSet" />
                <div class="row no-gutters content-wrap-info">
                    <div class="event-section-wrap">
                        <div class="event-section">

                            <div :class="['sticky-header', { scrolled: isScrolled }]">
                                <h6>Events I Follow</h6>
                                <div class="filter-top">
                                    <FollowingEventsFilter showLoader="showLoader"
                                        @follow-filter-changed="handleFiltersChanged" />
                                </div>
                            </div>
                          
                            <div class="event-section-content">
                                
                                <div v-if="followEvents.length > 0 && proStatus !== null " class="event-info-wrapper pb-0">

                                    <div class="event-info-sec" v-for="(event, index) in followEvents" :key="index">
                                        <div class="event-sec-top" @click="redirectToEvent(event)">
                                            <div class="sec-date-name">
                                                <!-- Display event date range using the start and end date -->
                                                <div class="date-time-zone d-flex">
                                                    <span>{{ formatDateRange(event?.exactStartDate, event?.exactEndDate)
                                                        }}</span>
                                                    <span class="ml-1" v-if="event?.time_zone"> ({{
                                                        formatTimeZone(event.time_zone) }}) </span>
                                                </div>
                                                <span>{{ event.eventName }}</span>
                                            </div>
                                            <div class="loc-follow w-100">
                                                <div class="loc-content justify-content-end" v-if="event && event.status === '2'">
                                                    <img :src="link" alt="link"
                                                        @click.stop="openInNewTab(event.Url)" />
                                                    <span @click.stop="openInNewTab(event.Url)">{{ event.Url }}</span>
                                                </div>
                                                <div class="loc-content justify-content-end" v-else-if="formatLocation(event)">
                                                    <img :src="location" alt="location" />
                                                    <span @click.stop="openGoogleMap($event, event)">{{
                                                        formatLocation(event) }}</span>
                                                </div>
                                                <div class="follow-unfollow-btns w-100 d-flex justify-content-end">
                                                    <template v-if="!event.showLoader">
                                                        <template v-if="event && event?.event_follower">
                                                            <!-- Show Unfollow Button -->
                                                            <button class="follow-btn"
                                                                @click.stop="unFollowEvent(event)">
                                                                <img :src="follow" alt="follow" />
                                                            </button>
                                                        </template>
                                                        <template v-else>
                                                            <!-- Show Follow Button -->
                                                            <button class="unfollow-btn"
                                                                @click.stop="followEvent(event)">
                                                                <img :src="unfollow" alt="unfollow" />
                                                                <span>Follow</span>
                                                            </button>
                                                        </template>
                                                    </template>

                                                    <!-- Show loader when event.showLoader is true -->
                                                    <div v-if="event.showLoader" class="d-flex justify-content-end">
                                                        <img src="../../../assets/loader.gif" alt="" width="35" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="event-sec-details" @click="redirectToEvent(event)">
                                            <div class="event-sec-left">
                                                <!-- Conditionally display association and event type -->
                                                <span class="asso-tour">
                                                    {{ event.association ? event.association : '' }}
                                                    {{ event.association && event.eventType ? ' - ' : '' }}
                                                    {{ event.eventType }}
                                                </span>
                                                <span class="director">Director: {{ event.director_name ?
                                                    event.director_name : "N/A" }}</span>
                                                <div v-if="event.eventTeam && event.eventTeam.length > 0">
                                                    <MatesData :members="event.eventTeam" />
                                                </div>
                                                <!-- <span v-else class="director">No teams</span> -->

                                            </div>

                                            <div class="event-sec-right">
                                                <template>
                                                    <div class="weather-sec following-weather-sec" v-if="event.weatherData">
                                                        <div class="weather-content"
                                                            v-for="(dataItem, index) in event.weatherData.slice(0, 5)"
                                                            :key="index">
                                                            <div class="weather-date">
                                                                <div class="date-part">{{
                                                                    formatDateWeather(dataItem.day).date }}</div>
                                                                <!-- <div class="year-part">{{
                                                                        formatDateWeather(dataItem.day).year }}</div> -->
                                                            </div>
                                                            <img :src="iconUrl + dataItem.icon" alt="Weather icon" />
                                                            <div class="tempr">
                                                                <span class="high">
                                                                    {{ Math.round(dataItem.temperatureMax) }}&deg;F
                                                                </span>
                                                                <span class="low">
                                                                    {{ Math.round(dataItem.temperatureMin) }}&deg;F
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="followEvents.length" v-observe-visibility="handleScrolledToButtom">
                                    </div>
                                    <div class="p-3" v-if="showLoader1">
                                        <content-placeholders :rounded="true">
                                            <content-placeholders-heading :img="false" />
                                        </content-placeholders>
                                    </div>
                                </div>
                                <div v-if="proStatus === null && showLoader === 0" class="no-event-wrap">
                                <img src="../../../assets/Svg/no-data.svg" alt="No data" />
                                <p class="header">No events access.</p>
                                <p class="info">You do not have access to view events. Please upgrade to pro plan to enjoy additional perks.</p>
                            </div>
                                <div class="no-event-wrap" v-if="followEvents.length == 0 && showLoader == 0 && proStatus !== null">
                                    <img src="../../../assets/Svg/no-data.svg" alt />
                                    <p class="header">No followed events found.</p>
                                    <p class="info">Events you follow will appear here. Change the filter above or Head over to <router-link exact to="/events/discover">Discover Events</router-link> to find something interesting.</p>
                                </div>
                                <div class="products-list-shim" v-if="followEvents.length == 0 && showLoader == 1">
                                    <content-placeholders :rounded="true">
                                        <content-placeholders-img />
                                        <content-placeholders-heading />
                                    </content-placeholders>
                                    <content-placeholders class="mt-3" :rounded="true">
                                        <content-placeholders-img />
                                        <content-placeholders-heading />
                                    </content-placeholders>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="live-today" v-if="showLiveToday">
                        <LiveToday />
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from "@/components/Header/Navbar.vue";
import Banner from "@/components/BannerNotify/BannerPage.vue";
import LeftMenu from "@/components/Home/LeftMenu.vue";
import LeftMenuSide from "@/components/NewGameTime/LeftMenuSide.vue";
import FollowingEventsFilter from "@/components/NewGameTime/Events/FollowingEventsFilter.vue";
// import LiveToday from "@/components/NewGameTime/LiveToday.vue";
import MatesData from "@/components/NewGameTime/Events/MatesData.vue";

import axios from "axios";
let states = require("../../../assets/js/state");



export default {
    name: "FollowingEvents",
    components: {
        Navbar,
        LeftMenu,
        Banner,
        LeftMenuSide,
        FollowingEventsFilter,
        // LiveToday,
        MatesData

    },
    data() {
        const today = new Date();
        const currentMonth = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based
        const currentYear = today.getFullYear();
        return {
            iconUrl: "https:",
            openTabSet: 1,
            location: require("../../../assets/Svg/location.svg"),
            unfollow: require("../../../assets/Svg/unfollow.svg"),
            follow: require("../../../assets/Svg/follow.svg"),
            link: require("../../../assets/images/link.png"),
            showLiveToday: true,   
            stateOptions: states,
            associationOptions: [],
            filter: {
                name: "",
                startDate: `${currentMonth}/${currentYear}`, // Set default to current month/year
                association: "",
                region: [],
            },
            page: 1,
            lastPage: 1,
            followEvents: [],
            proStatus : 0,
            showLoader: 0,
            showGameLoader: 0,
            showLoader1: false,
            gameScores:[],
            gameScoresOngoing:[],
            gameScoresFinal:[],
            total_game: '',
            url: this.$groupAvatarStorageURL,
            isScrolled: false,
            user_id: localStorage.getItem("userId"),
        };
    },
    methods: {
        formatTimeZone(timeZone) {
            if (timeZone && typeof timeZone === 'string') {
                return timeZone.replace(/\(UTC[^)]+\)\s*/, "").trim(); // Removes text like "(UTC-06:00)"
            }
            return timeZone; // Return the original value if it's not a valid string
        },
        handleScroll() {
            let scrollTop = document.querySelector('.shop-side')?.scrollTop || 0;
            this.isScrolled = scrollTop > 5;
        },
        formatDateWeather(date) {
            const options = { weekday: "short", month: 'short', day: 'numeric' };
            const datePart = new Date(date).toLocaleDateString(undefined, options);
            const yearPart = new Date(date).getFullYear();
            return { date: datePart, year: yearPart };
        },
        formatDateRange(startDate, endDate) {
            const start = new Date(startDate);
            const end = new Date(endDate);

            const startDateString = start.toLocaleDateString("en-US", {
                month: "short",
                day: "numeric",
            });
            const endDateString = end.toLocaleDateString("en-US", {
                month: "short",
                day: "numeric",
            });
            const startYear = start.getFullYear();
            const endYear = end.getFullYear();

            if (startYear === endYear) {
                return `${startDateString} to ${endDateString}, ${startYear}`;
            } else {
                return `${startDateString}, ${startYear} to ${endDateString}, ${endYear}`;
            }
        },
        formatLocation(event) {
            let location = '';

            if (event.city) {
                // Add comma if address exists, else just city
                location += (location ? ', ' : '') + event.city;
            }

            if (event.state) {
                // Add comma if address or city exists, else just state
                location += (location ? ', ' : '') + event.state;
            }

            return location;
        },
        redirectToEvent(item) {
            const eventId = item.guid;
            this.$router.push({ name: 'AssociationSingleEventDetailFollowing', params: { id: eventId } });
        },        
        showLocation(event) {
            return (
                (event.status === 1 &&
                    event.address !== null &&
                    event.address !== "" &&
                    event.address !== "null") ||
                (event.city !== null && event.city !== "" && event.city !== "null") ||
                (event.state !== null && event.state !== "" && event.state !== "null")
            );
        },
        getLocation(event) {
            const address =
                event.address && event.address !== "null" ? event.address : "";
            const city = event.city && event.city !== "null" ? event.city : "";
            const state = event.state && event.state !== "null" ? event.state : "";

            return `${address} ${city}, ${state}`.trim();
        },
        openGoogleMap(item) {
            const strlatLng = item["lan"] + "," + item["long"];
            const urlSuffix = strlatLng
                ? strlatLng
                : item["address"] + ", " + item["city"] + ", " + item["zipCode"];

            window.open(
                "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
                "_blank"
            );
        },
        openInNewTab(url) {
            if (url) {
            window.open(url, '_blank', 'noopener,noreferrer');
            }
        },

        handleRowClick(event) {
            this.$root.$emit("eventsIdForDetails", event);

            // setTimeout(() => {
            //   this.$root.$emit("eventsIdForDetails", event);
            //   this.$router.push({ name: "AssociationSingleEventDetail", params: { id: event.guid } });
            // }, 500);
        },

        handleScrolledToButtom(isVisible) {
            
            if (this.page >= this.lastPage) {
                return;
            }
            if (isVisible) {
                this.page++;
                this.EventsData();
            }
        },
        async followEvent(event){
            this.showLoader = 1;
            this.showLoader1 = true;
            try {
                const formData = new FormData();

                
                formData.append("event_id", event.id);
                formData.append("user_id", this.user_id);
                
                // API Call
                const response = await axios.post(
                    `${process.env.VUE_APP_API_URL}eventfollower/store`,
                    formData
                );
                if (response.data.statusCode === 200) {
                    // this.EventsData();
                } else {
                    this.errorMessage = response.data.message || "Failed to fetch events.";
                }
            } catch (error) {
                console.error("API Error:", error);
                this.errorMessage = "Failed to fetch data. Please try again.";
            } finally {
                this.showLoader = 1;
            }

        },
        async unFollowEvent(followEvent) {
            this.$set(followEvent, 'showLoader', true);
            try {
                const formData = new FormData();
                formData.append("follow_id", followEvent?.event_follower?.id);

                // API Call
                const response = await axios.post(
                    `${process.env.VUE_APP_API_URL}eventfollower/unfollow`,
                    formData
                );

                if (response.data.statusCode === 200) {
                    // Remove the event from followEvents and remap it using $set
                    const updatedFollowEvents = this.followEvents.filter(event => event.id !== followEvent.id);
                    this.$set(followEvent, 'showLoader', false);  // Stop the loader after the update
                    this.$set(this, 'followEvents', updatedFollowEvents);
                } else {
                    this.errorMessage = response.data.message || "Failed to unfollow the event.";
                }
            } catch (error) {
                console.error("API Error:", error);
                this.errorMessage = "Failed to fetch data. Please try again.";
            } 
        },

        async EventsData() {
            
            this.showLoader = 1;
            this.showLoader1 = true;

            // Ensure eventStatus has a default value
            if (!this.filter.eventStatus) {
                this.filter.eventStatus = "false"; // Default to upcoming events
            }

            try {
                const formData = new FormData();
                formData.append("user_id", this.user_id);
                if (this.filter.name) formData.append("name", this.filter.name);
                formData.append("eventStatus", this.filter.eventStatus);
                if (this.filter.association) formData.append("association", this.filter.association);

                // Use selectedYear or current year if not provided
                formData.append("selectedYear", this.filter.selectedYear || new Date().getFullYear());

                // Ensure regions (selectedStates) are handled properly
                if (Array.isArray(this.filter.selectedStates) && this.filter.selectedStates.length > 0) {
                    const selectedStatesFormatted = JSON.stringify(
                        this.filter.selectedStates.map(state => ({
                            name: state.name,
                            abbreviation: state.abbreviation
                        }))
                    );
                    formData.append("regions", selectedStatesFormatted);
                }

                // API Call
                const response = await axios.post(
                    `${process.env.VUE_APP_API_URL}eventfollower/getEventFollowers?page=${this.page}`,
                    formData
                );
                if (response.data.statusCode === 200) {
                    const newEventData = response.data.data.data;
                    this.proStatus = response.data.proStatus;
                    if (this.page === 1) {
                        this.followEvents = [];
                    }
                    this.followEvents =  this.followEvents.concat(newEventData);
                    
                    this.lastPage = response.data.data.last_page;
                } else {
                    this.errorMessage = response.data.message || "Failed to fetch events.";
                }
            } catch (error) {
                console.error("API Error:", error);
                this.errorMessage = "Failed to fetch data. Please try again.";
            } finally {
                this.showLoader = 0;
                this.showLoader1 = false;
            }
        },
        getAssociation() {
            axios
                .get(
                    `${process.env.VUE_APP_API_URL}getAssociations?search=${""}`,
                    this.details,
                    {}
                )
                .then((response) => {
                    this.associationOptions = [];
                    this.associationOptions = response.data.data.associations;

                });
        },
        handleFiltersChanged(filters) {
            console.log("Filters Changed:", filters);

            this.filter = {
                ...this.filter,
                eventStatus: filters.eventStatus,
            };

            this.page = 1;
            this.followEvents = [];
            this.EventsData();
        },
      callBoxViewModal(score) {
        this.$root.$emit("openBoxViewScoresModal", score);
        this.$root.$emit("openBoxViewInningModal", score);
        },
    },
    mounted() {
        this.getAssociation();
        this.EventsData();
        this.$root.$on("openTabSetting", (id) => {
            this.openTabSet = id;
        });
        let scrollContainer = document.querySelector('.shop-side'); // Update selector to match your container
        if (scrollContainer) {
            scrollContainer.addEventListener("scroll", this.handleScroll);
        }
    },
    beforeUnmount() {
        let scrollContainer = document.querySelector('.shop-side');
        if (scrollContainer) {
            scrollContainer.removeEventListener("scroll", this.handleScroll);
        }
    },
};
</script>