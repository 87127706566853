<template>
  <div class="mates-info">
    <div class="mates-image-wrap">
      <div
        class="mates-image position-relative"
        v-for="user in members.slice(0, 6)"
        :key="user.id"
      >
        <abbr :title="user?.team_name">
        <div class="users-notification" v-if="imgLoader == 1">
          <img src="../../../assets/loader.gif" alt="" />
        </div>
        <img @load="onImageLoad"
          :src="url + user?.team_avatar" alt="team-image"
          onerror="this.src='https://whoifollow.s3.us-west-2.amazonaws.com/chat/groupAvatar/NwqRnGfkQzHrf5lu1PeB1gwAdiZ8Ge5fSBFUTzLS.svg'"
            />
        </abbr>
      </div>
    </div>
    <h6 v-if="members.length === 1">{{ members.length }} Team</h6>
    <h6 v-else-if="members.length > 6">+{{ members.length - 6 }} Teams</h6>
    <h6 v-else>{{ members.length }} Teams</h6>
  </div>
</template>

<script>
export default {
  props: {
    members: {
      type: Array,
    },
  },
  data() {
    return {
      imgLoader: 1,
      url: this.$groupAvatarStorageURL,
    };
  },
  methods: {
    onImageLoad() {
      this.imgLoader = 0;
    },
  },
  mounted() {
        console.log("members", this.members)
    },
};
</script>

<style scoped>
.mates-info h6{
  font-size: 14px;
    font-weight: 400;
    line-height: 19.07px;
    color: #557491;
}
abbr[data-original-title], abbr[title] {
  cursor: pointer!important;
}
</style>