<template>
  <div class="order-wrapper">
    <div class="order-info">
      <div class="order-heading">
        <img src="../../../assets/images/delivery.png" alt="" />
        <h6>Where would you like to deliver the order ?</h6>
      </div>
      <div class="order-form">
        <form>
          <div class="form-row">
            <div class="form-group col-12">
              <label for="address">Street Address</label>
              <input
                type="text"
                class="form-control"
                id="address"
                v-model="details.address"
                @keyup="errorNull"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-12 col-md-6">
              <label for="city">City</label>
              <input
                type="text"
                class="form-control"
                id="city"
                v-model="details.city"
                @keyup="errorNull"
              />
            </div>
            <div class="form-group col-sm-12 col-md-6">
              <label for="state">State</label>
              <input
                type="text"
                class="form-control"
                id="state"
                v-model="details.state"
                @keyup="errorNull"
              />
            </div>
            <div class="form-group col-12 col-md-6">
              <label for="zip_code">Zip Code</label>
              <input
                type="text"
                class="form-control"
                id="zip_code"
                v-model="details.zip_code"
                @keyup="errorNull"
              />
            </div>
            <div class="form-group col-12 col-md-6">
              <label for="number">Mobile Number</label>
              <input
                type="number"
                class="form-control"
                id="inputAddress"
                v-model="details.mob"
                @keyup="errorNull"
              />
            </div>
          </div>
          <!-- <div class="form-row">
            <div class="form-group col-12 col-md-6">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="gridCheck"
                  v-model="details.use_billing"
                  @change="errorNull"
                />
              </div>
                <label
                  class="form-check-label"
                  for="gridCheck"
                  style="line-height: 60px;"
                  >Use shipping address as Billing Address</label
                >
              </div>
            </div>
          </div> -->
        </form>
      </div>
    </div>
    <div class="delivery-bottom event-btns-wrapper">
      <router-link to="/order" exact class="page-back">Previous</router-link>
      <button class="btn btn-primary-disabled" v-if="this.isDisabled == true">
        Payment
      </button>
      <button
        @click="proceed()"
        class="btn btn-primary"
        v-if="this.isDisabled == false"
      >
        Payment
      </button>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Shipping",
  data() {
    return {
      details: {
        address: "",
        // country: "",
        city: "",
        mob: "",
        state: "",
        zip_code: "",
        // use_billing: "",
      },
      isDisabled: true,
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    ...mapActions("auth", ["getUserData"]),
    proceed: function() {
      if (
        // this.details.country != "" &&
        this.details.city != "" &&
        this.details.mob != "" &&
        this.details.zip_code != "" &&
        this.details.address != "" &&
        this.details.state != ""
      ) {
        localStorage.setItem("shipping", JSON.stringify(this.details));
        this.$router.push({ name: "Payment" });
      }
    },

    errorNull: function() {
      if (
        // this.details.country == "" ||
        // this.details.country == null ||
        this.details.city == "" ||
        this.details.city == null ||
        this.details.mob == "" ||
        this.details.mob == null ||
        this.details.zip_code == "" ||
        this.details.zip_code == null ||
        this.details.address == "" ||
        this.details.address == null ||
        this.details.state == "" ||
        this.details.state == null
      ) {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    },
  },
  mounted() {
    this.getUserData();
    // this.details.country = this.user.country;
    this.details.city = this.user.city || "";
    this.details.state = this.user.state || "";
    this.details.zip_code = this.user.zip_code || "";
    this.details.mob = this.user.mob || "";
    this.details.address = this.user.address || "";
    this.errorNull();
  },
};
</script>
