<template>
  <div>
    <TeamLineUpModal :selectedRoomTeam="selectedRoomTeam" />
    <EditGame :singleGame="singleGame" :eventAlertOptions="eventAlertOptions" />
    <div
      v-if="gamesByDate && Object.keys(gamesByDate).length > 0"
      class="row m-0"
    >
      <!-- Iterate over each date in gamesByDate -->
      <div v-for="(games, date) in gamesByDate" :key="date" class="col-12 p-0">
        <!-- Display the date as a heading -->
        <h2 class="date-heading">{{ formatDateHeading(date) }}</h2>
        <!-- Iterate over games for this date -->
        <div class="row m-0">
          <div
            v-for="game in games"
            :key="game.id"
            class="col-12 col-md-6 col-xl-4 p-0"
          >
            <router-link
              :to="`/score-game/${game.team_id}-${game.guid}`"
              class="m-0"
            >
              <div class="game-detail-wrapper single-event-side-game">
                <div class="game-content">
                  <div class="live-inner-top">
                    <div class="d-flex align-items-center" style="gap: 5px">
                      <div
                        class="live-delayed-sec"
                        v-if="game.game_delayed == 1"
                      >
                        <span class="delayed-tag">Delayed</span>
                        <div class="video-icon">
                          <img
                            src="../../assets/Svg/delayed.svg"
                            alt="delayed"
                          />
                        </div>
                      </div>
                      <div
                        class="live-delayed-sec"
                        v-if="game.game_live == 1 && game.status == 1"
                      >
                        <span class="delayed-tag live-tag">Live</span>
                        <div class="video-icon">
                          <!-- <img src="../../assets/Svg/video.svg" alt="live" /> -->
                        </div>
                      </div>
                      <span class="game-name">{{ game.name }}</span>
                    </div>
                    <div class="right-part-sec">
                      <div
                        v-if="
                          game.status == 1 &&
                          game.game_scores &&
                          game.game_scores.length > 0
                        "
                        class="live-count"
                      >
                        {{ game.game_scores[0].end_inning_flag }}
                      </div>
                      <div class="final-count" v-if="game.status == 2">
                        <span>F</span>
                      </div>
                      <GameOptions
                        :singleGame="game"
                        :eventAlertOptions="eventAlertOptions"
                        :selectedRoomTeam="selectedRoomTeam"
                        v-if="
                          currentIsAdmin == '1' &&
                          selectedRoomTeam.teamType == 'sports' &&
                          currentTeamIsActive == 1
                        "
                      />
                    </div>
                  </div>
                  <div class="date-content">
                    <p>{{ getFromDate(game.exactDateFormat) }}</p>
                  </div>
                  <div class="team-opponent-score-wrap">
                    <div v-if="game.game_scores && game.game_scores.length">
                      <div
                        class="opponent-name"
                        v-if="
                          game.game_scores?.some((team) => team.team_type === 1)
                        "
                      >
                        <div
                          class="custom-avatar"
                          v-if="
                            game.game_scores?.find(
                              (team) =>
                                team.team_type === 1 &&
                                team.team_name !== selectedRoomTeam.teamName
                            )
                          "
                          :style="{ backgroundColor: getRandomColor() }"
                        >
                          {{
                            getInitials(
                              game.game_scores?.find(
                                (team) => team.team_type === 1
                              )?.team_name
                            )
                          }}
                        </div>
                        <div class="team-image" v-else>
                          <div class="users-notification" v-if="imgLoader == 1">
                            <img src="../../assets/loader.gif" alt="" />
                          </div>
                          <img
                            @load="onImageLoad"
                            v-if="selectedRoomTeam.teamAvatar == ''"
                            :src="team_image"
                            alt=""
                          />
                          <img
                            @load="onImageLoad"
                            v-if="
                              selectedRoomTeam.teamAvatar != '' &&
                              game.game_scores?.find(
                                (team) =>
                                  team.team_name === selectedRoomTeam.teamName
                              )
                            "
                            :src="url + selectedRoomTeam.teamAvatar"
                            alt=""
                          />
                        </div>
                        <h6>
                          {{
                            game.game_scores?.find(
                              (team) => team.team_type === 1
                            )?.team_name
                          }}
                        </h6>
                      </div>

                      <div
                        class="opponent-name"
                        v-if="
                          game.game_scores?.some((team) => team.team_type === 2)
                        "
                      >
                        <div
                          class="custom-avatar"
                          v-if="
                            game.game_scores?.find(
                              (team) =>
                                team.team_type === 2 &&
                                team.team_name !== selectedRoomTeam.teamName
                            )
                          "
                          :style="{ backgroundColor: getRandomColor() }"
                        >
                          {{
                            getInitials(
                              game.game_scores?.find(
                                (team) => team.team_type === 2
                              )?.team_name
                            )
                          }}
                        </div>
                        <div class="team-image" v-else>
                          <div class="users-notification" v-if="imgLoader == 1">
                            <img src="../../assets/loader.gif" alt="" />
                          </div>
                          <img
                            @load="onImageLoad"
                            v-if="selectedRoomTeam.teamAvatar == ''"
                            :src="team_image"
                            alt=""
                          />
                          <img
                            @load="onImageLoad"
                            v-if="
                              selectedRoomTeam.teamAvatar != '' &&
                              game.game_scores?.find(
                                (team) =>
                                  team.team_name === selectedRoomTeam.teamName
                              )
                            "
                            :src="url + selectedRoomTeam.teamAvatar"
                            alt=""
                          />
                        </div>
                        <h6>
                          {{
                            game.game_scores?.find(
                              (team) => team.team_type === 2
                            )?.team_name
                          }}
                        </h6>
                      </div>
                    </div>
                    <div v-else>
                      <div class="opponent-name">
                        <!-- Opponent Name with Custom Avatar -->
                        <div
                          class="custom-avatar"
                          :style="{ backgroundColor: getRandomColor() }"
                        >
                          {{ getInitials(game.opponent_name) }}
                        </div>
                        <h6 :class="{ 'fw-600': game.win_status == 1 }">
                          {{ game.opponent_name }}
                        </h6>
                      </div>
                      <div class="opponent-name">
                        <!-- Selected Team Name with Team Image -->
                        <div class="team-image">
                          <div class="users-notification" v-if="imgLoader == 1">
                            <img src="../../assets/loader.gif" alt="" />
                          </div>
                          <img
                            @load="onImageLoad"
                            v-if="selectedRoomTeam.teamAvatar == ''"
                            :src="team_image"
                            alt=""
                          />
                          <img
                            @load="onImageLoad"
                            v-if="selectedRoomTeam.teamAvatar != ''"
                            :src="url + selectedRoomTeam.teamAvatar"
                            alt=""
                          />
                        </div>
                        <h6 :class="{ 'fw-600': game.win_status == 1 }">
                          {{ selectedRoomTeam.teamName }}
                        </h6>
                      </div>
                    </div>
                    <div class="played-data text-right">
                      <div>
                        <p
                          class="tie"
                          v-if="game.status == 1 && game.game_live == 1"
                        >
                          <span
                            class="d-flex align-items-center"
                            style="gap: 5px"
                          >
                            <span
                              v-if="
                                game.game_scores?.find(
                                  (team) =>
                                    team.team_flag == 1 && team.team_type == 2
                                )
                              "
                            >
                              {{ game.opponent_score }}
                            </span>
                            <span
                              v-if="
                                game.game_scores?.find(
                                  (team) =>
                                    team.team_flag == 1 && team.team_type == 1
                                )
                              "
                            >
                              {{ game.team_score }}
                            </span>
                            <img
                              :src="arrow_left"
                              alt="arrow-left"
                              v-if="
                                game.game_scores &&
                                game.game_scores?.find(
                                  (team) =>
                                    team.team_flag == 2 &&
                                    team.team_type == 1 &&
                                    team.batting_flag == 1
                                )
                              "
                            />
                            <img
                              :src="arrow_left"
                              alt="arrow-left"
                              v-if="
                                game.game_scores &&
                                game.game_scores?.find(
                                  (team) =>
                                    team.team_flag == 1 &&
                                    team.team_type == 1 &&
                                    team.batting_flag == 1
                                )
                              "
                            />
                          </span>

                          <span
                            class="d-flex align-items-center"
                            style="gap: 5px"
                          >
                            <span
                              v-if="
                                game.game_scores?.find(
                                  (team) =>
                                    team.team_flag == 2 && team.team_type == 1
                                )
                              "
                            >
                              {{ game.team_score }}
                            </span>
                            <span
                              v-if="
                                game.game_scores?.find(
                                  (team) =>
                                    team.team_flag == 2 && team.team_type == 2
                                )
                              "
                            >
                              {{ game.opponent_score }}
                            </span>
                            <img
                              :src="arrow_left"
                              alt="arrow-left"
                              v-if="
                                game.game_scores &&
                                game.game_scores?.find(
                                  (team) =>
                                    team.team_type == 2 &&
                                    team.team_flag == 1 &&
                                    team.batting_flag == 1
                                )
                              "
                            />
                            <img
                              :src="arrow_left"
                              alt="arrow-left"
                              v-if="
                                game.game_scores &&
                                game.game_scores?.find(
                                  (team) =>
                                    team.team_type == 2 &&
                                    team.team_flag == 2 &&
                                    team.batting_flag == 1
                                )
                              "
                            />
                          </span>
                        </p>

                        <p class="tie pr-3" v-else-if="game.win_status == 0">
                          <span>{{ game.opponent_score }}</span>
                          <span>{{ game.team_score }}</span>
                        </p>

                        <p
                          class="win-data"
                          v-else-if="
                            game.win_status == 1 &&
                            game.game_scores &&
                            game.game_scores[0].team_type == 2
                          "
                        >
                          <span>{{ game.opponent_score }} </span>
                          <span
                            >{{ game.team_score }}
                            <img :src="cup" alt="cup" class="ml-1"
                          /></span>
                        </p>

                        <p
                          class="win-data-two"
                          v-else-if="
                            game.win_status == 1 &&
                            game.game_scores &&
                            game.game_scores[0].team_type == 1
                          "
                        >
                          <span
                            >{{ game.team_score }}
                            <img :src="cup" alt="cup" class="ml-1"
                          /></span>
                          <span>{{ game.opponent_score }} </span>
                        </p>

                        <p
                          class="loss-data"
                          v-else-if="
                            game.win_status == 2 &&
                            game.game_scores &&
                            game.game_scores[0].team_type == 1
                          "
                        >
                          <span>{{ game.team_score }} </span>
                          <span
                            >{{ game.opponent_score }}
                            <img :src="cup" alt="cup" class="ml-1" />
                          </span>
                        </p>

                        <p
                          class="loss-data-two"
                          v-else-if="
                            game.win_status == 2 &&
                            game.game_scores &&
                            game.game_scores[0].team_type == 2
                          "
                        >
                          <span
                            >{{ game.opponent_score }}
                            <img :src="cup" alt="cup" class="ml-1"
                          /></span>
                          <span>{{ game.team_score }} </span>
                        </p>

                        <p
                          class="not-started"
                          v-else-if="game.game_live == 0 && game.status == 1"
                        >
                          Game yet to begin
                        </p>
                      </div>
                    </div>
                  </div>
                  <span
                    class="game-location cursor-pointer"
                    @click="openGoogleMapforGamePark(game, $event)"
                    v-if="
                      game.park_name != null ||
                      game.park_name != '' ||
                      game?.field?.field_name != null ||
                      game?.field?.field_name != ''
                    "
                  >
                    <img src="../../assets/Svg/maximize.svg" class="mr-2" />
                    <p>{{ game?.field?.field_name }} <span v-if="game?.field?.field_name">-</span> {{ game?.park_name }}</p>
                  </span>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <!-- <div class="user-notification d-flex align-items-center justify-content-center" v-if="showGameLoader == true">
        <div class="lds-dual-ring-refresh"></div>
      </div> -->
    </div>
    <div class="no-event-wrap" v-else>
      <img src="../../assets/Svg/no-data.svg" alt />
      <p class="header">No games found.</p>
      <p class="info">
        Event games will appear here. Change the filter above to see the
        existing games.
      </p>
    </div>
  </div>
</template>

<script>
import GameOptions from "./GameOptions.vue";
import TeamLineUpModal from "../SingleBoxScore/SubLeft/TeamLineUpModal.vue";
import EditGame from "../Teams/Games/EditGame.vue";

export default {
  name: "GameInfo",
  components: {
    TeamLineUpModal,
    GameOptions,
    EditGame,
  },
  data() {
    return {
      singleGame: {},
      team_image: require("../../assets/Svg/people.svg"),
      cup: require("../../assets/Svg/cup.svg"),
      arrow_left: require("../../assets/Svg/arrow-left.svg"),
      imgLoader: 1,
      url: this.$groupAvatarStorageURL,
      gamesByDate: {}, // Add this to store games grouped by date
    };
  },
  props: {
    games: {
      type: Array,
    },
    showGameLoader: {
      type: Boolean,
    },
    eventAlertOptions: {
      type: Array,
    },
    selectedRoomTeam: {
      type: Object,
    },
    currentIsAdmin: {
      type: String,
    },
    currentTeamIsActive: {
      type: Number,
    },
  },
  watch: {
    // Watch for changes in the games prop
    games: {
      immediate: true,
      handler(newGames) {
        if (newGames && newGames.length > 0) {
          this.groupGamesByDate(newGames); // Remap games on change
        } else {
          this.gamesByDate = {};
        }
      },
    },
  },
  methods: {
    onImageLoad() {
      this.imgLoader = 0;
    },
    getInitials(name) {
      return name ? name.charAt(0).toUpperCase() : "?";
    },
    getRandomColor() {
      const colors = [
        "#FCE4EC",
        "#E3F2FD",
        "#F1F8E9",
        "#FFF8E1",
        "#EDE7F6",
        "#FFEBEE",
        "#F9FBE7",
        "#FFF3E0",
        "#ECEFF1",
        "#FFECB3",
      ];
      return colors[Math.floor(Math.random() * colors.length)];
    },
    getTimeFromDateFormat(dateString) {
      const date = new Date(dateString);
      if (isNaN(date)) {
        return "";
      }
      return date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    },
    getFromDate(dateString) {
      const date = new Date(dateString);
      if (isNaN(date)) {
        return "";
      }
      let formattedTime = date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
      const amIndex = formattedTime.indexOf("AM");
      if (amIndex !== -1) {
        formattedTime = formattedTime.slice(0, amIndex + 2);
      }
      return `${formattedTime}`;
    },
    openGoogleMapforGamePark(item, event) {
      event.preventDefault(); // Prevents <router-link> navigation
      event.stopPropagation();
      const strlatLng = item["park_lat"] + "," + item["park_lng"];
      const urlSuffix = strlatLng ? strlatLng : item["park_address"];
      window.open(
        "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
        "_blank"
      );
    },
    formatDateHeading(dateString) {
      const date = new Date(dateString);
      if (isNaN(date)) {
        return "";
      }
      return date.toLocaleDateString([], {
        weekday: "short",
        month: "long",
        day: "numeric",
        year: "numeric",
      });
    },
    groupGamesByDate(games) {
      // Group games by start_date
      this.gamesByDate = games.reduce((acc, game) => {
        const startDate = game.start_date;
        if (!acc[startDate]) {
          acc[startDate] = [];
        }
        acc[startDate].push(game);
        return acc;
      }, {});
    },
  },
};
</script>