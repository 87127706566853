import { render, staticRenderFns } from "./MatesData.vue?vue&type=template&id=8cf4d844&scoped=true&"
import script from "./MatesData.vue?vue&type=script&lang=js&"
export * from "./MatesData.vue?vue&type=script&lang=js&"
import style0 from "./MatesData.vue?vue&type=style&index=0&id=8cf4d844&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8cf4d844",
  null
  
)

export default component.exports