<template>
    <div class="calendar-top">
      <!-- <h6>Calendar</h6> -->
      <div class="event-tab22">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <!-- <li class="nav-item" role="presentation">
            <router-link
              class="nav-link"
              exact
              to="/event-calendar-table"
              active-class="active"
              exact-active-class="active"
            >
              <img alt="" :src="tableBlue" id="calender-img" />
            </router-link>
          </li> -->
          <li class="nav-item" role="presentation" >
            <span v-if="isActive('/my-events')" class="active-label">Grid view</span>
            <router-link
              class="nav-link"
              exact
              to="/my-events"
              active-class="active"
              exact-active-class="active"
            >
              <img alt="" :src="listWhite" id="event-list-img" />
            </router-link>
          </li>
          <li class="nav-item" role="presentation">
            <router-link
              class="nav-link"
              exact
              to="/my-events/calendar"
              active-class="active"
              exact-active-class="active"
            >
              <img alt="" :src="calenderBlue" id="calender-img" />
            </router-link>
            <span v-if="isActive('/my-events/calendar')" class="active-label">Calendar view</span>
          </li>
          
        </ul>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      listWhite: String,
      calenderBlue: String,
      tableBlue: String,
    },
     methods: {
    isActive(route) {
      return this.$route.path === route;
    },
  },
  
  };
  </script>
  <style>
  /*===============TOGGLE ==========*/
.event-tab22 ul {
  list-style-type: none;
  display: flex;
  margin-bottom: 0;
}

.event-tab22 ul li{
  color: #c4c4c4;
  cursor: pointer;
 
  text-align: center;
  display: flex;
  align-items: center;
  gap: 10px;
}

.event-tab22 ul li a {
   background-color: #e5f2fe;
}

.event-tab22 ul li:first-child {
  border-radius: 5px 0 0 5px !important;
  display: flex;
  align-items: center;
  gap: 10px;
}

.event-tab22 ul li:last-child {
  border-radius: 0 5px 5px 0 !important;
  display: flex;
  align-items: center;
  gap: 10px;
}

.event-tab22 .nav-tabs {
  border-bottom: 0 !important;
}

.event-tab22 .nav-tabs .nav-item.show .nav-link,
.event-tab22 .nav-tabs .nav-link.active {
  background-color: #188ef5 !important;
  border-radius: inherit !important;
}

.event-tab22 .nav-tabs .nav-link img {
  width: 20px;
}

.event-tab22 .nav-tabs .nav-link {
  border: 0 !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e5f2fe;
}

</style>