<template>
  <div>
     <ManageTeams :eventId="eventId" 
    :association_id = "association_id"
    :eventName = "eventName"
    />
    <div class="d-flex justify-content-end" v-if="currentIsAdmin == '1'">
      <button class="btn btn-primary" @click="manageTeams()" >Manage Teams</button>
    </div>

    <div v-if="joinedTeams.length > 0">
      <div v-for="(teams, ageGroup) in groupedTeams" :key="ageGroup">
        <p class="pb-2">
          <span class="bold-make">{{ ageGroup }} </span>({{
            teams.length
          }}
          Teams)
        </p>
        <b-row class="no-gutters" v-if="teams.length > 0">
          <div class="col-12">
            <div class="event-card">
              <div class="table-responsive mb-0">
                <b-table
                  :items="teams"
                  :fields="fields"
                  hover
                  thead-class="d-none"
                  responsive
                  class="mb-0 join-team-table"
                >
                  <template v-slot:cell(team.team_name)="data"> 
                    <div class="d-flex align-items-center">
                      <div class="user-img">
                          <div class="users-notification" v-if="imgLoader == 1">
                            <!-- <div class="lds-dual-ring-refresh"></div> -->
                            <img src="../../assets/loader.gif" alt="" />
                          </div>
                          <img @load="onImageLoad"
                            v-if="data.item.team.team_avatar"
                            :src="url + data.item.team.team_avatar"
                            alt=""
                            style="border: 2px solid white"
                          />
                          <img
                            @load="onImageLoad"
                            v-else
                            alt=""
                            src="../../assets/team-avatar.png"
                          />
                        </div>
                        <div class="group-table-name pl-2" >
                            <p>{{data.item.team.team_name}}</p>
                        </div>
                    </div>
                </template>
                </b-table>
              </div>
            </div>
          </div>
        </b-row>
      </div>
    </div>

    <div
      class="no-products-in-shop"
      v-if="(!joinedTeams || joinedTeams.length == 0) && showLoaderTeam == 0"
    >
      <img src="../../assets/images/no-product.png" alt />
      <p class="header">No team found.</p>
      <p class="info">
        There are no team to display.
      </p>
    </div>

    <div
      class="products-list-shim p-0 mt-3"
      v-if="(!joinedTeams || joinedTeams.length == 0) && showLoaderTeam == 1"
    >
      <content-placeholders :rounded="true">
        <content-placeholders-img />
        <content-placeholders-heading />
      </content-placeholders>
      <content-placeholders class="mt-3" :rounded="true">
        <content-placeholders-img />
        <content-placeholders-heading />
      </content-placeholders>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ManageTeams from './Modals/ManageTeams.vue';
export default {
  name: "AssociationTeamsInfo",
  components: { ManageTeams },
  data() {
    return {
      url: this.$groupAvatarStorageURL,
      imgLoader: 1,

      gameOptions: false,
      fields: [
        { key: "team.team_name", label: "Team Name" },
        {
          key: "team.age_group.name",
          label: "Age Group",
          formatter: (value) => {
            if (value === null) {
              return "N/A";
            } else {
              return `${value}`;
            }
          },
        },
        { key: "team.ratings.rate", label: "Rating", class: "text-center" },
        {
          key: "team.city",
          label: "City",
          class: "text-center",
          formatter: (value, key, item) => {
           return `${item?.team?.city}, ${item?.team?.state}`;
          }
        }
        // { key: 'action', label: 'Action' }
      ],
      // joinedTeams: [],
      // page: 1,
      // lastPage: 1,
      // showLoader: 1,
    };
  },
  props: {
    eventId: {
      type: Number,
    },
    joinedTeams: {
      type: Array
    },
     association_id: {
      type: Number,
    },
    page: {
      type: Number
    },
    currentIsAdmin: {
      type: String,
    },
    eventName: {
      type: String,
    },
    lastPage: {
      type: Number
    },
    showLoaderTeam: {
      type: Number
    },
  },
  computed: {
  groupedTeams() {
    const grouped = {};
    this.joinedTeams.forEach((team) => {
      const ageGroupName = team.team.age_group ? team.team.age_group.name : 'N/A';
      if (!grouped[ageGroupName]) {
        grouped[ageGroupName] = [];
      }
      grouped[ageGroupName].push(team);
    });

    // Sort age groups numerically
    const sortedGrouped = {};
    const sortedKeys = Object.keys(grouped).sort((a, b) => {
      // Extract numbers from the strings like "55 Older"
      const numA = parseInt(a);
      const numB = parseInt(b);
      return numA - numB;
    });

    sortedKeys.forEach((key) => {
      // Sort teams by team_name inside each group
      grouped[key].sort((a, b) => {
        const nameA = a.team.team_name.toLowerCase();
        const nameB = b.team.team_name.toLowerCase();
        return nameA.localeCompare(nameB);
      });
      sortedGrouped[key] = grouped[key];
    });

    return sortedGrouped;
  }
},
  methods: {
    onImageLoad() {
      this.imgLoader = 0;
    },
    openGameOptions() {
      this.gameOptions = !this.gameOptions;
    },
    onClickOutside() {
      this.gameOptions = false;
    },
    async getJoinTeams() {
      this.joinedTeams = [],
      this.showLoader = 1;
      try {
        const url = `${process.env.VUE_APP_API_URL}associationEvent/getJoinedTeam?page=${this.page}&event_id=${this.eventId}`;
        const response = await axios.get(url);
        if (response.data.statusCode === 200) {
          this.joinedTeams = response.data.data;
          
          this.showLoader = 0;
        } else {
          console.error(
            "Failed to fetch teams:",
            response.data.message || "Unknown error occurred"
          );
          this.showLoader = 0;
        }
      } catch (error) {
        this.showLoader = 0;
        console.error("Failed to fetch teams:", error);
      }
    },
    manageTeams() {
  this.$root.$emit("openManageTeamsModal");
}
  },
  mounted() {
    this.$root.$on("callJoinTeam", () => {
      this.getJoinTeams();
    });
  },
};
</script>

