<template>
  <div>
    <b-row v-if="associationinviteArray.length > 0">
      <b-col>
        <b-table
          :items="associationinviteArray"
          hover
          responsive
          class="mt-1 px-3 b-datatable cursor-pointer"
          :fields="fields"
        >
          <template v-slot:cell(name)="data">
            {{ data.item.association_name }}
          </template>
          <template v-slot:cell(email)="data">
            {{ data.item.target }}
          </template>
          <template v-slot:cell(action)="data">
            <div class="event-btns-wrapper p-1 justify-content-center">
              <button
                type="button"
                class="btn btn-primary accept-btn"
                @click="acceptInvite(data.item, data.index)"
                :disabled="isLoadingArray[data.item.id]"
              >
                <div v-if="isLoadingArray[data.item.id]" class="lds-dual-ring"></div>
                <span v-else>Accept</span>
              </button>
           </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <div v-else-if="showLoaderOnPage" class="p-3">
      <content-placeholders :rounded="true">
        <content-placeholders-img />
        <content-placeholders-heading />
      </content-placeholders>
      <content-placeholders class="mt-3" :rounded="true">
        <content-placeholders-img />
        <content-placeholders-heading />
      </content-placeholders>
    </div>
    <div class="shop-search" v-else>
        <div class="no-products-in-shop" >
          <img src="../../assets/images/team-grey.png" alt />
          <p class="header">No association invites found.</p>
          <p class="info">
            There are no invites to show in association invites.
          </p>
        </div>
      </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "JoinAssociation",
  props: {
    showLoaderOnPage: Boolean,
    associationinviteArray: {
      type: Array,
    },
  },
  data() {
    return {
      isLoadingArray: {}, // Use object instead of array
      showLoader: 0,
      fields: [
        { key: "name", label: "Association Name"},
        { key: "email", label: "Email",  class: "" },
        { key: "action", label: "Action", class: "text-center" },
      ],
    };
  },

  methods: {
    acceptInvite(invite) {
      this.$set(this.isLoadingArray, invite.id, true); // Show loading state

      axios
        .post(
          process.env.VUE_APP_API_URL + "association/acceptAssociationInvite",
          invite
        )
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.$root.$emit("fetchAssociationInvites");
            const successMessage =
              "You are now part of the " +
              invite.association_name +
              " association. You can access the association portal from the top right menu.";
            this.$alert(successMessage);
            this.$root.$emit("newAssociationAdded");
          } else if (response.data.statusCode === 403) {
            this.$root.$emit("fetchAssociationInvites");
            this.$alert("This invite is no longer valid or does not exist.");
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 403) {
            this.$alert("This invite is no longer valid or does not exist.");
          } else {
            this.$alert("An unexpected error occurred. Please try again.");
          }
        })
        .finally(() => {
          this.$set(this.isLoadingArray, invite.id, false); // Hide loading state
        });
    }



  },
};
</script>

<style scoped>
/* Add your component-specific styles here */
table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
}

th {
  background-color: #f2f2f2;
}

.lds-dual-ring {
    display: inline-block;
    cursor: not-allowed;
    position: absolute;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
}

.accept-btn{
    background: #188EF5;
    color: white;
    text-align: center;
    width: 78px;
}

.accept-btn span{
    font-size: 14px !important;
    font-weight: 400 !important;
}
</style>
