<template>
  <div>
    <Navbar />
    <div class="setting-main-wrapper">
      <div class="main-left-menu">
        <LeftMenu />
      </div>
      <Banner />
      <div class="shop-side p-md-0">
        <LeftMenuSide :openTabSet="openTabSet" />
        <div class="bg-white p-3 mb-3 rounded content-wrap-info">
          <AsociationSingleEvent />
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import Navbar from "../components/Header/Navbar.vue";
import Banner from "../components/BannerNotify/BannerPage.vue";
import LeftMenu from "../components/Home/LeftMenu.vue";
import AsociationSingleEvent from "../components/Association/AsociationSingleEvent.vue";
import LeftMenuSide from "@/components/NewGameTime/LeftMenuSide.vue";

export default {
  name: "AssociationSingleEventDetail",
  data() {
    return {
      openTabSet: 1,
    };
  },
  components: {
    Navbar,
    LeftMenu,
    Banner,
    AsociationSingleEvent,
    LeftMenuSide
  },
  methods: {},
  mounted() {
    this.$root.$on("openTabSetting", (id) => {
            this.openTabSet = id;
        });
  },
};
</script>