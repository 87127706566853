<template>
    <div>
      <Navbar />
      <div class="setting-main-wrapper">
        <div class="main-left-menu">
          <LeftMenu />
        </div>
        <Banner />
        <div class="shop-side pr-3 pb-3">
          <div class="row no-gutters">
            <div class="col-sm-12 col-md-7 col-lg-8 px-0">
              <div class="order-left-side">
                <CheckoutHeading />
                <div class="order-wrapper">
                  <div class="order-info">
                    <div class="order-heading">
                      <img src="../assets/images/card.png" alt />
                      <h6>How would you like to pay ?</h6>
                    </div>
                    <div class="order-form">
                      <div
                        class="account-wrapper-content my-0 px-0"
                        style="border-radius: 0; box-shadow: none"
                        id="accordionExample"
                      >
                        <div class="account-content-info pt-0 d-block">
                          <div
                            id="headingOne"
                            data-toggle="collapse"
                            data-target="#collapseOe"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            <div>
                              <h6 class="info-header mb-0">
                                <button class="btn pl-0 text-left" type="button">Add New Card</button>
                              </h6>
                            </div>
                          </div>
  
                          <div
                            id="collapseOne"
                            class="in"
                            aria-labelledby="headingOne"
                            data-parent="#accordionExample"
                          >
                            <div class="card-body p-0 d-flex flex-column">
                              <form @submit.prevent="handleSubmit" id="payment-form" class="m-0">
                                <div class="form-row">
                                  <div class="form-group col-12 col-md-6">
                                    <label for="first_name">First Name</label>
                                    <input
                                      name="cardholder-first-name"
                                      class="field input-box"
                                      placeholder="First Name"
                                      v-model="firstname"
                                      required
                                    />
                                  </div>
                                  <div class="form-group col-12 col-md-6">
                                    <label for="last_name">Last Name</label>
                                    <input
                                      name="cardholder-last-name"
                                      class="field input-box"
                                      placeholder="Last Name"
                                      v-model="lastname"
                                      required
                                    />
                                  </div>
                                </div>
                                <div class="form-row">
                                  <div class="form-group col-12 col-md-6">
                                    <label>Card Number</label>
                                    <div id="card-element" class="stripe-field"></div>
                                  </div>
                                  <div class="form-group col-12 col-md-3">
                                    <label>Expiration</label>
                                    <div id="card-expiry" class="stripe-field"></div>
                                  </div>
                                  <div class="form-group col-12 col-md-3">
                                    <label>CVC</label>
                                    <div id="card-cvc" class="stripe-field"></div>
                                  </div>
                                </div>
                                <div id="card-errors" role="alert" class="text-danger"></div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <PaymentMethod />
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-5 col-lg-4 px-0">
              <div class="order-item-history">
                <OrderItemHistory :shopConfigData="shopConfigData" />
                <button 
                id="paybutton" 
                type="submit" 
                class="order-btn px-4 py-2 font-semibold text-white rounded"
                @click="handleSubmit"
                :disabled="!isFormValid"
                :class="{ 'bg-gray-400 cursor-not-allowed': !isFormValid, 'bg-blue-500 hover:bg-blue-600': isFormValid }"
            >
                Place Order
            </button>


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  
  <script>
  import { mapState, mapGetters, mapActions } from "vuex";
  import Navbar from "../components/Header/Navbar.vue";
  import LeftMenu from "../components/Home/LeftMenu.vue";
  import CheckoutHeading from "../components/Shop/Order/CheckoutHeading.vue";
  import PaymentMethod from "../components/Shop/Payment/PaymentMethod.vue";
  import OrderItemHistory from "../components/Shop/Order/OrderItemHistory.vue";
  import Banner from "../components/BannerNotify/BannerPage.vue";
  import axios from "axios";
  import { loadStripe } from '@stripe/stripe-js';
  
  let stripePromise;
  const initializeStripe = () => {
    if (!stripePromise) {
      stripePromise = loadStripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);
    }
    return stripePromise;
  };
  
  export default {
    components: {
      Navbar,
      LeftMenu,
      CheckoutHeading,
      PaymentMethod,
      OrderItemHistory,
      Banner
    },
    data() {
      return {
        stripe: null,
        cardElement: null,
        cardExpiry: null,
        cardCvc: null,
        isChecked: false,
        processing: false,
        discountedPercentage: 0,
        paymentIntentClientSecret: null,
        firstname: "",
        lastname: "",
        unAvailable: false, // Track product availability
        isCardFormComplete: false, // Track Stripe card form completion
      };
    },
    computed: {
      ...mapState("cart", ["cart"]),
      ...mapGetters("cart", ["cartTotalPrice"]),
      ...mapGetters("auth", ["user"]),
      ...mapGetters("cart", ["cartItemCount"]),
      ...mapGetters("cart", ["shopConfigData"]),
      isFormValid() {
        // Check if all required fields are filled and the card form is complete
        const isValid =
          this.firstname &&
          this.lastname &&
          this.isCardFormComplete &&
          !this.unAvailable &&
          this.isChecked 
  
        console.log("isFormValid:", isValid); // Debugging
        return isValid;
      },
    },


    methods: {
      ...mapActions({
        clearCartItems: "cart/clearCartItems",
      }),

      async initializeStripeElements() {
        this.stripe = await initializeStripe();
        const elements = this.stripe.elements();

        this.cardElement = elements.create('cardNumber', {
          style: {
            base: {
              fontSize: '16px',
              color: '#32325d',
            },
          },
        });
        this.cardElement.mount('#card-element');

        this.cardExpiry = elements.create('cardExpiry', {
          style: {
            base: {
              fontSize: '16px',
              color: '#32325d',
            },
          },
        });
        this.cardExpiry.mount('#card-expiry');

        this.cardCvc = elements.create('cardCvc', {
          style: {
            base: {
              fontSize: '16px',
              color: '#32325d',
            },
          },
        });
        this.cardCvc.mount('#card-cvc');

        // Listen for changes in the card form
        this.cardElement.on('change', (event) => {
          const displayError = document.getElementById('card-errors');
          if (event.error) {
            displayError.textContent = event.error.message;
            this.isCardFormComplete = false;
          } else {
            displayError.textContent = '';
            this.isCardFormComplete = event.complete;
          }
          console.log("isCardFormComplete:", this.isCardFormComplete); // Debugging
        });
      },

      async checkProductAvailability() {
        const newCartCheck = this.cart.map(item => ({
          product_id: item.product.products.id,
          quantity: item.quantity,
          size: item.size?.description,
        }));

        try {
          const response = await axios.post("/shop/productAvailability", newCartCheck);
          if (response.data.statusCode === 200 && response.data.optional === 2) {
            this.unAvailable = true;
            this.flashMessage.error({
              title: "Error",
              message: response.data.message,
              time: 5000,
              blockClass: "custom-block-class",
            });
          } else {
            this.unAvailable = false;
          }
        } catch (error) {
          this.flashMessage.error({
            title: "Error",
            message: "Failed to check product availability",
            time: 5000,
          });
        } finally {
          console.log("unAvailable:", this.unAvailable); // Debugging
        }
      },

      async handleSubmit() {
    this.processing = true;
    console.log("Processing started"); // Debugging

    let loader = this.$loading.show({
      color: "#ffffff",
      backgroundColor: "#333",
      opacity: 0.6,
    });

    await this.checkProductAvailability();
    if (this.unAvailable) {
        console.log("Product unavailable, stopping payment"); // Debugging
        this.processing = false;
        loader.hide();
        return;
    }

    if (!this.paymentIntentClientSecret) {
        console.log("Client secret is missing, creating a new payment intent"); // Debugging
        await this.createPaymentIntent();
    }

    if (!this.paymentIntentClientSecret) {
        console.error("Client secret is still missing after attempting to create one");
        this.flashMessage.error({
            title: "Error",
            message: "Payment cannot proceed due to missing client secret",
            time: 5000,
        });
        this.processing = false;
        loader.hide();
        return;
    }

    const { paymentIntent, error } = await this.stripe.confirmCardPayment(
        this.paymentIntentClientSecret, {
        payment_method: {
            card: this.cardElement,
            billing_details: {
                name: `${this.firstname} ${this.lastname}`,
                address: {
                    line1: this.address_1,
                    city: this.address_city,
                    state: this.address_state,
                    postal_code: this.address_zip,
                    country: this.address_country,
                },
                email: this.user.email,
            },
        },
    });

    if (error) {
        console.error("Stripe Payment Error:", error);
        this.flashMessage.error({
            title: 'Payment failed',
            message: error.message,
            time: 5000,
        });
        this.processing = false;
        loader.hide();
    } else if (paymentIntent.status === 'succeeded') {
        await this.handleSuccessfulPayment(paymentIntent);
        loader.hide();
    }
},

            async handleSuccessfulPayment(paymentIntent) {
        try {
          const details = JSON.parse(localStorage.getItem("shipping"));
          const totalPayable = this.calculateTotalPayable();
          const shippingAmount = (this.cartTotalPrice / 100) * this.shopConfigData.shipping_cost;
          const taxAmount = (this.cartTotalPrice / 100) * this.shopConfigData.tax_percentage;
          const result = {
            id: paymentIntent.id,
            status: "PAID",
            cart: this.prepareCartData(),
            billingdata: details,
            total: this.cartTotalPrice.toFixed(2),
            tax_percentage: this.shopConfigData.tax_percentage,
            shipping_percentage: this.shopConfigData.shipping_cost,
            shipping_amount: isNaN(shippingAmount) ? "0.00" : shippingAmount.toFixed(2), // ✅ Prevent NaN
            grand_total: totalPayable.toFixed(2),
            no_of_products: this.cart.length,
            discounted_subtotal: ((this.cartTotalPrice / 100) * this.discountedPercentage).toFixed(2),
            discount: this.discountedPercentage,
            tax_amount: isNaN(taxAmount) ? "0.00" : taxAmount.toFixed(2), // ✅ Prevent NaN
            promo_id: this.promo?.id || null,
          };

          const response = await axios.post(process.env.VUE_APP_API_URL + "charge", result);
          if (response.data.statusCode === 200) {
            this.clearCartItems();
            this.flashMessage.success({
              title: "Success",
              message: "Payment successful!",
              time: 5000,
            });
            this.$router.push({ name: "Thanks" });
          }
        } catch (error) {
          this.flashMessage.error({
            title: "Error",
            message: error.response?.data?.message || 'Payment processing failed',
            time: 5000,
          });
        } finally {
          this.processing = false;
          console.log("Processing ended"); // Debugging
        }
      },

      calculateTotalPayable() {
        const cartTotalPrice = this.cartTotalPrice || 0;
        const discountedPercentage = this.discountedPercentage || 0;
        const taxPercentage = this.shopConfigData?.tax_percentage || 0;
        const shippingCost = this.shopConfigData?.shipping_cost || 0;

        return (
          cartTotalPrice -
          (cartTotalPrice / 100) * discountedPercentage +
          (cartTotalPrice / 100) * taxPercentage +
          (cartTotalPrice / 100) * shippingCost
        );
      },

      prepareCartData() {
        return this.cart.map(item => ({
          product_id: item.product.products.id,
          quantity: item.quantity,
          size: item.size?.description,
        }));
      },

      async createPaymentIntent() {
    const totalPayable = this.calculateTotalPayable();
    console.log("Total Payable:", totalPayable); // Debugging

    let loader = this.$loading.show({
      color: "#ffffff",
      backgroundColor: "#333",
      opacity: 0.6,
    });

    try {
        const response = await axios.post(process.env.VUE_APP_API_URL + 'createPaymentIntent', {
            amount: Math.round(totalPayable * 100),
            currency: 'usd',
        });

        console.log("Payment Intent Response:", response.data); // Debugging

        if (response.data && response.data.data.clientSecret) {
    this.paymentIntentClientSecret = response.data.data.clientSecret;
    console.log("Client Secret Set:", this.paymentIntentClientSecret);
} else {
    console.error("Client secret missing from API response", response.data);
}

    } catch (error) {
        console.error("Failed to create payment intent:", error);
        this.flashMessage.error({
            title: "Error",
            message: "Failed to create payment intent",
            time: 5000,
        });
    } finally {
        loader.hide();
    }
},

    },
    async mounted() {

      document.getElementById("gridCheck").addEventListener("change", (event) => {
    this.isChecked = event.target.checked;
  });
      if (this.cartItemCount <= 0) {
        this.$router.push({ name: "Shop" });
      }
  
      await this.initializeStripeElements();
      await this.createPaymentIntent();
      console.log("Final client secret after initialization:", this.paymentIntentClientSecret); // Debugging
  
      this.$root.$on("getDiscountFromPromo", (percentage, promo) => {
        this.discountedPercentage = percentage;
        this.promo = promo;
        this.createPaymentIntent();
      });
    },
  };
  </script>
  
  <style scoped>
  .stripe-field {
    padding: 10px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    background: white;
  }
  </style>