<template>
  <div>
    <Navbar />
    <div class="setting-main-wrapper">
      <div class="main-left-menu">
        <LeftMenu />
      </div>
      <Banner />
      <div class="shop-side p-0">
        <div class="row no-gutters">
          <div class="col-12 px-0">
            <div class="order-history single-box-score-loader" v-if="showLoader == 1">
              <div class="lds-dual-ring-media"></div>
            </div>
            <div class="single-box-score-left-side-page" v-if="showLoader == 0">
              <BoxScoreTop :singleGame="singleGame" :selectedRoomTeam="selectedRoomTeam" :scores="scores"/>
              <!-- <LeftSide /> -->
              <LeftSide :singleGame="singleGame" :showShimmer="showShimmer" :selectedRoomTeam="selectedRoomTeam"
                :currentIsAdmin="currentIsAdmin" :scores="scores" />

              <div class="card-event col-12 no-card-event" v-if="
                (showLoader == 0 && singleGame == 'null') ||
                singleGame == null
              ">
                <div class="img">
                  <img src="../assets/images/games.png" alt="" />
                </div>
                <p class="text">No game found.</p>
              </div>

              <!-- <div
                class="card-event col-12 no-card-event"
                v-if="showLoader == 0 && scores.length == 0"
              >
                <p class="no-event-header">No Home Team selected yet.</p>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/Header/Navbar.vue";
import LeftMenu from "../components/Home/LeftMenu.vue";
import Banner from "../components/BannerNotify/BannerPage.vue";
import BoxScoreTop from "../components/SingleBoxScore/BoxScoreTop.vue";
import LeftSide from "../components/SingleBoxScore/LeftSide.vue";
import * as firestoreService from "../database/firestore";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "singleBoxScore",
  components: {
    Navbar,
    LeftMenu,
    BoxScoreTop,
    LeftSide,
    Banner,
  },
  data() {
    return {
      singleGame: {},
      scores: [],
      showLoader: 1,
      showShimmer: 0,
      gameIdFromUrl: "",
      teamIdFromUrl: "",
      selectedRoomTeam: {},
      currentIsAdmin: "0",
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    ...mapActions("auth", ["getUserData"]),
    async fetchGameScore() {
      this.showLoader = 1;
      const formData = new FormData();
      formData.append("game_guid", this.gameIdFromUrl);

      await axios
        .post(`${process.env.VUE_APP_API_URL}game/getScores`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.singleGame = response.data.data.game;
            this.scores = response.data.data.game.game_scores;
          } else if (response.data.statusCode === 404) {
            this.$alert(response.data.message, "Error");
            this.$router.push({ name: "Teams" });
          } else {
            console.log(response.data.message);
          }
        })
        .catch((error) => {
          console.error("An unexpected error occurred:", error);
        })
        .finally(() => {
          this.showLoader = 0;
        });
    },

    async fetchGameScoreEmit() {
      this.showShimmer = 1;
      var formData = new FormData();
      formData.append("game_guid", this.gameIdFromUrl);
      await axios
        .post(process.env.VUE_APP_API_URL + "game/getScores", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.singleGame = response.data.data.game;
          this.scores = response.data.data.game.game_scores;
          this.showShimmer = 0;
        });
      this.$root.$on("scoresheetDeleted", (deletedGameId) => {

        if (deletedGameId === this.singleGame.id) {

          this.fetchGameScore();
        }
      });
    },

    extractTeamIdFromUrl() {
      var url = window.location.pathname;
      const lastSlashIndex = url.lastIndexOf("/");

      const firstDashIndex = url.indexOf("-", lastSlashIndex);

      if (
        lastSlashIndex !== -1 &&
        firstDashIndex !== -1 &&
        firstDashIndex > lastSlashIndex
      ) {
        this.teamIdFromUrl = url.substring(lastSlashIndex + 1, firstDashIndex);

        // Extract the substring after the first '-'
        this.gameIdFromUrl = url.substring(firstDashIndex + 1);
      } else {
        // If either '/' or '-' is not found, return appropriate values or handle it as needed
        return "";
      }
    },

    async checkTeamAndRoleOfAdmin() {
      var UserId = localStorage.getItem("userId");
      const promiseUser = await firestoreService.getUserCid(UserId.toString());
      if (promiseUser.data[0].id != undefined) {
        var currentUserId = promiseUser.data[0].id;
      }
      var teamId = this.teamIdFromUrl;
      const rawRoom = [];
      var teamDetail = "";

      // Get Team data
      const promise = await firestoreService.getRoomById(teamId);
      rawRoom.push(promise);

      const foundRoom = await Promise.all(rawRoom);
      Object.keys(foundRoom).forEach((key) => {
        teamDetail = foundRoom[key];
      });

      if (teamDetail.users != undefined) {
        const groupUsersRole = teamDetail.userRole;
        this.selectedRoomTeam = teamDetail;
        var admincount = 0;
        for (const userRole of groupUsersRole) {
          if (userRole.id == currentUserId) {
            if (userRole.admin == "1") {
              this.currentIsAdmin = "1";
            }
            if (userRole.admin == "0") {
              this.currentIsAdmin = "0";
            }
            admincount++;
          }
        }
        if (admincount > 0) {
          this.fetchGameScore();
        } else {
          this.$router.push({ name: "PageNotFound" });
        }
      } else {
        this.$router.push({ name: "PageNotFound" });
      }
    },
  },
  mounted() {
    this.extractTeamIdFromUrl();
    this.checkTeamAndRoleOfAdmin();
    this.$root.$on("showSingleBoxScore", () => {
      this.extractTeamIdFromUrl();
      this.fetchGameScoreEmit();
    });
    this.$root.$on("showSingleBoxScoreForGameSwitch", () => {
      this.extractTeamIdFromUrl();
      this.fetchGameScore();
    });
  },
};
</script>

<style></style>
