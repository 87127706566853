<template>
    <div>
      <h1>Hello World</h1>
    </div>
  </template>
  
  <script>
 import * as firestoreService from "@/database/firestore";
 import axios from "axios";
  export default {
    name: "ScriptFirebase",
    async mounted() {
      await this.fetchAllTeamsFromFirestore();
    },
    methods: {
      async fetchAllTeamsFromFirestore() {
        try {
          var UserId = localStorage.getItem("userId");
          const promiseUser = await firestoreService.getUserCid(UserId.toString());
          console.log(promiseUser)
            const roomsData = await firestoreService.getAllRooms();
           console.log(roomsData.data.length,"jddcf")
            if (Array.isArray(roomsData.data) && roomsData.data.length > 0) {
              const allTeamMembers = [];
              let totalUserRoles = 0;
  
              const userCache = new Map(); // Cache to store fetched users

          for (const room of roomsData.data) {
              if (Array.isArray(room.users) && room.users.length > 0 && Array.isArray(room.userRole)) {
                  totalUserRoles += room.userRole.length;
                  const rawUsers = [];

                  for (const userRole of room.userRole) {
                      if (!userRole || typeof userRole.id !== 'string' || userRole.id.trim().length === 0) {
                          console.warn(`Skipping invalid userRole ID in room ${room.id}:`, userRole);
                          console.warn(`Room details:`, room);
                          continue;
                      }

                      // Check if the user is already in the cache
                      if (userCache.has(userRole.id)) {
                          console.log(`Using cached data for user ${userRole.id}`);
                      } else {
                          try {
                              // Fetch user details and store in cache
                              const userPromise = firestoreService.getUser(userRole.id);
                              userCache.set(userRole.id, userPromise);
                          } catch (error) {
                              console.error(`Failed to fetch user ${userRole.id}:`, error);
                          }
                      }

                      // Push the promise from cache to be resolved later
                      rawUsers.push(userCache.get(userRole.id));
                  }

                  try {
                      const resolvedUsers = await Promise.all(rawUsers);

                      const teamMembers = resolvedUsers.map(member => {
                          const userRoleData = room.userRole.find(role => role.id === member.id);
                          console.log(userRoleData,"12345678")
                          if (!userRoleData) {
                              console.warn(`No role found for user ${member.id} in room ${room.id}`);
                              return null;
                          }
                          return {
                            team_id: room.id,
                            user_id: member.c_id,
                            user_id_firebase: userRoleData.id,
                            admin: userRoleData.admin,
                            fan: userRoleData.fan,
                            notification: userRoleData.notification,
                            status: userRoleData.status,
                            bat_size: userRoleData.bat_size,
                            hat_size: userRoleData.hat_size,
                            pant_size: userRoleData.pant_size,
                            shirt_size: userRoleData.shirt_size,
                            uniform: userRoleData.uniform,
                            bat_details: userRoleData.bat_details
                          };
                      }).filter(member => member !== null);

                      allTeamMembers.push(...teamMembers);
                      
                  } catch (error) {
                      console.error(`Error processing room ${room.id}:`, error);
                  }
              } else {
                  console.log(`Room skipped - missing users or roles. Room details:`, room);
              }
          }

              console.log("Final allTeamMembers:", allTeamMembers);
              console.log("Total userRole objects across all rooms:", totalUserRoles);
              await this.storeTeamMembers(allTeamMembers);
            }
         
        } catch (error) {
          console.error("Error fetching teams from Firestore:", error);
        }
      },

      async storeTeamMembers(teamMembers) {
    try {
        const response = await axios.post(
            process.env.VUE_APP_API_URL + "chat/storeNewAddedTeamMembers",
            {
            users: teamMembers // Send 'teamMembers' as 'users' key in the payload
        }
        );
        console.log("API Response:", response.data);
    } catch (error) {
        console.error("Error storing team members:", error);
    }
}

    }
  };
  </script>
  