<template>
  <div>
    <GoPayment />

    <!-- Loader while waiting for API response -->
    <div class="products-list-shim" v-if="showLoader">
      <content-placeholders :rounded="true">
        <content-placeholders-img />
        <content-placeholders-heading />
      </content-placeholders>
      <content-placeholders class="mt-3" :rounded="true">
        <content-placeholders-img />
        <content-placeholders-heading />
      </content-placeholders>
    </div>

    <!-- Stripe Pricing Table (only shown when API response is received) -->
    <stripe-pricing-table
      v-if="!showLoader"
      :pricing-table-id="$stripePricingTableId"
      :publishable-key="$stripePublishableKey"
      :customer-session-client-secret="customerSessionSecret"
    >
    </stripe-pricing-table>
  </div>
</template>

<script>
import GoPayment from "./GoPayment.vue";
import axios from "axios";

export default {
  name: "GoSubscribe",
  components: { GoPayment },
  data() {
    return {
      encryptedKey: "",
      decryptedEmail: "",
      customerId: "",
      customerSessionSecret: "",
      showLoader: true, // Initially show the loader
    };
  },
  computed: {
    sanitizedClientId() {
      return this.decryptedEmail
        ? this.decryptedEmail.replace(/[^a-zA-Z0-9-_ ]/g, "")
        : "default_user";
    },
  },
  async mounted() {
    await this.loadStripeScript();

    // Extract encrypted email and customerSessionSecret from URL
    const urlParams = this.getParamsFromURL();
    this.encryptedKey = urlParams.encryptedEmail;
    this.customerSessionSecret = urlParams.customerSessionSecret;

    if (this.encryptedKey) {
      const decryptedData = await this.fetchDecryptedEmail();
      if (decryptedData) {
        this.decryptedEmail = decryptedData.email;
        this.customerId = decryptedData.customer_id || "";
        
        // Only set customerSessionSecret if it was not found in the URL
        if (!this.customerSessionSecret) {
          this.customerSessionSecret = decryptedData.customer_session_secret || "";
        }
      }
    }

    this.showLoader = false; // Hide loader when API response is received
  },
  methods: {
    getParamsFromURL() {
      const pathParts = window.location.pathname.split("/");
      return {
        encryptedEmail: pathParts[pathParts.length - 2] || "",
        customerSessionSecret: pathParts[pathParts.length - 1] || "",
      };
    },
    async fetchDecryptedEmail() {
      try {
        const userId = localStorage.getItem("userId"); // Get userId from localStorage
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}decryptEmail`,
          { 
            encrypted_email: this.encryptedKey,
            user_id: userId // Include userId in the request payload
          },
          { headers: { "Content-Type": "application/json" } }
        );
        console.log("Response from decryptEmail:", response.data);

        return {
          email: response.data.email || "default_user",
          customer_id: response.data.customer_id || null,
          customer_session_secret: response.data.customer_session_secret || null,
        };
      } catch (error) {
        console.error("Error decrypting email:", error);
        return { email: "default_user", customer_id: null, customer_session_secret: null };
      }
    },
    async loadStripeScript() {
      if (!document.querySelector('script[src="https://js.stripe.com/v3/pricing-table.js"]')) {
        const script = document.createElement("script");
        script.src = "https://js.stripe.com/v3/pricing-table.js";
        script.async = true;
        script.onload = () => console.log("Stripe script loaded successfully");
        document.head.appendChild(script);
      }
    },
  },
};
</script>
