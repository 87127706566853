<template>
  <div class="filter-container">
    <!-- Year Dropdown -->
    <div class="filter-cotent">
      <div class="dropdown-wrapper" v-click-outside="() => closeDropdown('year')">
        <button class="dropdown-button" :class="{
          'has-value': selectedYear
        }" @click="toggleDropdown('year')">
          <span>{{ selectedYear }}</span>
          <img :src="arrow_down" alt="arrow-down" />
        </button>
        <div class="dropdown-menu-content" v-show="activeDropdown === 'year'">
          <div v-if="years && years?.length === 0" class="no-results">No results found</div>
          <div v-else>
            <div v-for="year in years" :key="year" class="dropdown-item" @click="selectYear(year)">
              <span> {{ year }} </span>
            </div>
          </div>
        </div>
      </div>

      <!-- Organization Dropdown (Multi-select) -->
      <div class="dropdown-wrapper" v-click-outside="() => closeDropdown('org')">
        <button class="dropdown-button" :class="{
          'has-value': selectedOrgs.length > 0
        }" @click="toggleDropdown('org')">
          <span>{{ selectedOrgs.length >= 1 ? selectedOrgs[0] : 'Association' }}</span>
          <div class="badge-wrapper" v-if="selectedOrgs.length > 1">
            <span class="counter-badge">+{{ selectedOrgs.length - 1 }}</span>
          </div>
          <img :src="arrow_down" alt="arrow-down" />
        </button>
        <div class="dropdown-menu-content" v-show="activeDropdown === 'org'">
          <div v-if="isLoadingAssociation == true" class="lds-dual-ring-refresh"></div>
          <div v-if="isLoadingAssociation == false">

            <div class="search-sec">
              <img :src="search_icon" alt="search-icon" class="search-icon" />
              <input type="text" v-model="searchQuery.association" placeholder="Search Association"
                class="search-input" />
            </div>
            <div class="dropdown-menu-content-inner">
              <div v-if="filteredAssociationOptions.length === 0 && isLoadingAssociation == false" class="no-results">No results found</div>
              <div v-else>
                <div v-for="org in filteredAssociationOptions" :key="org.id" class="dropdown-item"
                  :class="{ 'selected': selectedOrgs.includes(org.association_name) }" @click="toggleOrgSelection(org)">
                  <input type="checkbox" :checked="selectedOrgs.includes(org.association_name)"
                    class="checkbox circular-checkbox">
                  <span>{{ org.association_name }}</span>
                </div>
              </div>
            </div>
            <!-- Clear Selection Button -->
            <button class="clear-btn" @click.stop="clearSelection('org')"
              v-if="searchQuery.association || selectedOrgs.length > 0">
              Clear Selection
            </button>
          </div>
        </div>
      </div>

      <!-- Division Dropdown (Multi-select) -->
      <div class="dropdown-wrapper" v-click-outside="() => closeDropdown('division')">
        <button class="dropdown-button" :class="{
          'has-value': selectedDivisions.length > 0
        }" @click="toggleDropdown('division')">
          <span>{{ selectedDivisions.length >= 1 ? selectedEmitDivisions[0] : 'Team' }}</span>
          <div class="badge-wrapper" v-if="selectedDivisions.length > 1">
            <span class="counter-badge">+{{ selectedDivisions.length - 1 }}</span>
          </div>
          <img :src="arrow_down" alt="arrow-down" />
        </button>
        <div class="dropdown-menu-content" v-show="activeDropdown === 'division'">
          <div v-if="isLoadingTeams == true" class="lds-dual-ring-refresh"></div>
          <div v-if="isLoadingTeams == false">
            <div class="search-sec">
              <img :src="search_icon" alt="search-icon" class="search-icon" />
              <input type="text" v-model="searchQuery.division" placeholder="Search Team" class="search-input" />
            </div>
            <div class="dropdown-menu-content-inner">
              <div v-if="filteredTeamOptions.length === 0 && isLoadingTeams == false" class="no-results">No results found</div>
              <div v-else>
                <div v-for="division in filteredTeamOptions" :key="division.id" class="dropdown-item"
                  :class="{ 'selected': selectedDivisions.includes(division.id) }"
                  @click="toggleDivisionSelection(division)">
                  <input type="checkbox" :checked="selectedDivisions.includes(division.id)"
                    class="checkbox circular-checkbox">
                  <span>{{ division.teamName }}</span>
                </div>
              </div>
            </div>
            <!-- Clear Selection Button -->
            <button class="clear-btn" @click.stop="clearSelection('division')"
              v-if="searchQuery.division || selectedDivisions.length > 0">
              Clear Selection
            </button>
          </div>
        </div>
      </div>

      <!-- Type Dropdown -->
      <!-- Type Dropdown Template Fix -->
      <div class="dropdown-wrapper" v-click-outside="() => closeDropdown('type')">
        <button class="dropdown-button" :class="{ 'has-value': selectedType }" @click="toggleDropdown('type')">
          <!-- Changed to show eventType property -->
          <span>{{ selectedType || 'Type' }}</span>
          <img :src="arrow_down" alt="arrow-down" />
        </button>
        <!-- Changed v-show condition to 'type' -->
        <div class="dropdown-menu-content" v-show="activeDropdown === 'type'">
          <div v-if="isLoadingTypes == true" class="lds-dual-ring-refresh"></div>
          <div v-if="isLoadingTypes == false">
            <div v-if="types?.length === 0 && isLoadingTypes == false" class="no-results">No results found</div>
            <div v-else>
              <div v-for="type in types" :key="type.id" class="dropdown-item" @click="selectType(type)">
                <!-- Show eventType property -->
                <span>{{ type.eventType }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Additional Filter Buttons -->
      <button class="filter-button" :class="{ 'filter-button-active': eventStatus }" :disabled="isPastEventsDisabled"
        @click="togglePastEvents">Past Events</button>
      <!-- <button class="filter-button">Going</button> -->
      <!-- <button class="filter-button">All Filters</button> -->
    </div>
    <button class="reset-button" @click="resetMyEventsFilter" v-if="isResetVisible">Reset Filters</button>
  </div>
</template>


<script>
export default {
  name: 'MyEventsFilter',
  directives: {
    'click-outside': {
      bind(el, binding) {
        el.clickOutsideEvent = function (event) {
          if (!(el === event.target || el.contains(event.target))) {
            binding.value(event);
          }
        };
        document.addEventListener('click', el.clickOutsideEvent);
      },
      unbind(el) {
        document.removeEventListener('click', el.clickOutsideEvent);
      },
    },
  },
  data() {
    return {
      activeDropdown: null,
      selectedYear: '2025',
      selectedOrgs: [],
      selectedDivisions: [],
      selectedEmitDivisions: [],
      selectedType: '',
      eventStatus: false,
      years: this.eventYears,
      organizations: this.associationOptions,
      divisions: [
        'Dudley Lightening 65+',
        'Dudley Thunder 50+',
        'Dudley Storm 40+'
      ],
      types: this.eventTypes,
      arrow_down: require("../../../assets/Svg/arrow-down.svg"),
      isPastEventsDisabled: false,
      search_icon: require("../../../assets/Svg/Search.svg"),
      searchQuery: {
        association: '',
        division: '',
      }
    };
  },
  props: {
    eventTypes: {
      type: Array,
      default: () => []
    },
    eventYears: {
      type: Array,
      default: () => []
    },
    associationOptions: {
      type: Array

    },
    teamsArray: {
      type: Array
    },
    isLoadingTeams: {
      type: Boolean
    },
    isLoadingAssociation: {
      type: Boolean
    },
    isLoadingTypes: {
      type: Boolean
    }
  },
  computed: {
    filteredAssociationOptions() {
      return this.associationOptions.filter((org) =>
        org.association_name.toLowerCase().includes(this.searchQuery.association.toLowerCase())
      );
    },
    filteredTeamOptions() {
      return this.teamsArray.filter((division) =>
        division.teamName.toLowerCase().includes(this.searchQuery.division.toLowerCase())
      );
    },
    isResetVisible() {
      return (
        this.selectedOrgs.length > 0 ||
        this.selectedDivisions.length > 0 ||
        this.selectedType.length > 0 ||
        this.eventStatus
      );
    }
  },

  watch: {
    // Watcher for associationOptions
    associationOptions: {
      handler(newVal) {
        this.organizations = newVal;
      },

      deep: true, // Use this if you want to track changes within the array, e.g., object updates
      immediate: true, // Logs the initial value when the component is mounted
    },
    eventTypes: {
      handler(newVal) {
        this.types = newVal;
      },

      deep: true, // Use this if you want to track changes within the array, e.g., object updates
      immediate: true, // Logs the initial value when the component is mounted
    },
    eventYears: {
      handler(newVal) {
        this.years = newVal;
      },

      deep: true, // Use this if you want to track changes within the array, e.g., object updates
      immediate: true, // Logs the initial value when the component is mounted
    },
  },
  methods: {
    toggleDropdown(dropdownName) {
      this.activeDropdown = this.activeDropdown === dropdownName ? null : dropdownName;
    },
    closeDropdown(dropdownName) {
      if (this.activeDropdown === dropdownName) {
        this.activeDropdown = null;
      }
    },
    selectYear(year) {
      this.selectedYear = year;

      const currentYear = new Date().getFullYear();

      // If a past year is selected, keep eventStatus true and disable the button
      if (year < currentYear) {
        this.eventStatus = true;
        this.isPastEventsDisabled = true;
      } else {
        this.eventStatus = false;
        this.isPastEventsDisabled = false;
      }

      this.emitFilters();
      this.closeDropdown('year');
    },

    togglePastEvents() {
      if (this.isPastEventsDisabled) return; // Prevent toggling when disabled
      this.eventStatus = !this.eventStatus;
      this.emitFilters();
    },
    emitFilters() {
      this.$emit('filters-changed', {
        name: this.selectedDivisions,
        association: this.selectedOrgs,
        eventType: this.selectedType,
        eventStatus: this.eventStatus,
        year: this.selectedYear,
      });
    },
    toggleOrgSelection(org) {
      const orgName = org.association_name;
      const index = this.selectedOrgs.indexOf(orgName);
      if (index === -1) {
        this.selectedOrgs.push(org.association_name); // Store the whole object
      } else {
        this.selectedOrgs.splice(index, 1); // Remove by index
      }
      this.emitFilters();
      // this.closeDropdown('org');
    }
    ,
    toggleDivisionSelection(division) {
      const divisionName = division.id;
      const divisionNameS = division.teamName
      const index = this.selectedDivisions.indexOf(divisionName);
      if (index === -1) {
        this.selectedDivisions.push(divisionName); // Store the full object
        this.selectedEmitDivisions.push(divisionNameS); // Store the full object
      } else {
        this.selectedDivisions.splice(index, 1); // Remove by index
        this.selectedEmitDivisions.splice(index, 1); // Remove by index
      }
      this.emitFilters();
      // this.closeDropdown('division');
    },

    selectType(type) {
      this.selectedType = type.eventType;
      this.emitFilters();
      this.closeDropdown('type');
    },
    resetMyEventsFilter() {
      this.eventStatus = false;
      this.isPastEventsDisabled = false;
      this.selectedYear = '2025';
      this.selectedOrgs = [];
      this.selectedDivisions = [];
      this.selectedEmitDivisions = [];
      this.selectedType = '';
      this.emitFilters();  // ✅ Emit only if filters were reset
    },
    clearSelection(type) {
      if (type === 'org') {
        this.selectedOrgs = [];
        this.searchQuery.association = '';
      }
      else if (type === 'division') {
        this.selectedDivisions = [];
        this.searchQuery.division = '';
      }
      this.emitFilters();
    },

  },
};
</script>

<style scoped>
.filter-button {
  cursor: pointer;
  transition: all 0.1s ease;
}

.filter-button-active {
  background: #e5f2fe !important;
}
</style>