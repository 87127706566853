<template>
  <div>
    <div class="invalid" v-if="errorMessage">
      {{ errorMessage }}
    </div>
    <div v-if="posts.length">
      <div class="post-view" v-for="post in posts" :key="post.id">
        <div class="user-info" style="margin-bottom: 16px">
          <div class="user-img">
            <div class="users-notification" v-if="imgLoader == 1">
        <!-- <div class="lds-dual-ring-refresh"></div> -->
        <img src="../../assets/loader.gif" alt="">
    </div>
            <img
            @load="onImageLoad"
              v-if="post.propicture == 'yes'"
              :src="Storage_URL + post.user_id + '/profile_picture.jpg?v='+time"
              alt=""
            />
            <img @load="onImageLoad" v-else src="../../assets/images/user-default-new.png" alt="" />
          </div>
          <div class="user-details mr-auto">
            <span class="user-name ml-0"
              >{{ post.user_name}}</span
            >
            <p>{{ post.created_at | formatDate }}</p>
          </div>
          <!-- <PostReport
            :items="post"
            :openTab="1"
          ></PostReport> -->
        </div>
        <div class="post-content">
          <PostContent :items="post.caption"></PostContent>
          <div class="post-images">
            <div class="gallery-container">
              <lightbox :items="post.post_images" class="newPostC"></lightbox>
            </div>
          </div>
        </div>
        <PeopleComments :items="post" :type="type"/>
      </div>
    </div>

    <div v-if="noPost == 1" class="see-all-seen-post-wrapper mt-0">
      <p>No posts found.</p>
    </div>
    <content-placeholders
      class="post-view"
      :rounded="true"
      v-if="showLoader == 1"
    >
      <content-placeholders-heading :img="true" />
      <content-placeholders-text :lines="3" />
    </content-placeholders>
    <content-placeholders
      class="post-view"
      :rounded="true"
      v-if="showLoader == 1"
    >
      <content-placeholders-heading :img="true" />
      <content-placeholders-text :lines="3" />
    </content-placeholders>
    <content-placeholders
      class="post-view"
      :rounded="true"
      v-if="showLoader == 1"
    >
      <content-placeholders-heading :img="true" />
      <content-placeholders-text :lines="3" />
    </content-placeholders>
    <div
      v-if="posts.length"
      v-observe-visibility="handleScrolledToButtom"
    ></div>
  </div>
</template>
<script>
import axios from "axios";
import lightbox from "../ImageGrid/lightbox";
import "../ImageGrid/lightbox";
import "../ImageGrid/lightbox.css";
import PostContent from "../Posts/PostContent";
import PeopleComments from "../Posts/PeopleComments.vue";
// import PostReport from "../Posts/PostReport";

export default {
  name: "PostView",
  props: {
    userLink: {
      type: String,
    },
  },
  components: {
    lightbox,
    PostContent,
    PeopleComments,
    // PostReport
  },
  data() {
    return {
      imgLoader: 1,
      type:'otherProfile',
      time:this.$time,
      Storage_URL: this.$userProfileStorageURL,
      errorMessage: null,
      successMessage: null,
      posts: [],
      page: 1,
      noPost: 0,
      showLoader: 0,
      details: {
        post_id: "",
      },
      lastPage: 1,
      readMoreActivated: false,
      API_URL: this.$userProfileStorageURL,
      postOptions: false,
      lastSegment: "",
    };
  },
  methods: {
    onImageLoad() {
      this.imgLoader = 0;
    },
    async fetch() {
      this.showLoader = 1;
      let posts = await axios.get(`post/otherpostListing?page=${this.page}&userLink=${this.lastSegment}`);
      if (posts.data.statusCode == 200) {
        this.posts.push(...posts.data.data.listing.data);
        this.lastPage = posts.data.data.listing.last_page;
        this.noPost = posts.data.data.listing.data.length > 0 ? 0 : 1;
        this.showLoader = 0;
      } else {
        this.errorMessage = posts.data.message;
        this.showLoader = 0;
      }
    },
    handleScrolledToButtom(isVisible) {
      if (this.page >= this.lastPage) {
        return;
      }
      if (!isVisible) {
        return;
      }
      this.page++;
      this.fetch();
    },
    getuserLink() {
      var productId = window.location.pathname;
      var segment_array = productId.split("/");
      this.lastSegment = segment_array.pop();
      if (this.lastSegment !== "profileOther") {
        this.fetch();
      }
    },
  },
  mounted() {
    this.getuserLink();
    this.$root.$on("OtherProfilePost", () => {
      this.page = 1;
      this.lastPage = 1;
      this.posts = [];
      this.fetch(1);
    });
  },
};
</script>