<template>
  <div class="filter-container">
    <!-- Year Dropdown -->
    <div class="filter-cotent">
      <!-- Additional Filter Buttons -->
      <button
        class="filter-button"
        :class="{ 'filter-button-active': eventStatus }"
        @click="togglePastEvents"
      >
        Past Events
      </button>
    </div>
    <button class="reset-button" @click="resetMyEventsFilter" v-if="isResetVisible">
      Reset Filters
    </button>
  </div>
</template>

<script>
export default {
  name: "FollowingEventsFilter",
  data() {
    return {
      arrow_down: require("../../../assets/Svg/arrow-down.svg"),
      eventStatus: false,
    };
  },
  computed: {
    isResetVisible() {
      return this.eventStatus;
    },
  },
  methods: {
    togglePastEvents() {
      this.eventStatus = !this.eventStatus;
      this.emitFilters();
    },
    emitFilters() {
      this.$emit("follow-filter-changed", {
        eventStatus: this.eventStatus,
      });
    },
    resetMyEventsFilter() {
      this.eventStatus = false;
      this.emitFilters();
    },
  },
};
</script>
<style scoped>
.filter-button {
  cursor: pointer;
  transition: all 0.1s ease;
}

.filter-button-active {
  background: #e5f2fe!important;
}
</style>